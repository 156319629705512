//Base Url
// export const BASE_URL = "https://dev.my.ronove.io/api/v2";

// Login
export const LOGIN = "/login";

// Logout
export const LOGOUT = "/logout";

// Add Cleaning Type
export const ADD_CLEANING_TYPE = "/cleaning-type";

//Get Cleaning Type
export const GET_CLEANING_TYPE = "/cleaning-type-list";

//Get Active Cleaning Type
export const GET_ACTIVE_CLEANING_TYPE = "/active-cleaning-type-list";

//Update Cleaning Type
export const UPDATE_CLEANING_TYPE = "/cleaning-type";

//Delete Cleaning Type
export const DELETE_CLEANING_TYPE = "/cleaning-type";

// Add Property Type
export const ADD_PROPERTY_TYPE = "/property-type";

//Get Property Type
export const GET_PROPERTY_TYPE = "/property-type-list";

//Get Active Property Type
export const GET_ACTIVE_PROPERTY_TYPE = "/active-property-type-list";

//Update Property Type
export const UPDATE_PROPERTY_TYPE = "/property-type";

//Delete PropertyType
export const DELETE_PROPERTY_TYPE = "/property-type";

// Add Room Type
export const ADD_ROOM_TYPE = "/room-type";

//Get Room Type
export const GET_ROOM_TYPE = "/room-type-list";

//Get Active Room Type
export const GET_ACTIVE_ROOM_TYPE = "/active-room-type-list";

//Update Room Type
export const UPDATE_ROOM_TYPE = "/room-type";

//Delete Room Type
export const DELETE_ROOM_TYPE = "/room-type";

//Get Client
export const GET_CLIENT = "/client-list";

//Get Active Client
export const GET_ACTIVE_CLIENT = "/active-client-list";

//Add Client
export const ADD_CLIENT = "/client";

//Get Order Price
export const GET_ORDER_PRICE = "/order-price-list";

//Add Client
export const UPDATE_CLIENT = "/client";

//Get Default Order Price
export const GET_DEFAULT_ORDER_PRICE = "/default-price-list";

//Get Order Price by Client
export const GET_ORDER_PRICE_CLIENT = "/client-price-list";

//Add Order Price
export const ADD_ORDER_PRICE = "/order-price";

//Delete Order Price
export const DELETE_ORDER_PRICE = "/order-price";

//Update Order Price
export const UPDATE_Order_PRICE = "/order-price";

//Book Order
export const BOOK_ORDER = "/order";

//Get Checklist
export const GET_REJECTED_CHECKLIST = "/rejected-checklist-list";

//Add Order Checklist
export const ADD_REJECTED_CHECKLIST = "/rejected-checklist";

//Update Order Checklist
export const UPDATE_REJECTED_CHECKLIST = "/rejected-checklist";

//Delete Order Checklist
export const DELETE_REJECTED_CHECKLIST = "/rejected-checklist";

// Search reject checklist reasons
export const SEARCH_REJECTED_CHECKLIST = "/search-rejected-checklist";

//Get Order List
export const GET_ORDER_LIST = "/order-list";

//Get Client Order List
export const GET_CLIENT_ORDER_LIST = "/user-orders";

// Get Order Tasks
export const GET_ORDER_TASKS = "/order-task-list";

// Get Tasks by Booking
export const GET_ORDER_TASKS_WITH_BOOKING = "/order-task-by-booking";

// Get User Tasks
export const GET_USER_TASKS = "/user-order-task";

// Get Order Tasks by Group
export const GET_ORDER_GROUP_TASKS = "/order-task-by-group";

// Update Order Tasks Status
export const UPDATE_ORDER_TASK_STATUS = "/update-order-task-status";

// Update Order Tasks Priority
export const UPDATE_ORDER_TASK_PRIORITY = "/update-order-task-priority";

// Update Order Status
export const UPDATE_ORDER_STATUS = "/update-order-status";

// Update Order Priority
export const UPDATE_ORDER_PRIORITY = "/update-order-priority";

// Update Assign To
export const UPDATE_ASSIGN_TO = "/assign-order-task";

//Get Tax List
export const GET_TAX_LIST = "/tax-list";

//Get Active Tax List
export const GET_ACTIVE_TAX_LIST = "/tax-active-list";

// Add Tax
export const ADD_TAX = "/tax";

// Delete Tax
export const DELETE_TAX = "/tax";

// Update Tax
export const UPDATE_TAX = "/update-tax";

// Get User Address
export const GET_USER_ADDRESS = "/get-user-addresses";

// Get User Shifts
export const GET_USER_SHIFTS = "/user-shifts";

// Get User Staff Shifts
export const GET_USER_STAFF_SHIFTS = "/user-staff-shifts";

// Get Permission List
export const GET_PERMISSIONS = "/permission-list";

// Add New Invoice
export const ADD_INVOICE = "/invoice";

// Get Invoice List
export const GET_INVOICE_LIST = "/invoice-list";

// Get User Invoice List
export const GET_USER_INVOICE = "/user-invoice";

// Get Single Invoice
export const GET_INVOICE = "/invoice";

// Pay Invoice
export const PAY_INVOICE = "/invoice-pay";

// Get User Orders
export const GET_USER_ORDERS = "/user-orders";

// Update Invoice
export const UPDATE_INVOICE = "/invoice/update";

// Add Booking
export const ADD_BOOKING = "/booking";

// Get Booking
export const GET_BOOKING = "/booking-list";

// Get staff pending Booking
export const GET_PENDING_BOOKING = "/pending-staff-bookings";

// Get approved pending Booking
export const GET_APPROVED_BOOKING = "/approved-staff-bookings";

// Search Booking
export const SEARCH_BOOKING = "/search-bookings";

// Get Booking Staff
export const GET_BOOKING_STAFF = "/staff-bookings";

// Get User Booking
export const GET_EACH_USER_BOOKING = "/booking-users";

// Update staff booking status
export const UPDATE_STAFF_BOOKING_STATUS = "/update-staff-booking-status";

// Get Employees
export const GET_EMPLOYEES = "/employee-list";

// Get Active Employees
export const GET_ACTIVE_EMPLOYEES = "/active-employee-list";

// Get Employee Roles
export const GET_EMPLOYEE_ROLES = "/employee-roles";

// Add Employee
export const ADD_EMPLOYEE = "/employee";

// Delete Employee
export const DELETE_EMPLOYEE = "/employee";

// Update Employee
export const UPDATE_EMPLOYEE = "/employee";

//Subscribe Notification
export const SUBSCRIBE_NOTIFICATION = "/subscribe-notification";

// Get All Notifiction
export const GET_USER_NOTIFICATIONS = "/user-notifications";

// Marked Read Notifiction
export const MARK_READ_NOTIFICATIONS = "/markread-user-notification";

// Delete User Notifiction
export const DELETE_USER_NOTIFICATION = "/user-notification";

// Get User Notifications for admin to see
export const GET_USER_NOTIFICATION_ADMIN = "/view-user-notifications";

// Create Shift
export const CREATE_SHIFT = "/shifts";

//Get Payment Methods
export const GET_PAYMENT_METHODS = "/payment-method-list";

//Get Payment method Keys
export const GET_PAYMENT_METHODS_KEYS = "/payment-method/keys";

//Get Payment method Keys
export const PAYMENT_SUCCESS = "/invoice-paid-success";

// Get User Shift
export const GET_CURRENT_USER_SHIFTS = "/user-shifts";

// add User Shift
export const ADD_USER_SHIFTS = "/shift";

// Get User ClockIn
export const GET_CLOCKIN = "/clockin";

// Get User ClockOut
export const GET_CLOCKOUT = "/clockout";

// Get Groups
export const GET_GROUP = "/group-list";

// Create Group
export const ADD_GROUP = "/group";

// Update Group
export const UPDATE_GROUP = "/group/update";

// Delete Group
export const DELETE_GROUP = "/group";

// Get Group Members
export const GET_GROUP_MEMBERS = "/get-group-user";

//Assign User To Group
export const ASSIGN_USER_GROUP = "/assign-user-group";

// Update Password
export const UPDATE_PASSWORD = "/password-update";

// Update Avatar
export const UPDATE_AVATAR = "/user-avatar";

// Get Order Items
export const GET_ORDER_ITEM_LIST = "/order-item-list";

// Booking Clockin
export const BOOKING_CLOCKIN = "/clockin-booking";

// Booking ClockOut
export const BOOKING_CLOCKOUT = "/clockout-booking";

// Booking Last Clockin Detail
export const LAST_CLOCKIN_DETAIL = "/last-booking-clockin-detail";

// All Booking Clockin Details
export const ALL_CLOCKIN_DETAILS = "/all-booking-clockin-details";

// All Booking Clockin Details
export const SINGLE_CLOCKIN_CLOCKOUT_DETAILS = "/booking-clockin-details";

// Update Booking TimeSheet
export const UPDATE_BOOKING_TIMESHEET = "/update-booking-timesheet";

// Authenticate Booking TimeSheet
export const AUTHENTICATE_BOOKING_TIMESHEET = "/autheticate-booking-timesheet";

// disputed Booking TimeSheet
export const DISPUTED_BOOKING_TIMESHEET = "/disputed-booking-timesheet";

// Authenticate Booking TimeSheet
export const GET_USER_BOOKING_TIMESHEET = "/user-booking-timesheet";

// Authenticate Booking TimeSheet
export const GET_OWN_BOOKING_TIMESHEET = "/own-booking-timesheet";

// Client Booking TimeSheet
export const GET_CLIENT_BOOKING_TIMESHEET = "/client-booking-timesheet";

// Get Client Contacts
export const GET_CONTACTS = "/user-contact";

//Get leave types
export const GET_LEAVE_TYPES = "/leave-type-list";

// Leave request
export const LEAVE_REQUEST = "/leave-request";

// Folder Category
export const FOLDER_CATEGORY_LIST = "/folder-category-list";

// Store Folder Category
export const FOLDER_CATEGORY = "/folder-category";

//show category files
export const Show_Folder_Files = "/files-show";

//show folder content
export const Show_Folder_Content = "/folder-content-show";

// Store Folder
export const Add_Folder = "/folder";

// Upload Files
export const Upload_Files = "/upload-files";

// Delete File
export const Delete_File = "/delete-file";

// Delete Folder
export const Delete_Folder = "/delete-folder";
