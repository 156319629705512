import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  ImageBackground,
  Image,
  TouchableOpacity,
  StyleSheet,
  Platform,
} from "react-native";
import {
  DrawerContentScrollView,
  DrawerItemList,
  useDrawerStatus,
} from "@react-navigation/drawer";
import { ListItem, Icon, Avatar } from "react-native-elements";
import { NavigationActions, StackActions } from "@react-navigation/native";
import { CommonActions } from "@react-navigation/native";

import {
  Ionicons,
  AntDesign,
  MaterialIcons,
  Entypo,
  MaterialCommunityIcons,
  FontAwesome5,
} from "@expo/vector-icons";
import { Colors } from "../constants/style";

import jwt from "../helpers/jwt";
import * as Url from "../helpers/urlHelper";
import * as SecureStore from "expo-secure-store";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { ModulesPermission } from "../constants/modules";
import AuthorizedFor from "../constants/authorizedFor";
import * as Notifications from "expo-notifications";

const CustomDrawer = (props) => {
  const [permissions, setPermissions] = useState([]);
  const [userData, setUserData] = useState(global.userData);

  const handleLogOut = () => {
    jwt
      .get(Url.LOGOUT)
      .then(async (response) => {
        global.permissions = [];
        if (Platform.OS === "android" || Platform.OS === "ios") {
          await SecureStore.deleteItemAsync("userData");
          await SecureStore.deleteItemAsync("permissions");
          await SecureStore.deleteItemAsync("systemID");
          await AsyncStorage.removeItem("@previous_booking_time");
          await AsyncStorage.removeItem("@booking_time");
          await AsyncStorage.removeItem("@time_started");
          await AsyncStorage.removeItem("@previous_clock_time");
          await AsyncStorage.removeItem("@clock_time");
          await AsyncStorage.removeItem("@clock_time_started");
          await AsyncStorage.removeItem("current_clockin_bookingID");
          await AsyncStorage.removeItem("systems");
        } else {
          await AsyncStorage.removeItem("userData");
          await AsyncStorage.removeItem("permissions");
          await AsyncStorage.removeItem("systemID");
          await AsyncStorage.removeItem("@previous_booking_time");
          await AsyncStorage.removeItem("@booking_time");
          await AsyncStorage.removeItem("@time_started");
          await AsyncStorage.removeItem("@previous_clock_time");
          await AsyncStorage.removeItem("@clock_time");
          await AsyncStorage.removeItem("@clock_time_started");
          await AsyncStorage.removeItem("current_clockin_bookingID");
          await AsyncStorage.removeItem("systems");
        }
        props.navigation.navigate("Login");

        props.navigation.dispatch({
          ...CommonActions.reset({
            index: 0,
            routes: [{ name: "Dashboard" }],
          }),
        });
      })
      .catch((err) => {});
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const GetIntials = (nameString) => {
    const fullName = nameString.split(" ");
    const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
    return initials.toUpperCase();
  };

  return (
    <View style={{ flex: 1 }}>
      <DrawerContentScrollView
        {...props}
        contentContainerStyle={{ backgroundColor: Colors.primaryColor }}
      >
        <ImageBackground
          source={require("../assets/images/logo5.png")}
          style={{ padding: 20 }}
        >
          {/* <Avatar
            size={80}
            rounded
            // source={global.userData.avatar != null && { uri: global.userData.avatar }}
            title={GetIntials(
              `${global.userData.first_name} ${global.userData.last_name}`
            )}
            containerStyle={{ backgroundColor: "white", marginBottom: 10 }}
          ></Avatar> */}
          {global.userData &&
            (global.userData.avatar != undefined ||
            global.userData.avatar != null ? (
              <Image
                source={{ uri: global.userData.avatar }}
                style={{
                  height: 80,
                  width: 80,
                  borderRadius: 40,
                  marginBottom: 10,
                }}
              />
            ) : (
              <Text
                style={{
                  height: 80,
                  width: 80,
                  borderRadius: 40,
                  marginBottom: 10,
                  backgroundColor: "#fff",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: 44,
                  paddingLeft: 15,
                  fontWeight: "600",
                  color: "grey",
                  paddingTop: 10,
                }}
              >
                {GetIntials(global.userData.name)}
              </Text>
            ))}
          <Text
            style={{
              color: "#fff",
              fontSize: 18,
              //   fontFamily: "Roboto-Medium",
              marginBottom: 5,
            }}
          >
            {global.userData ? `${global.userData.name}` : "Hey There!"}
          </Text>
          {/* <View style={{ flexDirection: "row" }}>
            <Text
              style={{
                color: "#fff",
                marginRight: 5,
              }}
            >
              {global.userData
                ? capitalizeFirstLetter(
                    global.userData.role != null
                      ? global.userData.role
                      : "Not Desiginated"
                  )
                : "Admin"}
            </Text>
           
          </View> */}
        </ImageBackground>
        <View style={{ flex: 1, backgroundColor: "#fff", paddingTop: 10 }}>
          <CustomContent
            navigation={props.navigation}
            AuthorizedFor={AuthorizedFor}
          />
          {/* <DrawerItemList {...props} /> */}
        </View>
      </DrawerContentScrollView>
      <View style={{ padding: 20, borderTopWidth: 1, borderTopColor: "#ccc" }}>
        <TouchableOpacity
          onPress={() => handleLogOut()}
          style={{ paddingVertical: 8 }}
        >
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Ionicons name="exit-outline" size={22} />
            <Text
              style={{
                fontSize: 15,
                // fontFamily: "Roboto-Medium",
                marginLeft: 5,
              }}
            >
              Sign Out
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const CustomContent = ({ navigation, AuthorizedFor }) => {
  const [expanded, setExpanded] = useState(false);
  const [expandedBooking, setExpandedBooking] = useState(false);
  const [expandedSettings, setExpandedSettings] = useState(false);
  const [expandedTimesheet, setExpandedTimesheet] = useState(false);

  return (
    <View>
      <TouchableOpacity
        onPress={() => navigation.navigate("Dashboard")}
        style={{
          flexDirection: "row",
          marginLeft: "5%",
          marginBottom: 15,
          marginBottom: 5,
          marginTop: 3,
        }}
      >
        <MaterialIcons name="home" size={28} />
        <Text style={styles.menuTitle}>Dashboard</Text>
      </TouchableOpacity>

      <ListItem.Accordion
        content={
          <>
            <MaterialIcons name="description" size={28} />
            <ListItem.Content>
              <ListItem.Title style={{ marginLeft: 5 }}>
                Orders & Appraisals
              </ListItem.Title>
            </ListItem.Content>
          </>
        }
        isExpanded={expanded}
        onPress={() => {
          setExpanded(!expanded);
        }}
      >
        {/* {list2.map((l, i) => ( */}
        {AuthorizedFor(ModulesPermission.Create_Order) && (
          <ListItem
            onPress={() => {
              navigation.navigate("OrderForm");
            }}
            style={{ padding: 0, margin: 0, marginTop: -15 }}
          >
            <ListItem.Content>
              <ListItem.Title style={{ marginLeft: 30 }}>
                <AntDesign name="doubleright" size={14} color="black" />{" "}
                {"Generate Orders"}
              </ListItem.Title>
            </ListItem.Content>
          </ListItem>
        )}
        {AuthorizedFor(ModulesPermission.Get_All_Orders_In_System) && (
          <ListItem
            onPress={() => {
              navigation.navigate("Order");
            }}
            style={{ padding: 0, margin: 0, marginTop: -15 }}
          >
            <ListItem.Content>
              <ListItem.Title style={{ marginLeft: 30 }}>
                <AntDesign name="doubleright" size={14} color="black" />{" "}
                {"Orders"}
              </ListItem.Title>
            </ListItem.Content>
          </ListItem>
        )}
      </ListItem.Accordion>

      {AuthorizedFor(ModulesPermission.Get_All_Clients_Order) && (
        <TouchableOpacity
          onPress={() => navigation.navigate("ClientOrders")}
          style={styles.menuItem}
        >
          <Entypo name="list" size={28} />
          <Text style={styles.menuTitle}>My Orders</Text>
        </TouchableOpacity>
      )}

      {AuthorizedFor(ModulesPermission.Get_All_Order_Prices_In_System) && (
        <TouchableOpacity
          onPress={() => navigation.navigate("PriceList")}
          style={styles.menuItem}
        >
          <Entypo name="list" size={28} />
          <Text style={styles.menuTitle}>Order Prices</Text>
        </TouchableOpacity>
      )}

      {AuthorizedFor(ModulesPermission.Get_Order_Prices_For_Single_Client) && (
        <TouchableOpacity
          onPress={() => navigation.navigate("ClientPriceList")}
          style={styles.menuItem}
        >
          <Entypo name="list" size={28} />
          <Text style={styles.menuTitle}>My Pricelist</Text>
        </TouchableOpacity>
      )}

      <ListItem.Accordion
        style={{ marginTop: -12 }}
        content={
          <>
            <MaterialCommunityIcons name="clipboard-list-outline" size={28} />
            <ListItem.Content>
              <ListItem.Title style={{ marginLeft: 5 }}>
                Bookings
              </ListItem.Title>
            </ListItem.Content>
          </>
        }
        isExpanded={expandedBooking}
        onPress={() => {
          setExpandedBooking(!expandedBooking);
        }}
      >
        {/* {list2.map((l, i) => ( */}
        {AuthorizedFor(ModulesPermission.Create_Booking_Admin) && (
          <ListItem
            onPress={() => {
              navigation.navigate("CreateBooking");
            }}
            style={{ padding: 0, margin: 0, marginTop: -15 }}
          >
            <ListItem.Content>
              <ListItem.Title style={{ marginLeft: 30 }}>
                <AntDesign name="doubleright" size={14} color="black" />{" "}
                {"Generate Booking"}
              </ListItem.Title>
            </ListItem.Content>
          </ListItem>
        )}
        {AuthorizedFor(ModulesPermission.Get_All_Bookings_By_System) && (
          <ListItem
            onPress={() => {
              navigation.navigate("Bookings");
            }}
            style={{ padding: 0, margin: 0, marginTop: -15 }}
          >
            <ListItem.Content>
              <ListItem.Title style={{ marginLeft: 30 }}>
                <AntDesign name="doubleright" size={14} color="black" />{" "}
                {"Bookings"}
              </ListItem.Title>
            </ListItem.Content>
          </ListItem>
        )}
      </ListItem.Accordion>

      {AuthorizedFor(ModulesPermission.Search_Bookings_for_Client) && (
        <TouchableOpacity
          onPress={() => navigation.navigate("SearchBookings")}
          style={styles.menuItem}
        >
          <Ionicons name="search" size={28} />
          <Text style={styles.menuTitle}>Search Bookings</Text>
        </TouchableOpacity>
      )}

      {AuthorizedFor(ModulesPermission.Get_All_Clients) && (
        <TouchableOpacity
          onPress={() => navigation.navigate("Clients")}
          style={styles.menuItem}
        >
          <Entypo name="user" size={28} />
          <Text style={styles.menuTitle}>Clients</Text>
        </TouchableOpacity>
      )}

      {AuthorizedFor(ModulesPermission.Get_All_Employees) && (
        <TouchableOpacity
          onPress={() => navigation.navigate("Employees")}
          style={styles.menuItem}
        >
          <Entypo name="user" size={28} />
          <Text style={styles.menuTitle}>All Employees</Text>
        </TouchableOpacity>
      )}

      {AuthorizedFor(ModulesPermission.Get_All_Invoices_In_System) && (
        <TouchableOpacity
          onPress={() => navigation.navigate("Invoices")}
          style={styles.menuItem}
        >
          <FontAwesome5 name="file-invoice" size={28} />
          <Text
            style={{
              marginTop: 5,
              marginLeft: 8,
              fontSize: 16,
              paddingLeft: 5,
            }}
          >
            Invoices
          </Text>
        </TouchableOpacity>
      )}

      {AuthorizedFor(ModulesPermission.Get_All_User_Invoices) && (
        <TouchableOpacity
          onPress={() => navigation.navigate("UserInvoices")}
          style={{ flexDirection: "row", marginLeft: "5%", marginBottom: 15 }}
        >
          <FontAwesome5 name="file-invoice" size={28} />
          <Text
            style={{
              marginTop: 5,
              marginLeft: 8,
              fontSize: 16,
              paddingLeft: 5,
            }}
          >
            My Invoices
          </Text>
        </TouchableOpacity>
      )}

      {AuthorizedFor(ModulesPermission.Filter_Bookings_By_Date) && (
        <TouchableOpacity
          onPress={() => navigation.navigate("Calendar")}
          style={styles.menuItem}
        >
          <Entypo name="calendar" size={28} />
          <Text style={styles.menuTitle}>Calendar</Text>
        </TouchableOpacity>
      )}

      {AuthorizedFor(ModulesPermission.Get_All_Order_Tasks_By_User_ID) && (
        <TouchableOpacity
          onPress={() => navigation.navigate("UserTasks")}
          style={styles.menuItem}
        >
          <Entypo name="list" size={28} />
          <Text style={styles.menuTitle}>Tasks</Text>
        </TouchableOpacity>
      )}


{AuthorizedFor(ModulesPermission.Get_Folder_Category_List) && (
        <TouchableOpacity
          onPress={() => navigation.navigate("FolderCategory")}
          style={styles.menuItem}
        >
          <Entypo name="list" size={28} />
          <Text style={styles.menuTitle}>File Manger</Text>
        </TouchableOpacity>
      )}

      <ListItem.Accordion
        content={
          <>
            <MaterialIcons name="description" size={28} />
            <ListItem.Content>
              <ListItem.Title style={{ marginLeft: 5 }}>
                Timesheets
              </ListItem.Title>
            </ListItem.Content>
          </>
        }
        isExpanded={expandedTimesheet}
        onPress={() => {
          setExpandedTimesheet(!expandedTimesheet);
        }}
        style={{ marginTop: -10 }}
      >
        {AuthorizedFor(ModulesPermission.Authenticate_Booking_Timesheet) && (
          <ListItem
            onPress={() => {
              navigation.navigate("CreateTimesheet");
            }}
            style={{ padding: 0, margin: 0, marginTop: -15 }}
          >
            <ListItem.Content>
              <ListItem.Title style={{ marginLeft: 30 }}>
                <AntDesign name="doubleright" size={14} color="black" />{" "}
                {"Create Timesheet"}
              </ListItem.Title>
            </ListItem.Content>
          </ListItem>
        )}

        {AuthorizedFor(ModulesPermission.Get_Own_Booking_Timesheet) && (
          <ListItem
            onPress={() => {
              navigation.navigate("OwnBookingTimesheet");
            }}
            style={{ padding: 0, margin: 0, marginTop: -15 }}
          >
            <ListItem.Content>
              <ListItem.Title style={{ marginLeft: 30 }}>
                <AntDesign name="doubleright" size={14} color="black" />{" "}
                {"Booking Timesheet"}
              </ListItem.Title>
            </ListItem.Content>
          </ListItem>
        )}

        {AuthorizedFor(ModulesPermission.Get_User_Booking_Timesheet) && (
          <ListItem
            onPress={() => {
              navigation.navigate("UserBookingTimesheet");
            }}
            style={{ padding: 0, margin: 0, marginTop: -15 }}
          >
            <ListItem.Content>
              <ListItem.Title style={{ marginLeft: 30 }}>
                <AntDesign name="doubleright" size={14} color="black" />{" "}
                {"Booking Timesheet"}
              </ListItem.Title>
            </ListItem.Content>
          </ListItem>
        )}

        {AuthorizedFor(ModulesPermission.Get_Client_booking_timesheet) && (
          <ListItem
            onPress={() => {
              navigation.navigate("ClientBookingTimesheet");
            }}
            style={{ padding: 0, margin: 0, marginTop: -15 }}
          >
            <ListItem.Content>
              <ListItem.Title style={{ marginLeft: 30 }}>
                <AntDesign name="doubleright" size={14} color="black" />{" "}
                {"Booking Timesheet"}
              </ListItem.Title>
            </ListItem.Content>
          </ListItem>
        )}
      </ListItem.Accordion>

      <ListItem.Accordion
        content={
          <>
            <MaterialIcons name="settings" size={28} />
            <ListItem.Content>
              <ListItem.Title style={{ marginLeft: 5 }}>
                Settings
              </ListItem.Title>
            </ListItem.Content>
          </>
        }
        isExpanded={expandedSettings}
        onPress={() => {
          setExpandedSettings(!expandedSettings);
        }}
        style={{ marginTop: -12 }}
      >
        {AuthorizedFor(ModulesPermission.Get_All_Cleaning_Types_In_System) && (
          <ListItem
            onPress={() => {
              navigation.navigate("CleanType");
            }}
            style={{ padding: 0, margin: 0, marginTop: -15 }}
          >
            <ListItem.Content>
              <ListItem.Title style={{ marginLeft: 30 }}>
                <AntDesign name="doubleright" size={14} color="black" />{" "}
                {"Cleaning Type"}
              </ListItem.Title>
            </ListItem.Content>
          </ListItem>
        )}
        {AuthorizedFor(ModulesPermission.Get_All_Property_Types_In_System) && (
          <ListItem
            onPress={() => {
              navigation.navigate("PropertyType");
            }}
            style={{ padding: 0, margin: 0, marginTop: -15 }}
          >
            <ListItem.Content>
              <ListItem.Title style={{ marginLeft: 30 }}>
                <AntDesign name="doubleright" size={14} color="black" />{" "}
                {"Property Type"}
              </ListItem.Title>
            </ListItem.Content>
          </ListItem>
        )}

        {AuthorizedFor(ModulesPermission.Get_All_Room_Types_In_System) && (
          <ListItem
            onPress={() => {
              navigation.navigate("RoomType");
            }}
            style={{ padding: 0, margin: 0, marginTop: -15 }}
          >
            <ListItem.Content>
              <ListItem.Title style={{ marginLeft: 30 }}>
                <AntDesign name="doubleright" size={14} color="black" />{" "}
                {"Room Type"}
              </ListItem.Title>
            </ListItem.Content>
          </ListItem>
        )}

        {AuthorizedFor(
          ModulesPermission.Get_All_Rejected_List_Items_In_System
        ) && (
          <ListItem
            onPress={() => {
              navigation.navigate("RejectedCheckList");
            }}
            style={{ padding: 0, margin: 0, marginTop: -15 }}
          >
            <ListItem.Content>
              <ListItem.Title style={{ marginLeft: 30 }}>
                <AntDesign name="doubleright" size={14} color="black" />{" "}
                {"Rejected Checklist"}
              </ListItem.Title>
            </ListItem.Content>
          </ListItem>
        )}
        {AuthorizedFor(ModulesPermission.Get_All_Tax_Types_In_System) && (
          <ListItem
            onPress={() => {
              navigation.navigate("Tax");
            }}
            style={{ padding: 0, margin: 0, marginTop: -15 }}
          >
            <ListItem.Content>
              <ListItem.Title style={{ marginLeft: 30 }}>
                <AntDesign name="doubleright" size={14} color="black" />{" "}
                {"Taxes"}
              </ListItem.Title>
            </ListItem.Content>
          </ListItem>
        )}
        {AuthorizedFor(ModulesPermission.Get_User_Shifts_By_ID) && (
          <ListItem
            onPress={() => {
              navigation.navigate("Shifts");
            }}
            style={{ padding: 0, margin: 0, marginTop: -15 }}
          >
            <ListItem.Content>
              <ListItem.Title style={{ marginLeft: 30 }}>
                <AntDesign name="doubleright" size={14} color="black" />{" "}
                {"Shifts"}
              </ListItem.Title>
            </ListItem.Content>
          </ListItem>
        )}

        {AuthorizedFor(ModulesPermission.Get_All_Groups) && (
          <ListItem
            onPress={() => {
              navigation.navigate("Groups");
            }}
            style={{ padding: 0, margin: 0, marginTop: -15 }}
          >
            <ListItem.Content>
              <ListItem.Title style={{ marginLeft: 30 }}>
                <AntDesign name="doubleright" size={14} color="black" />{" "}
                {"Groups"}
              </ListItem.Title>
            </ListItem.Content>
          </ListItem>
        )}

        {AuthorizedFor(ModulesPermission.Change_System) && (
          <ListItem
            onPress={() => {
              navigation.navigate("Systems");
            }}
            style={{ padding: 0, margin: 0, marginTop: -15 }}
          >
            <ListItem.Content>
              <ListItem.Title style={{ marginLeft: 30 }}>
                <AntDesign name="doubleright" size={14} color="black" />{" "}
                {"Systems"}
              </ListItem.Title>
            </ListItem.Content>
          </ListItem>
        )}

        {AuthorizedFor(ModulesPermission.Update_User_Password_By_ID) && (
          <ListItem
            onPress={() => {
              navigation.navigate("Password");
            }}
            style={{ padding: 0, margin: 0, marginTop: -15 }}
          >
            <ListItem.Content>
              <ListItem.Title style={{ marginLeft: 30 }}>
                <AntDesign name="doubleright" size={14} color="black" />{" "}
                {"Change Password"}
              </ListItem.Title>
            </ListItem.Content>
          </ListItem>
        )}

        {AuthorizedFor(ModulesPermission.Create_Leave_Request) && (
          <ListItem
            onPress={() => {
              navigation.navigate("LeaveRequest");
            }}
            style={{ padding: 0, margin: 0, marginTop: -15 }}
          >
            <ListItem.Content>
              <ListItem.Title style={{ marginLeft: 30 }}>
                <AntDesign name="doubleright" size={14} color="black" />{" "}
                {"Create Leave Request"}
              </ListItem.Title>
            </ListItem.Content>
          </ListItem>
        )}
      </ListItem.Accordion>
    </View>
  );
};

const styles = StyleSheet.create({
  menuItem: {
    flexDirection: "row",
    marginLeft: "5%",
    marginBottom: 15,
  },
  menuTitle: { marginTop: 5, marginLeft: 8, fontSize: 16 },
});

export default CustomDrawer;
