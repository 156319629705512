import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  FlatList,
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  Button,
  SpeedDial,
  ListItem,
  Overlay,
  Dialog,
  SearchBar,
} from "react-native-elements";
import { Feather, Ionicons, Entypo, MaterialIcons } from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import moment from "moment";
import Loading from "../Utils/Loading";
import UpdateInvoice from "./UpdateInvoice";

const UserInvoices = (navigation) => {
  const [invoiceData, setInvoiceData] = useState([]);
  const [invoiceDataInitial, setInvoiceDataInitial] = useState([]);
  const [clientsData, setClientsData] = useState([]);
  const [clientsList, setClientsList] = useState([]);
  const [ordersData, setOrdersData] = useState([]);
  const [ordersList, setOrdersList] = useState([]);
  const [userId, setUserId] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [userAddress, setUserAddress] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [cleaningData, setCleaningData] = useState([]);
  const [openDial, setOpenDial] = useState(false);
  const [isLoding, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible1, setIsVisible1] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [deleteShow, setDeleteShow] = useState(false);

  const [taxsData, setTaxsData] = useState([]);
  const [taxsList, setTaxsList] = useState([]);
  const [selectedTax, setSelectedTax] = useState(null);

  const [date, setDate] = useState(new Date());
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Its Error");
  const [search, setSearch] = useState("");

  useEffect(() => {
    (async () => {
      GetData();
      GetClients();
      GetTaxes();
    })();
  }, []);

  const GetData = () => {
    setIsLoading(true);

    jwt
      .get(`${Url.GET_USER_INVOICE}/${global.userData.id}`)
      .then((response) => {
        setIsLoading(false);
        setInvoiceData(response.data.invoices);
        setInvoiceDataInitial(response.data.invoices);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetClients = () => {
    setIsLoading(true);

    jwt
      .get(`${Url.GET_ACTIVE_CLIENT}/${global.systemID}`)
      .then((response) => {
        const newClients = [];
        setIsLoading(false);
        setClientsList(response.data.clients);
        response.data.clients.map((item) => newClients.push(item.organization_name));
        setClientsData(newClients);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetClientOrders = (id) => {
    setIsLoading(true);

    jwt
      .get(`${Url.GET_USER_ORDERS}/${id}`)
      .then((response) => {
        const newOrders = [];
        setIsLoading(false);
        setOrdersData(response.data.orders);
        response.data.orders.map((item) => newOrders.push(item.name));
        setOrdersList(newOrders);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetTaxes = () => {
    jwt
      .get(`${Url.GET_ACTIVE_TAX_LIST}/${global.systemID}`)
      .then((response) => {
        setTaxsData(response.data.taxs);
        const newArr = [];
        response.data.taxs.map((item) => newArr.push(item.name));
        setTaxsList(newArr);
      })
      .catch((err) => {});
  };

  const handleSelectedClient = (index) => {
    setUserId(clientsList[index].user_id);
    GetClientOrders(clientsList[index].user_id);
    GetUserAddress(clientsList[index].user_id);
    // GetUserShifts(clientsList[index].user_id);
  };

  const handleSelectedOrder = (index) => {
    setSelectedOrder(ordersData[index].id);
  };

  const handleSelectedAddress = (index) => {
    setSelectedAddress(userAddress[index].id);
  };

  const handleSelectedTax = (index) => {
    setSelectedTax(taxsData[index].id);
  };

  const onChangeInvoiceDate = (event, selectedDate) => {
    const currentDate = selectedDate;
    setShow1(false);
    setInvoiceDate(currentDate);
  };

  const onChangeDueDate = (event, selectedDate) => {
    const currentDate = selectedDate;
    setShow2(false);
    setDueDate(currentDate);
  };

  const GetUserAddress = (id) => {
    setIsLoading(true);
    jwt
      .get(`${Url.GET_USER_ADDRESS}/${id}`)
      .then((response) => {
        setIsLoading(false);

        setUserAddress(response.data.user_addresses);
        const List = [];
        response.data.user_addresses.map((item) => List.push(item.address));
        setAddressList(List);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = useForm({});

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  const onSubmit = (data) => {
    const newData = {
      user_id: userId,
      order_id: selectedOrder,
      address_id: selectedAddress,
      tax_id: selectedTax,
      invoice_date: invoiceDate,
      due_date: dueDate,
      sub_total: data.subTotal,
      grand_total: data.grandTotal,
      notes: data.notes,
      invoice_item: data.items,
    };

    if (userId === null) {
      setErrorMessage("Please Select Client");
      setShow(true);
    } else if (selectedOrder === null) {
      setErrorMessage("Please Select Order");
      setShow(true);
    } else if (selectedAddress === null) {
      setErrorMessage("Please Select Address");
      setShow(true);
    } else if (selectedTax === null) {
      setErrorMessage("Please Select Vat");
      setShow(true);
    } else if (invoiceDate === null) {
      setErrorMessage("Please Select Invoice Date");
      setShow(true);
    } else if (dueDate === null) {
      setErrorMessage("Please Select Due Date");
      setShow(true);
    } else if (data.items.length === 0) {
      setErrorMessage("Please Add Items");
      setShow(true);
    } else {
      setIsLoading(true);
      jwt
        .post(`${Url.ADD_INVOICE}/${global.systemID}`, newData)
        .then((response) => {
          setIsLoading(false);
          setIsVisible(!isVisible);
          setOpenDial(!openDial);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          Invoices
        </Text>
        <Ionicons
          name="menu-outline"
          size={30}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 20.0,
            // fontWeight: 300
          }}
          onPress={() => navigation.navigation.toggleDrawer()}
        />
      </View>
    );
  };

  const handleDelete = (id) => {
    jwt
      .del(`${Url.DELETE_ROOM_TYPE}/${id}`)
      .then((response) => {
        setDeleteShow(!deleteShow);
        GetData();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const renderItem = ({ item, index }) => (
    <TouchableOpacity onPress={() => {
      navigation.navigation.navigate("InvoiceDetails", { item: item });
    }} key={index} >
<ListItem bottomDivider >
 <ListItem.Content>
   <ListItem.Title>
     <Text style={styles.lable}>ID:</Text> {item.id}
   </ListItem.Title>
   <ListItem.Subtitle style={{marginTop:10}}>
      {item.invoice_date}
   </ListItem.Subtitle>
 </ListItem.Content>
 <ListItem.Content right>
   <ListItem.Title right>
     
       {item.invoice_type}
     
   </ListItem.Title>
   <ListItem.Subtitle right style={{marginTop:10}}>
      {item.due_date}
   </ListItem.Subtitle>
 </ListItem.Content>

 <ListItem.Chevron />
</ListItem>
</TouchableOpacity>
  );

  const handleSearch = (value) => {
    setSearch(value);
    if (value.length > 1) {
      const newData = invoiceData.filter(
        (e) =>
          e.invoice_id.toUpperCase().includes(value.toUpperCase()) ||
          e.invoice_date.toUpperCase().includes(value.toUpperCase()) ||
          e.due_date.toUpperCase().includes(value.toUpperCase()) ||
          e.invoice_type.toUpperCase().includes(value.toUpperCase())
      );
      if (newData.length > 0) {
        setInvoiceData(newData);
      } else {
        setInvoiceData(invoiceDataInitial);
      }
    } else {
      setInvoiceData(invoiceDataInitial);
    }
  };

  return (
    <View style={styles.container}>
      {header()}
      <SearchBar
        placeholder="Type Here..."
        onChangeText={handleSearch}
        value={search}
        lightTheme
        containerStyle={{
          backgroundColor: "#eee",
          borderWidth: 0,
          elevation: 0,
          paddingLeft: Sizes.fixPadding * 2,
          paddingRight: Sizes.fixPadding * 2,
        }}
        // placeholderTextColor="#000"
        inputStyle={{
          color: "#000",
        }}
        inputContainerStyle={{
          backgroundColor: "#ccc",
        }}
      />
      <View style={{ flex: 1, backgroundColor:"#F2F3F4", padding: Sizes.fixPadding * 2, paddingTop: 0 }}>
        {/* <ScrollView style={{ flex: 1 }}> */}
        <FlatList
          showsVerticalScrollIndicator={false}
          nestedScrollEnabled
          data={invoiceData}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
        />
      </View>
      {isLoding && <Loading />}

      <Dialog isVisible={deleteShow}>
        <Dialog.Title title="Delete Type!" />
        <Text>Are you sure ? this action will not be revert.</Text>

        <Dialog.Actions>
          <Dialog.Button
            title="CONFIRM"
            onPress={() => {
              handleDelete(selectedItem.id);
            }}
          />
          <Dialog.Button
            title="CANCEL"
            onPress={() => {
              setDeleteShow(!deleteShow);
            }}
          />
        </Dialog.Actions>
      </Dialog>

      <Dialog isVisible={show}>
        <Dialog.Title title="Error!" />
        <Text>{errorMessage}</Text>

        <Dialog.Actions>
          <Dialog.Button
            title="Ok"
            onPress={() => {
              setShow(!show);
            }}
          />
        </Dialog.Actions>
      </Dialog>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: Colors.primaryColor,
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 15,
    marginTop: 40,
  },
  contentContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 10,
  },
  lable: {
    fontWeight: "700",
  },
  dropdown2BtnStyle: {
    width: "100%",
    height: 40,
    backgroundColor: "#FFF",
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#CCCCCC",
    marginBottom: 15,
  },
  dropdown2BtnTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 15,
  },
  dropdown2DropdownStyle: { backgroundColor: "#ddd" },
  dropdown2RowStyle: { backgroundColor: "#ddd", borderBottomColor: "#C5C5C5" },
  dropdown2RowTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 16,
  },
});
export default UserInvoices;
