import React, { useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  FlatList,
  Alert,
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  Button,
  Overlay,
  ListItem,
  SpeedDial,
  SearchBar,
} from "react-native-elements";

import {
  Ionicons,
  Entypo,
  MaterialIcons,
  MaterialCommunityIcons,
  FontAwesome,
  Octicons,
} from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import { useForm, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import SelectDropdown from "react-native-select-dropdown";
import Loading from "../Utils/Loading";
// import MultiSelect from "react-native-multiple-select";
import SectionedMultiSelect from "react-native-sectioned-multi-select";
import AuthorizedFor from "../../constants/authorizedFor";
import { authorizedForFlateList } from "../../constants/authorizedForFlateList";
import { ModulesPermission } from "../../constants/modules";
import AsyncStorage from "@react-native-async-storage/async-storage";

const UserTasks = (navigation) => {
  const [tasksData, setTasksData] = useState([]);
  const [tasksDataInitial, setTasksDataInitial] = useState([]);
  const [reasonsData, setReasonsData] = useState([]);
  const [openDial, setOpenDial] = useState(false);
  const [isLoding, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [search, setSearch] = useState("");
  const [isAllowStatus, setIsAllowStatus] = useState(false);
  const [isAllowPriority, setIsAllowPriority] = useState(false);
  const [statusData, setStautsData] = useState([
    "Accepted",
    "Done",
    "Rejected",
  ]);

  const ref = useRef(null);

  const filterStatus = [
    "All",
    "Pending",
    "Accepted",
    "Do Not Clean",
    "Done",
    "Rejected",
  ];

  const priorityData = ["Do Not Clean", "High", "Low", "Medium", "Urgent"];

  useEffect(() => {
    (async () => {
      const current_bookingID = await AsyncStorage.getItem(
        "current_clockin_bookingID"
      );
      // console.log(current_bookingID)
      GetTasks(current_bookingID);
      GetReasons();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      (await authorizedForFlateList(
        ModulesPermission.Update_Order_Task_Status
      )) && setIsAllowStatus(true);
      (await authorizedForFlateList(
        ModulesPermission.Update_Order_Task_Priority
      )) && setIsAllowPriority(true);
      (await authorizedForFlateList(
        ModulesPermission.Update_Order_Task_Status_For_Staff
      )) && (setIsAllowStatus(true), setStautsData(["Done"]));
    })();
  }, [tasksDataInitial.length]);

  const GetTasks = (current_bookingID) => {
    setIsLoading(true);

    jwt
      .get(`${Url.GET_USER_TASKS}/${global.userData.employee_id}`)
      .then((response) => {
        setIsLoading(false);
        // console.log(response);
        const newArray = [];
        response.data.order_task
          .filter((item) => item.booking_id === current_bookingID)
          .map((obj) => newArray.push(obj));

        setTasksData(newArray);
        setTasksDataInitial(newArray);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetReasons = () => {
    setIsLoading(true);
    jwt
      .get(`${Url.GET_REJECTED_CHECKLIST}/${global.systemID}`)
      .then((response) => {
        const newData = [];
        response.data.rejected_checklist.map((item) => {
          newData.push({
            id: item.id,
            name: item.reason,
          });
        });
        setReasonsData(newData);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleStatus = (selectedItem, id) => {
    if (selectedItem === "Rejected") {
      setSelectedId(id);
      setIsVisible2(true);
    } else {
      setIsLoading(true);

      jwt
        .post(`${Url.UPDATE_ORDER_TASK_STATUS}/${id}`, {
          status: selectedItem,
        })
        .then((response) => {
          setIsLoading(false);
          Alert.alert("Status Updated Successfully!");
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const onSelectedItemsChange = (selectedItems) => {
    // this.setState({ selectedItems });
    setSelectedItems(selectedItems);
  };

  const updateRejected = () => {
    setIsLoading(true);

    const reasonList = [];

    selectedItems.map((item) =>
      reasonsData
        .filter((obj) => obj.id === item)
        .map((res) => {
          reasonList.push({
            checklist_id: res.id,
            reason: res.name,
          });
        })
    );

    jwt
      .post(`${Url.UPDATE_ORDER_TASK_STATUS}/${selectedId}`, {
        status: "Rejected",
        rejected_checklist: reasonList,
      })
      .then((response) => {
        setIsLoading(false);
        setIsVisible2(false);
        Alert.alert("Status Updated Successfully!");
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleSearch = (value) => {
    setSearch(value);

    const newData = tasksDataInitial.filter(
      (e) => e.booking_id === parseInt(value) || e.order_id === parseInt(value)
    );
    if (newData.length > 0) {
      setTasksData(newData);
    } else {
      setTasksData(tasksDataInitial);
    }
  };

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          User Tasks
        </Text>
        <MaterialIcons
          name="arrow-back"
          size={26}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 15.0,
            fontWeight: 300,
          }}
          onPress={() => navigation.navigation.pop()}
        />
      </View>
    );
  };

  const handlePriority = (selectedItem, id) => {
    setIsLoading(true);

    jwt
      .post(`${Url.UPDATE_ORDER_TASK_PRIORITY}/${id}`, {
        priority: selectedItem,
      })
      .then((response) => {
        setIsLoading(false);
        Alert.alert("Priority Updated Successfully!");
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleFilter = (selectedItem) => {
    if (selectedItem === "All") {
      setTasksData(tasksDataInitial);
    } else {
      setTasksData(
        tasksDataInitial.filter((item) => item.status === selectedItem)
      );
    }
    setIsVisible(!isVisible);
    setOpenDial(!openDial);
  };

  const renderItem = ({ item, index }) => {
    return (
      <View
        key={index}
        style={{
          backgroundColor: "#fff",
          borderRadius: 6,
          padding: 15,
          marginTop: 15,
          flexDirection: "row",
          width: "100%",
        }}
      >
        <View style={{ width: "100%" }}>
          <View
            style={{
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <Text style={{ color: "#555" }}>Room Type:</Text>
            <Text>{item.room_type}</Text>
          </View>
          {isAllowStatus ? (
            item.status != "Done" ||
            item.status != "Completed" ||
            item.status != "Accepted" ? (
              <View style={{ flexDirection: "row" }}>
                <Text style={{ width: 55, marginTop: 10 }}>Status</Text>
                <SelectDropdown
                  data={statusData}
                  defaultValue={item.status}
                  // defaultValueByIndex={1}
                  onSelect={(selectedItem, index) => {
                    handleStatus(selectedItem, item.id);
                  }}
                  defaultButtonText={"Select Status"}
                  buttonTextAfterSelection={(selectedItem, index) => {
                    return selectedItem;
                  }}
                  rowTextForSelection={(item, index) => {
                    return item;
                  }}
                  buttonStyle={styles.dropdown2BtnStyle}
                  buttonTextStyle={styles.dropdown2BtnTxtStyle}
                  renderDropdownIcon={(isOpened) => {
                    return (
                      <Entypo
                        name={
                          isOpened ? "chevron-thin-up" : "chevron-thin-down"
                        }
                        color={"#444"}
                        size={14}
                      />
                    );
                  }}
                  dropdownIconPosition={"right"}
                  dropdownStyle={styles.dropdown2DropdownStyle}
                  rowStyle={styles.dropdown2RowStyle}
                  rowTextStyle={styles.dropdown2RowTxtStyle}
                />
              </View>
            ) : (
              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                  marginBottom: 10,
                }}
              >
                <Text style={{ color: "#555" }}>Status:</Text>
                <Text>{item.status}</Text>
              </View>
            )
          ) : (
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                marginBottom: 10,
              }}
            >
              <Text style={{ color: "#555" }}>Status:</Text>
              <Text>{item.status}</Text>
            </View>
          )}
          {isAllowPriority ? (
            <View style={{ flexDirection: "row" }}>
              <Text style={{ width: 55, marginTop: 10 }}>Priority</Text>
              <SelectDropdown
                data={priorityData}
                defaultValue={item.priority}
                // defaultValueByIndex={1}
                onSelect={(selectedItem, index) => {
                  handlePriority(selectedItem, item.id);
                }}
                defaultButtonText={"Select Priority"}
                buttonTextAfterSelection={(selectedItem, index) => {
                  return selectedItem;
                }}
                rowTextForSelection={(item, index) => {
                  return item;
                }}
                buttonStyle={styles.dropdown2BtnStyle}
                buttonTextStyle={styles.dropdown2BtnTxtStyle}
                renderDropdownIcon={(isOpened) => {
                  return (
                    <Entypo
                      name={isOpened ? "chevron-thin-up" : "chevron-thin-down"}
                      color={"#444"}
                      size={14}
                    />
                  );
                }}
                dropdownIconPosition={"right"}
                dropdownStyle={styles.dropdown2DropdownStyle}
                rowStyle={styles.dropdown2RowStyle}
                rowTextStyle={styles.dropdown2RowTxtStyle}
              />
            </View>
          ) : (
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                marginBottom: 10,
              }}
            >
              <Text style={{ color: "#555" }}>Priority:</Text>
              <Text>{item.priority}</Text>
            </View>
          )}

          <View
            style={{
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          ></View>
        </View>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      {header()}
      <SearchBar
        placeholder="Search by BookingID or OrderID..."
        onChangeText={handleSearch}
        value={search}
        lightTheme
        containerStyle={{
          backgroundColor: "#eee",
          borderWidth: 0,
          elevation: 0,
          paddingLeft: Sizes.fixPadding * 2,
          paddingRight: Sizes.fixPadding * 2,
        }}
        // placeholderTextColor="#000"
        inputStyle={{
          color: "#000",
        }}
        inputContainerStyle={{
          backgroundColor: "#ccc",
        }}
      />
      <View style={{ flex: 1, backgroundColor:"#F2F3F4" ,padding: Sizes.fixPadding * 2, paddingTop: 0 }}>
        {/* <ScrollView style={{ flex: 1 }}> */}
        {tasksData.length > 0 ? (
          <FlatList
            showsVerticalScrollIndicator={false}
            nestedScrollEnabled
            data={tasksData}
            renderItem={renderItem}
            keyExtractor={(item) => item.id}
          />
        ) : (
          <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            <View style={styles.noItemIconWrapStyle}>
              <MaterialCommunityIcons
                name="record-circle-outline"
                size={40}
                color="black"
              />
            </View>
            <Text style={{ fontSize: 18, marginTop: Sizes.fixPadding * 1.0 }}>
              Tasks list is empty!
            </Text>
          </View>
        )}
      </View>

      {isLoding && <Loading />}
      <SpeedDial
        isOpen={openDial}
        icon={{ name: "search", color: "#fff" }}
        openIcon={{ name: "close", color: "#fff" }}
        onOpen={() => setOpenDial(!openDial)}
        onClose={() => setOpenDial(!openDial)}
        style={{ zIndex: 2 }}
        buttonStyle={{ backgroundColor: Colors.primaryColor }}
      >
        <SpeedDial.Action
          icon={{ name: "list", color: "#fff" }}
          title="Filter by Status"
          onPress={() => setIsVisible(!isVisible)}
          buttonStyle={{ backgroundColor: Colors.primaryColor }}
        />
      </SpeedDial>
      <Overlay
        visible={isVisible}
        overlayStyle={{
          width: "80%",
          backgroundColor: "#FFFFFF",
          borderRadius: 8,
          // paddingTop: 15,
          // paddingBottom: 20,
          padding: Sizes.fixPadding * 2,
        }}
        onBackdropPress={() => setIsVisible(!isVisible)}
      >
        <SelectDropdown
          data={filterStatus}
          // defaultValueByIndex={1}
          onSelect={(selectedItem, index) => {
            handleFilter(selectedItem, index);
          }}
          defaultButtonText={"Select Status"}
          buttonTextAfterSelection={(selectedItem, index) => {
            return selectedItem;
          }}
          rowTextForSelection={(item, index) => {
            return item;
          }}
          buttonStyle={{
            ...styles.dropdown2BtnStyle,
            width: "100%",
            paddingBottom: 0,
            marginBottom: 0,
          }}
          buttonTextStyle={styles.dropdown2BtnTxtStyle}
          renderDropdownIcon={(isOpened) => {
            return (
              <Entypo
                name={isOpened ? "chevron-thin-up" : "chevron-thin-down"}
                color={"#444"}
                size={14}
              />
            );
          }}
          dropdownIconPosition={"right"}
          dropdownStyle={styles.dropdown2DropdownStyle}
          rowStyle={styles.dropdown2RowStyle}
          rowTextStyle={styles.dropdown2RowTxtStyle}
        />
      </Overlay>

      <Overlay
        visible={isVisible2}
        overlayStyle={{
          width: "80%",
          backgroundColor: "#FFFFFF",
          borderRadius: 8,
          paddingTop: 15,
          paddingBottom: 20,
          padding: Sizes.fixPadding * 2,
        }}
        onBackdropPress={() => setIsVisible2(!isVisible2)}
      >
        <SectionedMultiSelect
          items={reasonsData}
          ref={ref}
          IconRenderer={MaterialIcons}
          uniqueKey="id"
          subKey="children"
          selectText="Select Reason..."
          showDropDowns={true}
          //   readOnlyHeadings={true}
          onSelectedItemsChange={onSelectedItemsChange}
          selectedItems={selectedItems}
          searchPlaceholderText="Search reasons"
          //   text={Colors.primaryColor}
          styles={{
            button: {
              backgroundColor: Colors.primaryColor,
              selectedItemText: Colors.primaryColor,
            },
          }}
          theme={{
            Colors: {
              selectToggleTextColor: "#053075",
              text: "#053075",
              selectedItemText: Colors.primaryColor,
            },
          }}
          //   buttonStyle={{ backgroundColor: Colors.primaryColor }}
        />

        <Button
          title="Updated Status"
          type="solid"
          disabled={selectedItems.length > 0 ? false : true}
          loading={isLoding}
          onPress={() => updateRejected()}
          buttonStyle={{
            padding: Sizes.fixPadding,
            width: "100%",
            backgroundColor: Colors.primaryColor,
            borderRadius: 22,
          }}
          containerStyle={{
            width: "100%",
            // marginHorizontal: 50,
            marginVertical: 20,
          }}
          titleStyle={{
            fontSize: 14,
            fontWeight: "100",
            color: Colors.whiteColor,
          }}
          icon={
            <Ionicons
              name="add-circle-sharp"
              size={24}
              color="#ffffff"
              style={{ marginRight: "2%" }}
            />
          }
        />
      </Overlay>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor:Colors.primaryColor
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom:15,
    marginTop:40
  },
  dropdown2BtnStyle: {
    width: "80%",
    height: 40,
    backgroundColor: "#FFF",
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#CCCCCC",
    marginBottom: 15,
  },
  dropdown2BtnTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 15,
  },
  dropdown2DropdownStyle: { backgroundColor: "#ddd" },
  dropdown2RowStyle: { backgroundColor: "#ddd", borderBottomColor: "#C5C5C5" },
  dropdown2RowTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 16,
  },
});
export default UserTasks;
