import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  StatusBar,
  Image,
  TextInput,
  TouchableOpacity,
  Alert,
  FlatList,
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import { Button, SpeedDial, ListItem, Overlay } from "react-native-elements";
import { SafeAreaProvider } from "react-native-safe-area-context";
import {
  Fontisto,
  Ionicons,
  Entypo,
  FontAwesome5,
  MaterialIcons,
} from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import { useForm, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import SelectDropdown from "react-native-select-dropdown";
import SectionedMultiSelect from "react-native-sectioned-multi-select";
import Loading from "../Utils/Loading";
import moment from "moment";
const statusData = ["Pending", "Accepted", "Rejected"];
const dayItems = [
  {
    id: "1",
    name: "Monday",
  },
  {
    id: "2",
    name: "Tuesday",
  },
  {
    id: "3",
    name: "Wednesday",
  },
  {
    id: "4",
    name: "Thursday",
  },
  {
    id: "5",
    name: "Friday",
  },
  {
    id: "6",
    name: "Saturday",
  },
  {
    id: "7",
    name: "Sunday",
  },
];

const UpdateBooking = (navigation) => {
  const bookingDetails = navigation.route.params.bookingDetails;

  const [isLoding, setIsLoading] = useState(false);
  const [weeks, setWeeks] = useState([]);
  const [weeksData, setWeeksData] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [days, setDays] = useState([]);
  const [count, setCount] = useState(0);

  const [selectedShift, setSelectedShift] = useState([]);
  const [userShifts, setUserShifts] = useState([]);
  const [shiftsList, setShiftsList] = useState([]);

  const [selectedStaff, setSelectedStaff] = useState([]);
  const [staff, setStaff] = useState([]);
  const [staffList, setStaffList] = useState([]);

  const [newShifts123, setnewShifts123] = useState([]);

  let shifts = new Array();

  useEffect(() => {
    GetUserShifts(bookingDetails.bookingItem.user_ref_id);
    // GetUserStaff();
    GetDays();
    GetShifts();
    GetSelectedStaff();
  }, []);

  useEffect(() => {
    // console.log("called")
    // console.log(count)
    if (count === bookingDetails.bookingData.length) {
      setIsLoading(false);
    }
  }, [count]);

  const GetUserShifts = (id) => {
    jwt
      .post(Url.GET_USER_SHIFTS, { user_id: id })
      .then((response) => {
        setUserShifts(response.data.user_shifts);
        const Shifts = [];
        response.data.user_shifts.map((item) =>
          Shifts.push(`${item.day} ${item.start_time} - ${item.end_time}`)
        );
        setShiftsList(Shifts);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetDays = () => {
    const newday = [];

    bookingDetails.bookingData.map((obj) => {
      newday.push(obj.day);
    });

    setSelectedDays(newday);
  };

  const GetSelectedStaff = () => {
    const newStaff = [];

    // console.log(Array.isArray(newStaff));

    bookingDetails.bookingData.map((obj) => {
      const newStaf = [];
      obj.booking_staff.map((staffobj, index) =>
        newStaf.push({ id: index, name: staffobj.full_name })
      );
      newStaff.push(newStaf);
    });

    setSelectedStaff(newStaff);
  };

  /**
   *
   * HERE
   */
  const GetShifts = async () => {
    const newShifts = [];
    const newstaff = [];
    const newstaffList = new Array();
    let availableStaff = new Array();
    bookingDetails.bookingData.map((obj, index) => {
      newShifts.push([
        `${obj.shift.day} ${obj.shift.start_time} - ${obj.shift.end_time}`,
      ]);

      // newstaffList.push({
      //   availableStaff: new Array(),
      //   currentStaff: new Array(),
      //   generalStaff: new Array(),
      // });

      let shift = {
        day: obj.shift.day,
        id: obj.shift.id,
      };
      // newstaff.push(shift);

      const currentStaff = [];
      const generalStaff = [];

      getShifts(shift, bookingDetails.bookingItem.booking_date).then(
        (response) => {
          const newArr = new Array();
          response.data.user_staff_shifts.available.forEach((staff) => {
            let newStaff = {
              id: staff.staff_data.id + `${index}`,
              name: staff.staff_data.name,
            };
            newArr.push(newStaff);
            // console.log(index);

            // availableStaff.splice(index, 0, newStaff);
          });

          response.data.user_staff_shifts.current_available.forEach((staff) => {
            let newStaff = {
              id: staff.staff_data.id + `${index}`,
              name: staff.staff_data.name,
            };
            newArr.push(newStaff);
          });

          response.data.user_staff_shifts.general.forEach((staff) => {
            let newStaff = {
              id: staff.staff_data.id + `${index}`,
              name: staff.staff_data.name,
            };
            newArr.push(newStaff);
          });

          updateState(newArr, availableStaff).then((s) => {
            // console.log(`inside then`);
            // console.log(JSON.stringify(availableStaff));
          });
          //
        }
      );

      newstaffList.push(availableStaff);
      // [{newStaff: [{}]}
      //       [
      //        1 [{}, {}],
      //        2 [{}, {}],
      //       ];

      // console.log(`inside list`)
      // console.log(JSON.stringify(availableStaff));
      // newstaffList.push([...availableStaff, ...currentStaff, ...generalStaff]);
    });
    // newstaffList.push(availableStaff);
    // newstaffList.push(availableStaff);
    // console.log(availableStaff); //WILL LOG
    // console.log(currentStaff); //EMPTY
    // console.log(`outside list`)
    setStaffList(availableStaff);

    // console.log(newShifts);

    setSelectedShift(newShifts);

    // setStaffList(newstaffList);
  };
  // console.log(Array.isArray(staffList));
  // console.log(Array.isArray(staffList[0]));
  // console.log(typeof staffList);
  // console.log(staffList);
  // console.log(staffList.length);
  const updateState = async (childArray, parantArray) => {
    return parantArray.push(childArray);
  };

  const getShifts = async (shiftDay, bookDate) => {
    return jwt.post(Url.GET_USER_STAFF_SHIFTS, {
      system_id: global.systemID,
      day: shiftDay.day,
      shift_id: shiftDay.id,
      booking_date: `${moment(bookDate).format("YYYY-MM-DD")}`,
    });
  };

  // console.log(staffList);
  // const GetData = (i) => {
  //   jwt
  //     .post(`${Url.GET_EACH_USER_BOOKING}/${bookingItem.id}`, {
  //       week_no: "" + i,
  //     })
  //     .then((response) => {
  //       if (response.data.user_bookings.length > 0) {
  //         const data = response.data.user_bookings;

  //         setWeeksData([...weeksData, ...data]);

  //         setSelectedDays(
  //           ...selectedDays,
  //           data.map((item, index) => item.day)
  //         );

  //         setDays(
  //           ...days,
  //           data.map((item, index) => ({ booking_day: item.day }))
  //         );

  //         const shiftPrev = [...selectedShift];

  //         const newShift = data.map(
  //           (item, index) =>
  //             `${item.shift.day} ${item.shift.start_time} - ${item.shift.end_time}`
  //         );
  //         console.log(newShift, i);

  //         shiftPrev[i] = newShift;

  //         setSelectedShift(...shiftPrev);

  //         // setShifts(
  //         //   ...shifts,
  //         //   data.map(
  //         //     (item, index) =>
  //         //       `${item.shift.day} ${item.shift.start_time} - ${item.shift.end_time}`
  //         //   )
  //         // );
  //       }
  //       setCount(parseInt(i));
  //     });
  // };

  // const GetUserShifts = (id) => {
  //   jwt
  //     .post(Url.GET_USER_SHIFTS, { user_id: id })
  //     .then((response) => {
  //       setUserShifts(response.data.user_shifts);
  //       const Shifts = [];
  //       response.data.user_shifts.map((item) =>
  //         Shifts.push(`${item.day} ${item.start_time} - ${item.end_time}`)
  //       );
  //       setShiftsList(Shifts);
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //     });
  // };

  // const onSelectedDaysChange = (selectedItems) => {
  //   setSelectedDays(selectedItems);
  //   selectedItems.map((item) => {
  //     const listLeng = weeksData.filter((obj) => obj.day === item).length;
  //     if (listLeng === 0) {
  //       setWeeksData([...weeksData, { day: item }]);
  //     } else {
  //       const newData = [...weeksData];
  //       const indexNumber = weeksData.findIndex((obj) => obj.day === item);

  //     }
  //   });

  //   const newDays = [];
  //   selectedItems.map((item) => newDays.push({ booking_day: item }));
  //   setDays(newDays);
  // };

  const renderItem = ({ item, index }) => (
    <View
      style={{
        marginTop: 5,
        zIndex: 1,
        borderRadius: 6,
        backgroundColor: "#FFFFFF",
        padding: Sizes.fixPadding * 2,
        marginBottom: 15,
      }}
      key={index}
    >
      <Text style={{ marginBottom: 8, fontWeight: "700" }}>{item.day}</Text>
      <Text style={styles.label}>Shift</Text>
      <SelectDropdown
        data={shiftsList.filter((shift) => shift.includes(item.day))}
        // defaultValue={`${item.shift.day} ${item.shift.start_time} - ${item.shift.end_time}`}
        defaultValueByIndex={selectedShift[index]}
        onSelect={(selectedItem) => {
          handleSelectedShift(selectedItem, index);
        }}
        defaultButtonText={"Select Shift"}
        buttonTextAfterSelection={(selectedItem, index) => {
          return selectedItem;
        }}
        rowTextForSelection={(item, index) => {
          return item;
        }}
        buttonStyle={{
          ...styles.dropdown2BtnStyle,
          width: "100%",
          // marginLeft: "5%",
          marginBottom: 10,
        }}
        buttonTextStyle={{
          ...styles.dropdown2BtnTxtStyle,
          fontSize: 13,
        }}
        renderDropdownIcon={(isOpened) => {
          return (
            <Entypo
              name={isOpened ? "chevron-thin-up" : "chevron-thin-down"}
              color={"#444"}
              size={12}
            />
          );
        }}
        dropdownIconPosition={"right"}
        dropdownStyle={styles.dropdown2DropdownStyle}
        rowStyle={styles.dropdown2RowStyle}
        rowTextStyle={styles.dropdown2RowTxtStyle}
      />

      <Text style={styles.label}>Staff</Text>
      <SectionedMultiSelect
        items={staffList[index]}
        // ref={ref}
        IconRenderer={MaterialIcons}
        uniqueKey="name"
        // subKey="children"
        selectText="Select Staff"
        showDropDowns={true}
        //   readOnlyHeadings={true}
        onSelectedItemsChange={onSelectedStaffChange}
        selectedItems={selectedStaff[index]}
        hideSearch={true}
        searchPlaceholderText="Search reasons"
        //   text={Colors.primaryColor}
        styles={{
          button: {
            backgroundColor: Colors.primaryColor,
            selectedItemText: Colors.primaryColor,
          },
          selectToggle: {
            borderColor: "#CCCCCC",
            padding: 10,
            borderRadius: 4,
            borderWidth: 1,
            height: 40,
            // marginBottom: 15,
          },
          selectToggleText: {
            fontSize: 14,
          },
          itemIconStyle: {
            fontSize: 14,
          },
        }}
        theme={{
          Colors: {
            selectToggleTextColor: "#053075",
            text: "#053075",
            selectedItemText: Colors.primaryColor,
          },
        }}
      />
    </View>
  );

  // console.log(staffList.length);

  const onSelectedStaffChange = () => {};

  const onSelectedDaysChange = () => {};

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          Update Booking
        </Text>
        <MaterialIcons
          name="arrow-back"
          size={26}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 15.0,
            fontWeight: 300,
          }}
          onPress={() => navigation.navigation.pop()}
        />
      </View>
    );
  };
  return (
    <View style={{ flex: 1 }}>
      {header()}

      {isLoding ? (
        <Loading />
      ) : (
        <View
          style={{ flex: 1, backgroundColor: "#F2F3F4", paddingBottom: 80 }}
        >
          <View
            style={{
              marginTop: 5,
              zIndex: 1,
              borderRadius: 6,
              // backgroundColor: "#FFFFFF",
              padding: Sizes.fixPadding,
            }}
          >
            <Text style={styles.label}>{"Days"}</Text>
            <SectionedMultiSelect
              items={dayItems}
              // ref={ref}
              IconRenderer={MaterialIcons}
              uniqueKey="name"
              // subKey="children"
              selectText="Select Days"
              showDropDowns={true}
              //   readOnlyHeadings={true}
              onSelectedItemsChange={onSelectedDaysChange}
              selectedItems={selectedDays}
              hideSearch={true}
              searchPlaceholderText="Search reasons"
              //   text={Colors.primaryColor}
              styles={{
                button: {
                  backgroundColor: Colors.primaryColor,
                  selectedItemText: Colors.primaryColor,
                  marginBottom: 12,
                },
                selectToggle: {
                  borderColor: "#CCCCCC",
                  padding: 10,
                  borderRadius: 4,
                  borderWidth: 1,
                  height: 40,
                  // marginBottom: 15,
                },
                selectToggleText: {
                  fontSize: 14,
                },
                itemIconStyle: {
                  fontSize: 14,
                },
              }}
              theme={{
                Colors: {
                  selectToggleTextColor: "#053075",
                  text: "#053075",
                  selectedItemText: Colors.primaryColor,
                  marginBottom: 12,
                },
              }}
            />

            <FlatList
              showsVerticalScrollIndicator={false}
              nestedScrollEnabled
              data={bookingDetails.bookingData}
              renderItem={renderItem}
              keyExtractor={(item) => item.id}
            />
          </View>
        </View>
      )}
    </View>
  );
};
const styles = StyleSheet.create({
  headerWrapStyle: {
    height: 55.0,
    backgroundColor: Colors.primaryColor,
    elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
  },
  dropdown2BtnStyle: {
    width: "100%",
    height: 40,
    backgroundColor: "#FFF",
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#CCCCCC",
    marginBottom: 15,
  },
  dropdown2BtnTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 15,
  },
  dropdown2DropdownStyle: { backgroundColor: "#ddd" },
  dropdown2RowStyle: { backgroundColor: "#ddd", borderBottomColor: "#C5C5C5" },
  dropdown2RowTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 16,
  },
  contentContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 10,
  },
  lable: {
    fontWeight: "700",
  },
});
export default UpdateBooking;
