import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  FlatList,
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  Button,
  SpeedDial,
  ListItem,
  Overlay,
  Dialog,
  SearchBar,
} from "react-native-elements";
import { Feather, Ionicons, Entypo, MaterialIcons, MaterialCommunityIcons } from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import Loading from "../Utils/Loading";
import moment from "moment";

const ClientOrders = (navigation) => {
  const [orderData, setOrderData] = useState([]);
  const [orderDataInitial, setOrderDataInitial] = useState([]);
  const [isLoding, setIsLoading] = useState(false);
  const [clientsData, setClientsData] = useState([]);
  const [search, setSearch] = useState("");

  const [expanded, setExpanded] = useState(false);
  const [expandedID, setExpandedID] = useState(false);
  // console.log(global.userData.role_names.filter((role) => role === "Client").length)

  useEffect(() => {
    (async () => {
      GetData();
      GetClients();
    })();
  }, [navigation, navigation.route.params]);

  const GetData = () => {
    setIsLoading(true);
    jwt
      .get(`${Url.GET_CLIENT_ORDER_LIST}/${global.userData.id}`)
      .then((response) => {
        setOrderData(response.data.orders);
        setOrderDataInitial(response.data.orders);

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetClients = () => {
    jwt
      .get(`${Url.GET_ACTIVE_CLIENT}/${global.systemID}`)
      .then((response) => {
        setIsLoading(false);
        setClientsData(response.data.clients);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          Orders
        </Text>
        <Ionicons
          name="menu-outline"
          size={30}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 20.0,
            // fontWeight: 300
          }}
          onPress={() => navigation.navigation.toggleDrawer()}
        />
      </View>
    );
  };

  const renderItem = ({ item, index }) => (
    <ListItem.Accordion
    key={index}
    bottomDivider
    content={
      <>
        <ListItem.Content>
          <ListItem.Title>
            <Text style={styles.lable}>
              ID:
            </Text> {item.id}
          </ListItem.Title>
          <ListItem.Subtitle>
              {moment(item.duration_from).format('DD-MM-YYYY')}
            </ListItem.Subtitle>
        </ListItem.Content>
        <ListItem.Content right>
          <ListItem.Title right>
            <Text
              style={{
                color: item.status === "Cleaned" ? "green" : "#ffc107",
              }}
            >
              {item.status ? item.status : " "}
            </Text>
          </ListItem.Title>
          <ListItem.Subtitle right>
            {" "}
            {item.priority ? item.priority : " "}
          </ListItem.Subtitle>
        </ListItem.Content>
      </>
    }
    isExpanded={expanded && expandedID === item.id}
    onPress={() => {
      toggleAccordionItem(item.id);
    }}
  >
    <View style={styles.list}>
      <ListItem key={index}>
        <ListItem.Content>
          <ListItem.Title>Details</ListItem.Title>
          <ListItem.Subtitle style={{ marginTop: 8 }}>
            {" "}
            <Text style={{ fontWeight: "700" }}>Prop:</Text>{" "}
            {item.property_type}
          </ListItem.Subtitle>
          <ListItem.Subtitle style={{ marginTop: 8 }}>
            {" "}
            <Text style={{ fontWeight: "700" }}>O. Type:</Text>{" "}
            {item.order_type}
          </ListItem.Subtitle>
        </ListItem.Content>
        <ListItem.Content>
          <ListItem.Title> </ListItem.Title>
          <ListItem.Subtitle style={{ marginTop: 8 }}>
            {" "}
            <Text style={{ fontWeight: "700" }}>Clean:</Text>{" "}
            {item.cleaning_type}
          </ListItem.Subtitle>
          <ListItem.Subtitle style={{ marginTop: 8 }}>
            {" "}
            <Text style={{ fontWeight: "700" }}>Price:</Text>{" "}
            {item.total_price}
          </ListItem.Subtitle>
        </ListItem.Content>
      </ListItem>

      <ListItem bottomDivider>
        <ListItem.Content>
          <ListItem.Title style={{width:'100%', justifyContent:"center", alignItems:'center', alignContent:'center'}}>
            <TouchableOpacity
              onPress={() => {
                navigation.navigation.navigate("OrderDetails", {
                  item: item,
                });
              }}
              style={{backgroundColor:Colors.primaryColor, flexDirection:'row', padding:10, paddingLeft:15, paddingRight:15, borderRadius:30}}
            >
              <Text style={{textAlign:'center', fontSize:18, color:"#FFF", fontWeight:'600'}}>See more</Text>
              <MaterialCommunityIcons
          name="arrow-right"
          size={18}
          style={{ marginLeft: 2, paddingTop:2 }}
          color="#FFF"
        />
            </TouchableOpacity>
          </ListItem.Title>
        </ListItem.Content>
      </ListItem>
    </View>
  </ListItem.Accordion>
//     <TouchableOpacity
//       onPress={() => {
//         navigation.navigation.navigate("OrderDetails", { item: item });
//       }}
//       key={index}
//       style={{
//         marginTop: 15,
//         zIndex: 1,
//         borderRadius: 6,
//         backgroundColor: "#FFFFFF",
//         padding: Sizes.fixPadding,
//       }}
//     >
//       <View style={styles.contentContainer}>
//         <View style={{ flexDirection: "row" }}>
//           <Text style={styles.lable}>OrderID: </Text>
//           <Text>{item.id}</Text>
//         </View>
//         <View style={{ flexDirection: "row" }}>
//           <Text style={styles.lable}>Priority: </Text>
//           <Text>{item.priority ? item.priority : "Not Assigned"}</Text>
//         </View>
//       </View>

//       <View style={styles.contentContainer}>
//         {global.userData.role_names.filter((role) => role === "Client").length != 1 &&
//         <View style={{ flexDirection: "row" }}>
//           <Text style={styles.lable}>Client: </Text>
//           <Text>
//             {clientsData
//               .filter((cli) => cli.user_id == item.client_id)
//               .map((obj) => `${obj.first_name} ${obj.last_name}`)}
//           </Text>
//         </View>
// }
//         <View style={{ flexDirection: "row" }}>
//           <Text style={styles.lable}>Order Type: </Text>
//           <Text>{item.order_type}</Text>
//         </View>
//       </View>

//       <View style={styles.contentContainer}>
//         <View style={{ flexDirection: "row" }}>
//           <Text style={styles.lable}>Property: </Text>
//           <Text>{item.property_type}</Text>
//         </View>
//         <View style={{ flexDirection: "row" }}>
//           <Text style={styles.lable}>Cleaning: </Text>
//           <Text>{item.cleaning_type}</Text>
//         </View>
//       </View>

//       <View style={styles.contentContainer}>
//         <View style={{ flexDirection: "row" }}>
//           <Text style={styles.lable}>Amount: </Text>
//           <Text>{item.total_price}</Text>
//         </View>
//         <View style={{ flexDirection: "row" }}>
//           <Text style={styles.lable}>Status: </Text>
//           <Text>{item.status}</Text>
//         </View>
//       </View>
//     </TouchableOpacity>
  );

  const handleSearch = (value) => {
    setSearch(value);
    if (value.length > 1) {
      const newData = orderData.filter(
        (e) =>
          e.id === parseInt(value) ||
          e.cleaning_type.toUpperCase().includes(value.toUpperCase()) ||
          e.property_type.toUpperCase().includes(value.toUpperCase()) ||
          e.order_type.toUpperCase().includes(value.toUpperCase()) ||
          e.status.toUpperCase().includes(value.toUpperCase()) ||
          e.total_price === parseInt(value)
      );
      if (newData.length > 0) {
        setOrderData(newData);
      } else {
        setOrderData(orderDataInitial);
      }
    } else {
      setOrderData(orderDataInitial);
    }
  };

  const toggleAccordionItem = (key) => {
    setExpanded(expandedID === key ? !expanded : true);
    setExpandedID(key);
  };

  return (
    <View style={styles.container}>
      {header()}
      <SearchBar
        placeholder="Type Here..."
        onChangeText={handleSearch}
        value={search}
        lightTheme
        containerStyle={{
          backgroundColor: "#eee",
          borderWidth: 0,
          elevation: 0,
          paddingLeft: Sizes.fixPadding * 2,
          paddingRight: Sizes.fixPadding * 2,
        }}
        // placeholderTextColor="#000"
        inputStyle={{
          color: "#000",
        }}
        inputContainerStyle={{
          backgroundColor: "#ccc",
        }}
      />
      <View style={{ flex: 1, backgroundColor:"#F2F3F4",  padding: Sizes.fixPadding * 2, paddingTop: 0 }}>
        {/* <ScrollView style={{ flex: 1 }}> */}
        <FlatList
          showsVerticalScrollIndicator={false}
          nestedScrollEnabled
          data={orderData}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
        />
      </View>

      {isLoding && <Loading />}
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: Colors.primaryColor,
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 15,
    marginTop: 40,
  },
  contentContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 10,
  },
  lable: {
    fontWeight: "700",
  }
});
export default ClientOrders;
