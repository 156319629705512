import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, ScrollView, FlatList, Dimensions } from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  Button,
  SpeedDial,
  ListItem,
  Overlay,
  Dialog,
  SearchBar,
  Icon,
} from "react-native-elements";
import {
  Feather,
  Ionicons,
  Entypo,
  MaterialIcons,
  FontAwesome5,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import { useForm, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import SelectDropdown from "react-native-select-dropdown";
import Loading from "../Utils/Loading";
import moment from "moment";
import { List } from "react-native-paper";
import CustomPicker from "../../components/Picker/Picker";

const UserBookingTimesheet = (navigation) => {
  const [timesheetData, setTimesheetData] = useState([]);
  const [isLoding, setIsLoading] = useState(false);

  const [clientsData, setClientsData] = useState([]);
  const [clientsList, setClientsList] = useState([]);

  const [employeesData, setEmployeesData] = useState([]);
  const [employeesList, setEmployeesList] = useState([]);

  const [selectedOption, setSelectedOption] = useState("Client");
  const [selectedValue, setSelectedValue] = useState(0);

  const [expanded, setExpanded] = useState(false);
  const [expandedID, setExpandedID] = useState(false);

  const optionList = [{value:"Client", label:"Client"},
  {value:"Staff", label:"Staff"}];

  useEffect(() => {
    GetClients();
    GetEmployees();
  }, []);

  const GetClients = () => {
    setIsLoading(true);

    jwt
      .get(`${Url.GET_ACTIVE_CLIENT}/${global.systemID}`)
      .then((response) => {
        // console.log(response)
        const NewData = [];

        response.data.clients.map((item) => {
          NewData.push({
            value: item.id,
            label: `${item.organization_name}`,
            ...item,
          });
        });

        setClientsList(NewData);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetEmployees = () => {
    setIsLoading(true);

    jwt
      .get(`${Url.GET_ACTIVE_EMPLOYEES}/${global.systemID}`)
      .then((response) => {
        // console.log(response)
        const newEmployees = [];
        setIsLoading(false);
        // setEmployeesData(response.data.employee);
        response.data.employee.map((item) =>
          newEmployees.push(
            {
              value: item.id,
              label: `${item.employee.first_name} ${item.employee.last_name}`,
              ...item,
            }
            
          )
        );
        setEmployeesData(newEmployees);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetData = (id) => {
    setIsLoading(true);
    jwt
      .get(`${Url.GET_USER_BOOKING_TIMESHEET}/${id}`)
      .then((response) => {
        setIsLoading(false);
        setTimesheetData(response.data["user-booking-timesheet"]);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          Booking TimeSheet
        </Text>
        <Ionicons
          name="menu-outline"
          size={30}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 20.0,
            // fontWeight: 300
          }}
          onPress={() => navigation.navigation.toggleDrawer()}
        />
      </View>
    );
  };

  const toggleAccordionItem = (key) => {
    setExpanded(expandedID === key ? !expanded : true);
    setExpandedID(key);
  };

  const renderItem = ({ item, index }) => (
    <ListItem.Accordion
      key={index}
      bottomDivider
      content={
        <>
          <MaterialCommunityIcons
            name="clock-outline"
            size={26}
            style={{ marginRight: 5 }}
            color="red"
          />
          <ListItem.Content>
            <ListItem.Title style={{ color: "red" }}>
              {item.bookingID}
            </ListItem.Title>
            <ListItem.Subtitle>{item.status}</ListItem.Subtitle>
          </ListItem.Content>
          <ListItem.Content right>
            <ListItem.Title right style={{ color: "green" }}>
              {item.total_working_hours}
            </ListItem.Title>
            <ListItem.Subtitle right>
              {moment(item.created_at).format("DD-MM-YYYY")}
            </ListItem.Subtitle>
          </ListItem.Content>
        </>
      }
      isExpanded={expanded && expandedID === item.id}
      // onPress={() => {
      //   setExpanded(!expanded);
      // }}
      onPress={() => {
        toggleAccordionItem(item.id);
      }}
    >
      <View style={styles.list}>
        <ListItem key={index} bottomDivider>
          <ListItem.Content>
            <ListItem.Title>First Clock In</ListItem.Title>
            <ListItem.Subtitle style={{ marginTop: 8 }}>
              {" "}
              <Text style={{ fontWeight: "700" }}>Date:</Text>{" "}
              {moment(item.clockin_at).format("DD-MM-YYYY")}
            </ListItem.Subtitle>
          </ListItem.Content>
          <ListItem.Content>
            <ListItem.Title> </ListItem.Title>
            <ListItem.Subtitle style={{ marginTop: 8 }}>
              {" "}
              <Text style={{ fontWeight: "700" }}>time:</Text>{" "}
              {moment(item.clockin_at).format("hh:mm:ss")}
            </ListItem.Subtitle>
          </ListItem.Content>
        </ListItem>

        <ListItem bottomDivider>
          <ListItem.Content>
            <ListItem.Title>Last Clock Out</ListItem.Title>
            <ListItem.Subtitle style={{ marginTop: 8 }}>
              {" "}
              <Text style={{ fontWeight: "700" }}>Date:</Text>
              {moment(item.clockout_at).format("DD-MM-YYYY")}
            </ListItem.Subtitle>
          </ListItem.Content>

          <ListItem.Content>
            <ListItem.Title> </ListItem.Title>

            <ListItem.Subtitle style={{ marginTop: 8 }}>
              {" "}
              <Text style={{ fontWeight: "700" }}>Time:</Text>{" "}
              {moment(item.clockout_at).format("hh:mm:ss")}
            </ListItem.Subtitle>
          </ListItem.Content>
        </ListItem>

        <ListItem bottomDivider>
          <ListItem.Content>
            <ListItem.Title>Total Working Hours</ListItem.Title>
            <ListItem.Subtitle style={{ marginTop: 8 }}>
              {item.total_working_hours}
            </ListItem.Subtitle>
          </ListItem.Content>
        </ListItem>

        <ListItem bottomDivider>
          <ListItem.Content>
            <ListItem.Title>Total Break Hours</ListItem.Title>
            <ListItem.Subtitle style={{ marginTop: 8 }}>
              {item.total_break_hours}
            </ListItem.Subtitle>
          </ListItem.Content>
        </ListItem>

        {item.status === "Disputed" && (
          <ListItem bottomDivider>
            <ListItem.Content>
              <ListItem.Subtitle style={{ marginTop: 8 }}>
                {" "}
                <Text style={{ fontWeight: "700" }}>Reason:</Text>{" "}
                {item.comments}
              </ListItem.Subtitle>
            </ListItem.Content>
          </ListItem>
        )}

        <ListItem>
          <ListItem.Content>
            <ListItem.Subtitle style={{ marginTop: 8 }}>
              {" "}
              <Text style={{ fontWeight: "700" }}>Auth Date:</Text>{" "}
              {item.auth_date}
            </ListItem.Subtitle>
          </ListItem.Content>
        </ListItem>

        <ListItem>
          <ListItem.Content>
            <ListItem.Subtitle style={{ marginTop: 8 }}>
              {" "}
              <Text style={{ fontWeight: "700" }}>More Details:</Text>{" "}
            </ListItem.Subtitle>
          </ListItem.Content>
          <ListItem.Chevron />
        </ListItem>
      </View>
    </ListItem.Accordion>
  );

  const handleSelectedOption = (selectedItem, index) => {
    setSelectedOption(selectedItem);
  };

  const handleSelectedValue = (selectedItem, index) => {
    // console.log(selectedItem)
    if(parseInt(selectedItem) !== 0){
      setSelectedValue(selectedItem)
    if (selectedOption === "Client") {
      GetData(clientsList[index-1].id);
    } else {
      GetData(employeesData[index-1].id);
    }
  } else {
    setSelectedValue(selectedItem)
  } 
  }

  return (
    <View style={styles.container}>
      {header()}
       <View style={{ flex: 1, backgroundColor: "#F2F3F4", paddingTop: 0 }}>

      <View
          style={{
            // padding: Sizes.fixPadding,
            paddingTop: 5,
            paddingBottom: 0,
            flexDirection: "row",
            marginBottom:2,
          }}
        >
      <View style={{width:'49%', marginRight:"1%"}}>
          <CustomPicker
            data={optionList}
            selectedValue={selectedOption}
            handleSelectedValue={handleSelectedOption}
            // defaultLabel={"select Type"}
            style={{backgroundColor:"#FFF"}}
          />
          </View>
          <View style={{width:'49%', marginLeft:"1%"}}>
          <CustomPicker
            data={selectedOption === "Client" ? clientsList : employeesData}
            selectedValue={selectedValue}
            handleSelectedValue={handleSelectedValue}
            defaultLabel={"select Option"}
            style={{backgroundColor:"#FFF"}}
          />
         </View>
         </View>
    


        <FlatList
          showsVerticalScrollIndicator={false}
          nestedScrollEnabled
          data={timesheetData}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
        />
      </View> 
    
    
      {/* {isLoding && <Loading />} */}
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: Colors.primaryColor,
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 15,
    marginTop: 40,
  },
  dropdown2BtnStyle: {
    width: "100%",
    height: 40,
    backgroundColor: "#FFF",
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#CCCCCC",
  },
  dropdown2BtnTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 15,
  },
  dropdown2DropdownStyle: { backgroundColor: "#ddd" },
  dropdown2RowStyle: { backgroundColor: "#ddd", borderBottomColor: "#C5C5C5" },
  dropdown2RowTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 16,
  },
  contentContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 10,
  },
  lable: {
    fontWeight: "700",
  },
  list: {
    // marginTop: 20,
    borderTopWidth: 1,
    borderColor: "#cbd2d9",
  },
});
export default UserBookingTimesheet;
