import React, { useState } from "react";
import { NavigationContainer } from "@react-navigation/native";
import AppStack from "./navigation/AppStack";
import { StyleSheet, Text, View, Platform } from "react-native";


export default function Main(props) {
  const [publicKey, setPublicKey] = useState();

  const handlePublicKey = (value) => {
    setPublicKey(value);
  };

  return (
    // <StripeProvider apiKey={publicKey}>
      <AppStack onSelectPublicKey={handlePublicKey} />
    // </StripeProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
});
