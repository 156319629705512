import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Platform,
  SafeAreaView,
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  Button,
  SpeedDial,
  ListItem,
  Overlay,
  Dialog,
  SearchBar,
} from "react-native-elements";
import {
  Feather,
  Ionicons,
  Entypo,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import Loading from "../Utils/Loading";
import SelectDropdown from "react-native-select-dropdown";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import moment from "moment";

const Bookings = (navigation) => {
  const [orderData, setOrderData] = useState([]);
  const [orderDataInitial, setOrderDataInitial] = useState([]);
  const [isLoding, setIsLoading] = useState(false);
  const [clientsData, setClientsData] = useState([]);
  const [search, setSearch] = useState("");

  const [showSearchModal, setShowSearchModal] = useState(false);

  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState(null);
  const [showDate, setShowDate] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [showDate2, setShowDate2] = useState(false);

  const [status, setStatus] = useState(null);

  const [records, setRecords] = useState([
    {
      id: 1,
      name: "Created",
      rows: [],
    },
    {
      id: 2,
      name: "Confirmed",
      rows: [],
    },
    {
      id: 3,
      name: "Rejected",
      rows: [],
    },
  ]);

  const filterStatus = [
    "All",
    "Pending",
    "Accepted",
    "Assigned",
    "Do Not Clean",
    "Done",
    "Rejected",
  ];

  useEffect(() => {
    (async () => {
      GetData();
      GetPendingData();
      GetClients();
    })();
  }, [navigation, navigation.route.params]);

  const GetData = () => {
    const stateCopy = [...records];
    setIsLoading(true);
    jwt
      .get(`${Url.GET_BOOKING}/${global.systemID}`)
      .then((response) => {
        setIsLoading(false);
        setOrderData(response.data.bookings);
        setOrderDataInitial(response.data.bookings);
        response.data.bookings.map((item) => {
          stateCopy[index] = {
            ...stateCopy[index],
            rows: [...records[index], item.name],
          };
          if (item.status === "Created") {
          }
        });
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetPendingData = () => {
    // setIsLoading(true);
    jwt
      .get(`${Url.GET_PENDING_BOOKING}/${global.userData.id}`)
      .then((response) => {
        // setIsLoading(false);
        // setOrderData(response.data.bookings.reverse());
        // setOrderDataInitial(response.data.bookings.reverse());
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetClients = () => {
    jwt
      .get(`${Url.GET_ACTIVE_CLIENT}/${global.systemID}`)
      .then((response) => {
        setIsLoading(false);
        setClientsData(response.data.clients);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          Bookings
        </Text>
        <Ionicons
          name="menu-outline"
          size={30}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 20.0,
            // fontWeight: 300
          }}
          onPress={() => navigation.navigation.toggleDrawer()}
        />
        <Ionicons
          name="search"
          size={24}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            right: 20.0,
            // fontWeight: 300
          }}
          onPress={() => setShowSearchModal(!showSearchModal)}
        />
      </View>
    );
  };

  const renderItem = ({ item, index }) => (
    <TouchableOpacity onPress={() => {
           navigation.navigation.navigate("BookingDetails", { item: item });
         }} key={index} >
    <ListItem bottomDivider >
      <ListItem.Content>
        <ListItem.Title>
          <Text style={styles.lable}>ID:</Text> {item.booking_id}
        </ListItem.Title>
        <ListItem.Subtitle style={{marginTop:10}}>
           {item.booking_date}
        </ListItem.Subtitle>
      </ListItem.Content>
      <ListItem.Content right>
        <ListItem.Title right>
          <Text
            style={{
              color: item.booking_status === "Confired" ? "green" : "#ffc107",
            }}
          >
            {item.booking_status ? item.booking_status : " "}
          </Text>
        </ListItem.Title>
        <ListItem.Subtitle right style={{marginTop:10}}>
           {item.days_per_week}
        </ListItem.Subtitle>
      </ListItem.Content>
     
      <ListItem.Chevron />
    </ListItem>
    </TouchableOpacity>
   
  );

  const handleSearch = (value) => {
    setSearch(value);
    if (value.length > 1) {
      const newData = orderData.filter(
        (e) =>
          e.booking_id.toUpperCase().includes(value.toUpperCase()) ||
          e.booking_date.toUpperCase().includes(value.toUpperCase()) ||
          e.days_per_week.toUpperCase().includes(value.toUpperCase()) ||
          e.booking_status.toUpperCase().includes(value.toUpperCase())
      );
      if (newData.length > 0) {
        setOrderData(newData);
      } else {
        setOrderData(orderDataInitial);
      }
    } else {
      setOrderData(orderDataInitial);
    }
  };

  const handleFilter = (selectedItem) => {
    // if (selectedItem === "All") {
    //   setOrderData(orderDataInitial);
    // } else {
    //   setOrderData(
    //     orderDataInitial.filter((item) => item.booking_status === selectedItem)
    //   );
    // }

    setStatus(selectedItem);
  };

  const onChangeDateFrom = (event, selectedDate) => {
    const currentDate = selectedDate;
    setStartDate(currentDate);
    setShowDate(false);
  };

  const onChangeDateFrom2 = (event, selectedDate) => {
    const currentDate = selectedDate;
    setEndDate(currentDate);
    setShowDate2(false);
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = useForm({});

  const onSubmit = (data) => {
    setIsLoading(true);

    const userType = global.userData.roles[0].name;

    const newData = {
      user_type: userType === "Organization" ? "Admin" : userType,
      date_from: startDate,
      date_to: endDate,
      status: status,
      // user_id: 98
    };

    jwt
      .post(`${Url.SEARCH_BOOKING}/${global.systemID}`, newData)
      .then((response) => {
        // console.log(response);
        setOrderData(response.data.bookings);
        setOrderDataInitial(response.data.bookings);
        setIsLoading(false);
        setShowSearchModal(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <View style={styles.container}>
      {header()}
      <View style={{ flex: 1, backgroundColor: "#F2F3F4" }}>
        <SearchBar
          placeholder="Type Here..."
          onChangeText={handleSearch}
          value={search}
          lightTheme
          containerStyle={{
            backgroundColor: "#eee",
            borderWidth: 0,
            elevation: 0,
            paddingLeft: Sizes.fixPadding * 2,
            paddingRight: Sizes.fixPadding * 2,
          }}
          // placeholderTextColor="#000"
          inputStyle={{
            color: "#000",
          }}
          inputContainerStyle={{
            backgroundColor: "#ccc",
          }}
        />
        <View
          style={{
            padding: Sizes.fixPadding * 2,
            paddingTop: 5,
            paddingBottom: 0,
          }}
        >
          {/* <SelectDropdown
          data={filterStatus}
          // defaultValueByIndex={1}
          onSelect={(selectedItem, index) => {
            handleFilter(selectedItem, index);
          }}
          defaultButtonText={"Select Status"}
          buttonTextAfterSelection={(selectedItem, index) => {
            return selectedItem;
          }}
          rowTextForSelection={(item, index) => {
            return item;
          }}
          buttonStyle={{
            ...styles.dropdown2BtnStyle,
            width: "100%",
            paddingBottom: 0,
            marginBottom: 0,
          }}
          buttonTextStyle={styles.dropdown2BtnTxtStyle}
          renderDropdownIcon={(isOpened) => {
            return (
              <Entypo
                name={isOpened ? "chevron-thin-up" : "chevron-thin-down"}
                color={"#444"}
                size={14}
              />
            );
          }}
          dropdownIconPosition={"right"}
          dropdownStyle={styles.dropdown2DropdownStyle}
          rowStyle={styles.dropdown2RowStyle}
          rowTextStyle={styles.dropdown2RowTxtStyle}
        /> */}
        </View>
        {orderData.length > 0 ? (
          <View
            style={{ flex: 1, padding: Sizes.fixPadding * 2, paddingTop: 0 }}
          >
            {/* <ScrollView style={{ flex: 1 }}> */}
            <FlatList
              showsVerticalScrollIndicator={false}
              nestedScrollEnabled
              data={orderData}
              renderItem={renderItem}
              keyExtractor={(item) => item.id}
            />
          </View>
        ) : (
          <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            <View style={styles.noItemIconWrapStyle}>
              <MaterialCommunityIcons
                name="record-circle-outline"
                size={40}
                color="black"
              />
            </View>
            <Text style={{ fontSize: 18, marginTop: Sizes.fixPadding * 1.0 }}>
              No Bookings available !
            </Text>
          </View>
        )}

        {isLoding && <Loading />}

        <Overlay
          visible={showSearchModal}
          overlayStyle={{
            width: Platform.OS === "web" ? "50%" : "80%",
            backgroundColor: "#FFFFFF",
            borderRadius: 8,
            paddingTop: 15,
            paddingBottom: 20,
            padding: Sizes.fixPadding * 2,
          }}
          onBackdropPress={() => setShowSearchModal(!showSearchModal)}
        >
          <View
            style={{ alignItems: "center", marginTop: 3, marginBottom: 10 }}
          >
            <Text style={{ fontSize: 18 }}>Search Booking</Text>
          </View>
          {Platform.OS === "android" || Platform.OS === "ios" ? (
            <View>
              <Text style={{ marginTop: -10 }}>Start Date</Text>
              <TouchableOpacity
                onPress={() => setShowDate(!showDate)}
                style={{
                  backgroundColor: "white",
                  width: "100%",
                  borderColor: "#CCC",
                  borderWidth: 1,
                  height: 40,
                  borderRadius: 4,
                  padding: 6,
                  flexDirection: "row",
                  marginBottom: 15,
                }}
              >
                <MaterialIcons name="date-range" size={24} color="black" />

                <Text style={{ marginLeft: 5, paddingTop: 4 }}>
                  {startDate != null && moment(startDate).format("DD-MM-YYYY")}
                </Text>
              </TouchableOpacity>
            </View>
          ) : (
            <Controller
              name="start_date"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Start date is required",
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label="Start date"
                  placeholder="YYYY-MM-DD"
                  onChangeText={(value) => {
                    onChange(value);
                    setStartDate(value);
                  }}
                  value={value}
                  error={errors.start_date}
                  errorText={errors?.start_date?.message}
                />
              )}
              defaultValue=""
            />
          )}

          {Platform.OS === "android" || Platform.OS === "ios" ? (
            <View>
              <Text style={{ marginTop: -10 }}>End Date</Text>
              <TouchableOpacity
                onPress={() => setShowDate2(!showDate2)}
                style={{
                  backgroundColor: "white",
                  width: "100%",
                  borderColor: "#CCC",
                  borderWidth: 1,
                  height: 40,
                  borderRadius: 4,
                  padding: 6,
                  flexDirection: "row",
                  marginBottom: 15,
                }}
              >
                <MaterialIcons name="date-range" size={24} color="black" />

                <Text style={{ marginLeft: 5, paddingTop: 4 }}>
                  {endDate != null && moment(endDate).format("DD-MM-YYYY")}
                </Text>
              </TouchableOpacity>
            </View>
          ) : (
            <Controller
              name="end_date"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "End date is required",
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label="End date"
                  placeholder="YYYY-MM-DD"
                  onChangeText={(value) => {
                    onChange(value);
                    setEndDate(value);
                  }}
                  value={value}
                  error={errors.end_date}
                  errorText={errors?.end_date?.message}
                />
              )}
              defaultValue=""
            />
          )}

          <Text>Status</Text>
          <SelectDropdown
            data={filterStatus}
            // defaultValueByIndex={1}
            onSelect={(selectedItem, index) => {
              handleFilter(selectedItem, index);
            }}
            defaultButtonText={"Select Status"}
            buttonTextAfterSelection={(selectedItem, index) => {
              return selectedItem;
            }}
            rowTextForSelection={(item, index) => {
              return item;
            }}
            buttonStyle={{
              ...styles.dropdown2BtnStyle,
              width: "100%",
              paddingBottom: 0,
              marginBottom: 0,
            }}
            buttonTextStyle={styles.dropdown2BtnTxtStyle}
            renderDropdownIcon={(isOpened) => {
              return (
                <Entypo
                  name={isOpened ? "chevron-thin-up" : "chevron-thin-down"}
                  color={"#444"}
                  size={14}
                />
              );
            }}
            dropdownIconPosition={"right"}
            dropdownStyle={styles.dropdown2DropdownStyle}
            rowStyle={styles.dropdown2RowStyle}
            rowTextStyle={styles.dropdown2RowTxtStyle}
          />

          <Button
            title="Search Booking"
            type="solid"
            loading={isLoding}
            onPress={handleSubmit(onSubmit)}
            buttonStyle={{
              padding: Sizes.fixPadding * 1,
              width: "100%",
              backgroundColor: Colors.primaryColor,
              borderRadius: 22,
            }}
            containerStyle={{
              width: "100%",
              // marginHorizontal: 50,
              marginVertical: 30,
            }}
            titleStyle={{
              fontSize: 14,
              fontWeight: "100",
              color: Colors.whiteColor,
            }}
            icon={
              <Ionicons
                name="add-circle-sharp"
                size={24}
                color="#ffffff"
                style={{ marginRight: "2%" }}
              />
            }
          />
        </Overlay>

        {showDate && (
          <DateTimePicker
            testID="dateTimePicker"
            value={date}
            mode={"date"}
            onChange={onChangeDateFrom}
          />
        )}
        {showDate2 && (
          <DateTimePicker
            testID="dateTimePicker2"
            value={date}
            mode={"date"}
            onChange={onChangeDateFrom2}
          />
        )}
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: Colors.primaryColor,
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 15,
    marginTop: 40,
  },
  contentContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 10,
  },
  lable: {
    fontWeight: "700",
  },
  dropdown2BtnStyle: {
    width: "100%",
    height: 40,
    backgroundColor: "#FFF",
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#CCCCCC",
    marginBottom: 15,
  },
  dropdown2BtnTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 15,
  },
  dropdown2DropdownStyle: { backgroundColor: "#ddd" },
  dropdown2RowStyle: { backgroundColor: "#ddd", borderBottomColor: "#C5C5C5" },
  dropdown2RowTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 16,
  },
});
export default Bookings;
