/* eslint-disable consistent-this */
import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  StatusBar,
  Image,
  TextInput,
  TouchableOpacity,
  Alert,
  Platform,
  ImageBackground,
  Dimensions,
  Linking
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import { Button, Dialog } from "react-native-elements";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { Fontisto, AntDesign, Feather } from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import * as SecureStore from "expo-secure-store";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Location from "expo-location";
import { Video, AVPlaybackStatus } from "expo-av";
const windowWidth = Dimensions.get("window").width;
const Login = (navigation) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [location, setLocation] = useState(null);
  const [isLoding, setIsLoading] = useState(false);
  const [isRemember, setIsRmember] = useState(false);
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Its Error");
  const video = React.useRef(null);
  useEffect(() => {
    (async () => {
      let { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== "granted") {
        return;
      }

      let location = await Location.getCurrentPositionAsync({});
      setLocation(location.coords);
    })();
  }, []);

  const validate = (text) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(text) === false) {
      return false;
    } else {
      return true;
    }
  };

  const handleLogin = () => {
    if (email == "") {
      setErrorMessage("Please enter email!");
      setShow(true);
    } else if (!validate(email)) {
      setErrorMessage("Please provide valid email");
      setShow(true);
    } else if (password == "") {
      setErrorMessage("Please enter password!");
      setShow(true);
    } else {
      setIsLoading(true);
      jwt
        .post(Url.LOGIN, {
          email: email,
          password: password,
          latitude: location != null ? location.latitude.toString() : "",
          longitude: location != null ? location.longitude.toString() : "",
        })
        .then((response) => {
          if (response.data.token != null) {
            global.permissions = response.data.permissions;
            global.userData = response.data.user;
            global.accessToken = response.data.token;
            if (response.data.orgnaization_systems) {
              global.systems = response.data.orgnaization_systems;
              AsyncStorage.setItem(
                "systems",
                JSON.stringify(response.data.orgnaization_systems)
              );
            } else {
              global.systemID = response.data.user.system_id;
            }
            console.log(response.data.permissions);
            if (Platform.OS === "android" || Platform.OS === "ios") {
              SecureStore.setItemAsync(
                "permissions",
                JSON.stringify(response.data.permissions)
              );
              isRemember &&
                SecureStore.setItemAsync(
                  "userData",
                  JSON.stringify({
                    token: response.data.token,
                    userData: response.data.user,
                  })
                );
            } else {
              AsyncStorage.setItem(
                "permissions",
                JSON.stringify(response.data.permissions)
              );
              isRemember &&
                AsyncStorage.setItem(
                  "userData",
                  JSON.stringify({
                    token: response.data.token,
                    userData: response.data.user,
                  })
                );
            }

            if (isRemember) {
              global.isRemember = true;
            }

            setIsLoading(false);
            if (response.data.orgnaization_systems) {
              navigation.navigation.navigate("Systems");
            } else {
              navigation.navigation.navigate("Home");
            }
            // GetPermissions();
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => {
          // Alert.alert("Please Enter Correct login credentials ");
          setErrorMessage("Please Enter Correct login credentials");
          setShow(true);
          setIsLoading(false);
        });
    }
  };

  const CheckRmember = () => {
    setIsRmember(!isRemember);
  };

  return (
    <SafeAreaProvider>
        <ImageBackground
          style={{ flex: 1, height: "100%" }}
          //We are using online image to set background
          source={require("../../assets/images/login-bg.png")}
          //You can also set image from your project folder
          //require('./images/background_image.jpg')        //
        >
      <StatusBar
        // translucent
        backgroundColor={Colors.blackColor}
        barStyle="light-content"
      />
      <ScrollView style={{ flex: 1, height: "100%" }}>
      
          <View
            style={{
              flex: 1,
              paddingHorizontal: Sizes.fixPadding * 10,
            }}
          >
            <View
              style={{
                alignItems: "center",
                height: 160,
              }}
            >
              <Image
                style={{ height: 80, width: 260, marginTop: 30 }}
                source={require("../../assets/images/logo5.png")}
                resizeMode="stretch"
              />
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 20,
                  marginBottom: 40,
                }}
              >
                <Text style={{ color: "#ffffff", fontSize: 22 }}>
                  Secured Login
                </Text>

                <Fontisto
                  name="locked"
                  color="#ffffff"
                  size={25}
                  style={{ marginLeft: "3%" }}
                />
              </View>
            </View>

            <View
              style={[
                windowWidth > 786
                  ? { flexDirection: "row" }
                  : { width: "100%" },
              ]}
            >
              <View
                style={[
                  windowWidth > 786 ? { width: "58%" } : { width: "100%" },
                  {
                    marginRight: "1%",

                    position: "relative",
                    marginBottom: 10,
                    marginRight: "1%",
                  },
                ]}
              >
                <View style={styles.videoontainer}>
                  <Video
                    ref={video}
                    style={styles.video}
                    source={require("../../assets/ronove-dashboard.mp4")}
                    useNativeControls
                    resizeMode="cover"
                    isLooping
                    isMuted
                    // onPlaybackStatusUpdate={status => setStatus(() => status)}
                    shouldPlay
                  />
                </View>
              </View>
              <View
                style={[
                  windowWidth > 786 ? { width: "38%" } : { width: "100%" },
                  {
                    marginLeft: "1%",

                    position: "relative",
                    marginBottom: 10,
                  },
                ]}
              >
                <View
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: 20,
                    position: "relative",
                    zIndex: 99,
                    shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.15,
                    shadowRadius: 3.84,

                    elevation: 5,
                  }}
                >
                  <View
                    style={{
                      backgroundColor: "#2d8ce2",
                      alignContent: "center",
                      alignItems: "center",
                      padding: 20,
                      borderTopLeftRadius: 20,
                      borderTopRightRadius: 20,
                    }}
                  >
                    <Text
                      style={{ color: "#fff", fontSize: 20, fontWeight: "700" }}
                    >
                      Login Form
                    </Text>
                  </View>
                  <View style={{ padding: 20, marginTop:10 }}>
                    <TextInput
                      placeholder="example@example.com"
                      placeholderTextColor="#cccccc"
                      onChangeText={(text) => setEmail(text)}
                      style={styles.input}
                    />

                    <TextInput
                      placeholder="******"
                      placeholderTextColor="#cccccc"
                      secureTextEntry={true}
                      onChangeText={(text) => setPassword(text)}
                      style={styles.input}
                    />

                    <View
                      style={{
                        marginTop: Sizes.fixPadding * 2,
                      }}
                    >
                      <TouchableOpacity
                        style={{
                          flexDirection: "row",
                        }}
                        onPress={() => {
                          CheckRmember();
                        }}
                      >
                        <Feather
                          name={isRemember ? "check-square" : "square"}
                          size={24}
                          color="#2b8ae3"
                          style={{ height: 45, width: 30 }}
                        />
                        <View
                          style={{
                            marginHorizontal: 0,
                         
                          }}
                        >
                          <Text style={{ color: "#2b8ae3" }}>Remember Me?</Text>
                        </View>
                      </TouchableOpacity>
                    </View>

                    <Button
                      title="Sign In"
                      type="solid"
                      loading={isLoding}
                      onPress={() => handleLogin()}
                      buttonStyle={{
                        padding: Sizes.fixPadding * 1,
                        width: "100%",
                        backgroundColor: "#66d962",
                        borderRadius: 22,
                      }}
                      containerStyle={{
                        width: "100%",
                        // marginHorizontal: 50,
                        // marginVertical: 30,
                        marginBottom:10,
                        marginTop: 10,
                      }}
                      titleStyle={{
                        fontSize: 14,
                        fontWeight: "400",
                        color: "#FFF",
                      }}
                    />

<View
                      style={{
                        marginTop: Sizes.fixPadding,
                      }}
                    >
                      <TouchableOpacity
                        style={{
                          flexDirection: "row",
                        }}
                        onPress={() => Linking.openURL('https://my.ronove.io/signup')}
                      >
                        <View
                          style={{
                            marginHorizontal: 0,
                            width:'100%',
                            justifyContent:'flex-end',
                            alignContent:'flex-end',
                            alignItems:'flex-end'
                          }}
                        >
                          <Text style={{ color: "#2b8ae3" }}>Don't have account? Signup now</Text>
                        </View>
                      </TouchableOpacity>
                    </View>

                    {/* <Button
                      title="Don't have an account? Sign Up here"
                      type="solid"
                      buttonStyle={{
                        padding: Sizes.fixPadding * 1.5,
                        width: "100%",
                        backgroundColor: Colors.primaryColor,
                        borderRadius: 22,
                      }}
                      containerStyle={{
                        width: "100%",
                        // marginHorizontal: 50,
                        marginTop: 0,
                      }}
                      titleStyle={{
                        fontSize: 14,
                        fontWeight: "100",
                        color: Colors.blackColor,
                      }}
                    /> */}
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                marginTop: 15,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TouchableOpacity>
                <Text
                  style={{
                    marginRight: "8%",
                    color: "#ffffff",
                    marginTop: 15,
                    fontSize: 14,
                  }}
                >
                  Privacy Policy
                </Text>
              </TouchableOpacity>

              <TouchableOpacity>
                <Text
                  style={{
                    color: "#ffffff",
                    marginTop: 15,
                    fontSize: 14,
                    marginLeft: "2%",
                  }}
                >
                  Terms & Conditions
                </Text>
              </TouchableOpacity>
            </View>

            <View
              style={[
                windowWidth > 786
                  ? { flexDirection: "row" }
                  : { width: "100%" },
              ]}
            >
               <View
                style={[
                  windowWidth > 786 ? { width: "16.6666%" } : { width: "100%" },
                  {
                    position: "relative",
                    marginBottom: 10,
                    alignItems:'center'
                  },
                ]}
              >
              <Image
                style={{ width: 150, height: 150, marginTop: 5}}
                source={require("../../assets/images/iso-9001.png")}
                resizeMode="stretch"
              />
              </View>
              <View
                style={[
                  windowWidth > 786 ? { width: "16.6666%" } : { width: "100%" },
                  {
                    position: "relative",
                    marginBottom: 10,
                    alignItems:'center'
                  },
                ]}
              >
                <Image
                style={{ width: 150, height: 110, marginTop: 30 }}
                source={require("../../assets/images/equality-register.png")}
                resizeMode="stretch"
              />
              </View>
              <View
                style={[
                  windowWidth > 786 ? { width: "16.6666%" } : { width: "100%" },
                  {
                    position: "relative",
                    marginBottom: 10,
                    alignItems:'center'
                  },
                ]}
              >
                <Image
                style={{  width: 150, height: 110, marginTop: 30 }}
                source={require("../../assets/images/ico.png")}
                resizeMode="stretch"
              />
              </View>
              <View
                style={[
                  windowWidth > 786 ? { width: "16.6666%" } : { width: "100%" },
                  {
                    position: "relative",
                    marginBottom: 10,
                    alignItems:'center'
                  },
                ]}
              >
                <Image
                style={{ width: 150, height: 150, marginTop: 5}}
                source={require("../../assets/images/iso-27001.png")}
                resizeMode="stretch"
              />
              </View>
              <View
                style={[
                  windowWidth > 786 ? { width: "16.6666%" } : { width: "100%" },
                  {
                    position: "relative",
                    marginBottom: 10,
                    alignItems:'center'
                  },
                ]}
              >
               <Image
                style={{ width: 180, height: 70, marginTop: 35 }}
                source={require("../../assets/images/care-quality.png")}
                resizeMode="stretch"
              />
              </View>
              <View
                style={[
                  windowWidth > 786 ? { width: "16.6666%" } : { width: "100%" },
                  {
                    position: "relative",
                    marginBottom: 10,
                    alignItems:'center'
                  },
                ]}
              >
               <Image
                style={{ width: 150, height: 150, marginTop: 5}}
                source={require("../../assets/images/ssl-secure-connection.png")}
                resizeMode="stretch"
              />
              </View>
            </View>  

          </View>
     
      </ScrollView>
      </ImageBackground>
      <Dialog isVisible={show}>
        <Dialog.Title title="Error!" />
        <Text>{errorMessage}</Text>

        <Dialog.Actions>
          <Dialog.Button
            title="Ok"
            onPress={() => {
              setShow(!show);
            }}
          />
        </Dialog.Actions>
      </Dialog>
    </SafeAreaProvider>
  );
};

const styles = StyleSheet.create({
  headerWrapStyle: {
    height: 15.0,
    backgroundColor: Colors.blackColor,
    elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    flex: 1,
    backgroundColor: "#000000",
    alignItems: "center",
    justifyContent: "center",
  },
  label: {
    color: "#ffffff",
    fontSize: 16,
    marginTop: 20,
    // marginRight: "58%",
    marginBottom: 2,
  },
  input: {
    paddingHorizontal: 10,
    height: 40,
    // width: "100%",
    // color: "#FFFFFF",
    // borderColor: "#ffffff",
    // borderWidth: 1.5,
    // borderRadius: 5,
    // backgroundColor: "#000000",
    borderRadius: 20,
    backgroundColor: "#f3f1f1",
    fontSize: 18,
    color: "#2b8ae3",
    fontWeight: "300",
    borderColor: "#2b8ae3",
    marginBottom:20
  },
  videoontainer: {
    width: "100%",
    // backgroundColor: "#66d962",
    borderRadius: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 3.84,

    elevation: 5,
    // backgroundColor:"#000",
  },
  video: {
    // alignSelf: 'center',
    width: "100%",
    height: 300,
    borderRadius: 20,
    margin: 0,
  },
});

export default Login;
