import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  FlatList,
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  Button,
  SpeedDial,
  ListItem,
  Overlay,
  Dialog,
  SearchBar,
} from "react-native-elements";
import { Feather, Ionicons, Entypo, MaterialIcons } from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import Loading from "../Utils/Loading";
import SelectDropdown from "react-native-select-dropdown";
import * as Location from "expo-location";

const PendingBookings = (navigation) => {
  const [orderData, setOrderData] = useState([]);
  const [orderDataInitial, setOrderDataInitial] = useState([]);
  const [isLoding, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [search, setSearch] = useState("");
  const [showSuccess, setShowScuccess] = useState(false);
  const statusData = ["Approved", "Rejected"];

  useEffect(() => {
    (async () => {
      GetData();
    })();
  }, [navigation, navigation.route.params]);

  const GetData = () => {
    setIsLoading(true);
    jwt
      .get(`${Url.GET_PENDING_BOOKING}/${global.userData.id}`)
      .then((response) => {
        setIsLoading(false);
        setOrderData(response.data["pending-bookings"].reverse());
        setOrderDataInitial(response.data["pending-bookings"].reverse());
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleStatus = (selectedItems) => {
    setSelectedStatus(selectedItems);
  };

  const handleSubmitStatus = (navigation) => {
    setIsLoading(true);
    jwt
      .post(`${Url.UPDATE_STAFF_BOOKING_STATUS}/${selectedItem}`, {
        status: selectedStatus,
      })
      .then((response) => {
        setIsLoading(false);
        setShowScuccess(true);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          Pending Bookings
        </Text>
        <Ionicons
          name="menu-outline"
          size={30}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 20.0,
            // fontWeight: 300
          }}
          onPress={() => navigation.navigation.toggleDrawer()}
        />
      </View>
    );
  };

  const renderItem = ({ item, index }) => (
    <View
      key={index}
      style={{
        marginTop: 15,
        zIndex: 1,
        borderRadius: 6,
        backgroundColor: "#FFFFFF",
        padding: Sizes.fixPadding,
      }}
    >
      <View style={styles.contentContainer}>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.lable}>ID: </Text>
          <Text>{item.id}</Text>
        </View>

        <Feather
          name="edit"
          size={22}
          color={Colors.primaryColor}
          style={{
            position: "absolute",
            right: 0.0,
            // fontWeight: 300
          }}
          onPress={() => {
            setSelectedItem(item.id);
            setIsVisible(true);
          }}
        />
      </View>

      <View style={styles.contentContainer}>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.lable}>Booking Date: </Text>
          <Text>{item.booking_date}</Text>
        </View>
      </View>

      <View style={styles.contentContainer}>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.lable}>Day: </Text>
          <Text>{item.employee_shift_day}</Text>
        </View>
      </View>
      <View style={styles.contentContainer}>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.lable}>Start Time: </Text>
          <Text>{item.employee_shift_start_time}</Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.lable}>End Time: </Text>
          <Text>{item.employee_shift_end_time}</Text>
        </View>
      </View>
      <View style={styles.contentContainer}>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.lable}>Status: </Text>
          <Text>{item.booking_status}</Text>
        </View>
      </View>
      <View style={styles.contentContainer}>
        <Button
          title="View Task"
          type="solid"
          loading={isLoding}
          onPress={() =>
            navigation.navigation.navigate("BookingTasks", {
              bookingId: item.booking_id,
            })
          }
          buttonStyle={{
            backgroundColor: Colors.primaryColor,
            // borderRadius: 22,
          }}
          containerStyle={
            {
              // width: 110,
              // // marginHorizontal: 50,
              // marginVertical: 30,
            }
          }
          titleStyle={{
            fontSize: 12,
            fontWeight: "100",
            color: Colors.whiteColor,
          }}
        />
      </View>
    </View>
  );

  const handleSearch = (value) => {
    setSearch(value);
    if (value.length > 1) {
      const newData = orderData.filter(
        (e) =>
          e.booking_id.toUpperCase().includes(value.toUpperCase()) ||
          e.booking_date.toUpperCase().includes(value.toUpperCase()) ||
          e.days_per_week.toUpperCase().includes(value.toUpperCase()) ||
          e.booking_status.toUpperCase().includes(value.toUpperCase())
      );
      if (newData.length > 0) {
        setOrderData(newData);
      } else {
        setOrderData(orderDataInitial);
      }
    } else {
      setOrderData(orderDataInitial);
    }
  };

  return (
    <View style={styles.container}>
      {header()}
      <SearchBar
        placeholder="Type Here..."
        onChangeText={handleSearch}
        value={search}
        lightTheme
        containerStyle={{
          backgroundColor: "#eee",
          borderWidth: 0,
          elevation: 0,
          paddingLeft: Sizes.fixPadding * 2,
          paddingRight: Sizes.fixPadding * 2,
        }}
        // placeholderTextColor="#000"
        inputStyle={{
          color: "#000",
        }}
        inputContainerStyle={{
          backgroundColor: "#ccc",
        }}
      />
      <View style={{ flex: 1,backgroundColor:"#F2F3F4" ,padding: Sizes.fixPadding * 2, paddingTop: 0 }}>
        {/* <ScrollView style={{ flex: 1 }}> */}
        <FlatList
          showsVerticalScrollIndicator={false}
          nestedScrollEnabled
          data={orderData}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
        />
      </View>

      {isLoding && <Loading />}

      <Overlay
        visible={isVisible}
        overlayStyle={{
          width: "80%",
          backgroundColor: "#FFFFFF",
          borderRadius: 8,
          paddingTop: 15,
          paddingBottom: 20,
          padding: Sizes.fixPadding * 2,
        }}
        onBackdropPress={() => setIsVisible(!isVisible)}
      >
        <View style={{ alignItems: "center", marginTop: 3, marginBottom: 10 }}>
          <Text style={{ fontSize: 18 }}>Update Status</Text>
        </View>

        <Text style={styles.label}>{"Status"}</Text>
        <SelectDropdown
          data={statusData}
          onSelect={(selectedItem, index) => {
            handleStatus(selectedItem);
          }}
          defaultButtonText={"Select Status"}
          buttonTextAfterSelection={(selectedItem, index) => {
            return selectedItem;
          }}
          rowTextForSelection={(item, index) => {
            return item;
          }}
          buttonStyle={styles.dropdown2BtnStyle}
          buttonTextStyle={styles.dropdown2BtnTxtStyle}
          renderDropdownIcon={(isOpened) => {
            return (
              <Entypo
                name={isOpened ? "chevron-thin-up" : "chevron-thin-down"}
                color={"#444"}
                size={14}
              />
            );
          }}
          dropdownIconPosition={"right"}
          dropdownStyle={styles.dropdown2DropdownStyle}
          rowStyle={styles.dropdown2RowStyle}
          rowTextStyle={styles.dropdown2RowTxtStyle}
        />

        <Button
          title="Update Status"
          type="solid"
          loading={isLoding}
          onPress={() => handleSubmitStatus(navigation)}
          buttonStyle={{
            padding: Sizes.fixPadding * 1,
            width: "100%",
            backgroundColor: Colors.primaryColor,
            borderRadius: 22,
          }}
          containerStyle={{
            width: "100%",
            // marginHorizontal: 50,
            marginVertical: 20,
          }}
          titleStyle={{
            fontSize: 14,
            fontWeight: "100",
            color: Colors.whiteColor,
          }}
          icon={
            <Ionicons
              name="add-circle-sharp"
              size={24}
              color="#ffffff"
              style={{ marginRight: "2%" }}
            />
          }
        />
      </Overlay>

      <Dialog isVisible={showSuccess}>
        <Dialog.Title title="Congratulations!" />
        <Text>{"You have Scuccessfully Update Status"}</Text>

        <Dialog.Actions>
          <Dialog.Button
            title="Ok"
            onPress={() => {
              setShowScuccess(!showSuccess);
              setIsVisible(!isVisible);
              GetData();
            }}
          />
        </Dialog.Actions>
      </Dialog>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor:Colors.primaryColor
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom:15,
    marginTop:40
  },
  contentContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 10,
  },
  lable: {
    fontWeight: "700",
  },
  dropdown2BtnStyle: {
    width: "100%",
    height: 40,
    backgroundColor: "#FFF",
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#CCCCCC",
    marginBottom: 15,
  },
  dropdown2BtnTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 15,
  },
  dropdown2DropdownStyle: { backgroundColor: "#ddd" },
  dropdown2RowStyle: { backgroundColor: "#ddd", borderBottomColor: "#C5C5C5" },
  dropdown2RowTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 16,
  },
});
export default PendingBookings;
