import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  FlatList,
} from "react-native";
import { Colors, Sizes } from "../../../constants/style";
import {
  Button,
  SpeedDial,
  ListItem,
  Overlay,
  Dialog,
  SearchBar,
} from "react-native-elements";
import { Feather, Ionicons, Entypo, MaterialIcons } from "@expo/vector-icons";
import jwt from "../../../helpers/jwt";
import * as Url from "../../../helpers/urlHelper";
import Loading from "../../Utils/Loading";
import moment from "moment";

const CurrentApprovedBookings = ({ currentTime, onClickBooking, onCurrentBookingID }) => {
  const [orderData, setOrderData] = useState([]);
  const [isLoding, setIsLoading] = useState(false);

  const [date, setDate] = useState(new Date());

  useEffect(() => {
    (async () => {
      GetData();
    })();
  }, []);

  const GetData = () => {
    setIsLoading(true);
    jwt
      .get(`${Url.GET_APPROVED_BOOKING}/${global.userData.id}`)
      .then((response) => {
        // console.log(response.data);
        setIsLoading(false);
        const ListArray = [];

        response.data["pending-bookings"]
          .filter(
            (item) =>
              item.booking_date === `${moment(date).format("YYYY-MM-DD")}`
          )
          .map((obj) => ListArray.push(obj));

        setOrderData(ListArray);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <View
      style={{
        flex: 1,
        padding: Sizes.fixPadding,
        paddingTop: 0,
        width: "100%",
      }}
    >
      <Text style={{ fontSize: 16, fontWeight: "bold" }}>Today's Bookings</Text>

      {orderData.map((item, index) => {
       
        onClickBooking(item.id);
        onCurrentBookingID(item.booking_id);
        // const startTime = moment(item.employee_shift_start_time, "hh:mm:ss");
        // const endTime = moment(item.employee_shift_end_time, "hh:mm:ss");
        // if (
        //   item.employee_shift_start_time <= currentTime &&
        //   item.employee_shift_end_time > currentTime
        // ) {
        //   onClickBooking(item.id);
        // }

        return (
          <View
            key={index}
            style={{
              marginTop: 15,
              zIndex: 1,
              borderRadius: 6,
              borderBottomWidth: 1,
              borderBottomColor: "#ccc",
              width: "100%",
            }}
          >
            <View style={styles.contentContainer}>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.lable}>ID: </Text>
                <Text>{item.id}</Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                {/* {timeStarted == true ? ( */}
                {/* <Button
         title="Clock Out"
         type="solid"
         loading={isLoding}
         // onPress={() => {
         //   setSelectedClockItem(item.id);
         //   handleSelectItem(item.id);
         // }}
         buttonStyle={{
           backgroundColor: "red",
           // borderRadius: 22,
         }}
         containerStyle={
           {
             // width: 110,
             // // marginHorizontal: 50,
             // marginVertical: 30,
           }
         }
         titleStyle={{
           fontSize: 12,
           fontWeight: "100",
           color: Colors.whiteColor,
         }}
       />
     ) : ( */}
                {/* <Button
                  title="Clock In"
                  type="solid"
                  loading={isLoding}
                  onPress={() => {
                    onClickBooking(item.id);
                  }}
                  buttonStyle={{
                    backgroundColor: Colors.primaryColor,
                  }}
                  containerStyle={{
                    marginTop: -10,
                  }}
                  titleStyle={{
                    fontSize: 12,
                    fontWeight: "100",
                    color: Colors.whiteColor,
                  }}
                /> */}
                {/* )} */}
              </View>
            </View>

            <View style={styles.contentContainer}>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.lable}>Start Time: </Text>
                <Text>{item.employee_shift_start_time}</Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <Text style={styles.lable}>End Time: </Text>
                <Text>{item.employee_shift_end_time}</Text>
              </View>
            </View>
          </View>
        );
      })}
      {/* <FlatList
        showsVerticalScrollIndicator={false}
        nestedScrollEnabled
        data={orderData}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
      /> */}
    </View>
  );
};
const styles = StyleSheet.create({
  headerWrapStyle: {
    height: 55.0,
    backgroundColor: Colors.primaryColor,
    elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
  },
  contentContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 10,
  },
  lable: {
    fontWeight: "700",
  },
  dropdown2BtnStyle: {
    width: "100%",
    height: 40,
    backgroundColor: "#FFF",
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#CCCCCC",
    marginBottom: 15,
  },
  dropdown2BtnTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 15,
  },
  dropdown2DropdownStyle: { backgroundColor: "#ddd" },
  dropdown2RowStyle: { backgroundColor: "#ddd", borderBottomColor: "#C5C5C5" },
  dropdown2RowTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 16,
  },
});
export default CurrentApprovedBookings;
