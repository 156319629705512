/* eslint-disable consistent-this */
import React, { useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  TextInput,
  AppState,
  Platform,
  Dimensions
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import { Button, Overlay, Icon } from "react-native-elements";
import { MaterialIcons, Entypo, FontAwesome } from "@expo/vector-icons";
import SelectDropdown from "react-native-select-dropdown";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import * as Location from "expo-location";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { differenceInSeconds } from "date-fns";
import CustomPicker from "../../components/Picker/Picker";


const BookingClock = ({ navigation, bookingID, currentBookingID }) => {
  const [isClicked, setIsClicked] = useState(false);
  const [timeStarted, setTimeStarted] = useState(false);
  const [time, setTime] = useState("00:00:00");
  const [timeStamp, setTimeStamp] = useState(0);
  const [isLoding, setIsLoading] = useState(false);
  const [reason, setReason] = useState("");
  const [comments, setComments] = useState("");
  const [location, setLocation] = useState(null);

  const reasonsList = [{value:"Break", label:"Break"}, {value:"Shift End", label: "Shift End"}];

  // const appState = useRef(AppState.currentState);

  // Intial
  // useEffect(() => {
  //   ShowClockIn();
  // }, [bookingID != null]);

  //Previous Time
  useEffect(() => {
    async function fetchTime() {
      const startTime = await AsyncStorage.getItem("@previous_booking_time");
      if (parseInt(startTime) > 0) {
        setTime(secondsToHms(startTime));
      }
    }

    fetchTime();
  }, [navigation, bookingID]);

  // Location Area
  useEffect(() => {
    (async () => {
      let { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== "granted") {
        return;
      }

      let location = await Location.getCurrentPositionAsync({});
      setLocation(location.coords);
    })();
  }, []);

  // Click Main Component
  const ShowClockIn = () => {
    setIsClicked(!isClicked);
  };

  //Time Interval UseEffect
  useEffect(() => {
    const interval = setInterval(async () => {
      if (timeStarted) {
        // var difference = Math.floor(Date.now() / 1000) - timeStamp;
        // setTime(secondsToHms(difference));
        const startTime = await AsyncStorage.getItem("@booking_time");
        const now = new Date();
        const differ = differenceInSeconds(now, Date.parse(startTime));
        setTime(secondsToHms(differ + timeStamp));
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [timeStarted]);

  // Convert Seconds to HH:MM:SS
  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    if (h < 10) {
      h = "0" + h;
    }
    var m = Math.floor((d % 3600) / 60);
    if (m < 10) {
      m = "0" + m;
    }
    var s = Math.floor((d % 3600) % 60);
    if (s < 10) {
      s = "0" + s;
    }
    return h.toString() + ":" + m.toString() + ":" + s.toString();
  };

  // Clockin/Out Parent Call Function
  const ClockINOUT = async () => {
    if (location == null) {
      let { status } = await Location.requestForegroundPermissionsAsync();
    }
    let location1 = await Location.getCurrentPositionAsync({});
    setLocation(location1.coords);

    if (timeStarted) {
      setClockOutFN();
    } else {
      setClockInFN();
    }
  };

  // ClockIn Function
  const setClockInFN = async () => {
    setIsLoading(true);
    jwt
      .post(Url.BOOKING_CLOCKIN, {
        // latitude: location != null ? location.latitude.toString() : "",
        // longitude: location != null ? location.longitude.toString() : "",
        latitude: "52.62578",
        longitude: "-1.12517",
        employee_booking_id: bookingID,
      })
      .then(async(response) => {
        recordStartTime();
        await AsyncStorage.setItem("current_clockin_bookingID", JSON.stringify(currentBookingID));
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  // Clock Out Function
  const setClockOutFN = async () => {
    setIsLoading(true);

    jwt
      .post(Url.BOOKING_CLOCKOUT, {
        reason: reason,
        comments: comments,
        // latitude: location != null ? location.latitude.toString() : "",
        // longitude: location != null ? location.longitude.toString() : "",
        latitude: "52.62578",
        longitude: "-1.12517",
        employee_booking_id: bookingID,
      })
      .then(async (response) => {
        recordStopTime(response.data.clockin.reason_clockout);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  //Record Time Start
  const recordStartTime = async () => {
    try {
      if ((await AsyncStorage.getItem("@time_started")) === "true") {
        const now = new Date();
        await AsyncStorage.setItem("@booking_time", now.toISOString());

        const differ = await AsyncStorage.getItem("@previous_booking_time");
        setTimeStamp(parseInt(differ));

        setTimeStarted(true);
        setIsLoading(false);
        setIsClicked(!isClicked);
      } else {
        const now = new Date();
        await AsyncStorage.setItem("@booking_time", now.toISOString());
        setTimeStarted(true);
        setIsLoading(false);
        setIsClicked(!isClicked);
      }
    } catch (err) {
      // TODO: handle errors from setItem properly
      console.warn(err);
    }
  };

  // Record Time Stop
  const recordStopTime = async (reason) => {
    try {
      if (reason === "Break") {
        const startTime = await AsyncStorage.getItem("@booking_time");
        const now = new Date();
        const timeSec = differenceInSeconds(now, Date.parse(startTime));
        const newTime = timeStamp + parseInt(timeSec);
        await AsyncStorage.setItem(
          "@previous_booking_time",
          newTime.toString()
        );
        await AsyncStorage.setItem("@time_started", "true");
        setIsLoading(false);
        setTimeStarted(false);
        setIsClicked(!isClicked);
      } else {
        await AsyncStorage.removeItem("@previous_booking_time");
        await AsyncStorage.removeItem("@booking_time");
        await AsyncStorage.removeItem("@time_started");
        await AsyncStorage.removeItem("current_clockin_bookingID");
        await AsyncStorage.setItem(
          "current_Emp_bookingID",
          bookingID.toString()
        );
        setIsLoading(false);
        setTimeStarted(false);
        setIsClicked(!isClicked);
        navigation.navigation.navigate("CreateTimesheet");
      }
    } catch (err) {
      // TODO: handle errors from setItem properly
      console.warn(err);
    }
  };

  // Handle Clockout Reason
  const handleReason = (selectedItem, index) => {
    setReason(selectedItem);
  };

  //App State Portion

  // useEffect(() => {
  //   AppState.addEventListener("change", handleAppStateChange);
  //   return () => AppState.remove("change", handleAppStateChange);
  // }, []);

  // const handleAppStateChange = async (nextAppState) => {
  //   if (
  //     appState.current.match(/inactive|background/) &&
  //     nextAppState === "active"
  //   ) {
  //     // We just became active again: recalculate elapsed time based
  //     // on what we stored in AsyncStorage when we started.
  //     const elapsed = await getElapsedTime();
  //     // Update the elapsed seconds state
  //     setTimeStamp(parseInt(elapsed));
  //     setTime(secondsToHms(elapsed));
  //   }
  //   appState.current = nextAppState;
  // };

  // const getElapsedTime = async () => {
  //   try {
  //     if ((await AsyncStorage.getItem("@time_started")) === "true") {
  //       const PrevTime = await AsyncStorage.getItem("@previous_booking_time");

  //       const startTimeNew = await AsyncStorage.getItem("@booking_time");
  //       const now = new Date();
  //       const timeSec = differenceInSeconds(now, Date.parse(startTimeNew));
  //       const newTime = PrevTime + parseInt(timeSec);
  //       return parseInt(newTime);
  //     }
  //   } catch (err) {
  //     // TODO: handle errors from setItem properly
  //     console.warn(err);
  //   }
  // };

  return (
    <View>
      {Platform.OS === "android" || Platform.OS === "ios" ? (
        <TouchableOpacity onPress={() => ShowClockIn()}>
          <View style={styles.mainWrap}>
            {timeStarted == true ? (
              <View style={{ ...styles.icon, backgroundColor: "green" }}>
                <MaterialIcons name="timelapse" color="#ffffff" size={35} />
              </View>
            ) : null}

            {timeStarted == false ? (
              <View style={{ ...styles.icon, backgroundColor: "red" }}>
                <MaterialIcons name="timelapse" color="#ffffff" size={35} />
              </View>
            ) : null}
            <View style={{ marginLeft: "8%" }}>
              <Text style={{ fontSize: 24 }}>{time}</Text>
              {timeStarted == true ? (
                <Text style={{ fontSize: 13 }}>BOOKING CLOCK OUT</Text>
              ) : null}
              {timeStarted == false ? (
                <Text style={{ fontSize: 13 }}>BOOKING CLOCK IN</Text>
              ) : null}
            </View>
          </View>
        </TouchableOpacity>
      ) : (
        <TouchableOpacity
          onPress={() => ShowClockIn()}
          style={{ width: "100%" }}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              backgroundColor: "#ffffff",
              alignSelf: "center",
              padding: Sizes.fixPadding * 2,
              marginTop: 20,
            }}
          >
            {timeStarted == true ? (
              <View
                style={{
                  height: 50,
                  width: 50,
                  padding: 10,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 100,
                  backgroundColor: "#68b828",
                }}
              >
                <MaterialIcons name="timelapse" color="#ffffff" size={25} />
              </View>
            ) : null}

            {timeStarted == false ? (
              <View
                style={{
                  height: 50,
                  width: 50,
                  padding: 10,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 100,
                  backgroundColor: "#d5080f",
                }}
              >
                <MaterialIcons name="timelapse" color="#ffffff" size={25} />
              </View>
            ) : null}

            <View style={{ marginLeft: "8%" }}>
              <Text style={{ fontSize: 24, color: "#3e3f3f" }}>{time}</Text>
              {timeStarted == true ? (
                <Text style={{ fontSize: 12, color: "#979898" }}>
                  BOOKING CLOCK OUT
                </Text>
              ) : null}
              {timeStarted == false ? (
                <Text style={{ fontSize: 12, color: "#979898" }}>
                  BOOKING CLOCK IN
                </Text>
              ) : null}
            </View>
          </View>
        </TouchableOpacity>
      )}
      <Overlay
        isVisible={isClicked}
        overlayStyle={{
          width: "80%",
          alignItems: "center",
          backgroundColor: "#DDDDDD",
          borderRadius: 8,
        }}
      >
        {timeStarted == true ? (
          <Text style={{ fontSize: 18, marginTop: "5%", marginBottom: 5 }}>
            CLOCK OUT
          </Text>
        ) : null}
        {timeStarted == false ? (
          <Text style={{ fontSize: 18, marginTop: "5%", marginBottom: 5 }}>
            CLOCK IN
          </Text>
        ) : null}
        {timeStarted == true ? (
          <View
            style={{
              marginTop: "1%",
              flexDirection: "row",
              width: "100%",
              paddingHorizontal: "5%",
              // heights: 60,
            }}
          >
           <CustomPicker
                  data={reasonsList}
                  selectedValue={reason}
                  handleSelectedValue={handleReason}
                  defaultLabel={"Select Reason"}
                  style={{}}
                />
          </View>
        ) : null}
        {timeStarted == true ? (
          <View
            style={{
              marginTop: "1%",
              flexDirection: "row",
              width: "100%",
              paddingHorizontal: "5%",
              // heights: 60,
            }}
          >
            <TextInput
              style={{
                height: 80,
                width: "90%",
                margin: 12,
                borderWidth: 1,
                padding: 10,
                borderRadius: 5,
                fontSize: 16,
              }}
              placeholder="Enter comments..."
              multiline={true}
              underlineColorAndroid="transparent"
              onChangeText={(text) => setComments(text)}
            />
          </View>
        ) : null}
        <View
          style={{
            marginTop: "1%",
            flexDirection: "row",
            width: "100%",
            paddingHorizontal: "10%",
            justifyContent: "space-between",
            paddingBottom: "5%",
          }}
        >
          <Button
            buttonStyle={{
              padding: Sizes.fixPadding * 1.5,
              width: "100%",
              backgroundColor: "red",
            }}
            containerStyle={{
              width: 100,
            }}
            titleStyle={{
              fontSize: 14,
              fontWeight: "100",
              color: Colors.whiteColor,
            }}
            title="Cancel"
            onPress={ShowClockIn}
          />
          <Button
            title={timeStarted ? "Clock Out" : "Clock In"}
            type="solid"
            loading={isLoding}
            onPress={() => ClockINOUT()}
            buttonStyle={{
              padding: Sizes.fixPadding * 1.5,
              width: "100%",
              backgroundColor: Colors.primaryColor,
            }}
            containerStyle={{
              width: 110,
            }}
            titleStyle={{
              fontSize: 14,
              fontWeight: "100",
              color: Colors.whiteColor,
            }}
          />
        </View>
      </Overlay>
    </View>
  );
};

const styles = StyleSheet.create({
  mainWrap: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    borderRadius: 10,
    backgroundColor: "#dddddd",
    alignSelf: "center",
    padding: Sizes.fixPadding,
  },
  icon: {
    height: 50,
    width: 50,
    borderRadius: 25,
    justifyContent: "center",
    alignItems: "center",
  },
  time: {},
  button: {
    margin: 10,
  },
  textPrimary: {
    marginVertical: 20,
    textAlign: "center",
    fontSize: 20,
  },
  textSecondary: {
    marginBottom: 10,
    textAlign: "center",
    fontSize: 17,
  },
  dropdown2BtnStyle: {
    width: "100%",
    height: 40,
    backgroundColor: "#FFF",
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#CCCCCC",
    marginBottom: 15,
  },
  dropdown2BtnTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 16,
  },
  dropdown2DropdownStyle: { backgroundColor: "#ddd" },
  dropdown2RowStyle: { backgroundColor: "#ddd", borderBottomColor: "#C5C5C5" },
  dropdown2RowTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 16,
  },
});

export default BookingClock;
