import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  FlatList,
  TouchableOpacity,
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import { Button, SpeedDial, Overlay } from "react-native-elements";
import { Feather, Ionicons, Entypo, MaterialIcons } from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import Loading from "../Utils/Loading";
import SelectDropdown from "react-native-select-dropdown";
import AuthorizedFor from "../../constants/authorizedFor";
import { authorizedForFlateList } from "../../constants/authorizedForFlateList";
import { ModulesPermission } from "../../constants/modules";

const Shifts = (navigation) => {
  const [shiftsData, setShiftsData] = useState([]);
  const [openDial, setOpenDial] = useState(false);
  const [isLoding, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    GetData();
  }, []);

  const dayItems = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const GetData = () => {
    setIsLoading(true);
    jwt
      .post(Url.GET_CURRENT_USER_SHIFTS, { user_id: global.userData.id })
      .then((response) => {
        setIsLoading(false);
        setShiftsData(response.data.user_shifts);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = useForm({ shifts: shiftsData });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "shifts",
  });

  const renderItem = ({ item }) => (
    <View
      style={{
        marginTop: 15,
        zIndex: 1,
        borderRadius: 6,
        backgroundColor: "#FFFFFF",
        padding: Sizes.fixPadding * 1.5,
      }}
    >
      <View style={styles.contentContainer}>
        <View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.lable}>Day: </Text>
            <Text>{item.day}</Text>
          </View>
        </View>
      </View>
      <View style={styles.contentContainer}>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.lable}>Start Time: </Text>
          <Text>{item.start_time}</Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.lable}>End Time: </Text>
          <Text>{item.end_time}</Text>
        </View>
      </View>
    </View>
  );

  const onSubmit = (data) => {
    setIsLoading(true);
    jwt
      .post(`${Url.ADD_USER_SHIFTS}/${global.userData.id}`, {
        shifts: data.shifts,
      })
      .then((response) => {
        setIsLoading(false);
        setIsVisible(!isVisible);
        GetData();
        setOpenDial(!openDial);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          Shifts
        </Text>
        <Ionicons
          name="menu-outline"
          size={30}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 20.0,
            // fontWeight: 300
          }}
          onPress={() => navigation.navigation.toggleDrawer()}
        />
      </View>
    );
  };

  return (
    <View style={styles.container}>
      {header()}
      {isLoding && <Loading />}

      <View style={{ flex: 1, backgroundColor:"#F2F3F4" ,padding: Sizes.fixPadding * 2, paddingTop: 0 }}>
        {/* <ScrollView style={{ flex: 1 }}> */}
        <FlatList
          showsVerticalScrollIndicator={false}
          nestedScrollEnabled
          data={shiftsData}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
        />
      </View>

      <Overlay
        visible={isVisible}
        overlayStyle={{
          width: "80%",
          backgroundColor: "#FFFFFF",
          borderRadius: 8,
          paddingTop: 15,
          paddingBottom: 20,
          padding: Sizes.fixPadding * 2,
          minHeight: 500,
        }}
        onBackdropPress={() => setIsVisible(!isVisible)}
      >
        <View style={{ alignItems: "center", marginTop: 3, marginBottom: 10 }}>
          <Text style={{ fontSize: 18 }}>New Shifts</Text>
        </View>

        <ScrollView
          style={{
            flex: 1,
            marginBottom: 20,
            paddingBottom: 40,
            backgroundColor: "#fff",
          }}
        >
          {fields.map((item, index) => (
            <View
              style={
                {
                  // flexDirection: "row",
                  // width: "100%",
                }
              }
              key={item.id}
            >
              <Controller
                name={`shifts.${index}.day`}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <SelectDropdown
                    data={dayItems}
                    defaultValue={value}
                    value={value}
                    onSelect={(selectedItem, index) => {
                      onChange(selectedItem);
                    }}
                    defaultButtonText={"Select Day"}
                    buttonTextAfterSelection={(selectedItem, index) => {
                      return selectedItem;
                    }}
                    rowTextForSelection={(item, index) => {
                      return item;
                    }}
                    buttonStyle={styles.dropdown2BtnStyle}
                    buttonTextStyle={styles.dropdown2BtnTxtStyle}
                    renderDropdownIcon={(isOpened) => {
                      return (
                        <Entypo
                          name={
                            isOpened ? "chevron-thin-up" : "chevron-thin-down"
                          }
                          color={"#444"}
                          size={14}
                        />
                      );
                    }}
                    dropdownIconPosition={"right"}
                    dropdownStyle={styles.dropdown2DropdownStyle}
                    rowStyle={styles.dropdown2RowStyle}
                    rowTextStyle={styles.dropdown2RowTxtStyle}
                  />
                )}
              />

              <Controller
                name={`shifts.${index}.start_time`}
                control={control}
                rules={{
                  required: { value: true, message: "Start Time is Required" },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    placeholder="14:00"
                    label="Start Time"
                    onChangeText={(value) => onChange(value)}
                    value={value}
                    error={errors.start_time}
                    errorText={errors?.start_time?.message}
                  />
                )}
                defaultValue=""
              />

              <Controller
                name={`shifts.${index}.end_time`}
                control={control}
                rules={{
                  required: { value: true, message: "End Time is Required" },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    placeholder="16:00"
                    label="End Time"
                    onChangeText={(value) => onChange(value)}
                    value={value}
                    error={errors.end_time}
                    errorText={errors?.end_time?.message}
                  />
                )}
                defaultValue=""
              />

              <TouchableOpacity
                style={{ width: 50, marginLeft: 25, marginTop: 8 }}
                onPress={() => remove(index)}
              >
                <Feather name="delete" size={24} color="red" />
              </TouchableOpacity>
            </View>
          ))}
          <TouchableOpacity
            onPress={() =>
              append({
                day: "",
                start_time: "",
                end_time: "",
              })
            }
            style={{
              flexDirection: "row",
              backgroundColor: "#EEE",
              padding: 10,
              width: 110,
              borderRadius: 4,
              marginTop: 10,
            }}
          >
            <Ionicons
              name="add-circle-sharp"
              size={22}
              color="black"
              style={{ marginRight: "2%" }}
            />
            <Text style={{ paddingTop: 3 }}>Add Item</Text>
          </TouchableOpacity>
          <Button
            title="Add Shift"
            type="solid"
            //   loading={isLoding}
            onPress={handleSubmit(onSubmit)}
            buttonStyle={{
              padding: Sizes.fixPadding,
              width: "100%",
              backgroundColor: Colors.primaryColor,
              borderRadius: 22,
            }}
            containerStyle={{
              width: "100%",
              // marginHorizontal: 50,
              marginVertical: 20,
            }}
            titleStyle={{
              fontSize: 14,
              fontWeight: "100",
              color: Colors.whiteColor,
            }}
            icon={
              <Ionicons
                name="add-circle-sharp"
                size={24}
                color="#ffffff"
                style={{ marginRight: "2%" }}
              />
            }
          />
        </ScrollView>
      </Overlay>
      {AuthorizedFor(ModulesPermission.Create_User_Shift_By_ID) && (
        <SpeedDial
          isOpen={openDial}
          icon={{ name: "edit", color: "#fff" }}
          openIcon={{ name: "close", color: "#fff" }}
          onOpen={() => setOpenDial(!openDial)}
          onClose={() => setOpenDial(!openDial)}
          style={{ zIndex: 2 }}
          buttonStyle={{ backgroundColor: Colors.primaryColor }}
        >
          <SpeedDial.Action
            icon={{ name: "add", color: "#fff" }}
            title="Add Type"
            onPress={() => setIsVisible(!isVisible)}
            buttonStyle={{ backgroundColor: Colors.primaryColor }}
          />
        </SpeedDial>
      )}
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor:Colors.primaryColor
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom:15,
    marginTop:40
  },
  dropdown2BtnStyle: {
    width: "100%",
    height: 40,
    backgroundColor: "#FFF",
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#CCCCCC",
    marginBottom: 15,
  },
  dropdown2BtnTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 15,
  },
  dropdown2DropdownStyle: { backgroundColor: "#ddd" },
  dropdown2RowStyle: { backgroundColor: "#ddd", borderBottomColor: "#C5C5C5" },
  dropdown2RowTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 16,
  },
  lable: {
    fontWeight: "700",
  },
  contentContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 10,
  },
});
export default Shifts;
