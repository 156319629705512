import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  StatusBar,
  Image,
  TextInput,
  TouchableOpacity,
  Alert,
  Dimensions
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import { Button, SpeedDial, ListItem, Overlay } from "react-native-elements";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { Fontisto, Ionicons, MaterialIcons, Entypo, FontAwesome5 } from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import { useForm, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import CustomPicker from "../../components/Picker/Picker";

const windowWidth = Dimensions.get("window").width;

const UpdateGroup = (navigation) => {

  const selectedItem = navigation.route.params.item;

  const [selectedSystem, setSelectedSystem] = useState(selectedItem.system_id);
  const [selectedStatus, setSelectedStatus] = useState(""+selectedItem.status);

  const statusData = [{value:"1", label:"Active"},{value:"0", label:"InActive"}];

  const handleSelectedStatus = (val, index) => {
    setSelectedStatus(val);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    jwt
      .post(`${Url.UPDATE_GROUP}/${selectedItem.id}`, {
        name: data.group,
        system_id: parseInt(global.systemID),
        status: parseInt(selectedStatus),
      })
      .then((response) => {
        navigation.navigation.navigate("Groups", {Refresh:true});
      })
      .catch((err) => {});
  };

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
         Update Group
                 </Text>
        <MaterialIcons
          name="arrow-back"
          size={26}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 15.0,
            fontWeight: 300,
          }}
          onPress={() => navigation.navigation.pop()}
        />
      </View>
    );
  };

  return (
    <View style={styles.container}>
    {header()}
    <View style={{ flex: 1, backgroundColor:"#F2F3F4" ,padding: Sizes.fixPadding * 2 }}>
    <View
          style={{
            padding: Sizes.fixPadding,
            backgroundColor: "#fff",
            borderRadius: 6,
          }}
        >
      <Controller
        name="group"
        control={control}
        rules={{
          required: { value: true, message: "Group is required" },
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            label="Group"
            type="text"
            onChangeText={(value) => onChange(value)}
            value={value}
            error={errors.group}
            errorText={errors?.group?.message}
          />
        )}
        defaultValue={selectedItem.name}
      />

<View style={{flexDirection:'row', marginTop:15}}>
                   <View style={styles.child1}>
                <Text style={{ ...styles.label}}>{"Status"}</Text>
               </View>
               <View style={styles.child2}>
                <CustomPicker
                  data={statusData}
                  selectedValue={selectedStatus}
                  handleSelectedValue={handleSelectedStatus}
                  // defaultLabel={"Select Status"}
                  style={{}}
                />
                </View>
                </View>

      <Button
        title="Update Group"
        type="solid"
        //   loading={isLoding}
        onPress={handleSubmit(onSubmit)}
        buttonStyle={{
          padding: Sizes.fixPadding,
          width: "100%",
          backgroundColor: Colors.primaryColor,
          borderRadius: 22,
        }}
        containerStyle={{
          width: "100%",
          // marginHorizontal: 50,
          marginVertical: 20,
        }}
        titleStyle={{
          fontSize: 14,
          fontWeight: "100",
          color: Colors.whiteColor,
        }}
        icon={
          <Ionicons
            name="add-circle-sharp"
            size={24}
            color="#ffffff"
            style={{ marginRight: "2%" }}
          />
        }
      />
      </View>
   </View>
   </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor:Colors.primaryColor
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom:15,
    marginTop:40
  },
  label: {
    color: "black",
    marginLeft: 0,
    marginBottom: 3,
    fontSize: windowWidth > 600 ? 18 : 16,
    fontWeight: "500",
    marginTop: windowWidth > 600 ? 0 : 12,
  },
  child1: {
    width: windowWidth > 600 ? "15%" : "30%",
  },
  child2: {
    width: windowWidth > 600 ? "85%" : "70%",
  },
});
export default UpdateGroup;
