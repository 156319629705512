import React, { useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Dimensions
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  Button,
  Overlay,
  ListItem,
  SpeedDial,
  Avatar,
  Dialog,
  SearchBar,
} from "react-native-elements";

import {
  Ionicons,
  Entypo,
  MaterialIcons,
  MaterialCommunityIcons,
  FontAwesome,
  Octicons,
  FontAwesome5,
} from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import { useForm, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import Loading from "../Utils/Loading";
import SectionedMultiSelect from "react-native-sectioned-multi-select";
// import DateTimePicker from "@react-native-community/datetimepicker";
import moment from "moment";
import { ModulesPermission } from "../../constants/modules";
import { authorizedForFlateList } from "../../constants/authorizedForFlateList";
import AuthorizedFor from "../../constants/authorizedFor";
import CustomDatePicker from "../../components/datePicker/datePicker";
import CustomPicker from "../../components/Picker/Picker";
const windowWidth = Dimensions.get("window").width;

const Employees = (navigation) => {
  const [employeesData, setEmployeesData] = useState([]);
  const [employeesDataIntial, setEmployeesDataIntial] = useState([]);
  const [openDial, setOpenDial] = useState(false);
  const [isLoding, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [employeeRoles, setEmployeeRoles] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedGender, setSelectedGender] = useState(null);
  const [date, setDate] = useState(new Date());
  const [date1, setDate1] = useState(new Date());
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [dob, setDob] = useState(new Date());
  const [joiningDate, setJoiningDate] = useState(new Date());
  const [deleteShow, setDeleteShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Its Error");
  const [search, setSearch] = useState("");

  const gendersData = [{value:"Male", label:"Male"},{value:"Female", label:"Female"}];

  const [rolesList, setRolesList] = useState([]);
  const [selectedRole, setSelectedRole] = useState(0);

  const [isDeleteAble, setIsDeleteAble] = useState(false);
  const [isUpdateAble, setIsUpdateAble] = useState(false);

  useEffect(() => {
    (async () => {
      GetEmployees();
      GetEmployeeRoles();
    })();
  }, [navigation, navigation.route.params]);

  useEffect(() => {
    (async () => {
      (await authorizedForFlateList(ModulesPermission.Update_Employee)) &&
        setIsUpdateAble(true);
      (await authorizedForFlateList(ModulesPermission.Delete_Employee)) &&
        setIsDeleteAble(true);
    })();
  }, [employeesDataIntial.length]);

  const GetEmployees = () => {
    setIsLoading(true);

    jwt
      .get(`${Url.GET_EMPLOYEES}/${global.systemID}`)
      .then((response) => {
        setIsLoading(false);
        setEmployeesData(response.data.employees);
        setEmployeesDataIntial(response.data.employees);
        // setClientsData(response.data.clients);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetEmployeeRoles = () => {
    jwt
      .get(`${Url.GET_EMPLOYEE_ROLES}/${global.systemID}`)
      .then((response) => {
        const newLit = [{value:"All", label:"All"}];
        response.data.roles.map((item) => newLit.push({value:item.name, label:item.name, ...item}));
        setEmployeeRoles(newLit);
      })
      .catch((err) => {});
  };

  const handleGender = (selectedItem) => {
    setSelectedGender(selectedItem);
  };

  const onSelectedItemsChange = (selectedItems) => {
    setSelectedItems(selectedItems);
  };

  const onChangeDob = (event, selectedDate) => {
    const currentDate = selectedDate;
    setShow1(!show);
    setDob(currentDate);
  };

  const onChangeJoiningDate = (event, selectedDate) => {
    const currentDate = selectedDate;
    setShow2(false);
    setJoiningDate(currentDate);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setIsLoading(true);
    let newRoles = [];
    selectedItems.map((item) =>
      employeeRoles
        .filter((obj) => obj.name === item)
        .map((rol) =>
          newRoles.push({
            role_id: rol.id,
            name: rol.name,
          })
        )
    );

    const newData = {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      phone: data.phone,
      address: data.address,
      post_code: data.postCode,
      gender: selectedGender,
      roles: newRoles,
      dob: `${moment(dob).format("YYYY-MM-DD")}`,
      joining_date: `${moment(joiningDate).format("YYYY-MM-DD")}`,
      status: "Inactive",
      system_id: global.systemID,
    };

    if (dob === null) {
      setErrorMessage("Please Select date of birth");
      setShow(true);
    } else if (selectedGender === null) {
      setErrorMessage("Please Select Gender");
      setShow(true);
    } else if (newRoles.length === 0) {
      setErrorMessage("Please Select Roles");
      setShow(true);
    } else {
      jwt
        .post(Url.ADD_EMPLOYEE, newData)
        .then((response) => {
          setIsLoading(false);
          setIsVisible(!isVisible);
          GetEmployees();
          setOpenDial(!openDial);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }

    const handleDelete = (id) => {
      jwt
        .del(`${Url.DELETE_EMPLOYEE}/${id}`)
        .then((response) => {
          setDeleteShow(!deleteShow);
          GetEmployees();
        })
        .catch((err) => {
          setIsLoading(false);
        });
    };
  };

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          Employees
        </Text>
        <Ionicons
          name="menu-outline"
          size={30}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 20.0,
            // fontWeight: 300
          }}
          onPress={() => navigation.navigation.toggleDrawer()}
        />
      </View>
    );
  };

  const handleSearch = (value) => {
    setSearch(value);
    if (value.length > 1) {
      const newData = employeesData.filter(
        (e) =>
          e.employee.first_name.toUpperCase().includes(value.toUpperCase()) ||
          e.employee.last_name.toUpperCase().includes(value.toUpperCase()) ||
          e.employee.address.toUpperCase().includes(value.toUpperCase())
      );
      if (newData.length > 0) {
        setEmployeesData(newData);
      } else {
        setEmployeesData(employeesDataIntial);
      }
    } else {
      setEmployeesData(employeesDataIntial);
    }
  };

  const GetIntials = (nameString) => {
    const fullName = nameString.split(" ");
    const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
    return initials.toUpperCase();
  };

  const handleFilter = (selectedItem, index) => {
    if (selectedItem === "All") {
      setEmployeesData(employeesDataIntial);
    } else {
      setEmployeesData(
        employeesDataIntial.filter((obj) =>
          obj.role_names.some((role) => role === selectedItem)
        )
      );
    }
  };

  const renderItem = ({ item, index }) => (
    <View style={styles.gridbox}>
    <ListItem.Swipeable
      key={index}
      leftContent={
        isUpdateAble ? (
          <Button
            title="Update"
            icon={{ name: "edit", color: "white" }}
            buttonStyle={{
              marginTop: 15,
              minHeight: "80%",
              borderBottomLeftRadius: 6,
              borderTopLeftRadius: 6,
            }}
            onPress={() => {
              navigation.navigation.navigate("UpdateEmployee", {
                item: item,
              });
            }}
          />
        ) : null
      }
      rightContent={
        isDeleteAble ? (
          <Button
            title="Delete"
            icon={{ name: "delete", color: "white" }}
            buttonStyle={{
              marginTop: 15,
              minHeight: "80%",
              backgroundColor: "red",
              borderBottomRightRadius: 6,
              borderTopRightRadius: 6,
            }}
            onPress={() => {
              setSelectedItem(item);
              setDeleteShow(!deleteShow);
            }}
          />
        ) : null
      }
      containerStyle={{
        marginTop: 15,
        zIndex: 1,
        borderRadius: 6,
      }}
    >
      <Avatar
        size={54}
        rounded
        source={item.avatar != null && { uri: item.avatar }}
        title={GetIntials(
          `${item.employee.first_name} ${item.employee.last_name}`
        )}
        containerStyle={{ backgroundColor: "grey" }}
      ></Avatar>

      <ListItem.Content>
        <ListItem.Title style={{ marginBottom: 4 }}>
          {item.employee.first_name} {item.employee.last_name}
        </ListItem.Title>
        <ListItem.Subtitle numberOfLines={1}>
          {item.employee.address}
        </ListItem.Subtitle>
        <View
          style={{
            flexDirection: "row",

            paddingTop: 10,
            width: "100%",
          }}
        >
          <Text>{item.city}</Text>
          <TouchableOpacity
            style={{
              position: "absolute",
              right: 2,
              top: -50,
              zIndex: 10,
            }}
            onPress={() => {
              navigation.navigation.navigate("UpdateEmployee", {
                item: item,
              });
            }}
          >
            <MaterialCommunityIcons
              name="account-edit-outline"
              size={30}
              color={Colors.primaryColor}
            />
          </TouchableOpacity>
        </View>
      </ListItem.Content>
    </ListItem.Swipeable>
    </View>
  );


  return (
    <View style={styles.container}>
      {header()}
      <SearchBar
        placeholder="Type Here..."
        onChangeText={handleSearch}
        value={search}
        lightTheme
        containerStyle={{
          backgroundColor: "#eee",
          borderWidth: 0,
          elevation: 0,
          paddingLeft: Sizes.fixPadding * 2,
          paddingRight: Sizes.fixPadding * 2,
        }}
        // placeholderTextColor="#000"
        inputStyle={{
          color: "#000",
        }}
        inputContainerStyle={{
          backgroundColor: "#ccc",
        }}
      />
      <View
        style={{
          padding: Sizes.fixPadding * 2,
          paddingTop: 5,
          paddingBottom: 0,
          backgroundColor:"#F2F3F4"
        }}
      >
        <CustomPicker
                  data={employeeRoles}
                  selectedValue={selectedRole}
                  handleSelectedValue={handleFilter}
                  defaultLabel = {"Select Roles"}
                  style={{}}
                />
       
      </View>
      <View style={{ flex: 1, backgroundColor:"#F2F3F4" ,padding: Sizes.fixPadding * 2, paddingTop: 0 }}>
        {/* <ScrollView style={{ flex: 1 }}> */}
        <FlatList
          showsVerticalScrollIndicator={false}
          nestedScrollEnabled
          data={employeesData}
          renderItem={renderItem}
   
          keyExtractor={(item) => item.id}
          numColumns={windowWidth > 700 ? 3 : 1}
        />
      </View>

      {isLoding && <Loading />}
      {AuthorizedFor(ModulesPermission.Create_Employee) && (
        <SpeedDial
          isOpen={openDial}
          icon={{ name: "edit", color: "#fff" }}
          openIcon={{ name: "close", color: "#fff" }}
          onOpen={() => setOpenDial(!openDial)}
          onClose={() => setOpenDial(!openDial)}
          style={{ zIndex: 2 }}
          buttonStyle={{ backgroundColor: Colors.primaryColor }}
        >
          <SpeedDial.Action
            icon={{ name: "add", color: "#fff" }}
            title="Add Employee"
            onPress={() => setIsVisible(!isVisible)}
            buttonStyle={{ backgroundColor: Colors.primaryColor }}
          />
        </SpeedDial>
      )}
      <Overlay
        visible={isVisible}
        overlayStyle={{
          width: "80%",
          backgroundColor: "#FFFFFF",
          borderRadius: 8,
          paddingTop: 15,
          paddingBottom: 20,
          padding: Sizes.fixPadding * 2,
          //   marginTop: 40,
          //   marginBottom: 50,
          height: 500,
        }}
        onBackdropPress={() => setIsVisible(!isVisible)}
      >
        <View style={{ alignItems: "center", marginTop: 3, marginBottom: 10 }}>
          <Text style={{ fontSize: 18 }}>New Employee</Text>
        </View>
        <ScrollView
        //   style={{
        //     flex: 1,
        //   }}
        >
            <View style={{flexDirection:'row', marginBottom:15, marginTop:15}}>
                <View style={styles.child1}>
                <Text style={styles.label}>{"First Name"}</Text>
                </View>
                <View style={styles.child2}>
          <Controller
            name="firstName"
            control={control}
            rules={{
              required: { value: true, message: "FirstName is required" },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                // label="First Name"
                type="text"
                onChangeText={(value) => onChange(value)}
                value={value}
                error={errors.firstName}
                errorText={errors?.firstName?.message}
              />
            )}
            defaultValue=""
          />
          </View>
          </View>

          <View style={{flexDirection:'row', marginBottom:15}}>
                <View style={styles.child1}>
                <Text style={styles.label}>{"Last Name"}</Text>
                </View>
                <View style={styles.child2}>
          <Controller
            name="lastName"
            control={control}
            rules={{
              required: { value: true, message: "Last Name is required" },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                // label="Last Name"
                type="text"
                onChangeText={(value) => onChange(value)}
                value={value}
                error={errors.lastName}
                errorText={errors?.lastName?.message}
              />
            )}
            defaultValue=""
          />
          </View>
          </View>

          <View style={{flexDirection:'row', marginBottom:15}}>
                <View style={styles.child1}>
                <Text style={styles.label}>{"Email"}</Text>
                </View>
                <View style={styles.child2}>
          <Controller
            name="email"
            control={control}
            rules={{
              required: { value: true, message: "Email is required" },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                // label="Email"
                type="email"
                onChangeText={(value) => onChange(value)}
                value={value}
                error={errors.email}
                errorText={errors?.email?.message}
              />
            )}
            defaultValue=""
          />
          </View>
          </View>

          <View style={{flexDirection:'row', marginBottom:15}}>
                <View style={styles.child1}>
                <Text style={styles.label}>{"Phone"}</Text>
                </View>
                <View style={styles.child2}>
          <Controller
            name="phone"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                // label="Phone"
                type="text"
                onChangeText={(value) => onChange(value)}
                value={value}
              />
            )}
            defaultValue=""
          />
          </View>
          </View>


<View style={{flexDirection:'row', marginBottom:15}}>
                <View style={styles.child1}>
                <Text style={ styles.label }>Dob</Text>
                </View>
                <View style={{...styles.child2, }}>
                <CustomDatePicker
                  date={onChangeDob}
                  defaultDate={dob}
                  show={show1}
                  setShow={setShow1}
                 
                />
                </View>
                </View>

                <View style={{flexDirection:'row', marginBottom:15}}>
                <View style={styles.child1}>
                <Text style={styles.label}>{"Address"}</Text>
                </View>
                <View style={styles.child2}>

          <Controller
            name="address"
            control={control}
            rules={{
              required: { value: true, message: "Address is required" },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                // label="Address"
                type="text"
                onChangeText={(value) => onChange(value)}
                value={value}
                error={errors.address}
                errorText={errors?.address?.message}
              />
            )}
            defaultValue=""
          />
          </View>
          </View>

          <View style={{flexDirection:'row', marginBottom:15}}>
                <View style={styles.child1}>
                <Text style={styles.label}>{"PostCode"}</Text>
                </View>
                <View style={styles.child2}>
          <Controller
            name="postCode"
            control={control}
            //   rules={{
            //     required: { value: true, message: "Company is required" },
            //   }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                // label="PostCode"
                type="text"
                onChangeText={(value) => onChange(value)}
                value={value}
                //   error={errors.company}
                //   errorText={errors?.company?.message}
              />
            )}
            defaultValue=""
          />
          </View>
          </View>

<View style={{flexDirection:'row', marginBottom:15}}>
                <View style={styles.child1}>
                <Text style={ styles.label }>Joining Date</Text>
                </View>
                <View style={{...styles.child2, }}>
                <CustomDatePicker
                  date={onChangeJoiningDate}
                  defaultDate={joiningDate}
                  show={show2}
                  setShow={setShow2}
                 
                />
                </View>
                </View>
          
                <View style={{flexDirection:'row', marginBottom:15}}>
                <View style={styles.child1}>
                <Text style={styles.label}>{"Gender"}</Text>
                </View>
                <View style={styles.child2}>
                <CustomPicker
                  data={gendersData}
                  selectedValue={selectedGender}
                  handleSelectedValue={handleGender}
                  defaultLabel = {"Select gender"}
                  style={{}}
                />
</View>
                </View>

        
                <View style={{flexDirection:'row', marginBottom:15}}>
                <View style={styles.child1}>
                <Text style={styles.label}>{"Role"}</Text>
                </View>
                <View style={styles.child2}>
         
          <SectionedMultiSelect
            items={employeeRoles}
            // ref={ref}
            IconRenderer={MaterialIcons}
            uniqueKey="name"
            // subKey="children"
            selectText="Select Days"
            showDropDowns={true}
            //   readOnlyHeadings={true}
            onSelectedItemsChange={onSelectedItemsChange}
            selectedItems={selectedItems}
            hideSearch={true}
            searchPlaceholderText="Search reasons"
            //   text={Colors.primaryColor}
            styles={{
              button: {
                backgroundColor: Colors.primaryColor,
                selectedItemText: Colors.primaryColor,
              },
              selectToggle: {
                borderColor: "#CCCCCC",
                padding: 10,
                borderRadius: 4,
                borderWidth: 1,
                height: 40,
                // marginBottom: 15,
              },
              selectToggleText: {
                fontSize: 14,
              },
              itemIconStyle: {
                fontSize: 14,
              },
            }}
            theme={{
              Colors: {
                selectToggleTextColor: "#053075",
                text: "#053075",
                selectedItemText: Colors.primaryColor,
              },
            }}
          />
          </View>
          </View>

          <Button
            title="Add Employee"
            type="solid"
            loading={isLoding}
            onPress={handleSubmit(onSubmit)}
            buttonStyle={{
              padding: Sizes.fixPadding,
              width: "100%",
              backgroundColor: Colors.primaryColor,
              borderRadius: 22,
            }}
            containerStyle={{
              width: "100%",
              // marginHorizontal: 50,
              marginVertical: 20,
            }}
            titleStyle={{
              fontSize: 14,
              fontWeight: "100",
              color: Colors.whiteColor,
            }}
            icon={
              <Ionicons
                name="add-circle-sharp"
                size={24}
                color="#ffffff"
                style={{ marginRight: "2%" }}
              />
            }
          />
        </ScrollView>
      </Overlay>
{/* 
      {show1 && (
        <DateTimePicker
          testID="dateTimePicker"
          value={date}
          mode={"date"}
          // display={"spinner"}
          // is24Hour={true}
          onChange={onChangeDob}
        />
      )} */}

      {/* {show2 && (
        <DateTimePicker
          testID="dateTimePicker"
          value={date}
          mode={"date"}
          // display={"spinner"}
          // is24Hour={true}
          onChange={onChangeJoiningDate}
        />
      )} */}
      <Dialog isVisible={deleteShow}>
        <Dialog.Title title="Delete Price!" />
        <Text>Are you sure ? this action will not be revert.</Text>

        <Dialog.Actions>
          <Dialog.Button
            title="CONFIRM"
            onPress={() => {
              handleDelete(selectedItem.employee.id);
            }}
          />
          <Dialog.Button
            title="CANCEL"
            onPress={() => {
              setDeleteShow(!deleteShow);
            }}
          />
        </Dialog.Actions>
      </Dialog>

      <Dialog isVisible={show}>
        <Dialog.Title title="Error!" />
        <Text>{errorMessage}</Text>

        <Dialog.Actions>
          <Dialog.Button
            title="Ok"
            onPress={() => {
              setShow(!show);
            }}
          />
        </Dialog.Actions>
      </Dialog>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor:Colors.primaryColor
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom:15,
    marginTop:40
  },
  label: {
    color: "black",
    marginLeft: 0,
    marginBottom: 3,
    fontSize:windowWidth > 600 ? 18 : 16,
    fontWeight:'500',
    marginTop:windowWidth > 600 ? 0 : 12
  },
  child1:{
    width:windowWidth > 600 ? '15%' : '30%'
  },
  child2:{
    width:windowWidth > 600 ? '85%' : '70%'
  },
  gridbox: {
    flex: 1, 
    margin: windowWidth > 700 ? 5 : 0,
    marginBottom:0
  },
});
export default Employees;
