import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, ScrollView, FlatList, Dimensions } from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  Button,
  SpeedDial,
  ListItem,
  Overlay,
  Dialog,
  SearchBar,
} from "react-native-elements";
import {
  Feather,
  Ionicons,
  Entypo,
  MaterialIcons,
  Octicons,
} from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import { useForm, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import SelectDropdown from "react-native-select-dropdown";
import Loading from "../Utils/Loading";
import UpdateList from "./UpdateList";
import AuthorizedFor from "../../constants/authorizedFor";
import { authorizedForFlateList } from "../../constants/authorizedForFlateList";
import { ModulesPermission } from "../../constants/modules";
import CustomPicker from "../../components/Picker/Picker";
const windowWidth = Dimensions.get('window').width;

const RejectedCheckList = (navigation) => {
  const refreshScreen = navigation.route.params ? navigation.route.params.Refresh : false;
  const [checkListData, setCheckListData] = useState([]);
  const [checkListDataIntial, setCheckListDataIntial] = useState([]);
  const [cleaningData, setCleaningData] = useState([]);
  const [cleaningList, setCleaningList] = useState([]);
  const [roomData, setRoomData] = useState([]);
  const [roomList, setRoomList] = useState([]);
  const [propertyData, setPropertyData] = useState([]);
  const [propertyList, setPropertList] = useState([]);
  const [selectedCleaningType, setSelectedCleaningType] = useState(null);
  const [selectedRoomType, setSelectedRoomType] = useState(null);
  const [selectedPropertyType, setSelectedPropertyType] = useState(null);
  const [openDial, setOpenDial] = useState(false);
  const [isLoding, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible1, setIsVisible1] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [deleteShow, setDeleteShow] = useState(false);
  const [search, setSearch] = useState("");
  const [isDeleteAble, setIsDeleteAble] = useState(false);
  const [isUpdateAble, setIsUpdateAble] = useState(false);

  useEffect(() => {
    (async () => {
      GetData();
      GetCleaningType();
      GetRoomType();
      GetPropertyType();
    })();
  }, [refreshScreen]);

  useEffect(() => {
    (async () => {
      (await authorizedForFlateList(
        ModulesPermission.Update_Single_Rejected_Checklist
      )) && setIsUpdateAble(true);
      (await authorizedForFlateList(
        ModulesPermission.Delete_Rejected_Checklist
      )) && setIsDeleteAble(true);
    })();
  }, [checkListDataIntial.length]);

  const GetData = () => {
    setIsLoading(true);
    jwt
      .get(`${Url.GET_REJECTED_CHECKLIST}/${global.systemID}`)
      .then((response) => {
        setIsLoading(false);
        setCheckListData(response.data.rejected_checklist);
        setCheckListDataIntial(response.data.rejected_checklist);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetCleaningType = () => {
    jwt
      .get(`${Url.GET_ACTIVE_CLEANING_TYPE}/${global.systemID}`)
      .then((response) => {
        const newArr = [];
        // setCleaningData(response.data.cleaning_types);
        response.data.cleaning_types.map((item) => {
          newArr.push({
            value: item.id,
            label: item.name,
            ...item,
          });
        });
        setCleaningData(newArr);
      })
      .catch((err) => {});
  };

  const GetPropertyType = () => {
    jwt
      .get(`${Url.GET_ACTIVE_PROPERTY_TYPE}/${global.systemID}`)
      .then((response) => {
        const newArr = [];
        // setCleaningData(response.data.cleaning_types);
        response.data.property_types.map((item) => {
          newArr.push({
            value: item.id,
            label: item.name,
            ...item,
          });
        });
        setPropertyData(newArr);
      })
      .catch((err) => {});
  };

  const GetRoomType = () => {
    jwt
      .get(`${Url.GET_ACTIVE_ROOM_TYPE}/${global.systemID}`)
      .then((response) => {
        const newArr = [];
        // setRoomData(response.data.room_types);
        response.data.room_types.map((item) => newArr.push({
          value: item.id,
          label: item.name,
          ...item,
        }));
        setRoomData(newArr);
      })
      .catch((err) => {});
  };


  const handleSelectedCleaning = (val, index) => {
    // setSystemError(false);
    setSelectedCleaningType(val);
  };

  const handleSelectedRoom = (val, index) => {
    // setSystemError(false);
    setSelectedRoomType(val);
  };

  const handleSelectedProperty = (val, index) => {
    // setSystemError(false);
    setSelectedPropertyType(val);
  };

  const toggelVisible = () => {
    setIsVisible1(!isVisible1);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // if (selectedSystem !== null) {
    setIsLoading(true);
    jwt
      .post(Url.ADD_REJECTED_CHECKLIST, {
        cleaning_type_id: selectedCleaningType,
        room_type_id: selectedRoomType,
        property_type_id: selectedPropertyType,
        reason: data.reason,
        system_id: parseInt(global.systemID),
      })
      .then((response) => {
        setIsLoading(false);
        setIsVisible(!isVisible);
        GetData();
        setOpenDial(!openDial);
      })
      .catch((err) => {
        setIsLoading(false);
      });
    // } else {
    //   setSystemError(true);
    // }
  };

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          Rejected Checklist
        </Text>
        <Ionicons
          name="menu-outline"
          size={30}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 20.0,
            // fontWeight: 300
          }}
          onPress={() => navigation.navigation.toggleDrawer()}
        />
      </View>
    );
  };

  const handleDelete = (id) => {
    jwt
      .del(`${Url.DELETE_REJECTED_CHECKLIST}/${id}`)
      .then((response) => {
        setDeleteShow(!deleteShow);
        GetData();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const renderItem = ({ item, index }) => (
    <ListItem.Swipeable
      // onPress={() => {
      //   navigation.navigation.navigate("Tasks", { item: item });
      // }}
      key={index}
      leftContent={
        isUpdateAble ? (
          <Button
            title="Update"
            icon={{ name: "edit", color: "white" }}
            buttonStyle={{
              minHeight: "80%",
              borderBottomLeftRadius: 6,
              borderTopLeftRadius: 6,
            }}
            onPress={() => {
              setSelectedItem(item);
              navigation.navigation.navigate("UpdateList", { item: item });
            }}
          />
        ) : null
      }
      rightContent={
        isDeleteAble ? (
          <Button
            title="Delete"
            icon={{ name: "delete", color: "white" }}
            buttonStyle={{
              minHeight: "80%",
              backgroundColor: "red",
              borderBottomRightRadius: 6,
              borderTopRightRadius: 6,
            }}
            onPress={() => {
              setSelectedItem(item);
              setDeleteShow(!deleteShow);
            }}
          />
        ) : null
      }
      containerStyle={{ marginBottom: 15, zIndex: 1, borderRadius: 6 }}
    >
      {/* <FontAwesome5 name="user-tie" size={38} color="#444" /> */}
      <Octicons name="checklist" size={38} color="#444" />
      {/* <MaterialIcons name="room-preferences" size={38} color="#444" /> */}
      <ListItem.Content>
        <ListItem.Title style={{ marginBottom: 4 }}>
          {item.property_type}
        </ListItem.Title>
        <ListItem.Subtitle numberOfLines={1}>{item.reason}</ListItem.Subtitle>
        <ListItem.Subtitle numberOfLines={1}>
          {item.status == 1 ? "Active" : "InActive"}
        </ListItem.Subtitle>
      </ListItem.Content>
      {/* <ListItem.Chevron /> */}
    </ListItem.Swipeable>
  );

  const handleSearch = (value) => {
    setSearch(value);
    if (value.length > 1) {
      const newData = checkListData.filter(
        (e) =>
          e.reason.toUpperCase().includes(value.toUpperCase()) ||
          e.property_type.toUpperCase().includes(value.toUpperCase())
      );
      if (newData.length > 0) {
        setCheckListData(newData);
      } else {
        setCheckListData(checkListDataIntial);
      }
    } else {
      setCheckListData(checkListDataIntial);
    }
  };

  return (
    <View style={styles.container}>
      {header()}

      <SearchBar
        placeholder="Type Here..."
        onChangeText={handleSearch}
        value={search}
        lightTheme
        containerStyle={{
          backgroundColor: "#eee",
          borderWidth: 0,
          elevation: 0,
          paddingLeft: Sizes.fixPadding * 2,
          paddingRight: Sizes.fixPadding * 2,
        }}
        // placeholderTextColor="#000"
        inputStyle={{
          color: "#000",
        }}
        inputContainerStyle={{
          backgroundColor: "#ccc",
        }}
      />
      <View style={{ flex: 1, backgroundColor:"#F2F3F4" ,padding: Sizes.fixPadding * 2, paddingTop: 0 }}>
        {/* <ScrollView style={{ flex: 1 }}> */}
        <FlatList
          showsVerticalScrollIndicator={false}
          nestedScrollEnabled
          data={checkListData}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
        />
      </View>

      {isLoding && <Loading />}
      <Overlay
        visible={isVisible}
        overlayStyle={{
          width: "80%",
          backgroundColor: "#FFFFFF",
          borderRadius: 8,
          paddingTop: 15,
          paddingBottom: 20,
          padding: Sizes.fixPadding * 2,
        }}
        onBackdropPress={() => setIsVisible(!isVisible)}
      >
        <View style={{ alignItems: "center", marginTop: 3, marginBottom: 10 }}>
          <Text style={{ fontSize: 18 }}>New CheckList</Text>
        </View>

        <View style={{flexDirection:'row', marginTop:15}}>
                <View style={styles.child1}>
                <Text style={{ ...styles.label, marginTop: 10 }}>
                  {"Cleaning Type"}
                </Text>
                </View>
                <View style={styles.child2}>
                <CustomPicker
                  data={cleaningData}
                  selectedValue={selectedCleaningType}
                  handleSelectedValue={handleSelectedCleaning}
                  defaultLabel={"Select cleaning type"}
                  style={{}}
                />
                </View>
                </View>
                <View style={{flexDirection:'row', marginTop:15}}>
                <View style={styles.child1}>
                <Text style={styles.label}>{"Room Type"}</Text>
</View>

<View style={styles.child2}>
                <CustomPicker
                  data={roomData}
                  selectedValue={selectedRoomType}
                  handleSelectedValue={handleSelectedRoom}
                  defaultLabel={"Select Room Type"}
                  style={{}}
                />
                </View>
                </View>

<View style={{flexDirection:'row', marginTop:15}}>
                <View style={styles.child1}>
                <Text style={styles.label}>{"Property Type"}</Text>
</View>

<View style={styles.child2}>
                <CustomPicker
                  data={propertyData}
                  selectedValue={selectedPropertyType}
                  handleSelectedValue={handleSelectedProperty}
                  defaultLabel={"Select Property Type"}
                  style={{}}
                />
                </View>
                </View>

                <View style={{flexDirection:'row', marginTop:15}}>
                <View style={styles.child1}>
                <Text style={styles.label}>{"Reason"}</Text>
</View>

<View style={styles.child2}>
        <Controller
          name="reason"
          control={control}
          // rules={{
          //   required: { value: true, message: "Room type is required" },
          // }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              // label="Reason"
              type="text"
              onChangeText={(value) => onChange(value)}
              value={value}
              // error={errors.type}
              // errorText={errors?.type?.message}
            />
          )}
          defaultValue=""
        />
        </View>
        </View>

        <Button
          title="Add Type"
          type="solid"
          //   loading={isLoding}
          onPress={handleSubmit(onSubmit)}
          buttonStyle={{
            padding: Sizes.fixPadding,
            width: "100%",
            backgroundColor: Colors.primaryColor,
            borderRadius: 22,
          }}
          containerStyle={{
            width: "100%",
            // marginHorizontal: 50,
            marginVertical: 20,
          }}
          titleStyle={{
            fontSize: 14,
            fontWeight: "100",
            color: Colors.whiteColor,
          }}
          icon={
            <Ionicons
              name="add-circle-sharp"
              size={24}
              color="#ffffff"
              style={{ marginRight: "2%" }}
            />
          }
        />
      </Overlay>
      {AuthorizedFor(ModulesPermission.Create_Rejected_Checklist) && (
        <SpeedDial
          isOpen={openDial}
          icon={{ name: "edit", color: "#fff" }}
          openIcon={{ name: "close", color: "#fff" }}
          onOpen={() => setOpenDial(!openDial)}
          onClose={() => setOpenDial(!openDial)}
          style={{ zIndex: 2 }}
          buttonStyle={{ backgroundColor: Colors.primaryColor }}
        >
          <SpeedDial.Action
            icon={{ name: "add", color: "#fff" }}
            title="Add Type"
            onPress={() => setIsVisible(!isVisible)}
            buttonStyle={{ backgroundColor: Colors.primaryColor }}
          />
        </SpeedDial>
      )}

      {isVisible1 && (
        <UpdateList
          selectedItem={selectedItem}
          isVisible1={isVisible1}
          toggleOverlay={toggelVisible}
        />
      )}

      <Dialog isVisible={deleteShow}>
        <Dialog.Title title="Delete Type!" />
        <Text>Are you sure ? this action will not be revert.</Text>

        <Dialog.Actions>
          <Dialog.Button
            title="CONFIRM"
            onPress={() => {
              handleDelete(selectedItem.id);
            }}
          />
          <Dialog.Button
            title="CANCEL"
            onPress={() => {
              setDeleteShow(!deleteShow);
            }}
          />
        </Dialog.Actions>
      </Dialog>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor:Colors.primaryColor
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom:15,
    marginTop:40
  },
  label: {
    color: "black",
    marginLeft: 0,
    marginBottom: 3,
    fontSize:windowWidth > 600 ? 18 : 16,
    fontWeight:'500',
    marginTop:windowWidth > 600 ? 0 : 12
  },
  child1:{
    width:windowWidth > 600 ? '15%' : '30%'
  },
  child2:{
    width:windowWidth > 600 ? '85%' : '70%'
  }

});
export default RejectedCheckList;
