import React, { useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  Dimensions
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import { Button } from "react-native-elements";

import { Ionicons } from "@expo/vector-icons";
import AllPrices from "./allPrices";
import DefaultPrices from "./DefaultPrices";

const windowWidth = Dimensions.get("window").width;

const PriceList = (navigation) => {
  const [showAllPrices, setShowAllPrices] = useState(true);
  const [showDefaultPrices, setShowDefaultPrices] = useState(false);

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          Price List
        </Text>
        <Ionicons
          name="menu-outline"
          size={30}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 20.0,
            // fontWeight: 300
          }}
          onPress={() => navigation.navigation.toggleDrawer()}
        />
       
      </View>
    );
  };

  return (
    <View style={styles.container}>
      {header()}
      <View
        style={{
          padding: Sizes.fixPadding,
          flexDirection: "row",
          marginTop: 10,
          backgroundColor: "#F2F3F4",
        }}
      >
        <View style={{ width: "49%", marginRight: "1%" }}>
          <Button
            title="All Prices"
            type="solid"
            onPress={() => { setShowAllPrices(true); setShowDefaultPrices(false) }}
            buttonStyle={{
              padding: Sizes.fixPadding,
              width: "100%",
              backgroundColor: Colors.primaryColor,
              borderRadius: 12,
       
            }}
            containerStyle={{
              width: "100%",
              marginVertical: 20,
            }}
            titleStyle={{
              fontSize: 14,
              fontWeight: "100",
              color: Colors.whiteColor,
            }}
          />
        </View>
        <View style={{ width: "49%", marginLeft: "1%" }}>
          <Button
            title="Default Prices"
            type="solid"
            onPress={() => { setShowAllPrices(false); setShowDefaultPrices(true) }}
            buttonStyle={{
              padding: Sizes.fixPadding,
              width: "100%",
              backgroundColor: Colors.primaryColor,
              borderRadius: 12,
       
            }}
            containerStyle={{
              width: "100%",

              marginVertical: 20,
            }}
            titleStyle={{
              fontSize: 14,
              fontWeight: "100",
              color: Colors.whiteColor,
            }}
          />
        </View>
      </View>

      {showAllPrices && <AllPrices />}
      {showDefaultPrices && <DefaultPrices />}
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: Colors.primaryColor,
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 15,
    marginTop: 40,
  },
  contentContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 10,
  },
  label: {
    color: "black",
    marginLeft: 0,
    marginBottom: 3,
    fontSize: windowWidth > 600 ? 18 : 16,
    fontWeight: "500",
    marginTop: windowWidth > 600 ? 0 : 12,
  },
  child1: {
    width: windowWidth > 600 ? "15%" : "30%",
  },
  child2: {
    width: windowWidth > 600 ? "85%" : "70%",
  },
});
export default PriceList;
