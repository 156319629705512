import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  StatusBar,
  Image,
  TextInput,
  TouchableOpacity,
  Alert,
  Dimensions,
  FlatList,
  SafeAreaView,
} from "react-native";
import {
  Button,
  Overlay,
  ListItem,
  SpeedDial,
  Avatar,
  Dialog,
} from "react-native-elements";

import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  FontAwesome5,
  Ionicons,
  MaterialCommunityIcons,
  Feather,
  MaterialIcons,
} from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import Constants from "expo-constants";
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import * as SecureStore from "expo-secure-store";
import Loading from "../Utils/Loading";
import AuthorizedFor from "../../constants/authorizedFor";
import { authorizedForFlateList } from "../../constants/authorizedForFlateList";
import { ModulesPermission } from "../../constants/modules";
import CustomPicker from "../../components/Picker/Picker";

const windowWidth = Dimensions.get("screen");

const UserNotifications = (navigation) => {
  const [notifications, setNotifications] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [expoKey, setExpoKey] = useState(null);
  const [isLoding, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDeleteAble, setIsDeleteAble] = useState(false);
  const [clientsList, setClientsList] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  useEffect(() => {
    GetClients();
  }, []);

  const GetClients = () => {
    setIsLoading(true);

    jwt
      .get(`${Url.GET_ACTIVE_EMPLOYEES}/${global.systemID}`)
      .then((response) => {
        setIsLoading(false);
        console.log(response.data)
        if (response.data.employee.length > 0) {
          // setClientsList(response.data.clients);
          const NewData = [];

          response.data.employee.map((item) => {
            NewData.push({
              value: item.id,
              label: item.name,
              ...item,
            });
          });

          setClientsList(NewData);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    (async () => {
      (await authorizedForFlateList(
        ModulesPermission.Delete_User_Notification
      )) && setIsDeleteAble(true);
    })();
  }, [notifications.length]);

  const GetData = (id) => {
    setIsLoading(true);
    jwt
      .get(`${Url.GET_USER_NOTIFICATION_ADMIN}/${id}`)
      .then((response) => {
        console.log(response);
        setNotifications(response.data.user_notifications);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleSelectedClient = (val, index) => {
    setSelectedClient(parseInt(val));
    console.log(val);
    GetData(val);
  };

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "bold", fontSize: 20 }}
        >
          User Notifications
        </Text>
        <MaterialIcons
          name="arrow-back"
          size={26}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 15.0,
            fontWeight: 300,
          }}
          onPress={() => {
            navigation.navigation.navigate("Dashboard", {
              notifyBack: "notifyBack",
            });
            // global.notification = false;
          }}
        />
      </View>
    );
  };

  const renderItem = ({ item }) => (
    <ListItem.Swipeable
      key={item.id}
      leftContent={
        <Button
          title="Details"
          // icon={{ name: "", color: "white" }}
          buttonStyle={{
            marginTop: 15,
            minHeight: "80%",
            borderBottomLeftRadius: 6,
            borderTopLeftRadius: 6,
          }}
          onPress={() => {
            item.data.data.push_notification_info &&
              navigation.navigation.navigate("NotificationDetail", {
                ListItem: item,
              });
          }}
        />
      }
      rightContent={
        isDeleteAble ? (
          <Button
            title="Delete"
            icon={{ name: "delete", color: "white" }}
            buttonStyle={{
              marginTop: 15,
              minHeight: "80%",
              backgroundColor: "red",
              borderBottomRightRadius: 6,
              borderTopRightRadius: 6,
            }}
            onPress={() => {
              setIsVisible(true);
              setSelectedItem(item);
            }}
          />
        ) : null
      }
      containerStyle={{
        marginTop: 15,
        zIndex: 1,
        borderRadius: 6,
      }}
    >
      <MaterialIcons
        name="notifications-active"
        size={35}
        color={Colors.primaryColor}
      />
      <ListItem.Content>
        {item.read_at == null ? (
          <ListItem.Title style={{ fontWeight: "bold", marginBottom: 4 }}>
            {item.data.data.push_notification_info
              ? item.data.data.push_notification_info.title
              : item.data.data.title}
          </ListItem.Title>
        ) : (
          <ListItem.Title style={{ fontWeight: "300", marginBottom: 4 }}>
            {item.data.data.push_notification_info
              ? item.data.data.push_notification_info.title
              : item.data.data.title}
          </ListItem.Title>
        )}
        <ListItem.Subtitle numberOfLines={2}>
          {item.data.data.push_notification_info
            ? item.data.data.push_notification_info.description
            : item.data.data.body}
        </ListItem.Subtitle>
      </ListItem.Content>
    </ListItem.Swipeable>

    // <TouchableOpacity
    //   style={{
    //     flex: 1,
    //     backgroundColor: "#FFF",
    //     borderRadius: 5,
    //     marginBottom: 15,
    //   }}
    //   onPress={() => {
    //     item.data.data.push_notification_info &&
    //       navigation.navigation.navigate("NotificationDetail", {
    //         ListItem: item,
    //       });
    //   }}
    // >
    //   <View
    //     style={{
    //       padding: Sizes.fixPadding,
    //       paddingTop: 15,
    //       paddingBottom: 15,
    //     }}
    //   >
    //     <View style={{ flexDirection: "row", alignItems: "center" }}>
    //       <View style={styles.notificationIconWrapStyle}>
    //         <MaterialIcons name="notifications-active" size={35} color="#FFF" />
    //       </View>
    //       <View
    //         style={{
    //           width: width / 1.9,
    //           marginLeft: Sizes.fixPadding,
    //         }}
    //       >
    //         {item.read_at == null ? (
    //           <Text style={styles.lable} numberOfLines={1}>
    //             {item.data.data.push_notification_info
    //               ? item.data.data.push_notification_info.title
    //               : item.data.data.title}
    //           </Text>
    //         ) : (
    //           <Text
    //             style={{ ...styles.lable, fontWeight: "300" }}
    //             numberOfLines={1}
    //           >
    //             {item.data.data.push_notification_info
    //               ? item.data.data.push_notification_info.title
    //               : item.data.data.title}
    //           </Text>
    //         )}

    //         <Text numberOfLines={2} style={{ width: "100%" }}>
    //           {item.data.data.push_notification_info
    //             ? item.data.data.push_notification_info.description
    //             : item.data.data.body}
    //         </Text>
    //       </View>

    //       <View
    //         style={{
    //           // width: width / 1.7,
    //           marginLeft: Sizes.fixPadding,
    //         }}
    //       >
    //         <Ionicons
    //           name="trash-bin-outline"
    //           size={24}
    //           color="red"
    //           onPress={() => {
    //             setIsVisible(true);
    //             setSelectedItem(item);
    //           }}
    //         />
    //         {/* <MaterialIcons name="notifications-active" size={20} /> */}
    //       </View>
    //     </View>
    //   </View>
    // </TouchableOpacity>
  );

  const handleDelete = (id) => {
    setIsLoading(true);
    jwt
      .del(`${Url.DELETE_USER_NOTIFICATION}/${id}`)
      .then((response) => {
        setIsLoading(false);
        setIsVisible(!isVisible);
        GetData();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <SafeAreaView style={styles.container}>
      {/* <StatusBar
        // translucent
        backgroundColor={Colors.primaryColor}
        barStyle="light-content"
      /> */}
      {header()}
      <View
        style={{
          flex: 1,
          backgroundColor: "#F2F3F4",
          padding: Sizes.fixPadding * 2,
          paddingTop: 20,
        }}
      >
        <View style={{ flexDirection: "row" }}>
          <View style={styles.child1}>
            <Text style={{ ...styles.label }}>{"Select Client"}</Text>
          </View>
          <View style={styles.child2}>
            <CustomPicker
              data={clientsList}
              selectedValue={selectedClient}
              handleSelectedValue={handleSelectedClient}
              defaultLabel={"Please select a client"}
              style={{}}
            />
          </View>
        </View>

        {notifications.length == 0 ? (
          <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            <View style={styles.noItemIconWrapStyle}>
              <MaterialIcons
                name="notifications-off"
                size={50}
                color={Colors.grayColor}
              />
            </View>
            <Text
              style={{
                marginTop: Sizes.fixPadding * 2.0,
              }}
            >
              No notifications
            </Text>
          </View>
        ) : (
          <View style={{ flex: 1, paddingTop: 0 }}>
            {/* <ScrollView style={{ flex: 1 }}> */}
            <FlatList
              showsVerticalScrollIndicator={false}
              nestedScrollEnabled
              data={notifications}
              renderItem={renderItem}
              keyExtractor={(item) => item.id}
            />
          </View>
        )}

        {isLoding && <Loading />}
      </View>
      <Dialog isVisible={isVisible}>
        <Dialog.Title title="Delete Notification!" />
        <Text>Are you sure ? this action will not be revert.</Text>

        <Dialog.Actions>
          <Dialog.Button
            title="CONFIRM"
            onPress={() => {
              handleDelete(selectedItem.id);
            }}
          />
          <Dialog.Button
            title="CANCEL"
            onPress={() => {
              setIsVisible(!isVisible);
            }}
          />
        </Dialog.Actions>
      </Dialog>
    </SafeAreaView>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: Colors.primaryColor,
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 15,
    marginTop: 40,
  },
  lable: {
    fontWeight: "700",
    marginBottom: 3,
  },
  headerWrapStyle: {
    height: 55.0,
    backgroundColor: Colors.primaryColor,
    elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
  },
  flexbox: {
    flexDirection: "row",
    // alignItems: "center",
    // justifyContent: "center",
    flexWrap: "wrap",
    // display: "flex",
  },
  innerBox: {
    flex: 1,
    backgroundColor: Colors.whiteColor,
    height: 100,
    padding: Sizes.fixPadding * 2,
    alignItems: "center",
    justifyContent: "center",
    // width: "50%",
    width: window.width / 2,
  },
  noItemIconWrapStyle: {
    backgroundColor: "#E8E8E8",
    height: 100.0,
    width: 100.0,
    borderRadius: 50.0,
    alignItems: "center",
    justifyContent: "center",
  },
  notificationIconWrapStyle: {
    height: 65.0,
    width: 65.0,
    borderRadius: 35.0,
    backgroundColor: Colors.primaryColor,
    alignItems: "center",
    justifyContent: "center",
  },
  child1: {
    width: windowWidth > 600 ? "15%" : "30%",
  },
  child2: {
    width: windowWidth > 600 ? "85%" : "70%",
  },
});
export default UserNotifications;
