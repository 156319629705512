import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  ImageBackground,
  Image,
  TouchableOpacity,
  StyleSheet,
  Platform,
} from "react-native";
import * as SecureStore from "expo-secure-store";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const authorizedForFlateList = async (module) => {
  // let permissions =  global.permissions ? global.permissions : [];
  let permissions =
    Platform.OS === "android" || Platform.OS === "ios"
      ? JSON.parse(await SecureStore.getItemAsync("permissions"))
      : JSON.parse(await AsyncStorage.getItem("permissions"));

  if (permissions === null) {
    permissions = [];
  }
  // console.log(permissions)

  if (permissions.filter((item) => item.id === module).length > 0) {
    return true;
  } else {
    return false;
  }
};

// export default AuthorizedFor;
