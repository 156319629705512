import React, { useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Dimensions,
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  Button,
  Overlay,
  ListItem,
  SpeedDial,
  SearchBar,
  Avatar,
} from "react-native-elements";

import {
  Ionicons,
  Entypo,
  MaterialIcons,
  MaterialCommunityIcons,
  FontAwesome,
  Octicons,
  FontAwesome5,
} from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import { useForm, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import SelectDropdown from "react-native-select-dropdown";
import Loading from "../Utils/Loading";
// import MultiSelect from "react-native-multiple-select";
import AuthorizedFor from "../../constants/authorizedFor";
import { authorizedForFlateList } from "../../constants/authorizedForFlateList";
import { ModulesPermission } from "../../constants/modules";

import CustomPicker from "../../components/Picker/Picker";

const windowWidth = Dimensions.get("window").width;

const Clients = (navigation) => {
  const [clientsDataIntial, setClientsDataIntial] = useState([]);
  const [clientsData, setClientsData] = useState([]);
  const [openDial, setOpenDial] = useState(false);
  const [isLoding, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [clientType, setClientType] = useState(null);
  const [search, setSearch] = useState("");

  const clientTypes = [
    { value: "Individual", label: "Individual" },
    { value: "Company", label: "Company" },
    { value: "Chairity", label: "Chairity" },
  ];

  const [isDeleteAble, setIsDeleteAble] = useState(false);
  const [isUpdateAble, setIsUpdateAble] = useState(false);

  useEffect(() => {
    GetClients();
  }, []);

  useEffect(() => {
    (async () => {
      (await authorizedForFlateList(ModulesPermission.Update_Client)) &&
        setIsUpdateAble(true);
      (await authorizedForFlateList(ModulesPermission.Delete_Client)) &&
        setIsDeleteAble(true);
    })();
  }, [clientsDataIntial.length]);

  const GetClients = () => {
    setIsLoading(true);

    jwt
      .get(`${Url.GET_CLIENT}/${global.systemID}`)
      .then((response) => {
        // console.log(response)
        setIsLoading(false);
        setClientsData(response.data.clients);
        setClientsDataIntial(response.data.clients);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setIsLoading(true);
    jwt
      .post(Url.ADD_CLIENT, {
        organization_name: data.organization_name,
        last_name: data.lastName,
        email: data.email,
        phone: data.phone,
        address: data.address,
        post_code: data.post_code,
        client_type: clientType,
        company: data.company,
        company_register: data.company_register,
        unique_tax_reference: data.unique_tax_reference,
        charity: data.charity,
        system_id: parseInt(global.systemID),
      })
      .then((response) => {
        setIsLoading(false);
        setIsVisible(!isVisible);
        GetClients();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          Clients
        </Text>
        <Ionicons
          name="menu-outline"
          size={30}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 20.0,
            // fontWeight: 300
          }}
          onPress={() => navigation.navigation.toggleDrawer()}
        />
      </View>
    );
  };

  const GetIntials = (nameString) => {
    const fullName = nameString.split(" ");
    const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
    return initials.toUpperCase();
  };

  const renderItem = ({ item, index }) => (
    <View style={styles.gridbox}>
    <ListItem.Swipeable
      // onPress={() => {
      //   navigation.navigation.navigate("Tasks", { item: item });
      // }}
      key={index}
      // contentContainerStyle={{width:'50%'}}
      leftContent={
        isUpdateAble ? (
          <Button
            title="Update"
            icon={{ name: "edit", color: "white" }}
            buttonStyle={{
              minHeight: "80%",
              borderBottomLeftRadius: 6,
              borderTopLeftRadius: 6,
             
            }}
            onPress={() => {
              navigation.navigation.navigate("UpdateClient", {
                item: item,
              });
            }}
          />
        ) : null
      }
      rightContent={
        isDeleteAble ? (
          <Button
            title="Delete"
            icon={{ name: "delete", color: "white" }}
            buttonStyle={{
              minHeight: "80%",
              backgroundColor: "red",
              borderBottomRightRadius: 6,
              borderTopRightRadius: 6,
             
            }}
          />
        ) : null
      }
      containerStyle={{ marginBottom: 15, zIndex: 1, borderRadius: 6 }}
    >
      {/* <FontAwesome5 name="user-tie" size={40} color="#444" /> */}
      {/* <Avatar
        // avatarStyle={{ height: 80, width: 80, zIndex: 2, position: "relative" }}
        size="medium"
        rounded
        // source={{ uri: item.avatar }}
        title={GetIntials(`${item.first_name} ${item.last_name}`)}
      /> */}

      {item.avatar != undefined || item.avatar != null ? (
        <Image
          source={{ uri: global.userData.avatar }}
          style={{
            height: 80,
            width: 80,
            borderRadius: 40,
            marginBottom: 10,
          }}
        />
      ) : (
        <Text
          style={{
            height: 50,
            width: 50,
            borderRadius: 40,
            marginBottom: 10,
            backgroundColor: "grey",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 26,
            paddingLeft: 10,
            fontWeight: "600",
            color: "#fff",
            paddingTop: 8,
          }}
        >
          {GetIntials(`${item.organization_name !== null ? item.organization_name : 'Orginization Name'}`)}
        </Text>
      )}

      <ListItem.Content>
        <ListItem.Title style={{ marginBottom: 4 }}>
          {item.organization_name}
        </ListItem.Title>
        <ListItem.Subtitle numberOfLines={1}>
          {item.address}, {item.address_2}
        </ListItem.Subtitle>
        <View
          style={{
            flexDirection: "row",
            //   justifyContent: "space-between",
            paddingTop: 10,
            width: "100%",
          }}
        >
          {/* <Text>Status</Text> */}
          <Text>{item.city}</Text>
          <TouchableOpacity
            style={{
              position: "absolute",
              right: 2,
              top: -50,
              zIndex: 10,
            }}
            onPress={() => {
              navigation.navigation.navigate("UpdateClient", {
                item: item,
              });
            }}
          >
            <MaterialCommunityIcons
              name="account-edit-outline"
              size={30}
              color={Colors.primaryColor}
            />
          </TouchableOpacity>
        </View>
      </ListItem.Content>
      {/* <ListItem.Chevron /> */}
    </ListItem.Swipeable>
    </View>
  );

  const handleSearch = (value) => {
    setSearch(value);
    if (value.length > 1) {
      const newData = clientsData.filter(
        (e) =>
        e.organization_name !== null && (e.organization_name.toUpperCase().includes(value.toUpperCase())) ||
          e.address.toUpperCase().includes(value.toUpperCase())
      );
      if (newData.length > 0) {
        setClientsData(newData);
      } else {
        setClientsData(clientsDataIntial);
      }
    } else {
      setClientsData(clientsDataIntial);
    }
  };

  // const renderClient = row => {
  //   const stateNum = Math.floor(Math.random() * 6),
  //     states = ['light-success', 'light-danger', 'light-warning', 'light-info', 'light-primary', 'light-secondary'],
  //     color = states[stateNum]

  //   if (row.avatar) {
  //     return <Avatar img={row.avatar} width='32' height='32' />
  //   } else {
  //     return <Avatar color={color || 'primary'}  content={row.name || 'John Doe'} initials />
  //   }
  // }

  return (
    <View style={styles.container}>
      {header()}

      <SearchBar
        placeholder="Type Here..."
        onChangeText={handleSearch}
        value={search}
        lightTheme
        containerStyle={{
          backgroundColor: "#eee",
          borderWidth: 0,
          elevation: 0,
          paddingLeft: Sizes.fixPadding * 2,
          paddingRight: Sizes.fixPadding * 2,
        }}
        // placeholderTextColor="#000"
        inputStyle={{
          color: "#000",
        }}
        inputContainerStyle={{
          backgroundColor: "#ccc",
        }}
      />
      <View
        style={{
          flex: 1,
          backgroundColor: "#F2F3F4",
          padding: Sizes.fixPadding * 2,
          paddingTop: 0,
        }}
      >
        {/* <ScrollView style={{ flex: 1 }}> */}
        <FlatList
          showsVerticalScrollIndicator={false}
          nestedScrollEnabled
          data={clientsData != null ? clientsData : []}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
          numColumns={windowWidth > 700 ? 3 : 1}
          // columnWrapperStyle={{justifyContent:'space-between', }}
        />
      </View>

      {isLoding && <Loading />}
      {AuthorizedFor(ModulesPermission.Create_Client) && (
        <SpeedDial
          isOpen={openDial}
          icon={{ name: "edit", color: "#fff" }}
          openIcon={{ name: "close", color: "#fff" }}
          onOpen={() => setOpenDial(!openDial)}
          onClose={() => setOpenDial(!openDial)}
          style={{ zIndex: 2 }}
          buttonStyle={{ backgroundColor: Colors.primaryColor }}
        >
          <SpeedDial.Action
            icon={{ name: "add", color: "#fff" }}
            title="Add Client"
            onPress={() => setIsVisible(!isVisible)}
            buttonStyle={{ backgroundColor: Colors.primaryColor }}
          />
        </SpeedDial>
      )}
      <Overlay
        visible={isVisible}
        overlayStyle={{
          width: "80%",
          backgroundColor: "#FFFFFF",
          borderRadius: 8,
          paddingTop: 15,
          paddingBottom: 20,
          padding: Sizes.fixPadding * 2,
          //   marginTop: 40,
          //   marginBottom: 50,
          height: 500,
        }}
        onBackdropPress={() => setIsVisible(!isVisible)}
      >
        <View style={{ alignItems: "center", marginTop: 3, marginBottom: 10 }}>
          <Text style={{ fontSize: 18 }}>New Client</Text>
        </View>
        <ScrollView
        //   style={{
        //     flex: 1,
        //   }}
        >
         

          <View style={{ flexDirection: "row" }}>
            <View style={styles.child1}>
              <Text style={{ ...styles.label }}>{"Client Type"}</Text>
            </View>
            <View style={styles.child2}>
              <CustomPicker
                data={clientTypes}
                selectedValue={clientType}
                handleSelectedValue={(val) => setClientType(val)}
                defaultLabel={"Select Client Type"}
                style={{}}
              />
            </View>
          </View>
         
          <View style={{ flexDirection: "row", marginTop:15 }}>
            <View style={styles.child1}>
              <Text style={{ ...styles.label }}>{"Organization Name"}</Text>
            </View>
            <View style={styles.child2}>
          <Controller
            name="organization_name"
            control={control}
            rules={{
              required: { value: true, message: "Organization Name is required" },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
               
                type="text"
                onChangeText={(value) => onChange(value)}
                value={value}
                error={errors.organization_name}
                errorText={errors?.organization_name?.message}
              />
            )}
            defaultValue=""
          />
          </View>
          </View>

          <View style={{ flexDirection: "row", marginTop:15 }}>
            <View style={styles.child1}>
              <Text style={{ ...styles.label }}>{"Email"}</Text>
            </View>
            <View style={styles.child2}>
          <Controller
            name="email"
            control={control}
            rules={{
              required: { value: true, message: "Email is required" },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                // label="Email"
                type="email"
                onChangeText={(value) => onChange(value)}
                value={value}
                error={errors.email}
                errorText={errors?.email?.message}
              />
            )}
            defaultValue=""
          />
          </View>
          </View>

          <View style={{ flexDirection: "row", marginTop:15 }}>
            <View style={styles.child1}>
              <Text style={{ ...styles.label }}>{"Phone"}</Text>
            </View>
            <View style={styles.child2}>
          <Controller
            name="phone"
            control={control}
            rules={{
              required: { value: true, message: "Phone is required" },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                // label="Phone"
                type="text"
                onChangeText={(value) => onChange(value)}
                value={value}
                error={errors.address}
                errorText={errors?.address?.message}
              />
            )}
            defaultValue=""
          />
          </View>
          </View>

          <View style={{ flexDirection: "row", marginTop:15 }}>
            <View style={styles.child1}>
              <Text style={{ ...styles.label }}>{"Address"}</Text>
            </View>
            <View style={styles.child2}>
          <Controller
            name="address"
            control={control}
            rules={{
              required: { value: true, message: "Address is required" },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                // label="Address"
                type="text"
                onChangeText={(value) => onChange(value)}
                value={value}
                error={errors.address}
                errorText={errors?.address?.message}
              />
            )}
            defaultValue=""
          />
          </View>
          </View>

          <View style={{ flexDirection: "row", marginTop:15 }}>
            <View style={styles.child1}>
              <Text style={{ ...styles.label }}>{"Postal Code"}</Text>
            </View>
            <View style={styles.child2}>
          <Controller
            name="post_code"
            control={control}
            //   rules={{
            //     required: { value: true, message: "Addre is required" },
            //   }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                // label="Postal Code"
                type="text"
                onChangeText={(value) => onChange(value)}
                value={value}
                //   error={errors.company}
                //   errorText={errors?.company?.message}
              />
            )}
            defaultValue=""
          />
          </View>
          </View>
          {clientType === "Company" &&
          <View>
          <View style={{ flexDirection: "row", marginTop:15 }}>
            <View style={styles.child1}>
              <Text style={{ ...styles.label }}>{"Company"}</Text>
            </View>
            <View style={styles.child2}>
          <Controller
            name="company"
            control={control}
            // rules={{
            //   required: { value: true, message: "Company is required" },
            // }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                // label="Company"
                type="text"
                onChangeText={(value) => onChange(value)}
                value={value}
              />
            )}
            defaultValue=""
          />
          </View>
          </View>

          <View style={{ flexDirection: "row", marginTop:15 }}>
            <View style={styles.child1}>
              <Text style={{ ...styles.label }}>{"Company Register"}</Text>
            </View>
            <View style={styles.child2}>
          <Controller
            name="company_register"
            control={control}
            // rules={{
            //   required: { value: true, message: "Country is required" },
            // }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                // label="Company Register"
                type="text"
                onChangeText={(value) => onChange(value)}
                value={value}
              />
            )}
            defaultValue=""
          />
          </View>
          </View>

          <View style={{ flexDirection: "row", marginTop:15 }}>
            <View style={styles.child1}>
              <Text style={{ ...styles.label }}>{"Tax Reference"}</Text>
            </View>
            <View style={styles.child2}>
          <Controller
            name="unique_tax_reference"
            control={control}
            //   rules={{
            //     required: { value: true, message: "Company is required" },
            //   }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                // label="Unique Tax Reference"
                type="text"
                onChangeText={(value) => onChange(value)}
                value={value}
                //   error={errors.company}
                //   errorText={errors?.company?.message}
              />
            )}
            defaultValue=""
          />
          </View>
          </View>
          </View>
}
{clientType === 'Chairity' &&
          <View style={{ flexDirection: "row", marginTop:15 }}>
            <View style={styles.child1}>
              <Text style={{ ...styles.label }}>{"Chairity"}</Text>
            </View>
            <View style={styles.child2}>
          <Controller
            name="charity"
            control={control}
            //   rules={{
            //     required: { value: true, message: "Company is required" },
            //   }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                // label="Charity"
                type="text"
                onChangeText={(value) => onChange(value)}
                value={value}
                //   error={errors.company}
                //   errorText={errors?.company?.message}
              />
            )}
            defaultValue=""
          />
          </View>
          </View>
}

          <Button
            title="Add Client"
            type="solid"
            loading={isLoding}
            onPress={handleSubmit(onSubmit)}
            buttonStyle={{
              padding: Sizes.fixPadding,
              width: "100%",
              backgroundColor: Colors.primaryColor,
              borderRadius: 22,
            }}
            containerStyle={{
              width: "100%",
              // marginHorizontal: 50,
              marginVertical: 20,
            }}
            titleStyle={{
              fontSize: 14,
              fontWeight: "100",
              color: Colors.whiteColor,
            }}
            icon={
              <Ionicons
                name="add-circle-sharp"
                size={24}
                color="#ffffff"
                style={{ marginRight: "2%" }}
              />
            }
          />
        </ScrollView>
      </Overlay>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: Colors.primaryColor,
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 15,
    marginTop: 40,
  },
  label: {
    color: "black",
    marginLeft: 0,
    marginBottom: 3,
    fontSize:windowWidth > 600 ? 18 : 16,
    fontWeight:'500',
    marginTop:windowWidth > 600 ? 0 : 12
  },
  child1:{
    width:windowWidth > 600 ? '15%' : '30%'
  },
  child2:{
    width:windowWidth > 600 ? '85%' : '70%'
  },
  gridbox: {
    flex: 1, 
    margin: windowWidth > 700 ? 5 : 0,
    marginBottom:0
  },
});
export default Clients;
