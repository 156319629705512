export const ModulesPermission = {
  Get_All_Clients: 98,
  Create_Client: 99,
  Update_Client: 101,
  Delete_Client: 102,
  Get_All_Employees: 103,
  Create_Employee: 104,
  Update_Employee: 105,
  Delete_Employee: 106,
  Get_All_Clients_Order: 107,
  Create_Group: 108,
  Get_All_Groups: 109,
  Update_Group: 110,
  Delete_Group: 111,
  Create_Booking_Admin: 112,
  Get_All_Bookings_By_System: 113,
  Get_All_Staff_Assigned_To_Booking: 114,
  Approve_Or_Reject_Booking: 115,
  Get_Order_Tasks_By_Booking_ID: 116,
  Filter_Bookings_By_Date: 117,
  Booking_Clock_In: 118,
  Booking_Clock_Out: 119,
  Get_Service_Staff_Approved_Bookings_By_ID: 120,
  Get_Single_Booking_Clockin_Details: 121,
  Get_All_Booking_Clockin_Details_By_Employee_Booking_ID: 122,
  Get_Last_Booking_Clockin_Details_By_Employee_Booking_ID: 123,
  Authenticate_Booking_Timesheet: 124,
  Create_Cleaning_Type: 125,
  Get_All_Cleaning_Types_In_System: 126,
  Update_Cleaning_Type: 127,
  Delete_Cleaning_Type: 128,
  Get_All_Room_Types_In_System: 129,
  Create_Room_Type: 130,
  Update_Room_Type: 131,
  Delete_Room_Type: 132,
  Get_All_Property_Types_In_System: 133,
  Create_Property_Type: 134,
  Update_Property_Type: 135,
  Delete_Property_Type: 136,
  Get_All_Tax_Types_In_System: 137,
  Get_All_Active_Tax_Types_in_System: 145,
  Create_Tax_Type: 138,
  Update_Tax_Type: 139,
  Delete_Tax_Type: 140,
  Create_Leave_Request: 141,
  Get_All_Leave_Types_In_System: 142,
  Get_All_Orders_In_System: 143,
  Create_Order: 144,
  Update_Order_Priority: 146,
  Update_Order_Status: 147,
  Get_Order_Tasks_By_Order_ID: 148,
  Assign_Order_Task_To_User: 149,
  Update_Order_Task_Priority: 150,
  Update_Order_Task_Status: 151,
  Get_All_Order_Prices_In_System: 152,
  Create_Order_Price: 153,
  Update_Single_Order_Price: 154,
  Delete_Order_Price: 155,
  Get_Order_Prices_For_Single_Client: 156,
  Get_All_Invoices_In_System: 157,
  Create_Invoice: 158,
  Update_Invoice: 159,
  Get_Order_Item_list_By_Order_ID: 160,
  Get_Invoice_By_ID: 161,
  Get_All_User_Invoices: 162,
  Pay_Invoice: 163,
  Get_All_Payment_Methods_In_System: 164,
  Inform_Successful_Payment: 165,
  Get_All_Order_Tasks_By_User_ID: 166,
  Get_All_User_Notification: 167,
  Mark_Notification_As_Read: 168,
  Delete_User_Notification: 169,
  Get_All_Marked_Read_Notifications: 170,
  Get_All_Unread_Notifications: 171,
  Subscribe_Expo_Token: 172,
  Unsubscirbe_Expo_Token: 173,
  Get_Single_Order_Price: 174,
  Get_All_Rejected_List_Items_In_System: 175,
  Create_Rejected_Checklist: 176,
  Update_Single_Rejected_Checklist: 177,
  Delete_Rejected_Checklist: 178,
  View_Single_Rejected_Checklist: 179,
  Get_User_Shifts_By_ID: 180,
  Create_User_Shift_By_ID: 181,
  Get_All_Active_Cleaning_Types_In_System: 182,
  Get_All_Active_Clients_In_System: 183,
  Get_All_Active_Room_Types_In_System: 184,
  Get_Default_Prices_For_System: 185,
  Get_All_Order_Tasks_By_Group: 186,
  Get_All_Active_Property_Types_In_System: 187,
  Get_Group_Members_By_Group_ID: 188,
  Assign_User_To_Group: 189,
  Get_All_Active_Employees_In_System: 190,
  Get_Assigned_Booking_Staff_By_Week: 191,
  Get_Address_By_ID: 192,
  Get_User_Contacts_By_ID: 193,
  Get_Staff_Shifts_By_Date: 194,
  Update_User_Password_By_ID: 195,
  Update_Avatar_By_ID: 196,
  Get_Approved_Booking_Timesheets: 197,
  Get_Disputed_Booking_Timesheets: 198,
  User_ClockIn_By_ID: 199,
  User_Clock_Out_By_ID: 200,
  Search_Rejected_Checklist: 201,
  Search_Bookings: 203,
  Search_Bookings_for_Client: 204,
  Get_Booking_Tasks_By_BookingID_For_Client: 205,
  Update_Order_Task_Status_For_Client: 206,
  Update_Order_Task_Status_For_Staff: 207,
  Get_Pending_Staff_Bookings: 208,
  Get_Total_Employee_widget: 209,
  Get_All_User_Orders: 210,
  Complete_Booking_Order: 212,
  Get_User_Booking_Timesheet: 213,
  Get_Own_Booking_Timesheet: 214,
  Get_Client_booking_timesheet: 215,
  Get_User_Bookings: 216,
  Change_System: 217,
  Get_Folder_Category_List: 220,
  Get_User_Notifications_For_Admin: 228,
};
