import React from "react";
import { Dialog } from "react-native-elements";
import { Colors } from "../../constants/style";
const Loading = (props) => {
  return (
    <Dialog>
      <Dialog.Loading
        loadingProps={{ color: Colors.primaryColor, size: "large" }}
      />
    </Dialog>
  );
};
export default Loading;
