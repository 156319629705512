import React, { useState, useEffect, useRef } from "react";
import { StyleSheet, Text, View, ScrollView } from "react-native";
import { Colors, Sizes } from "../../constants/style";
import { Button, Overlay, ListItem, SpeedDial } from "react-native-elements";

import {
  Ionicons,
  Entypo,
  MaterialIcons,
  FontAwesome,
  Octicons,
} from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import { useForm, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import SelectDropdown from "react-native-select-dropdown";
import Loading from "../Utils/Loading";

const Projects = (navigation) => {
  const [projectsData, setProjectsData] = useState([]);
  const [isLoding, setIsLoading] = useState(false);
  useEffect(() => {
    (async () => {
      GetProjects();
    })();
  }, []);

  const GetProjects = () => {
    setIsLoading(true);

    jwt
      .get("user/all-projects")
      .then((response) => {
        setIsLoading(false);
        setProjectsData(response.orders);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const header = () => {
    return (
      <View style={styles.headerWrapStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          Projects
        </Text>
        <Ionicons
          name="menu-outline"
          size={30}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 20.0,
            // fontWeight: 300
          }}
          onPress={() => navigation.navigation.toggleDrawer()}
        />
      </View>
    );
  };

  return (
    <View style={{ flex: 1 }}>
      {header()}
      <ScrollView
        style={{
          flex: 1,
          padding: Sizes.fixPadding * 2,
          marginBottom: 30,
          paddingBottom: 30,
        }}
      >
        {projectsData.map((item, index) => (
          <ListItem.Swipeable
            onPress={() => {
              navigation.navigation.navigate("Tasks", { item: item });
            }}
            key={index}
            leftContent={
              <Button
                title="Info"
                icon={{ name: "info", color: "white" }}
                buttonStyle={{
                  minHeight: "80%",
                  borderBottomLeftRadius: 6,
                  borderTopLeftRadius: 6,
                }}
                onPress={() => {
                  navigation.navigation.navigate("Tasks", {
                    item: item,
                  });
                }}
              />
            }
            rightContent={
              <Button
                title="Delete"
                icon={{ name: "delete", color: "white" }}
                buttonStyle={{
                  minHeight: "80%",
                  backgroundColor: "red",
                  borderBottomRightRadius: 6,
                  borderTopRightRadius: 6,
                }}
              />
            }
            containerStyle={{ marginBottom: 15, zIndex: 1, borderRadius: 6 }}
          >
            <Octicons name="project" size={40} color="#444" />
            <ListItem.Content>
              <ListItem.Title style={{ marginBottom: 4 }}>
                {item.first_name} - {item.property_type}
              </ListItem.Title>
              <ListItem.Subtitle>{item.type_of_cleaning}</ListItem.Subtitle>
              <View
                style={{
                  flexDirection: "row",
                  //   justifyContent: "space-between",
                  paddingTop: 10,
                  width: "100%",
                }}
              >
                {/* <Text>Status</Text> */}
                <Text>{item.status}</Text>
              </View>
            </ListItem.Content>
            {/* <ListItem.Chevron /> */}
          </ListItem.Swipeable>
        ))}
      </ScrollView>
      {isLoding && <Loading />}
    </View>
  );
};
const styles = StyleSheet.create({
  headerWrapStyle: {
    height: 55.0,
    backgroundColor: Colors.primaryColor,
    elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
  },
});
export default Projects;
