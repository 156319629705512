/* eslint-disable consistent-this */
import React, { useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  StatusBar,
  Image,
  TouchableOpacity,
  Modal,
  TextInput,
  Alert,
  Platform,
} from "react-native";
import { Colors, Sizes } from "../../constants/style";
import {
  Button,
  Overlay,
  Icon,
  CheckBox,
  Switch,
  Dialog,
} from "react-native-elements";

import { Picker } from "@react-native-picker/picker";

const CustomPicker = (props) => {
  const pickerRef = useRef();
  return (
    <View style={{
      flex: 1, // This flex is optional, but probably desired
      alignItems: 'center',
      flexDirection: 'row',
      width:'100%'
    }}>
      <View style={{
        flex: 1,
        width:'100%'
      }}>
      <Picker
      style={{width:'100%'}}
      // style={{...props.style && props.style}}
        ref={pickerRef}
        selectedValue={props.selectedValue}
        onValueChange={(itemValue, itemIndex) =>
            props.customIndex !== undefined ?
            props.handleSelectedValue(itemValue, props.customIndex)
            :
            props.handleSelectedValue(itemValue, itemIndex)
        }
      >
        {props.defaultLabel && (
          <Picker.Item label={props.defaultLabel} value={0} />
        )}
        {props.data.map((s, i) => {
          return <Picker.Item key={i} value={s.value} label={s.label} />;
        })}
      </Picker>
    </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width:'100%'
  }
});

export default CustomPicker;

