import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Platform,
  SafeAreaView,
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  Button,
  SpeedDial,
  ListItem,
  Overlay,
  Dialog,
  SearchBar,
} from "react-native-elements";
import {
  Feather,
  Ionicons,
  Entypo,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import Loading from "../Utils/Loading";
import SelectDropdown from "react-native-select-dropdown";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import moment from "moment";

const RejectedHistory = (navigation) => {
  const Items = navigation.route.params.item;
  const [isLoding, setIsLoading] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);

  // console.log(Items);

  useEffect(() => {
    (async () => {
      GetEmployee();
    })();
  }, []);


  const GetEmployee = () => {
    setIsLoading(true);
    jwt
      .get(`${Url.GET_ACTIVE_EMPLOYEES}/${global.systemID}`)
      .then((response) => {
        setIsLoading(false);
        // console.log("Called");
        // console.log(response.data.employee);
        setEmployeeData(response.data.employee);
        // response.data.employee.map((item) => (
        //   // console.log(item.employee.user_id)
        // ))
        })
      .catch((err) => {
        setIsLoading(false);
      });
  };


  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          History
        </Text>
        <MaterialIcons
          name="arrow-back"
          size={26}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 15.0,
            fontWeight: 300,
          }}
          onPress={() => navigation.navigation.pop()}
        />
      </View>
    );
  };

  const renderItem = ({ item, index }) => (
    <View>
      <ListItem>
        <ListItem.Content>
          <ListItem.Title>
            <Text style={styles.lable}>ID:</Text> {item.id}
          </ListItem.Title>
        </ListItem.Content>
        <ListItem.Content right>
          {/* <ListItem.Title right>
            <Text>{moment(item.created_at).format("DD-MM-YYYY")}</Text>
          </ListItem.Title> */}
        </ListItem.Content>
      </ListItem>

      <ListItem>
        <ListItem.Content>
          <ListItem.Subtitle>
          <Text>Date:{moment(item.created_at).format("DD-MM-YYYY")}</Text>
          </ListItem.Subtitle>
        </ListItem.Content>
        <ListItem.Content right>
          <ListItem.Title right>
            <Text>Time:{moment(item.created_at).format("hh:mm:ss")}</Text>
          </ListItem.Title>
        </ListItem.Content>
      </ListItem>

      <ListItem bottomDivider style={{marginTop:-10}}>
        <ListItem.Content>
          <ListItem.Subtitle right style={{ marginTop: 10 }}>
            <Text style={{fontSize:16}}>{item.reason}</Text>
          </ListItem.Subtitle>
        </ListItem.Content>
      </ListItem>
    </View>
  );

  return (
    <View style={styles.container}>
      {header()}
      <View
        style={{
          flex: 1,
          padding: Sizes.fixPadding * 1.5,
          backgroundColor: "#F2F3F4",
        }}
      >
        {Items.length > 0 ? (
          <FlatList
            // showsVerticalScrollIndicator={false}
            // nestedScrollEnabled
            data={Items}
            renderItem={renderItem}
            keyExtractor={(item) => item.id}
          />
        ) : (
          <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            <View style={styles.noItemIconWrapStyle}>
              <MaterialCommunityIcons
                name="record-circle-outline"
                size={40}
                color="black"
              />
            </View>
            <Text style={{ fontSize: 18, marginTop: Sizes.fixPadding * 1.0 }}>
              History list is empty!
            </Text>
          </View>
        )}
      </View>
      {isLoding && <Loading />} 
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    // justifyContent: "center",
    backgroundColor: Colors.primaryColor,
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 15,
    marginTop: 40,
  },
  contentContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 10,
  },
  lable: {
    fontWeight: "700",
  },
  list: {
    borderBottomWidth: 1,
    borderColor: "#cbd2d9",
  },
});
export default RejectedHistory;
