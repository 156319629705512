import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  Button,
  FlatList,
} from "react-native";
import { Colors, Sizes } from "../../constants/style";
import {
  Ionicons,
  Entypo,
  MaterialIcons,
  MaterialCommunityIcons,
  FontAwesome,
  Octicons,
  FontAwesome5,
} from "@expo/vector-icons";
import moment from "moment";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import Loading from "../Utils/Loading";

const DateView = ({ navigation }) => {
  const [activeDate, setActiveDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [isLoding, setIsLoading] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [filterBookings, setFilterBookings] = useState([]);
  const [futureMonth, setFutureMonth] = useState();
  const [previousMonth, setPreviousMonth] = useState();
  const [isCalled, setIsCalled] = useState(false);

  useEffect(() => {
    setSelectedDate(moment(activeDate).format("YYYY-MM-DD"));
    // setFutureMonth(moment(activeDate).add(1, "M").format("YYYY-MM-DD"));
    // setPreviousMonth(moment(activeDate).subtract(1, "M").format("YYYY-MM-DD"));
    GetBooking(
      moment(activeDate).add(1, "M").format("YYYY-MM-DD"),
      moment(activeDate).subtract(1, "M").format("YYYY-MM-DD")
    );
  }, []);

  // useEffect(() => {
  //   GetFilterBooking(selectedDate);
  // }, [selectedDate, GetBooking, isCalled]);

  const GetBooking = (fMonth, pMonth, newdate) => {
    setIsLoading(true);

    const data = {
      user_type: "Admin",
      date_from: pMonth,
      date_to: fMonth,
      status: "All",
    };

    jwt
      .post(`${Url.SEARCH_BOOKING}/${global.systemID}`, data)
      .then((response) => {
        setBookings(response.data.bookings);
        const newBook = response.data.bookings.filter(
          (obj) => obj.booking_date === newdate
        );
        setFilterBookings(newBook);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const GetFilterBooking = (date) => {
    const newBookings = bookings.filter((item) => item.booking_date === date);
    setFilterBookings(newBookings);
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const nDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  const generateMatrix = () => {
    var matrix = [];
    // Create header
    matrix[0] = weekDays;

    var year = activeDate.getFullYear();
    var month = activeDate.getMonth();

    var firstDay = new Date(year, month, 1).getDay();

    var maxDays = nDays[month];
    if (month == 1) {
      // February
      if ((year % 4 == 0 && year % 100 != 0) || year % 400 == 0) {
        maxDays += 1;
      }
    }

    var counter = 1;
    for (var row = 1; row < 7; row++) {
      matrix[row] = [];
      for (var col = 0; col < 7; col++) {
        matrix[row][col] = -1;
        if (row == 1 && col >= firstDay) {
          // Fill in rows only after the first day of the month
          matrix[row][col] = counter++;
        } else if (row > 1 && counter <= maxDays) {
          // Fill in rows only if the counter's not greater than
          // the number of days in the month
          matrix[row][col] = counter++;
        }
      }
    }

    return matrix;
  };

  var matrix = generateMatrix();

  var rows = [];
  rows = matrix.map((row, rowIndex) => {
    var rowItems = row.map((item, colIndex) => {
      return (
        <Text
          style={{
            flex: 1,
            height: 30,
            textAlign: "center",
            paddingTop: weekDays.indexOf(item) < 0 ? 0 : 5,
            // width: "100%",
            // Highlight header
            backgroundColor: rowIndex == 0 ? "#ddd" : "#fff",
            // Highlight Sundays
            color:
              item === activeDate.getDate()
                ? Colors.primaryColor
                : colIndex == 0
                ? "#a00"
                : "#000",
            // Highlight current date
            fontWeight: item === activeDate.getDate() ? "bold" : "300",
            fontSize: item === activeDate.getDate() ? 16 : 14,
          }}
          onPress={() => _onPress(item)}
          key={colIndex}
        >
          {item != -1 ? item : ""}
        </Text>
      );
    });
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          padding: 15,
          justifyContent: "space-around",
          alignItems: "center",
        }}
        key={rowIndex}
      >
        {rowItems}
      </View>
    );
  });

  const _onPress = (item) => {
    if (!item.match && item != -1) {
      const newDate = new Date(activeDate.setDate(item));
      setActiveDate(newDate);
      setSelectedDate(moment(newDate).format("YYYY-MM-DD"));

      const checkDate = moment(moment(newDate).format("YYYY-MM-DD")).isBetween(
        previousMonth,
        futureMonth
      );

      if (checkDate) {
        GetFilterBooking(moment(newDate).format("YYYY-MM-DD"));
      } else {
        setIsLoading(true);
        setFutureMonth(moment(newDate).add(1, "M").format("YYYY-MM-DD"));
        setPreviousMonth(moment(newDate).subtract(1, "M").format("YYYY-MM-DD"));
        GetBooking(
          moment(newDate).add(1, "M").format("YYYY-MM-DD"),
          moment(newDate).subtract(1, "M").format("YYYY-MM-DD"),
          moment(newDate).format("YYYY-MM-DD")
        );
      }
    }
  };

  const changeMonth = (n) => {
    setActiveDate(new Date(activeDate.setMonth(activeDate.getMonth() + n)));
  };

  const changeDay = (n) => {
    const newDate = new Date(activeDate.setDate(activeDate.getDate() + n));
    setActiveDate(newDate);
    setSelectedDate(moment(newDate).format("YYYY-MM-DD"));
    GetFilterBooking(moment(newDate).format("YYYY-MM-DD"));
  };

  const renderItem = ({ item, index }) => (
    <View>
      <View
        style={{
          flexDirection: "row",
          marginBottom: 10,
          marginTop: 10,
          paddingHorizontal: Sizes.fixPadding * 2,
          paddingLeft: 10,
          minHeight: 110,
        }}
      >
        <View
          style={{
            width: "22%",
            justifyContent: "center",
            textAlign: "left",
          }}
        >
          <Text>{moment(item.created_at).format("h:mmA")}</Text>
        </View>
        <TouchableOpacity
          style={{
            width: "78%",
            backgroundColor: "#fff",
            padding: Sizes.fixPadding * 2,
            borderRadius: 6,
          }}
          onPress={() => {
            navigation.navigation.navigate("BookingDetails", { item: item });
          }}
        >
          <View style={{ flexDirection: "row", marginBottom: 5 }}>
            <Text style={{ fontWeight: "700", paddingRight: 5 }}>
              BookingID:
            </Text>
            <Text>{item.booking_id}</Text>
          </View>

          <View style={{ flexDirection: "row", marginBottom: 5 }}>
            <Text style={{ fontWeight: "700", paddingRight: 5 }}>Days:</Text>
            <Text>{item.days_per_week}</Text>
          </View>

          <View style={{ flexDirection: "row", marginBottom: 5 }}>
            <Text style={{ fontWeight: "700", paddingRight: 5 }}>Status:</Text>
            <Text>{item.booking_status}</Text>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );

  return (
    <View style={{ flex: 1 }}>
      <View
        style={{
          width: "100%",
          padding: Sizes.fixPadding * 2,
        }}
      >
        <View
          style={{
            width: "100%",
            backgroundColor: "#FFFFFF",
            paddingTop: 10,
            borderRadius: 6,
          }}
        >
          <View style={styles.calendarHeader}>
            <Text
              style={{
                fontWeight: "bold",
                fontSize: 18,
                textAlign: "center",
                marginBottom: 10,
                color: Colors.blackColor,
              }}
            >
              {months[activeDate.getMonth()]} &nbsp;
              {activeDate.getFullYear()}
            </Text>
            <Entypo
              name="chevron-left"
              size={24}
              color={Colors.blackColor}
              style={{
                position: "absolute",
                left: 10.0,
                // fontWeight: 300
              }}
              onPress={() => changeMonth(-1)}
            />
            <Entypo
              name="chevron-right"
              size={24}
              color={Colors.blackColor}
              style={{
                position: "absolute",
                right: 10.0,
                // fontWeight: 300
              }}
              onPress={() => changeMonth(+1)}
            />
          </View>

          {rows}
        </View>
      </View>
      <View style={{ flex: 1 }}>
        <View
          style={{
            marginTop: 8,
            height: 40,
            backgroundColor: "#ddd",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text style={{ fontSize: 16 }}>{selectedDate}</Text>
          <Entypo
            name="chevron-left"
            size={22}
            color={Colors.blackColor}
            style={{
              position: "absolute",
              left: 10.0,
              // fontWeight: 300
            }}
            onPress={() => changeDay(-1)}
          />
          <Entypo
            name="chevron-right"
            size={22}
            color={Colors.blackColor}
            style={{
              position: "absolute",
              right: 10.0,
              // fontWeight: 300
            }}
            onPress={() => changeDay(+1)}
          />
        </View>
        <View
          style={{ flex: 1, justifyContent: "center", textAlign: "center" }}
        >
          {filterBookings.length > 0 ? (
            <FlatList
              showsVerticalScrollIndicator={false}
              nestedScrollEnabled
              data={filterBookings}
              renderItem={renderItem}
              keyExtractor={(item) => item.id}
              contentContainerStyle={{ paddingTop: 10, paddingBottom: 20 }}
            />
          ) : (
            <Text
              style={{ textAlign: "center", fontWeight: "bold", fontSize: 18 }}
            >
              No Booking Available{" "}
            </Text>
          )}
        </View>
        {isLoding && <Loading />}
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  headerWrapStyle: {
    height: 55.0,
    backgroundColor: Colors.primaryColor,
    elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
  },
  calendarHeader: {
    // height: 55.0,
    // backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
  },
});
export default DateView;
