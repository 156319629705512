import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  Alert,
  Platform,
  Dimensions,
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import { Button, Dialog } from "react-native-elements";
import { Entypo, Ionicons, MaterialIcons } from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import Loading from "../Utils/Loading";
import moment from "moment";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import SectionedMultiSelect from "react-native-sectioned-multi-select";
import CustomDatePicker from "../../components/datePicker/datePicker";
import CustomPicker from "../../components/Picker/Picker";

const windowWidth = Dimensions.get("window").width;

const CreateBooking = (navigation) => {
  const [date, setDate] = useState(new Date());
  const [bookingDate, setBookingDate] = useState(new Date());
  const [showDate, setShowDate] = useState(false);
  const [clientsList, setClientsList] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isLoding, setIsLoading] = useState(false);
  const [bookingType, setBookingType] = useState(null);
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Its Error");
  const [userId, setUserId] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState([]);

  const [userAddress, setUserAddress] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);

  const [userShifts, setUserShifts] = useState([]);
  const [shiftsList, setShiftsList] = useState([]);

  const [days, setDays] = useState([]);
  const [newDaysList, setNewDaysList] = useState([]);

  const [staff, setStaff] = useState([]);
  const [staffList, setStaffList] = useState([]);

  const [preferedList, setPreferedList] = useState([]);

  const [newIndex, setNewIndex] = useState([]);

  const [showSuccess, setShowScuccess] = useState(false);

  const items = [
    {
      id: "1",
      name: "Monday",
    },
    {
      id: "2",
      name: "Tuesday",
    },
    {
      id: "3",
      name: "Wednesday",
    },
    {
      id: "4",
      name: "Thursday",
    },
    {
      id: "5",
      name: "Friday",
    },
    {
      id: "6",
      name: "Saturday",
    },
    {
      id: "7",
      name: "Sunday",
    },
  ];

  const bookingTypes = [
    {
      value: "Medical",
      label: "Medical",
    },
    {
      value: "Cleaning",
      label: "Cleaning",
    },
  ];

  useEffect(() => {
    (async () => {
      GetClients();
    })();
  }, []);

  const GetClients = () => {
    setIsLoading(true);

    jwt
      .get(`${Url.GET_ACTIVE_CLIENT}/${global.systemID}`)
      .then((response) => {
        // console.log(response)
        setIsLoading(false);
        const NewData = [];

        response.data.clients.map((item) => {
          NewData.push({
            value: item.id,
            label: `${item.organization_name}`,
            ...item,
          });
        });

        setClientsList(NewData);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleSelectedClient = (val, index) => {
    setSelectedClient(parseInt(val));
    if (parseInt(val) !== 0) {
      setUserId(clientsList[index - 1].user_id);
      GetUserAddress(clientsList[index - 1].user_id);
      GetUserShifts(clientsList[index - 1].user_id);
    }
  };

  // const handleSelectedClient = (index) => {
  //   setUserId(clientsList[index].user_id);
  //   GetUserAddress(clientsList[index].user_id);
  //   GetUserShifts(clientsList[index].user_id);
  // };

  const handleSelectedAddress = (val, index) => {
    setSelectedAddress(val);
  };

  const GetUserAddress = (id) => {
    setIsLoading(true);
    jwt
      .get(`${Url.GET_USER_ADDRESS}/${id}`)
      .then((response) => {
        setIsLoading(false);

        // setUserAddress(response.data.user_addresses);
        const List = [];
        response.data.user_addresses.map((item) => List.push({
          value: item.id,
          label: item.address,
          ...item,
        }));
        setUserAddress(List);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetUserShifts = (id) => {
    jwt
      .post(Url.GET_USER_SHIFTS, { user_id: id })
      .then((response) => {
        // setUserShifts(response.data.user_shifts);
        const Shifts = [];
        response.data.user_shifts.map((item) =>
          Shifts.push({
            value: item.id,
            label: `${item.day} ${item.start_time} - ${item.end_time}`,
            ...item,
          })
        );
        setUserShifts(Shifts);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleSelectedShift = (val, index) => {

    const newData = userShifts.filter((item) => item.id === parseInt(val));

    // const newData = selectedItem.split(" ");
    // const Day = newData[0];
    // const StartTime = newData[1];
    // const EndTime = newData[3];

    // const currentShift = userShifts.filter(
    //   (obj) =>
    //     obj.day == Day && obj.start_time == StartTime && obj.end_time == EndTime
    // );

    if (newData.length > 0) {

      

      GetUserStaff(newData[0].day, val, bookingDate, index);

      const labelNew = `${newData[0].day} ${newData[0].start_time} - ${newData[0].end_time}`;

      let newDays = [...days];
      newDays[index] = {
        ...newDays[index],
        booking_shift_id: parseInt(val),
        label: labelNew,
        value: parseInt(val),
      };

      setDays(newDays);

      setNewDaysList(newDays);
    }
    setPreferedList([...preferedList, []]);
  };

  const GetUserStaff = (day, shiftId, bookDate, index) => {
    jwt
      .post(Url.GET_USER_STAFF_SHIFTS, {
        system_id: global.systemID,
        day: day,
        shift_id: shiftId,
        booking_date: `${moment(bookDate).format("YYYY-MM-DD")}`,
      })
      .then((response) => {
        let newAvailbleList = [];

        response.data.user_staff_shifts.available.map((item) => {
          newAvailbleList.push({
            id: item.staff_data.id,
            name: item.staff_data.name,
          });
        });

        let newCurrentList = [];

        response.data.user_staff_shifts.current_available.map((item) => {
          newCurrentList.push({
            id: item.staff_data.id,
            name: item.staff_data.name,
          });
        });

        let newGeneralList = [];

        response.data.user_staff_shifts.general.map((item) => {
          newGeneralList.push({
            id: item.staff_data.id,
            name: item.staff_data.name,
          });
        });

        if (
          response.data.user_staff_shifts.available.length > 0 ||
          response.data.user_staff_shifts.general.length > 0 ||
          response.data.user_staff_shifts.current_available.length > 0
        ) {
          setStaff([
            ...staff,
            ...newCurrentList,
            ...newAvailbleList,
            ...newGeneralList,
          ]);
        }

        const newList = [
          {
            name: "Current Available",
            id: 0,
            // these are the children or 'sub items'
            children: newCurrentList,
          },
          {
            name: "Available",
            id: 1,
            // these are the children or 'sub items'
            children: newAvailbleList,
          },
          {
            name: "General",
            id: 2,
            // these are the children or 'sub items'
            children: newGeneralList,
          },
        ];

        // setSelectedStaff([...selectedStaff, []]);
        // setPreferedList([...preferedList, []]);
        // ,
        // newList1.push({
        //   id: "" + item.staff_data.id,
        //   name: item.staff_data.name,
        //   booking: item.booking,
        // });
        if (
          response.data.user_staff_shifts.available.length > 0 ||
          response.data.user_staff_shifts.general.length > 0 ||
          response.data.user_staff_shifts.current_available.length > 0
        ) {
          setStaffList([...staffList, newList]);
        }

        // }
      })
      .catch((err) => {
        setIsLoading(false);
      });

    setNewIndex([...newIndex, index]);
  };

  const handleSelectedStaff = (selectedItems, index) => {
    let newStaff = [...selectedStaff];
    newStaff[index] = [...selectedItems];

    setSelectedStaff(newStaff);

    let unique = staff.filter(
      (ele, ind) => ind === staff.findIndex((elem) => elem.id === ele.id)
    );

    let prefList = [...preferedList];
    // newStaff[index] = [...selectedItems];

    newStaff[index].map((item) =>
      unique
        .filter((obj) => obj.id === item)
        .map(
          (newObj) =>
            (prefList[index] = [...prefList[index], { staff_id: newObj.id }])
        )
    );

    let newDays = [...days];
    newDays[index] = {
      ...newDays[index],
      preferred_staff: [...prefList[index]],
    };

    setDays(newDays);

    setNewDaysList(newDays);
  };

  const onSelectedItemsChange = (selectedItems) => {
    setSelectedItems(selectedItems);
    const newDays = [];
    selectedItems.map((item) => newDays.push({ booking_day: item }));
    setDays(newDays);
  };

  const onChangeDateFrom = (event, selectedDate) => {
    const currentDate = selectedDate;
    setDate(currentDate);
    setBookingDate(currentDate);
    setShowDate(!showDate);
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = useForm({});

  const { fields, append, remove } = useFieldArray({
    control,
    name: "test",
  });

  const onSubmit = (data) => {
    const dataNew = {
      client_id: userId,
      booking_type: bookingType,
      no_of_staff: data.no_of_staff,
      no_of_weeks_recurring: data.no_of_weeks_recurring,
      system_id: parseInt(global.systemID),
      booking_date: `${moment(bookingDate).format("YYYY-MM-DD")}`,
      booking_address_id: selectedAddress,
      booking_notes: data.booking_notes,
      days: newDaysList,
    };

    if (userId === null || userId === 0) {
      setErrorMessage("Please Select Client");
      setShow(true);
    } else if (bookingType === null || bookingType === 0) {
      setErrorMessage("Please Select Booking Type");
      setShow(true);
    } else if (bookingDate === null) {
      setErrorMessage("Please Select Booking Date");
      setShow(true);
    } else if (newDaysList.length === 0) {
      setErrorMessage("Please Select Days and Shifts");
      setShow(true);
    } else if (selectedAddress === null || selectedAddress === 0) {
      setErrorMessage("Please Select address");
      setShow(true);
    } else {
      genrateBooking(dataNew);
    }
  };

  const genrateBooking = (dataNew) => {
    setIsLoading(true);
    jwt
      .post(`${Url.ADD_BOOKING}/${global.systemID}`, dataNew)
      .then((response) => {
        setShowScuccess(true);
        setIsLoading(false);
        reset();
        setSelectedItems([]);
        setSelectedStaff([]);
        selectedAddress(null);
      })
      .catch((err) => {
        Alert.alert("Something Went Wrong!");
        setIsLoading(false);
      });
  };

  return (
    <View style={styles.container}>
      {header(navigation)}
      <ScrollView
        style={{ flex: 1, backgroundColor: "#F2F3F4", paddingBottom: 20 }}
      >
        <View
          style={{
            flex: 1,
            paddingHorizontal: Sizes.fixPadding * 2,
          }}
        >
          <View
            style={{
              backgroundColor: "#ffffff",
              marginTop: 20,
              borderRadius: 10,
              padding: Sizes.fixPadding * 1.5,
            }}
          >
            <View style={{ flexDirection: "row" }}>
              <View style={styles.child1}>
                <Text style={{ ...styles.label }}>{"Select Client"}</Text>
              </View>
              <View style={styles.child2}>
                <CustomPicker
                  data={clientsList}
                  selectedValue={selectedClient}
                  handleSelectedValue={handleSelectedClient}
                  defaultLabel={"Please select a client"}
                  style={{}}
                />
              </View>
            </View>

            <View style={{ flexDirection: "row", marginTop: 15 }}>
              <View style={styles.child1}>
                <Text style={{ ...styles.label }}>{"Booking Type"}</Text>
              </View>
              <View style={styles.child2}>
                <CustomPicker
                  data={bookingTypes}
                  selectedValue={bookingType}
                  handleSelectedValue={(val, index) => {
                    setBookingType(val);
                  }}
                  defaultLabel={"Please select Booking Type"}
                  style={{}}
                />
              </View>
            </View>

            <View style={{ flexDirection: "row", marginTop: 15 }}>
              <View style={styles.child1}>
                <Text style={{ ...styles.label }}>{"No. of Staff"}</Text>
              </View>
              <View style={styles.child2}>
                <Controller
                  name="no_of_staff"
                  control={control}
                  rules={{
                    required: { value: true, message: "Staff No. is required" },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      // label="No. of Staff"
                      onChangeText={(value) => onChange(value)}
                      value={value}
                      error={errors.no_of_staff}
                      errorText={errors?.no_of_staff?.message}
                    />
                  )}
                  defaultValue=""
                />
              </View>
            </View>

            <View style={{ flexDirection: "row", marginTop: 15 }}>
              <View style={styles.child1}>
                <Text style={{ ...styles.label }}>{"Weeks"}</Text>
              </View>
              <View style={styles.child2}>
                <Controller
                  name="no_of_weeks_recurring"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "No. of weeks recurring is required",
                    },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      // label="No. of weeks recurring"
                      onChangeText={(value) => onChange(value)}
                      value={value}
                      error={errors.no_of_weeks_recurring}
                      errorText={errors?.no_of_weeks_recurring?.message}
                    />
                  )}
                  defaultValue=""
                />
              </View>
            </View>

            <View style={{ flexDirection: "row", marginBottom: 15 }}>
              <View style={styles.child1}>
                <Text style={styles.label}>Booking Date</Text>
              </View>
              <View style={{ ...styles.child2 }}>
                <CustomDatePicker
                  date={onChangeDateFrom}
                  defaultDate={bookingDate}
                  show={showDate}
                  setShow={setShowDate}
                />
              </View>
            </View>

            {/* {Platform.OS === "android" || Platform.OS === "ios" ? (
              <View>
                <Text style={{ marginTop: 15 }}>Booking date</Text>
               
                {showDate && (
            <DateTimePicker
              testID="dateTimePicker"
              value={date}
              mode={"date"}
              onChange={onChangeDateFrom}
              style={{marginTop:-25}}
            />
          )}
              </View>
            ) : (
              <Controller
                name="Booking_date"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Booking date is required",
                  },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    label="Booking date"
                    placeholder="YYYY-MM-DD"
                    onChangeText={(value) => {
                      onChange(value);
                      setBookingDate(value);
                    }}
                    value={value}
                    error={errors.Booking_date}
                    errorText={errors?.Booking_date?.message}
                  />
                )}
                defaultValue=""
              />
            )} */}

<View style={{ flexDirection: "row", marginBottom: 15 }}>
              <View style={styles.child1}>
                <Text style={styles.label}>Days</Text>
              </View>
              <View style={{ ...styles.child2 }}>
            <SectionedMultiSelect
              items={items}
              // ref={ref}
              IconRenderer={MaterialIcons}
              uniqueKey="name"
              // subKey="children"
              selectText="Select Days"
              showDropDowns={true}
              //   readOnlyHeadings={true}
              onSelectedItemsChange={onSelectedItemsChange}
              selectedItems={selectedItems}
              hideSearch={true}
              searchPlaceholderText="Search reasons"
              //   text={Colors.primaryColor}
              styles={{
                button: {
                  backgroundColor: Colors.primaryColor,
                  selectedItemText: Colors.primaryColor,
                },
                selectToggle: {
                  borderColor: "#CCCCCC",
                  padding: 10,
                  borderRadius: 4,
                  borderWidth: 1,
                  height: 40,
                  // marginBottom: 15,
                },
                selectToggleText: {
                  fontSize: 14,
                },
                itemIconStyle: {
                  fontSize: 14,
                },
              }}
              theme={{
                Colors: {
                  selectToggleTextColor: "#053075",
                  text: "#053075",
                  selectedItemText: Colors.primaryColor,
                },
              }}
            />
            </View>
</View>

            {selectedItems.map((item, Mainindex) => (
               <View style={{flexDirection:'row', marginBottom:15}}  key={Mainindex}>
               <View style={styles.child1}> 
               <Text style={styles.label}>{`${item}`}</Text>
</View>
<View style={styles.child2}> 
                    <CustomPicker
                      data={userShifts.filter(
                        (shift) => shift.day === item
                      )}
                      selectedValue={
                        days[Mainindex].booking_shift_id !== undefined
                          ? days[Mainindex].booking_shift_id > 0 &&
                            days[Mainindex].booking_shift_id
                          : 0
                      }
                      handleSelectedValue={handleSelectedShift}
                      defaultLabel={"Select Shift"}
                      style={{}}
                      customIndex={Mainindex}
                    />
</View>
                  </View>
              // <View style={{ marginTop: 10 }} key={Mainindex}>
              //   <Text>{`${item} Shift`}</Text>
              //   <View
              //   // style={{
              //   //   flexDirection: "row",
              //   //   width: "50%",
              //   // }}
              //   >
              //     <View
              //     // style={{
              //     //   flexDirection: "row",
              //     //   width: "100%",
              //     // }}
              //     >
              //       <SelectDropdown
              //         data={shiftsList.filter((shift) => shift.includes(item))}
              //         // defaultValueByIndex={1}
              //         onSelect={(selectedItem, index) => {
              //           handleSelectedShift(selectedItem, Mainindex);
              //         }}
              //         defaultButtonText={"Select Shift"}
              //         buttonTextAfterSelection={(selectedItem, index) => {
              //           return selectedItem;
              //         }}
              //         rowTextForSelection={(item, index) => {
              //           return item;
              //         }}
              //         buttonStyle={{
              //           ...styles.dropdown2BtnStyle,
              //           width: "100%",
              //           // marginLeft: "5%",
              //           marginBottom: 10,
              //         }}
              //         buttonTextStyle={{
              //           ...styles.dropdown2BtnTxtStyle,
              //           fontSize: 13,
              //         }}
              //         renderDropdownIcon={(isOpened) => {
              //           return (
              //             <Entypo
              //               name={
              //                 isOpened ? "chevron-thin-up" : "chevron-thin-down"
              //               }
              //               color={"#444"}
              //               size={12}
              //             />
              //           );
              //         }}
              //         dropdownIconPosition={"right"}
              //         dropdownStyle={styles.dropdown2DropdownStyle}
              //         rowStyle={styles.dropdown2RowStyle}
              //         rowTextStyle={styles.dropdown2RowTxtStyle}
              //       />
              //     </View>
              //   </View>
              // </View>
            ))}

            {staffList.length > 0 &&
              staffList.map((item, index) => (
            
                <View style={{ flexDirection: "row", marginBottom: 15 }}>
              <View style={styles.child1}>
                <Text style={styles.label}>{selectedItems[index]} Staff</Text>
              </View>
              <View style={{ ...styles.child2 }}>
                
                  <SectionedMultiSelect
                    items={staffList[index]}
                    // ref={ref}
                    IconRenderer={MaterialIcons}
                    uniqueKey="id"
                    subKey="children"
                    selectText="Select Staff"
                    showDropDowns={false}
                    readOnlyHeadings={true}
                    onSelectedItemsChange={(selectedItems) => {
                      handleSelectedStaff(selectedItems, index);
                    }}
                    // onSelectedItemsChange={handleSelectedStaff}
                    selectedItems={selectedStaff[index]}
                    hideSearch={true}
                    searchPlaceholderText="Search reasons"
                    //   text={Colors.primaryColor}
                    styles={{
                      button: {
                        backgroundColor: Colors.primaryColor,
                        selectedItemText: Colors.primaryColor,
                      },
                      selectToggle: {
                        borderColor: "#CCCCCC",
                        padding: 10,
                        borderRadius: 4,
                        borderWidth: 1,
                        height: 40,
                        // marginBottom: 15,
                      },
                      selectToggleText: {
                        fontSize: 14,
                      },
                      itemIconStyle: {
                        fontSize: 14,
                      },
                    }}
                    theme={{
                      Colors: {
                        selectToggleTextColor: "#053075",
                        text: "#053075",
                        selectedItemText: Colors.primaryColor,
                      },
                    }}
                  />
                  </View>
                </View>
              ))}

<View style={{ flexDirection: "row", marginTop: 15 }}>
              <View style={styles.child1}>
                <Text style={{ ...styles.label }}>{"Address"}</Text>
              </View>
              <View style={styles.child2}>
                <CustomPicker
                  data={userAddress}
                  selectedValue={selectedAddress}
                  handleSelectedValue={(val, index) => {
                    handleSelectedAddress(val)
                  }}
                  defaultLabel={"Please select User Address"}
                  style={{}}
                />
              </View>
            </View>

            <View style={{ flexDirection: "row", marginTop: 15 }}>
              <View style={styles.child1}>
                <Text style={{ ...styles.label }}>{"Booking notes"}</Text>
              </View>
              <View style={styles.child2}>
            <Controller
              name="booking_notes"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Booking notes is required",
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  // label="Booking notes"
                  onChangeText={(value) => onChange(value)}
                  value={value}
                  error={errors.booking_notes}
                  errorText={errors?.booking_notes?.message}
                  // mt={15}
                />
              )}
              defaultValue=""
            />
</View>
</View>
            <View>
              {fields.map((item, index) => (
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                  }}
                  key={item.id}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      width: "20%",
                      marginLeft: 15,
                    }}
                  >
                    <Controller
                      name={`test.${index}.day`}
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "day is required",
                        },
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          placeholder="Day"
                          onChangeText={(value) => onChange(value)}
                          value={value}
                          error={`${errors}.test.${index}.day`}
                          errorText={errors?.day?.message}
                        />
                      )}
                    />

                    <Controller
                      name={`test.${index}.staff`}
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "Staff is required",
                        },
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          placeholder="Staff"
                          onChangeText={(value) => onChange(value)}
                          value={value}
                          error={`${errors}.test.${index}.staff`}
                          errorText={errors?.staff?.message}
                        />
                      )}
                    />
                  </View>
                </View>
              ))}
            </View>

            <Button
              title="Book Order"
              type="solid"
              loading={isLoding}
              onPress={handleSubmit(onSubmit)}
              buttonStyle={{
                padding: Sizes.fixPadding * 1,
                width: "100%",
                backgroundColor: Colors.primaryColor,
                borderRadius: 22,
              }}
              containerStyle={{
                width: "100%",
                // marginHorizontal: 50,
                marginVertical: 30,
              }}
              titleStyle={{
                fontSize: 14,
                fontWeight: "100",
                color: Colors.whiteColor,
              }}
              icon={
                <Ionicons
                  name="add-circle-sharp"
                  size={24}
                  color="#ffffff"
                  style={{ marginRight: "2%" }}
                />
              }
            />
          </View>
          {isLoding && <Loading />}
          <Dialog isVisible={show}>
            <Dialog.Title title="Error!" />
            <Text>{errorMessage}</Text>

            <Dialog.Actions>
              <Dialog.Button
                title="Ok"
                onPress={() => {
                  setShow(!show);
                }}
              />
            </Dialog.Actions>
          </Dialog>

          <Dialog isVisible={showSuccess}>
            <Dialog.Title title="Congratulations!" />
            <Text>{"You have Scuccessfully Booked your Order"}</Text>

            <Dialog.Actions>
              <Dialog.Button
                title="Ok"
                onPress={() => {
                  setShowScuccess(!showSuccess);
                  navigation.navigation.navigate("Bookings");
                }}
              />
            </Dialog.Actions>
          </Dialog>
        </View>
      </ScrollView>
    </View>
  );
};

const header = (navigation) => {
  return (
    <View style={HeaderStyle}>
      <Text
        style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
      >
        Booking Form
      </Text>
      <Ionicons
        name="menu-outline"
        size={30}
        color={Colors.whiteColor}
        style={{
          position: "absolute",
          left: 20.0,
          // fontWeight: 300
        }}
        onPress={() => navigation.navigation.toggleDrawer()}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: Colors.primaryColor,
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 15,
    marginTop: 40,
  },
  label: {
    color: "black",
    marginLeft: 0,
    marginBottom: 3,
    fontSize: windowWidth > 600 ? 18 : 16,
    fontWeight: "500",
    marginTop: windowWidth > 600 ? 0 : 12,
  },
  child1: {
    width: windowWidth > 600 ? "15%" : "30%",
  },
  child2: {
    width: windowWidth > 600 ? "85%" : "70%",
  },
});
export default CreateBooking;
