import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Platform,
  Dimensions,
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  Button,
  SpeedDial,
  ListItem,
  Overlay,
  Dialog,
  SearchBar,
} from "react-native-elements";
import {
  Feather,
  Ionicons,
  Entypo,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import Loading from "../Utils/Loading";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import moment from "moment";

import CustomDatePicker from "../../components/datePicker/datePicker";
import CustomPicker from "../../components/Picker/Picker";
const windowWidth = Dimensions.get("window").width;

const SearchBookings = (navigation) => {
  const [orderData, setOrderData] = useState([]);
  const [isLoding, setIsLoading] = useState(false);

  const [date, setDate] = useState(new Date());
  const [date1, setDate1] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [showDate, setShowDate] = useState(false);
  const [endDate, setEndDate] = useState(new Date());
  const [showDate2, setShowDate2] = useState(false);

  const [status, setStatus] = useState("All");

  const filterStatus = [
    {
      value: "All",
      label: "All",
    },
    {
      value: "Created",
      label: "Created",
    },
    {
      value: "Awaiting Service Staff",
      label: "Awaiting Service Staff",
    },
    {
      value: "Rejected",
      label: "Rejected",
    },
    {
      value: "Confirmed",
      label: "Confirmed",
    },
    {
      value: "Pending Staff Confirmation",
      label: "Pending Staff Confirmation",
    },
  ];

  useEffect(() => {
    (async () => {
      // GetData();
      // GetPendingData();
      // GetClients();
    })();
  }, [navigation, navigation.route.params]);

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          Bookings
        </Text>
        <Ionicons
          name="menu-outline"
          size={30}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 20.0,
            // fontWeight: 300
          }}
          onPress={() => navigation.navigation.toggleDrawer()}
        />
      </View>
    );
  };

  const renderItem = ({ item, index }) => (
    <TouchableOpacity
      onPress={() => {
        navigation.navigation.navigate("BookingDetails", { item: item });
      }}
      key={index}
      style={{
        marginTop: 15,
        zIndex: 1,
        borderRadius: 6,
        backgroundColor: "#FFFFFF",
        padding: Sizes.fixPadding,
      }}
    >
      <View style={styles.contentContainer}>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.lable}>ID: </Text>
          <Text>{item.booking_id}</Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.lable}>Status: </Text>
          <Text>{item.booking_status}</Text>
        </View>
      </View>

      <View style={styles.contentContainer}>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.lable}>Booking Date: </Text>
          <Text>{item.booking_date}</Text>
        </View>
      </View>

      <View style={styles.contentContainer}>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.lable}>Days: </Text>
          <Text>{item.days_per_week}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );

  const handleFilter = (selectedItem, index) => {
    setStatus(selectedItem);
  };

  const onChangeDateFrom = (event, selectedDate) => {
    const currentDate = selectedDate;
    setDate(currentDate);
    setStartDate(currentDate);
    setShowDate(!showDate);
  };

  const onChangeDateFrom2 = (event, selectedDate) => {
    const currentDate = selectedDate;
    setDate1(currentDate);
    setEndDate(currentDate);
    setShowDate2(!showDate2);
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = useForm({});

  const onSubmit = (data) => {
    setIsLoading(true);

    const userType = global.userData.roles[0].name;

    const newData = {
      user_type: userType === "Organization" ? "Admin" : userType,
      date_from: startDate,
      date_to: endDate,
      status: status,
      user_id: global.userData.id,
    };

    jwt
      .post(`${Url.SEARCH_BOOKING}/${global.systemID}`, newData)
      .then((response) => {
        setOrderData(response.data.bookings);
        setIsLoading(false);
        setShowSearchModal(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <View style={styles.container}>
      {header()}
      <View
        style={{
          flex: 1,
          padding: Sizes.fixPadding * 2,
          backgroundColor: "#F2F3F4",
        }}
      >
        <View
          style={{
            padding: Sizes.fixPadding * 2,
            // paddingTop: 5,
            // paddingBottom: 0,
            backgroundColor: "#FFFFFF",
            // alignItems:'center',
            // justifyContent:'center',
            borderRadius: 10,
            width: "100%",
          }}
        >
          {/* <Text style={{ marginTop: 10 }}>Start Date</Text>
            {showDate && (
        <DateTimePicker
          testID="dateTimePicker"
          value={date}
          mode={"date"}
          onChange={onChangeDateFrom}
          style={{marginTop:-25}}
        />
      )} */}

          <View style={{ flexDirection: "row", marginBottom: 15 }}>
            <View style={styles.child1}>
              <Text style={styles.label}>Duration From</Text>
            </View>
            <View style={{ ...styles.child2 }}>
              <CustomDatePicker
                date={onChangeDateFrom}
                defaultDate={startDate}
                show={showDate}
                setShow={setShowDate}
              />
            </View>
          </View>

          <View style={{ flexDirection: "row", marginBottom: 15 }}>
            <View style={styles.child1}>
              <Text style={styles.label}>Duration From</Text>
            </View>
            <View style={{ ...styles.child2 }}>
              <CustomDatePicker
                date={onChangeDateFrom2}
                defaultDate={endDate}
                show={showDate2}
                setShow={setShowDate2}
              />
            </View>
          </View>

          <View style={{flexDirection:'row', marginBottom:15}}>
                   <View style={styles.child1}>
                <Text style={{ ...styles.label}}>{"Vat"}</Text>
               </View>
               <View style={styles.child2}>
                <CustomPicker
                  data={filterStatus}
                  selectedValue={status}
                  handleSelectedValue={handleFilter}
                  defaultLabel={"Select Status"}
                  style={{}}
                />
</View>
</View>

          <Button
            title="Search Booking"
            type="solid"
            loading={isLoding}
            onPress={handleSubmit(onSubmit)}
            buttonStyle={{
              padding: Sizes.fixPadding * 1,
              width: "100%",
              backgroundColor: Colors.primaryColor,
              borderRadius: 22,
            }}
            containerStyle={{
              width: Platform.OS === "web" ? "50%" : "80%",
              // marginHorizontal: 50,
              marginVertical: 30,
            }}
            titleStyle={{
              fontSize: 14,
              fontWeight: "100",
              color: Colors.whiteColor,
            }}
            icon={
              <Ionicons
                name="add-circle-sharp"
                size={24}
                color="#ffffff"
                style={{ marginRight: "2%" }}
              />
            }
          />
        </View>
        <View
          style={{
            padding: Sizes.fixPadding * 2,
            paddingTop: 5,
            paddingBottom: 0,
          }}
        >
          {/* <SelectDropdown
          data={filterStatus}
          // defaultValueByIndex={1}
          onSelect={(selectedItem, index) => {
            handleFilter(selectedItem, index);
          }}
          defaultButtonText={"Select Status"}
          buttonTextAfterSelection={(selectedItem, index) => {
            return selectedItem;
          }}
          rowTextForSelection={(item, index) => {
            return item;
          }}
          buttonStyle={{
            ...styles.dropdown2BtnStyle,
            width: "100%",
            paddingBottom: 0,
            marginBottom: 0,
          }}
          buttonTextStyle={styles.dropdown2BtnTxtStyle}
          renderDropdownIcon={(isOpened) => {
            return (
              <Entypo
                name={isOpened ? "chevron-thin-up" : "chevron-thin-down"}
                color={"#444"}
                size={14}
              />
            );
          }}
          dropdownIconPosition={"right"}
          dropdownStyle={styles.dropdown2DropdownStyle}
          rowStyle={styles.dropdown2RowStyle}
          rowTextStyle={styles.dropdown2RowTxtStyle}
        /> */}
        </View>
        {orderData.length > 0 ? (
          <View
            style={{ flex: 1, padding: Sizes.fixPadding * 2, paddingTop: 0 }}
          >
            {/* <ScrollView style={{ flex: 1 }}> */}
            <FlatList
              showsVerticalScrollIndicator={false}
              nestedScrollEnabled
              data={orderData}
              renderItem={renderItem}
              keyExtractor={(item) => item.id}
            />
          </View>
        ) : (
          <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            <View style={styles.noItemIconWrapStyle}>
              <MaterialCommunityIcons
                name="record-circle-outline"
                size={40}
                color="black"
              />
            </View>
            <Text style={{ fontSize: 18, marginTop: Sizes.fixPadding * 1.0 }}>
              No Bookings available !
            </Text>
          </View>
        )}

        {isLoding && <Loading />}
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: Colors.primaryColor,
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 15,
    marginTop: 40,
  },
  contentContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 10,
  },
  lable: {
    fontWeight: "700",
  },
  label: {
    color: "black",
    marginLeft: 0,
    marginBottom: 3,
    fontSize: windowWidth > 600 ? 18 : 16,
    fontWeight: "500",
    marginTop: windowWidth > 600 ? 0 : 12,
  },
  child1: {
    width: windowWidth > 600 ? "15%" : "30%",
  },
  child2: {
    width: windowWidth > 600 ? "85%" : "70%",
  },
});
export default SearchBookings;
