import React, { useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  FlatList,
  Alert,
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  Button,
  Overlay,
  ListItem,
  SpeedDial,
  Avatar,
} from "react-native-elements";

import {
  Ionicons,
  Entypo,
  MaterialIcons,
  MaterialCommunityIcons,
  FontAwesome,
  Octicons,
} from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import Loading from "../Utils/Loading";

const BookingStaff = (navigation) => {
  const [staffData, setStaffData] = useState([]);
  const [reasonsData, setReasonsData] = useState([]);
  const [openDial, setOpenDial] = useState(false);
  const [isLoding, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [employeeData, setEmployeeData] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);


  const BookingId = navigation.route.params.bookingId;

  useEffect(() => {
    (async () => {
      GetEmployee();
      GetStaff();
    })();
  }, []);

  const GetStaff = () => {
    setIsLoading(true);

    jwt
      .get(`${Url.GET_BOOKING_STAFF}/${BookingId}`)
      .then((response) => {
        setIsLoading(false);

        setStaffData(response.data.booking_staff);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetReasons = () => {
    setIsLoading(true);
    jwt
      .get(`${Url.GET_REJECTED_CHECKLIST}/${global.systemID}`)
      .then((response) => {
        const newData = [];
        response.data.rejected_checklist.map((item) => {
          newData.push({
            id: item.id,
            name: item.reason,
          });
        });
        setReasonsData(newData);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetEmployee = () => {
    setIsLoading(true);
    jwt
      .get(`${Url.GET_ACTIVE_EMPLOYEES}/${global.systemID}`)
      .then((response) => {
        setIsLoading(false);
        setEmployeeData(response.data.employee);
        const newData = [];
        response.data.employee.map((item) =>
          newData.push(`${item.employee.first_name} ${item.employee.last_name}`)
        );
        setEmployeeList(newData);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          Staff List
        </Text>
        <MaterialIcons
          name="arrow-back"
          size={26}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 15.0,
            fontWeight: 300,
          }}
          onPress={() => navigation.navigation.pop()}
        />
      </View>
    );
  };


  const GetIntials = (nameString) => {
    const fullName = nameString.split(" ");
    const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
    return initials.toUpperCase();
  };

  const renderItem = ({ item, index }) => {
    return (
      <ListItem.Swipeable
        key={index}
        containerStyle={{
          marginTop: 15,
          zIndex: 1,
          borderRadius: 6,
        }}
      >
        <Avatar
          size={54}
          rounded
          // source={item.avatar != null && { uri: item.avatar }}
          title={GetIntials(item.full_name)}
          containerStyle={{ backgroundColor: "grey" }}
        ></Avatar>

        <ListItem.Content>
          <ListItem.Title style={{ marginBottom: 4 }}>
            {item.full_name}
          </ListItem.Title>
          <ListItem.Subtitle numberOfLines={1}>
            {item.booking_status}
          </ListItem.Subtitle>

          {/* <View
            style={{
              flexDirection: "row",

              paddingTop: 10,
              width: "100%",
            }}
          >
            <Text>{item.city}</Text>
            <TouchableOpacity
              style={{
                position: "absolute",
                right: 2,
                top: -50,
                zIndex: 10,
              }}
              onPress={() => {
                navigation.navigation.navigate("UpdateEmployee", {
                  item: item,
                });
              }}
            >
              <MaterialCommunityIcons
                name="account-edit-outline"
                size={30}
                color={Colors.primaryColor}
              />
            </TouchableOpacity>
          </View> */}
        </ListItem.Content>
      </ListItem.Swipeable>
    );
  };

  return (
    <View style={styles.container}>
      {header()}
      <View style={{ flex: 1, backgroundColor:"#F2F3F4" ,padding: Sizes.fixPadding * 2, paddingTop: 0 }}>
        {/* <ScrollView style={{ flex: 1 }}> */}
        {staffData.length > 0 ? (
          <FlatList
            showsVerticalScrollIndicator={false}
            nestedScrollEnabled
            data={staffData}
            renderItem={renderItem}
            keyExtractor={(item) => item.id}
          />
        ) : (
          <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            <View style={styles.noItemIconWrapStyle}>
              <MaterialCommunityIcons
                name="record-circle-outline"
                size={40}
                color="black"
              />
            </View>
            <Text style={{ fontSize: 18, marginTop: Sizes.fixPadding * 1.0 }}>
              Staff list is empty!
            </Text>
          </View>
        )}
      </View>

      {isLoding && <Loading />}
   
     
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor:Colors.primaryColor
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom:15,
    marginTop:40
  },
  dropdown2BtnStyle: {
    width: "80%",
    height: 40,
    backgroundColor: "#FFF",
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#CCCCCC",
    marginBottom: 15,
  },
  dropdown2BtnTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 15,
  },
  dropdown2DropdownStyle: { backgroundColor: "#ddd" },
  dropdown2RowStyle: { backgroundColor: "#ddd", borderBottomColor: "#C5C5C5" },
  dropdown2RowTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 16,
  },
});
export default BookingStaff;
