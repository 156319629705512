import React, { useEffect } from "react";
import { View, Platform } from "react-native";
import * as SecureStore from "expo-secure-store";
import AsyncStorage from "@react-native-async-storage/async-storage";

const LoadingScreen = (navigation) => {
  useEffect(() => {
    getValueFor("userData");
  }, []);

  const getValueFor = async (key) => {
    let response =
      Platform.OS === "android" || Platform.OS === "ios"
        ? await SecureStore.getItemAsync(key)
        : await AsyncStorage.getItem(key);

    if (response && response !== null && response !== "") {
      let permissionsData =
        Platform.OS === "android" || Platform.OS === "ios"
          ? await SecureStore.getItemAsync("permissions")
          : await AsyncStorage.getItem("permissions");

      const newData = JSON.parse(response);
      global.accessToken = newData.token;
      global.userData = newData.userData;

      if (
        permissionsData &&
        permissionsData !== null &&
        permissionsData !== ""
      ) {
        global.permissions = JSON.parse(permissionsData);
      }

      let response2 =
        Platform.OS === "android" || Platform.OS === "ios"
          ? await SecureStore.getItemAsync("systemID")
          : await AsyncStorage.getItem("systemID");
      if (response2 && response2 !== null && response2 !== "") {
        global.systemID = parseInt(response2);
      } else {
        global.systemID = newData.userData.system_id;
      }

      navigation.navigation.navigate("Home");
    } else {
      navigation.navigation.navigate("Login");
    }
  };

  return <View style={{ flex: 1, backgroundColor: "white" }}></View>;
};

export default LoadingScreen;
