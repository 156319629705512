import React, { useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  FlatList,
  Alert,
  Dimensions,
  TouchableOpacity
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import { Button, Overlay, ListItem, SpeedDial } from "react-native-elements";

import {
  Ionicons,
  Entypo,
  MaterialIcons,
  MaterialCommunityIcons,
  FontAwesome,
  Octicons,
} from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import { useForm, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import SelectDropdown from "react-native-select-dropdown";
import Loading from "../Utils/Loading";
// import MultiSelect from "react-native-multiple-select";
import SectionedMultiSelect from "react-native-sectioned-multi-select";
import AuthorizedFor from "../../constants/authorizedFor";
import { authorizedForFlateList } from "../../constants/authorizedForFlateList";
import { ModulesPermission } from "../../constants/modules";
import CustomPicker from "../../components/Picker/Picker";

const windowWidth = Dimensions.get("window").width;

const BookingTasks = (navigation) => {
  const [tasksData, setTasksData] = useState([]);
  const [reasonsData, setReasonsData] = useState([]);
  const [openDial, setOpenDial] = useState(false);
  const [isLoding, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [employeeData, setEmployeeData] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [roles, setRoles] = useState(global.userData.role_names);
  const [isAllowStatus, setIsAllowStatus] = useState(false);
  const [isStaff, setIsStaff] = useState(false);
  const [isAllowAssign, setIsAllowAssign] = useState(false);

  const [expanded, setExpanded] = useState(false);
  const [expandedID, setExpandedID] = useState(false);

  const [statusList, setStautsList] = useState([]);
  const [assignList, setAssignList] = useState([]);
  const [priorityList, setPriorityList] = useState([]);
  const [statusData, setStautsData] = useState([
    {
      value: "Accepted",
      label: "Accepted",
    },
    {
      value: "Done",
      label: "Done",
    },
    {
      value: "Rejected",
      label: "Rejected",
    },
  ]);

  const ref = useRef(null);

  const bookingId = navigation.route.params.bookingId;

  const priorityData = [
    { value: "Do Not Clean", label: "Do Not Clean" },
    { value: "High", label: "High" },
    { value: "Low", label: "Low" },
    { value: "Medium", label: "Medium" },
    { value: "Urgent", label: "Urgent" },
  ];

  useEffect(() => {
    (async () => {
      // GetEmployee();
      GetTasks();
      GetStaff(bookingId);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      (await authorizedForFlateList(
        ModulesPermission.Update_Order_Task_Status
      )) && setIsAllowStatus(true);

      (await authorizedForFlateList(
        ModulesPermission.Assign_Order_Task_To_User
      )) && setIsAllowAssign(true);

      (await authorizedForFlateList(
        ModulesPermission.Update_Order_Task_Status_For_Client
      )) &&
        (setIsAllowStatus(true),
        setStautsData([
          {
            value: "Accepted",
            label: "Accepted",
          },
          {
            value: "Rejected",
            label: "Rejected",
          },
        ]));

      (await authorizedForFlateList(
        ModulesPermission.Update_Order_Task_Status_For_Staff
      )) &&
        (setIsAllowStatus(true),
        setStautsData([
          {
            value: "Done",
            label: "Done",
          },
        ]));
    })();
  }, [tasksData.length]);

  const GetStaff = () => {
    setIsLoading(true);

    jwt
      .get(`${Url.GET_BOOKING_STAFF}/${bookingId}`)
      .then((response) => {
        setIsLoading(false);
        // console.log(response);
        // setStaffData(response.data.booking_staff);
        const newData = [];
        response.data.employee.map((item) =>
          newData.push({
            value: item.id,
            label: `${item.employee.first_name} ${item.employee.last_name}`,
            ...item,
          })
        );
        setEmployeeData(newData);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetTasks = () => {
    setIsLoading(true);

    jwt
      .get(`${Url.GET_ORDER_TASKS_WITH_BOOKING}/${bookingId}`)
      .then((response) => {
        // console.log(response);
        setIsLoading(false);

        const lenght = roles.filter((item) => item === "Service Staff").length;
        if (lenght > 0) {
          setIsStaff(true);
          const NewData = response.data.order_task.filter(
            (item) => item.assigned_to === global.userData.employee_id
          );
          setTasksData(NewData);
          const NewStatus = [];
          NewData.map((obj) => NewStatus.push(obj.status ? obj.status : 0));
          setStautsList(NewStatus);
        } else {
          setTasksData(response.data.order_task);
          const NewStatus = [];
          response.data.order_task.map((obj) =>
            NewStatus.push(obj.status ? obj.status : 0)
          );
          setStautsList(NewStatus);

          const NewAssign = [];
          response.data.order_task.map((obj) =>
            NewAssign.push(obj.assigned_to ? obj.assigned_to : 0)
          );
          setAssignList(NewAssign);

          const NewAssPList = [];
          response.data.order_task.map((obj) =>
            NewAssPList.push(obj.priority ? obj.priority : 0)
          );
          setPriorityList(NewAssPList);

        }

        const tasksLength = response.data.order_task.length;
        const statusLength = response.data.order_task.filter(
          (obj) => obj.status === "Done"
        ).length;

        // console.log(tasksLength);
        // console.log(statusLength);
        // global.userData.employee_id;
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetReasons = (item) => {
    setIsLoading(true);
    jwt
      .post(`${Url.SEARCH_REJECTED_CHECKLIST}/${global.systemID}`, {
        room_type_id: item.room_type_id,
      })
      .then((response) => {
        // console.log(response);
        const newData = [];
        response.data.rejected_checklist.map((item) => {
          newData.push({
            id: item.id,
            name: item.reason,
          });
        });
        setReasonsData(newData);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetEmployee = () => {
    setIsLoading(true);
    jwt
      .get(`${Url.GET_ACTIVE_EMPLOYEES}/${global.systemID}`)
      .then((response) => {
        setIsLoading(false);
        setEmployeeData(response.data.employee);
        const newData = [];
        response.data.employee.map((item) =>
          newData.push(`${item.employee.first_name} ${item.employee.last_name}`)
        );
        setEmployeeList(newData);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {};

  const handleStatus = (selectedItem, item) => {
    // console.log(selectedItem, item);
    tasksData.map((tsk, index) => {
      if (tsk.id === item.id) {
        const List = [...statusList];
        List[index] = selectedItem;
        setStautsList(List);
      }
    });
    if (selectedItem === "Rejected") {
      GetReasons(item);
      setSelectedId(item.id);
      setIsVisible2(true);
    } else {
      setIsLoading(true);

      jwt
        .post(`${Url.UPDATE_ORDER_TASK_STATUS}/${item.id}`, {
          status: selectedItem,
        })
        .then((response) => {
          setIsLoading(false);
          Alert.alert("Status Updated Successfully!");
          GetTasks();
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
        });
    }
  };

  const onSelectedItemsChange = (selectedItems) => {
    // this.setState({ selectedItems });
    setSelectedItems(selectedItems);
  };

  const updateRejected = () => {
    setIsLoading(true);

    const reasonList = [];

    selectedItems.map((item) =>
      reasonsData
        .filter((obj) => obj.id === item)
        .map((res) => {
          reasonList.push({
            checklist_id: res.id,
            reason: res.name,
          });
        })
    );

    jwt
      .post(`${Url.UPDATE_ORDER_TASK_STATUS}/${selectedId}`, {
        status: "Rejected",
        rejected_checklist: reasonList,
      })
      .then((response) => {
        setIsLoading(false);
        setIsVisible2(false);
        Alert.alert("Status Updated Successfully!");
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          Booking Tasks
        </Text>
        <MaterialIcons
          name="arrow-back"
          size={26}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 15.0,
            fontWeight: 300,
          }}
          onPress={() => navigation.navigation.pop()}
        />
      </View>
    );
  };

  const handlePriority = (selectedItem, id) => {
    setIsLoading(true);

    jwt
      .post(`${Url.UPDATE_ORDER_TASK_PRIORITY}/${id}`, {
        priority: selectedItem,
      })
      .then((response) => {
        setIsLoading(false);
        Alert.alert("Priority Updated Successfully!");
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleAssignTo = (selectedItem, item) => {
    setIsLoading(true);

    tasksData.map((tsk, index) => {
      if (tsk.id === item.id) {
        const List = [...assignList];
        List[index] = selectedItem != 0 ? selectedItem : 0;
        setAssignList(List);
      }
    });

    jwt
      .post(`${Url.UPDATE_ASSIGN_TO}/${item.id}`, {
        assign_to: "" + selectedItem,
      })
      .then((response) => {
        setIsLoading(false);
        Alert.alert("Assigned Successfully!");
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const toggleAccordionItem = (key) => {
    setExpanded(expandedID === key ? !expanded : true);
    setExpandedID(key);
  };

  const renderItem = ({ item, index }) => {
    return (
      <ListItem.Accordion
        key={index}
        bottomDivider
        content={
          <>
            <ListItem.Content>
              <ListItem.Title style={{}}>{item.room_type}</ListItem.Title>
            </ListItem.Content>
            <ListItem.Content right>
              <ListItem.Title right style={{}}>
              <Text
                style={{
                  color: item.status === "Assigned" ? "green" : "#ffc107",
                }}
              >
                {item.status ? item.status : " "}
              </Text>
              </ListItem.Title>
            </ListItem.Content>
          </>
        }
        isExpanded={expanded && expandedID === item.id}
        onPress={() => {
          toggleAccordionItem(item.id);
        }}
      >
        <View style={styles.list}>
          {isAllowStatus ? (
            <ListItem>
              <ListItem.Content>
                <ListItem.Title>
                  <View style={{ flexDirection: "row" }}>
                    <View style={styles.child1}>
                      <Text style={{ ...styles.label }}>{"Status"}</Text>
                    </View>
                    <View style={styles.child2}>
                      <CustomPicker
                        data={statusData}
                        selectedValue={statusList[index]}
                        handleSelectedValue={handleStatus}
                        defaultLabel={"Please select a status"}
                        customIndex={item}
                        style={{}}
                      />
                    </View>
                  </View>
                </ListItem.Title>
              </ListItem.Content>
            </ListItem>
          ) : (
            <ListItem>
              <ListItem.Content>
                <ListItem.Title>
                  <Text style={{ color: "#555" }}>Status: {item.status}</Text>
                </ListItem.Title>
              </ListItem.Content>
            </ListItem>
          )}
          {isAllowAssign ? (
            <ListItem>
              <ListItem.Content>
                <ListItem.Title>
                  <View style={{ flexDirection: "row", marginTop: 5 }}>
                    <View style={styles.child1}>
                      <Text style={{ ...styles.label }}>{"Assign To"}</Text>
                    </View>
                    <View style={styles.child2}>
                      <CustomPicker
                        data={employeeData}
                        selectedValue={assignList[index]}
                        handleSelectedValue={handleAssignTo}
                        defaultLabel={"Please Select Staff"}
                        customIndex={item}
                        style={{}}
                      />
                    </View>
                  </View>
                </ListItem.Title>
              </ListItem.Content>
            </ListItem>
          ) : (
            <ListItem>
              <ListItem.Content>
                <ListItem.Title>
                  <Text style={{ color: "#555" }}>
                    Assign To: {item.assigned_name}
                  </Text>
                </ListItem.Title>
              </ListItem.Content>
            </ListItem>
          )}

{item.rejected_checklist_histories.length > 0 &&
  <ListItem>
              <ListItem.Content>
                <ListItem.Title>
                <TouchableOpacity
                onPress={() => {
                  navigation.navigation.navigate("RejectedHistory", {
                    item: item.rejected_checklist_histories,
                  });
                }}
                style={{backgroundColor:Colors.primaryColor, flexDirection:'row', padding:10, paddingLeft:15, paddingRight:15, borderRadius:30}}
              >
                <Text style={{textAlign:'center', fontSize:18, color:"#FFF", fontWeight:'600'}}>History</Text>
                <MaterialCommunityIcons
            name="arrow-right"
            size={18}
            style={{ marginLeft: 2, paddingTop:2 }}
            color="#FFF"
          />
              </TouchableOpacity>
                </ListItem.Title>
              </ListItem.Content>
            </ListItem>
}

        </View>
      </ListItem.Accordion>
    );
  };

  return (
    <View style={styles.container}>
      {header()}
      <View
        style={{
          flex: 1,
          backgroundColor: "#F2F3F4",
          padding: Sizes.fixPadding * 2,
          paddingTop: 20,
        }}
      >
        {tasksData.length > 0 ? (
          <FlatList
            showsVerticalScrollIndicator={false}
            nestedScrollEnabled
            data={tasksData}
            renderItem={renderItem}
            keyExtractor={(item) => item.id}
          />
        ) : (
          <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            <View style={styles.noItemIconWrapStyle}>
              <MaterialCommunityIcons
                name="record-circle-outline"
                size={40}
                color="black"
              />
            </View>
            <Text style={{ fontSize: 18, marginTop: Sizes.fixPadding * 1.0 }}>
              Tasks list is empty!
            </Text>
          </View>
        )}
      </View>

      {isLoding && <Loading />}
      <SpeedDial
        isOpen={openDial}
        icon={{ name: "edit", color: "#fff" }}
        openIcon={{ name: "close", color: "#fff" }}
        onOpen={() => setOpenDial(!openDial)}
        onClose={() => setOpenDial(!openDial)}
        style={{ zIndex: 2 }}
        buttonStyle={{ backgroundColor: Colors.primaryColor }}
      >
        <SpeedDial.Action
          icon={{ name: "add", color: "#fff" }}
          title="Add Task"
          onPress={() => setIsVisible(!isVisible)}
          buttonStyle={{ backgroundColor: Colors.primaryColor }}
        />
      </SpeedDial>
      <Overlay
        visible={isVisible}
        overlayStyle={{
          width: "80%",
          backgroundColor: "#FFFFFF",
          borderRadius: 8,
          paddingTop: 15,
          paddingBottom: 20,
          padding: Sizes.fixPadding * 2,
        }}
        onBackdropPress={() => setIsVisible(!isVisible)}
      >
        <View style={{ alignItems: "center", marginTop: 3, marginBottom: 10 }}>
          <Text style={{ fontSize: 18 }}>New Task</Text>
        </View>

        <Text style={styles.label}>{"Room type"}</Text>
        <SelectDropdown
          data={statusData}
          defaultValueByIndex={1}
          onSelect={(selectedItem, index) => {}}
          defaultButtonText={"Select country"}
          buttonTextAfterSelection={(selectedItem, index) => {
            return selectedItem;
          }}
          rowTextForSelection={(item, index) => {
            return item;
          }}
          buttonStyle={styles.dropdown2BtnStyle}
          buttonTextStyle={styles.dropdown2BtnTxtStyle}
          renderDropdownIcon={(isOpened) => {
            return (
              <Entypo
                name={isOpened ? "chevron-thin-up" : "chevron-thin-down"}
                color={"#444"}
                size={14}
              />
            );
          }}
          dropdownIconPosition={"right"}
          dropdownStyle={styles.dropdown2DropdownStyle}
          rowStyle={styles.dropdown2RowStyle}
          rowTextStyle={styles.dropdown2RowTxtStyle}
        />

        <Controller
          name="quantity"
          control={control}
          rules={{
            required: { value: true, message: "Quantity is required" },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              label="Quantity"
              type="number"
              onChangeText={(value) => onChange(value)}
              value={value}
              error={errors.quantity}
              errorText={errors?.quantity?.message}
            />
          )}
          defaultValue=""
        />
        <Button
          title="Add Task"
          type="solid"
          loading={isLoding}
          onPress={handleSubmit(onSubmit)}
          buttonStyle={{
            padding: Sizes.fixPadding * 1,
            width: "100%",
            backgroundColor: Colors.primaryColor,
            borderRadius: 22,
          }}
          containerStyle={{
            width: "100%",
            // marginHorizontal: 50,
            marginVertical: 20,
          }}
          titleStyle={{
            fontSize: 14,
            fontWeight: "100",
            color: Colors.whiteColor,
          }}
          icon={
            <Ionicons
              name="add-circle-sharp"
              size={24}
              color="#ffffff"
              style={{ marginRight: "2%" }}
            />
          }
        />
      </Overlay>

      <Overlay
        visible={isVisible2}
        overlayStyle={{
          width: "80%",
          backgroundColor: "#FFFFFF",
          borderRadius: 8,
          paddingTop: 15,
          paddingBottom: 20,
          padding: Sizes.fixPadding * 2,
        }}
        onBackdropPress={() => setIsVisible2(!isVisible2)}
      >
        <SectionedMultiSelect
          items={reasonsData}
          ref={ref}
          IconRenderer={MaterialIcons}
          uniqueKey="id"
          subKey="children"
          selectText="Select Reason..."
          showDropDowns={true}
          //   readOnlyHeadings={true}
          onSelectedItemsChange={onSelectedItemsChange}
          selectedItems={selectedItems}
          searchPlaceholderText="Search reasons"
          //   text={Colors.primaryColor}
          styles={{
            button: {
              backgroundColor: Colors.primaryColor,
              selectedItemText: Colors.primaryColor,
            },
          }}
          theme={{
            Colors: {
              selectToggleTextColor: "#053075",
              text: "#053075",
              selectedItemText: Colors.primaryColor,
            },
          }}
          //   buttonStyle={{ backgroundColor: Colors.primaryColor }}
        />
        {/* <MultiSelect
          hideTags
          items={items}
          uniqueKey="id"
          ref={(component) => {
            component;
          }}
          onSelectedItemsChange={onSelectedItemsChange}
          selectedItems={selectedItems}
          selectText="Select Reason"
          searchInputPlaceholderText="Search Reasons..."
          onChangeInput={(text) => console.log(text)}
          tagRemoveIconColor="#CCC"
          tagBorderColor="#CCC"
          tagTextColor="#CCC"
          selectedItemTextColor={Colors.primaryColor}
          selectedItemIconColor={Colors.primaryColor}
          itemTextColor="#000"
          displayKey="name"
          searchInputStyle={{ color: "#CCC" }}
          submitButtonColor={Colors.primaryColor}
          submitButtonText="Add"
          hideSubmitButton
        /> */}

        <Button
          title="Updated Status"
          type="solid"
          disabled={selectedItems.length > 0 ? false : true}
          loading={isLoding}
          onPress={() => updateRejected()}
          buttonStyle={{
            padding: Sizes.fixPadding,
            width: "100%",
            backgroundColor: Colors.primaryColor,
            borderRadius: 22,
          }}
          containerStyle={{
            width: "100%",
            // marginHorizontal: 50,
            marginVertical: 20,
          }}
          titleStyle={{
            fontSize: 14,
            fontWeight: "100",
            color: Colors.whiteColor,
          }}
          icon={
            <Ionicons
              name="add-circle-sharp"
              size={24}
              color="#ffffff"
              style={{ marginRight: "2%" }}
            />
          }
        />
      </Overlay>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: Colors.primaryColor,
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 15,
    marginTop: 40,
  },
  label: {
    color: "black",
    marginLeft: 0,
    marginBottom: 3,
    fontSize: windowWidth > 600 ? 18 : 16,
    fontWeight: "500",
    marginTop: windowWidth > 600 ? 0 : 12,
  },
  child1: {
    width: windowWidth > 600 ? "15%" : "30%",
  },
  child2: {
    width: windowWidth > 600 ? "85%" : "70%",
  },
});
export default BookingTasks;
