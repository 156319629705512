import React, { useState } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { StyleSheet, Text, View, Platform } from "react-native";
import Main from "./Main";
// import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";
export default function App(props) {
  return (
    <NavigationContainer>
      <Main />
    </NavigationContainer>
  );
}
// serviceWorkerRegistration.register();
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
});
