import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, ScrollView } from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  Button,
  SpeedDial,
  ListItem,
  Overlay,
  Dialog,
} from "react-native-elements";
import {
  Feather,
  Ionicons,
  Entypo,
  MaterialIcons,
  Octicons,
} from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import { useForm, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import SelectDropdown from "react-native-select-dropdown";
import Loading from "../Utils/Loading";
import moment from "moment";
import { ModulesPermission } from "../../constants/modules";
import AuthorizedFor from "../../constants/authorizedFor";

const BookingHistory = (navigation) => {
  const item = navigation.route.params.item;
  const [isLoding, setIsLoading] = useState(false);
  const [lastClockInDetails, setLastClockInDetails] = useState(null);
  const [allClockInDetails, setAllClockInDetails] = useState(null);

  useEffect(() => {
    (async () => {
      lastClockIn();
      allClockIn();
      singleClockIn();
      disputedTimeSheet();
    })();
  }, [navigation, navigation.route.params]);

  const lastClockIn = () => {
    setIsLoading(true);
    jwt
      .get(`${Url.LAST_CLOCKIN_DETAIL}/${item.id}`)
      .then((response) => {
        setLastClockInDetails(response.data["clockin-bookings"]);
        // setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const allClockIn = () => {
    setIsLoading(true);
    jwt
      .get(`${Url.ALL_CLOCKIN_DETAILS}/${item.id}`)
      .then((response) => {
        setAllClockInDetails(response.data["booking-details"]);
        // setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const singleClockIn = () => {
    setIsLoading(true);
    jwt
      .get(`${Url.SINGLE_CLOCKIN_CLOCKOUT_DETAILS}/${item.id}`)
      .then((response) => {
        // setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const disputedTimeSheet = () => {
    setIsLoading(true);
    jwt
      .get(Url.DISPUTED_BOOKING_TIMESHEET)
      .then((response) => {
        // setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const header = (item) => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          History
        </Text>
        <MaterialIcons
          name="arrow-back"
          size={26}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 15.0,
            fontWeight: 300,
          }}
          onPress={() => navigation.navigation.pop()}
        />
      </View>
    );
  };

  return (
    <View style={styles.container}>
      {header(item)}

      <ScrollView
        style={{
          padding: Sizes.fixPadding * 2,
          marginBottom: 10,
          backgroundColor:"#F2F3F4"
        }}
      >
         {AuthorizedFor(
          ModulesPermission.Get_Last_Booking_Clockin_Details_By_Employee_Booking_ID
        ) && (
        <View
          style={{
            padding: Sizes.fixPadding * 1.5,
            backgroundColor: "#fff",
            borderRadius: 6,
          }}
        >
          <Text style={{ fontSize: 16, fontWeight: "bold" }}>
            Last ClockIn Details
          </Text>
          {lastClockInDetails != null && (
            <View>
              <View style={{ ...styles.contentContainer, marginTop: 15 }}>
                <Text style={styles.label}>ClockIn</Text>
                <Text>{lastClockInDetails.clockin}</Text>
              </View>

              <View style={{ ...styles.contentContainer, marginTop: 10 }}>
                <Text style={styles.label}>ClockOut</Text>
                <Text>{lastClockInDetails.clockout}</Text>
              </View>

              <View style={{ marginTop: 10 }}>
                <Text style={styles.label}>Comments</Text>
                <Text>{lastClockInDetails.comments}</Text>
              </View>

              <View style={{ marginTop: 10 }}>
                <Text style={styles.label}>Reason ClockOut</Text>
                <Text>{lastClockInDetails.reason_clockout}</Text>
              </View>
            </View>
          )}
        </View>
        )}
        {AuthorizedFor(
          ModulesPermission.Get_All_Booking_Clockin_Details_By_Employee_Booking_ID
        ) && (
          <View
            style={{
              padding: Sizes.fixPadding * 1.5,
              backgroundColor: "#fff",
              borderRadius: 6,
              marginTop: 15,
              paddingBottom: 30,
            }}
          >
            <Text style={{ fontSize: 16, fontWeight: "bold" }}>
              All ClockIn Details
            </Text>
            {allClockInDetails != null && (
              <View>
                <View style={{ ...styles.contentContainer, marginTop: 15 }}>
                  <Text style={styles.label}>Booking Date</Text>
                  <Text>{allClockInDetails.booking_date}</Text>
                </View>

                <View style={{ ...styles.contentContainer, marginTop: 10 }}>
                  <Text style={styles.label}>ClockIn </Text>
                  <Text>{allClockInDetails.clockin}</Text>
                </View>

                <View style={{ ...styles.contentContainer, marginTop: 10 }}>
                  <Text style={styles.label}>ClockOut</Text>
                  <Text>{allClockInDetails.clockout}</Text>
                </View>

                <View style={{ ...styles.contentContainer, marginTop: 10 }}>
                  <Text style={styles.label}>Shift Start</Text>
                  <Text>{allClockInDetails.shift_start}</Text>
                </View>

                <View style={{ ...styles.contentContainer, marginTop: 10 }}>
                  <Text style={styles.label}>Shift End</Text>
                  <Text>{allClockInDetails.shift_end}</Text>
                </View>

                <View style={{ ...styles.contentContainer, marginTop: 10 }}>
                  <Text style={styles.label}>Work Hours</Text>
                  <Text>{allClockInDetails.total_hours_worked}</Text>
                </View>

                <View style={{ ...styles.contentContainer, marginTop: 10 }}>
                  <Text style={styles.label}>Break Hours</Text>
                  <Text>{allClockInDetails.total_hours_break}</Text>
                </View>

                <Text
                  style={{ fontSize: 16, fontWeight: "bold", marginTop: 10 }}
                >
                  Clocking Details
                </Text>

                {allClockInDetails.clocking_detail.map((item, index) => (
                  <View
                    key={index}
                    style={{ ...styles.contentContainer, marginTop: 10 }}
                  >
                    <Text style={styles.label}>{item.title}</Text>
                    <Text>{item.clocktime}</Text>
                    <View style={{}}>
                      <Text style={styles.label}>Reason</Text>
                      <Text>{item.reason}</Text>
                    </View>
                  </View>
                ))}
              </View>
            )}
          </View>
        )}
      </ScrollView>
      {isLoding && <Loading />}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor:Colors.primaryColor
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom:15,
    marginTop:40
  },
  label: {
    fontWeight: "700",
  },
  contentContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 10,
  },
});

export default BookingHistory;
