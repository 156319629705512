import React, { useRef } from "react";
import { StyleSheet, View, Button, Text } from "react-native";
import SignatureScreen from "react-native-signature-canvas";

const Sign = ({ onOkSign }) => {
  const ref = useRef();

  // Called after ref.current.readSignature() reads a non-empty base64 string
  const handleOK = (signature) => {
    onOkSign(signature); // Callback from Component props
  };

  // Called after ref.current.readSignature() reads an empty string
  //   const handleEmpty = () => {
  //     console.log("Empty");
  //   };

  // Called after ref.current.clearSignature()
  //   const handleClear = () => {
  //     console.log("clear success!");
  //   };

  // Called after end of stroke
  //   const handleEnd = () => {
  //     ref.current.readSignature();
  //   };

  // Called after ref.current.getData()
  //   const handleData = (data) => {
  //     console.log(data);
  //   };

  const imgWidth = 300;
  const imgHeight = 200;
  const style = `.m-signature-pad {box-shadow: none; border: none; } 
  .m-signature-pad--body {border: none;}
  .m-signature-pad--footer {display: none; margin: 0px;}
  body,html {
  width: ${imgWidth}px; height: ${imgHeight}px;}`;

  const handleClear = () => {
    ref.current.clearSignature();
  };

  const handleConfirm = () => {
    ref.current.readSignature();
  };

  return (
    <View style={styles.container}>
      <Text>Singature</Text>
      {/* <View style={{ width: imgWidth, height: imgHeight }}> */}
      <SignatureScreen
        ref={ref}
        //   onEnd={handleEnd}
        onOK={handleOK}
        //   onEmpty={handleEmpty}
        //   onClear={handleClear}
        //   onGetData={handleData}
        //   autoClear={true}
        //   descriptionText={text}
        scrollable={false}
        bgWidth={imgWidth}
        bgHeight={imgHeight}
        webStyle={style}
        //   bgSrc="https://via.placeholder.com/300x200/ff726b"
      />
      <View style={styles.row}>
        <Button title="Clear" onPress={handleClear} />
        <Button title="Confirm" onPress={handleConfirm} />
      </View>
      {/* </View> */}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    height: 250,
    padding: 10,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
});

export default Sign;
