import React, { useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  FlatList,
  Alert,
  Dimensions,
  TouchableOpacity,
  Platform
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import { Button, Overlay, ListItem, SpeedDial } from "react-native-elements";

import {
  Ionicons,
  Entypo,
  MaterialIcons,
  MaterialCommunityIcons,
  FontAwesome,
  Octicons,
} from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import { useForm, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import SelectDropdown from "react-native-select-dropdown";
import Loading from "../Utils/Loading";
import SectionedMultiSelect from "react-native-sectioned-multi-select";
import AuthorizedFor from "../../constants/authorizedFor";
import { authorizedForFlateList } from "../../constants/authorizedForFlateList";
import { ModulesPermission } from "../../constants/modules";
import CustomPicker from "../../components/Picker/Picker";

const windowWidth = Dimensions.get("window").width;


const TaskDetails = (navigation) => {

  const item = navigation.route.params.item;

  const [reasonsData, setReasonsData] = useState([]);
  const [openDial, setOpenDial] = useState(false);
  const [isLoding, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [employeeData, setEmployeeData] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);

  const [isAllowStatus, setIsAllowStatus] = useState(false);
  const [isAllowPriority, setIsAllowPriority] = useState(false);
  const [isAllowAssign, setIsAllowAssign] = useState(false);


  const [selectedStatus, setSelectedStauts] = useState(item.status)
  const [selectedPriority, setSelectedPriority] = useState(item.priority)
  const [selectedAssign, setSelectedAssign] = useState(item.assigned_to)



  const ref = useRef(null);
  const [roles, setRoles] = useState(global.userData.role_names);
 
  const statusData = [
    {
      value:  "Pending",
      label:  "Pending",
    },
    {
      value: "Accepted",
      label: "Accepted",
    },
    {
      value: "Assigned",
      label:  "Assigned",
    },
    {
      value: "Do Not Clean",
      label: "Do Not Clean",
    },
  
    {
      value: "Done",
      label: "Done",
    },
    {
      value: "Rejected",
      label: "Rejected",
    }
  ];

  const priorityData = [
    {
      value:  "Do Not Clean",
      label:  "Do Not Clean",
    },
    {
      value: "High",
      label: "High",
    },
    {
      value: "Low",
      label:  "Low",
    },
    {
      value: "Do Not Clean",
      label: "Do Not Clean",
    },
  
    {
      value: "Medium",
      label: "Medium",
    },
    {
      value: "Urgent",
      label: "Urgent",
    }];

  useEffect(() => {
    (async () => {
      GetEmployee();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      (await authorizedForFlateList(
        ModulesPermission.Update_Order_Task_Status
      )) && setIsAllowStatus(true);
      (await authorizedForFlateList(
        ModulesPermission.Update_Order_Task_Priority
      )) && setIsAllowPriority(true);
      (await authorizedForFlateList(
        ModulesPermission.Assign_Order_Task_To_User
      )) && setIsAllowAssign(true);
    })();
  }, [item]);


  const GetEmployee = () => {
    setIsLoading(true);
    jwt
      .get(`${Url.GET_ACTIVE_EMPLOYEES}/${global.systemID}`)
      .then((response) => {
        setIsLoading(false);
        // setEmployeeData(response.data.employee);
        const newData = [];
        response.data.employee.map((item) =>
          newData.push({
            value: item.id,
            label: `${item.employee.first_name} ${item.employee.last_name}`,
            ...item,
          })
        );
        setEmployeeData(newData);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {};

  const handleStatus = (selectedItem, item) => {

   
       setSelectedStauts(selectedItem)
      
      
    if (selectedItem === "Rejected") {
      // GetReasons(item);
      // setSelectedId(item.id);
      // setIsVisible2(true);
      navigation.navigation.navigate("RejectedStatus", {item:item})
    } else {
      setIsLoading(true);

      jwt
        .post(`${Url.UPDATE_ORDER_TASK_STATUS}/${item.id}`, {
          status: selectedItem,
        })
        .then((response) => {
          setIsLoading(false);
          Alert.alert("Status Updated Successfully!");
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const onSelectedItemsChange = (selectedItems) => {
    // this.setState({ selectedItems });
    setSelectedItems(selectedItems);
  };

  const updateRejected = () => {
    setIsLoading(true);

    const reasonList = [];

    selectedItems.map((item) =>
      reasonsData
        .filter((obj) => obj.id === item)
        .map((res) => {
          reasonList.push({
            checklist_id: res.id,
            reason: res.name,
          });
        })
    );

    jwt
      .post(`${Url.UPDATE_ORDER_TASK_STATUS}/${selectedId}`, {
        status: "Rejected",
        rejected_checklist: reasonList,
      })
      .then((response) => {
        setIsLoading(false);
        setIsVisible2(false);
        Alert.alert("Status Updated Successfully!");
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          Tasks
        </Text>
        <MaterialIcons
          name="arrow-back"
          size={26}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 15.0,
            fontWeight: 300,
          }}
          onPress={() => navigation.navigation.pop()}
        />
      </View>
    );
  };

  const handlePriority = (selectedItem, item) => {

  
       setSelectedPriority(selectedItem)
     
    setIsLoading(true);

    jwt
      .post(`${Url.UPDATE_ORDER_TASK_PRIORITY}/${item.id}`, {
        priority: selectedItem,
      })
      .then((response) => {
        setIsLoading(false);
        Alert.alert("Priority Updated Successfully!");
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleAssignTo = (selectedItem, item) => {
    setIsLoading(true);

       setSelectedAssign(selectedItem)
     

    // const EmplId = employeeData[index].employee_id;

    jwt
      .post(`${Url.UPDATE_ASSIGN_TO}/${item.id}`, {
        assign_to: "" + selectedItem,
      })
      .then((response) => {
        setIsLoading(false);
        Alert.alert("Assigned Successfully!");
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };


  return (
    <View style={styles.container}>
      {header()}
      <View style={{ flex: 1, backgroundColor:"#F2F3F4", padding: Sizes.fixPadding * 2, paddingTop: 0 }}>
      <View style={{backgroundColor:'#FFF', borderRadius:5, padding:Sizes.fixPadding, marginTop:15, width:'100%'}}>
        {isAllowStatus && ( 
         
                <View style={{ flexDirection: "row", width:'100%'}}>
                  <View style={styles.child1}>
                    <Text style={{ ...styles.label }}>{"Status"}</Text>
                  </View>
                  <View style={styles.child2}>
                    <CustomPicker
                      data={statusData}
                      selectedValue={selectedStatus}
                      handleSelectedValue={handleStatus}
                      defaultLabel={"Please select a status"}
                      customIndex={item}
                      // style={{position:'absolute'}}
                    />
                  </View>
                </View>
                
        
        )}
       {isAllowAssign && (
         
                <View style={{ flexDirection: "row", width:'100%', marginTop:5}}>
                  <View style={styles.child1}>
                    <Text style={{ ...styles.label }}>{"Assign To"}</Text>
                  </View>
                  <View style={styles.child2}>
                    <CustomPicker
                      data={employeeData}
                      selectedValue={selectedAssign}
                      handleSelectedValue={handleAssignTo}
                      defaultLabel={"Please Select Staff"}
                      customIndex={item}
                      style={{}}
                    />
                  </View>
                </View>
     
        )} 

{isAllowPriority && (

                <View style={{ flexDirection: "row", width:'100%', marginTop:5}}>
           <View style={styles.child1}>
               <Text style={{ ...styles.label }}>{"Priority"}</Text>
           </View>
             <View style={styles.child2}>
               <CustomPicker
                data={priorityData}
                 selectedValue={selectedPriority}
                 handleSelectedValue={handlePriority}
                 defaultLabel={"Please select Priority"}
                 customIndex={item}
                 style={{}}
              />
             </View>
          </View>

          )}

      </View>
      </View>

      {isLoding && <Loading />}
     

      <Overlay
        visible={isVisible2}
        overlayStyle={{
          width: "80%",
          backgroundColor: "#FFFFFF",
          borderRadius: 8,
          paddingTop: 15,
          paddingBottom: 20,
          padding: Sizes.fixPadding * 2,
          position: 'relative'
        }}
        onBackdropPress={() => setIsVisible2(!isVisible2)}
      >
        <SectionedMultiSelect
          items={reasonsData}
          ref={ref}
          IconRenderer={MaterialIcons}
          uniqueKey="id"
          subKey="children"
          selectText="Select Reason..."
          showDropDowns={true}
          //   readOnlyHeadings={true}
          onSelectedItemsChange={onSelectedItemsChange}
          selectedItems={selectedItems}
          searchPlaceholderText="Search reasons"
          //   text={Colors.primaryColor}
          styles={{
            button: {
              backgroundColor: Colors.primaryColor,
              selectedItemText: Colors.primaryColor,
            },
          }}
          theme={{
            Colors: {
              selectToggleTextColor: "#053075",
              text: "#053075",
              selectedItemText: Colors.primaryColor,
            },
          }}
          //   buttonStyle={{ backgroundColor: Colors.primaryColor }}
        />
        {/* <MultiSelect
          hideTags
          items={items}
          uniqueKey="id"
          ref={(component) => {
            component;
          }}
          onSelectedItemsChange={onSelectedItemsChange}
          selectedItems={selectedItems}
          selectText="Select Reason"
          searchInputPlaceholderText="Search Reasons..."
          onChangeInput={(text) => console.log(text)}
          tagRemoveIconColor="#CCC"
          tagBorderColor="#CCC"
          tagTextColor="#CCC"
          selectedItemTextColor={Colors.primaryColor}
          selectedItemIconColor={Colors.primaryColor}
          itemTextColor="#000"
          displayKey="name"
          searchInputStyle={{ color: "#CCC" }}
          submitButtonColor={Colors.primaryColor}
          submitButtonText="Add"
          hideSubmitButton
        /> */}

        <Button
          title="Updated Status"
          type="solid"
          disabled={selectedItems.length > 0 ? false : true}
          loading={isLoding}
          onPress={() => updateRejected()}
          buttonStyle={{
            padding: Sizes.fixPadding,
            width: "100%",
            backgroundColor: Colors.primaryColor,
            borderRadius: 22,
          }}
          containerStyle={{
            width: "100%",
            // marginHorizontal: 50,
            marginVertical: 20,
          }}
          titleStyle={{
            fontSize: 14,
            fontWeight: "100",
            color: Colors.whiteColor,
          }}
          icon={
            <Ionicons
              name="add-circle-sharp"
              size={24}
              color="#ffffff"
              style={{ marginRight: "2%" }}
            />
          }
        />
      </Overlay>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor:Colors.primaryColor
  },

  label: {
    color: "black",
    marginLeft: 0,
    marginBottom: 3,
    fontSize: windowWidth > 600 ? 18 : 16,
    fontWeight: "500",
    marginTop: windowWidth > 600 ? 0 : 12,
  },
  child1: {
    width: windowWidth > 600 ? "15%" : "30%",
  },
  child2: {
    width: windowWidth > 600 ? "85%" : "70%",
  },
});
export default TaskDetails;
