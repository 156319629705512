import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  ImageBackground,
  Image,
  TouchableOpacity,
  StyleSheet,
  Platform,
} from "react-native";
import * as SecureStore from "expo-secure-store";
import AsyncStorage from "@react-native-async-storage/async-storage";

const AuthorizedFor = (module) => {
  const [permissions, setPermissions] = useState(
    global.permissions ? global.permissions : []
  );

  useEffect(() => {
    (async () => {
      const permit =
        Platform.OS === "android" || Platform.OS === "ios"
          ? JSON.parse(await SecureStore.getItemAsync("permissions"))
          : JSON.parse(await AsyncStorage.getItem("permissions"));
      setPermissions(
        permit != null
          ? permit
          : // : global.permissions != null
            // ? global.permissions
            []
      );
    })();
  }, [global.permissions, module]);

  if (permissions.filter((item) => item.id === module).length > 0) {
    return true;
  } else {
    return false;
  }
};

export default AuthorizedFor;
