import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Platform,
  Image,
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  Button,
  SpeedDial,
  ListItem,
  Overlay,
  Dialog,
} from "react-native-elements";
import {
  Feather,
  Ionicons,
  Entypo,
  MaterialIcons,
  Octicons,
} from "@expo/vector-icons";
import Loading from "../Utils/Loading";
import moment from "moment";
import UpdateInvoice from "./UpdateInvoice";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import AuthorizedFor from "../../constants/authorizedFor";
import { authorizedForFlateList } from "../../constants/authorizedForFlateList";
import { ModulesPermission } from "../../constants/modules";

const InvoiceDetails = (navigation) => {
  const [item, setItem] = useState(null);
  const [clients, setClients] = useState([]);
  const [isVisibleOverlay, setIsVisibleOverlay] = useState(false);
  const [ordersItems, setOrderItems] = useState([]);
  const [isLoding, setIsLoading] = useState(false);
  const [isOrdered, setIsOrdered] = useState(false);
  const [isPayable, setIsPayable] = useState(false);

  useEffect(() => {
    (async () => {
      GetClients();
      GetData(navigation.route.params.item.id);
      GetOrderItems(navigation.route.params.item.order_id);
    })();
  }, [isVisibleOverlay]);

  useEffect(() => {
    (async () => {
      (await authorizedForFlateList(
        ModulesPermission.Get_Order_Item_list_By_Order_ID
      )) && setIsOrdered(true);
      (await authorizedForFlateList(ModulesPermission.Pay_Invoice)) &&
        setIsPayable(true);
    })();
  }, [isVisibleOverlay]);

  const GetData = (id) => {
    setIsLoading(true);

    jwt
      .get(`${Url.GET_INVOICE}/${id}`)
      .then((response) => {
        // console.log(response.data)
        // setIsLoading(false);
        setItem(response.data.invoice);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetClients = (id) => {
    setIsLoading(true);

    jwt
      .get(`${Url.GET_ACTIVE_CLIENT}/${global.systemID}`)
      .then((response) => {
        // setIsLoading(false);
        setClients(response.data.clients);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetOrderItems = (id) => {
    // setIsLoading(true);

    jwt
      .get(`${Url.GET_ORDER_ITEM_LIST}/${id}`)
      .then((response) => {
        setIsLoading(false);
        setOrderItems(response.data.orders_items);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const toggelVisible = () => {
    setIsVisibleOverlay(!isVisibleOverlay);
  };

  const header = (item) => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          Invoice Details
        </Text>
        <MaterialIcons
          name="arrow-back"
          size={26}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 15.0,
            fontWeight: 300,
          }}
          onPress={() => navigation.navigation.pop()}
        />
        {AuthorizedFor(ModulesPermission.Update_Invoice) && (
          <MaterialIcons
            name="edit"
            size={26}
            color={Colors.whiteColor}
            style={{
              position: "absolute",
              right: 15.0,
              fontWeight: 300,
            }}
            onPress={() => navigation.navigation.navigate("UpdateInvoice", { item: navigation.route.params.item })}
          />
        )}
      </View>
    );
  };

  return (
    <View style={styles.container}>
      {header(item)}

      {Platform.OS === "android" || Platform.OS === "ios" ? (
        <ScrollView
          style={{
            padding: Sizes.fixPadding * 2,
            backgroundColor:"#F2F3F4"
            // paddingBottom: 50,
          }}
        >
          {item != null && (
            <View
              style={{
                padding: Sizes.fixPadding * 2,
                backgroundColor: "#fff",
                borderRadius: 6,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  alignContent: "space-between",
                  marginBottom: 25,
                }}
              >
                <View style={{ width: "40%" }}>
                  <Image
                    source={require("../../assets/icon.png")}
                    style={{
                      width: 100,
                      height: 100,
                    }}
                  />
                </View>
                <View
                  style={{
                    width: "60%",
                    // alignItems: "end",
                    textAlign: "right",
                  }}
                >
                  <View style={{ flexDirection: "row", marginBottom: 10 }}>
                    <Text style={{ fontSize: 14 }}>InvoiceID: </Text>
                    <Text style={{ fontSize: 14 }}>{item.invoice_id}</Text>
                  </View>
                  <View style={{ flexDirection: "row", marginBottom: 10 }}>
                    <Text style={{ fontSize: 14 }}>Invoice Date: </Text>
                    <Text style={{ fontSize: 14 }}>{item.invoice_date}</Text>
                  </View>
                  <View style={{ flexDirection: "row", marginBottom: 10 }}>
                    <Text style={{ fontSize: 14 }}>Due Date: </Text>
                    <Text style={{ fontSize: 14 }}>{item.due_date}</Text>
                  </View>
                  <View style={{ flexDirection: "row", marginBottom: 10 }}>
                    <Text style={{ fontSize: 14 }}>Invoice Statu: </Text>
                    {item.status === "Partial-Paid" ? (
                      <Text
                        style={{
                          color: "#721c24",
                          fontSize: 14,
                          backgroundColor: "#f8d7da",
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        {item.status}
                      </Text>
                    ) : (
                      <Text
                        style={{
                          color: "#155724",
                          fontSize: 18,
                          backgroundColor: "#d4edda",
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        {item.status}
                      </Text>
                    )}
                  </View>
                </View>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  alignContent: "space-between",
                }}
              >
                <View style={{ width: "50%" }}>
                  <Text style={{ fontSize: 18 }}>Invoice To</Text>

                  <Text>{item.email}</Text>
                </View>
                <View
                  style={{
                    width: "50%",
                    textAlign: "right",
                    alignContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <Text style={{ fontSize: 18 }}>Payment Details</Text>
                  <View style={{ flexDirection: "row", marginBottom: 5 }}>
                    <Text style={{ fontSize: 14 }}>Total Paid: </Text>
                    <Text style={{ fontSize: 14 }}>{item.total_paid}</Text>
                  </View>
                  <View style={{ flexDirection: "row", marginBottom: 10 }}>
                    <Text style={{ fontSize: 14 }}>Total Due: </Text>
                    <Text style={{ fontSize: 14 }}>{item.total_due}</Text>
                  </View>
                </View>
              </View>
              {isOrdered && (
                <View style={{ marginTop: 25 }}>
                  <View style={{ flexDirection: "row", width: "100%" }}>
                    <Text style={{ width: "48%", fontSize: 14 }}>Type</Text>
                    <Text style={{ width: "17%", fontSize: 14 }}>Unit Co.</Text>
                    <Text style={{ width: "15%", fontSize: 14 }}>Qty</Text>
                    <Text style={{ width: "20%", fontSize: 14 }}>Total</Text>
                  </View>
                  {ordersItems.map((item, index) => (
                    <View
                      key={index}
                      style={{
                        backgroundColor: "#f7f7f7",
                        borderTopColor: "#dee2e6",
                        borderTopWidth: 1,
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <Text
                        style={{
                          width: "48%",
                          fontSize: 14,
                          padding: Sizes.fixPadding,
                        }}
                      >
                        {" "}
                        {item.room_type_name ? item.room_type_name : "Hourly"}
                      </Text>
                      <Text
                        style={{
                          width: "17%",
                          fontSize: 14,
                          padding: Sizes.fixPadding,
                        }}
                      >
                        {item.price ? item.price : item.hourly_price}
                      </Text>
                      <Text
                        style={{
                          width: "15%",
                          fontSize: 14,
                          padding: Sizes.fixPadding,
                        }}
                      >
                        {item.quantity ? item.quantity : item.hours}
                      </Text>
                      <Text
                        style={{
                          width: "20%",
                          fontSize: 14,
                          padding: Sizes.fixPadding,
                        }}
                      >
                        {item.room_type_name
                          ? item.sub_total
                          : item.hours * item.hourly_price}
                      </Text>
                    </View>
                  ))}
                </View>
              )}
              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  alignContent: "space-between",
                }}
              >
                <View style={{ width: "40%" }}></View>
                <View
                  style={{
                    width: "60%",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: 10,
                      marginTop: 15,
                    }}
                  >
                    <Text style={{ fontWeight: "bold", fontSize: 14 }}>
                      Sub Total:{" "}
                    </Text>
                    <Text style={{ fontWeight: "normal", fontSize: 14 }}>
                      {item.sub_total}
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: 15,
                    }}
                  >
                    <Text style={{ fontWeight: "bold", fontSize: 14 }}>
                      {`Discount:(${item.discount_percentage}%)`}
                    </Text>
                    <Text style={{ fontWeight: "normal", fontSize: 14 }}>
                      {item.discount_value}
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: 15,
                    }}
                  >
                    <Text style={{ fontWeight: "bold", fontSize: 14 }}>
                      {`Tax: ${item.tax_type} (${item.tax_value}%)`}
                    </Text>
                    <Text style={{ fontWeight: "normal", fontSize: 14 }}>
                      {item.total_tax}
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: 20,
                    }}
                  >
                    <Text style={{ fontWeight: "bold", fontSize: 14 }}>
                      Grand Total:{" "}
                    </Text>
                    <Text style={{ fontWeight: "normal", fontSize: 14 }}>
                      {item.grand_total}
                    </Text>
                  </View>
                </View>
              </View>

              {item.total_due > 0 && (
                <View
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {isPayable && (
                    <Button
                      title="Pay Inovice"
                      type="solid"
                      //   loading={isLoding}
                      onPress={() =>
                        navigation.navigation.navigate("PaymentScreen", {
                          item: item,
                        })
                      }
                      buttonStyle={{
                        padding: Sizes.fixPadding,
                        width: "100%",
                        backgroundColor: Colors.primaryColor,
                        borderRadius: 22,
                      }}
                      containerStyle={{
                        width: "100%",
                        // marginHorizontal: 50,
                        marginVertical: 20,
                      }}
                      titleStyle={{
                        fontSize: 14,
                        fontWeight: "100",
                        color: Colors.whiteColor,
                      }}
                      icon={
                        <Ionicons
                          name="add-circle-sharp"
                          size={24}
                          color="#ffffff"
                          style={{ marginRight: "2%" }}
                        />
                      }
                    />
                  )}
                </View>
              )}
            </View>
          )}
        </ScrollView>
      ) : (
        <View
          style={{
            padding: Sizes.fixPadding * 2,
            backgroundColor:"#F2F3F4"
          }}
        >
          {item != null && (
            <View
              style={{
                padding: Sizes.fixPadding * 3,
                backgroundColor: "#fff",
                borderRadius: 6,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  alignContent: "space-between",
                  marginBottom: 25,
                }}
              >
                <View style={{ width: "50%" }}>
                  <Image
                    source={require("../../assets/icon.png")}
                    style={{
                      width: 210,
                      height: 210,
                      marginTop: -30,
                    }}
                  />
                </View>
                <View
                  style={{
                    width: "50%",
                    alignItems: "end",
                    textAlign: "right",
                  }}
                >
                  <View style={{ flexDirection: "row", marginBottom: 10 }}>
                    <Text style={{ fontSize: 30 }}>InvoiceID: </Text>
                    <Text style={{ fontSize: 30 }}>{item.invoice_id}</Text>
                  </View>
                  <View style={{ flexDirection: "row", marginBottom: 10 }}>
                    <Text style={{ fontSize: 18 }}>Invoice Date: </Text>
                    <Text style={{ fontSize: 18 }}>{item.invoice_date}</Text>
                  </View>
                  <View style={{ flexDirection: "row", marginBottom: 10 }}>
                    <Text style={{ fontSize: 18 }}>Due Date: </Text>
                    <Text style={{ fontSize: 18 }}>{item.due_date}</Text>
                  </View>
                  <View style={{ flexDirection: "row", marginBottom: 10 }}>
                    <Text style={{ fontSize: 18 }}>Invoice Statu: </Text>
                    {item.status === "Partial-Paid" ? (
                      <Text
                        style={{
                          color: "#721c24",
                          fontSize: 18,
                          backgroundColor: "#f8d7da",
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        {item.status}
                      </Text>
                    ) : (
                      <Text
                        style={{
                          color: "#155724",
                          fontSize: 18,
                          backgroundColor: "#d4edda",
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        {item.status}
                      </Text>
                    )}
                  </View>
                </View>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  alignContent: "space-between",
                }}
              >
                <View style={{ width: "50%" }}>
                  <Text style={{ fontSize: 22 }}>Invoice To</Text>

                  <Text>{item.email}</Text>
                </View>
                <View
                  style={{
                    width: "50%",
                    alignItems: "end",
                    textAlign: "right",
                  }}
                >
                  <Text style={{ fontSize: 22 }}>Payment Details</Text>
                  <View style={{ flexDirection: "row", marginBottom: 10 }}>
                    <Text style={{ fontSize: 16 }}>Total Paid: </Text>
                    <Text style={{ fontSize: 16 }}>{item.total_paid}</Text>
                  </View>
                  <View style={{ flexDirection: "row", marginBottom: 10 }}>
                    <Text style={{ fontSize: 18 }}>Total Due: </Text>
                    <Text style={{ fontSize: 18 }}>{item.total_due}</Text>
                  </View>
                </View>
              </View>
              {isOrdered && (
                <View>
                  <View style={{ flexDirection: "row", width: "100%" }}>
                    <Text style={{ width: "10%", fontSize: 18 }}>#</Text>
                    <Text style={{ width: "45%", fontSize: 18 }}>Type</Text>
                    <Text style={{ width: "15%", fontSize: 18 }}>
                      Unit Cost
                    </Text>
                    <Text style={{ width: "15%", fontSize: 18 }}>Qunatity</Text>
                    <Text style={{ width: "15%", fontSize: 18 }}>Total</Text>
                  </View>
                  {ordersItems.map((item, index) => (
                    <View
                      key={index}
                      style={{
                        backgroundColor: "#f7f7f7",
                        borderTopColor: "#dee2e6",
                        borderTopWidth: 1,
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <Text
                        style={{
                          width: "10%",
                          fontSize: 18,
                          padding: Sizes.fixPadding,
                        }}
                      >
                        {index + 1}
                      </Text>
                      <Text
                        style={{
                          width: "45%",
                          fontSize: 18,
                          padding: Sizes.fixPadding,
                        }}
                      >
                        {" "}
                        {item.room_type_name ? item.room_type_name : "Hourly"}
                      </Text>
                      <Text
                        style={{
                          width: "15%",
                          fontSize: 18,
                          padding: Sizes.fixPadding,
                        }}
                      >
                        {item.price ? item.price : item.hourly_price}
                      </Text>
                      <Text
                        style={{
                          width: "15%",
                          fontSize: 18,
                          padding: Sizes.fixPadding,
                        }}
                      >
                        {item.quantity ? item.quantity : item.hours}
                      </Text>
                      <Text
                        style={{
                          width: "15%",
                          fontSize: 18,
                          padding: Sizes.fixPadding,
                        }}
                      >
                        {item.room_type_name
                          ? item.sub_total
                          : item.hours * item.hourly_price}
                      </Text>
                    </View>
                  ))}
                </View>
              )}
              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  alignContent: "space-between",
                }}
              >
                <View style={{ width: "70%" }}></View>
                <View
                  style={{
                    width: "30%",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: 10,
                      marginTop: 15,
                    }}
                  >
                    <Text style={{ fontWeight: "bold", fontSize: 16 }}>
                      Sub Total:{" "}
                    </Text>
                    <Text style={{ fontWeight: "normal", fontSize: 16 }}>
                      {item.sub_total}
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: 10,
                      marginTop: 15,
                    }}
                  >
                    <Text style={{ fontWeight: "bold", fontSize: 16 }}>
                      {`Discount:(${item.discount_percentage}%)`}
                    </Text>
                    <Text style={{ fontWeight: "normal", fontSize: 16 }}>
                      {item.discount_value}
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: 10,
                      marginTop: 15,
                    }}
                  >
                    <Text style={{ fontWeight: "bold", fontSize: 16 }}>
                      {`Tax: ${item.tax_type} (${item.tax_value}%)`}
                    </Text>
                    <Text style={{ fontWeight: "normal", fontSize: 16 }}>
                      {item.total_tax}
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: 10,
                      marginTop: 15,
                    }}
                  >
                    <Text style={{ fontWeight: "bold", fontSize: 16 }}>
                      Grand Total:{" "}
                    </Text>
                    <Text style={{ fontWeight: "normal", fontSize: 16 }}>
                      {item.grand_total}
                    </Text>
                  </View>
                </View>
              </View>

              {item.total_due > 0 && (
                <View
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {isPayable && (
                    <Button
                      title="Pay Inovice"
                      type="solid"
                      //   loading={isLoding}
                      onPress={() =>
                        navigation.navigation.navigate("PaymentScreen", {
                          item: item,
                        })
                      }
                      buttonStyle={{
                        padding: Sizes.fixPadding,
                        width: "100%",
                        backgroundColor: Colors.primaryColor,
                        borderRadius: 22,
                      }}
                      containerStyle={{
                        width: "30%",
                        // marginHorizontal: 50,
                        marginVertical: 20,
                      }}
                      titleStyle={{
                        fontSize: 14,
                        fontWeight: "100",
                        color: Colors.whiteColor,
                      }}
                      icon={
                        <Ionicons
                          name="add-circle-sharp"
                          size={24}
                          color="#ffffff"
                          style={{ marginRight: "2%" }}
                        />
                      }
                    />
                  )}
                </View>
              )}
            </View>
          )}
        </View>
      )}
      {isLoding && <Loading />}

      {isVisibleOverlay && (
        <UpdateInvoice
          selectedItemData={item}
          isVisibleOverlay={isVisibleOverlay}
          toggleOverlay={toggelVisible}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor:Colors.primaryColor
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom:15,
    marginTop:40
  },
  dropdown2BtnStyle: {
    width: "100%",
    height: 40,
    backgroundColor: "#FFF",
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#CCCCCC",
    marginBottom: 15,
  },
  dropdown2BtnTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 15,
  },
  dropdown2DropdownStyle: { backgroundColor: "#ddd" },
  dropdown2RowStyle: { backgroundColor: "#ddd", borderBottomColor: "#C5C5C5" },
  dropdown2RowTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 16,
  },
});

export default InvoiceDetails;
