import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import { MaterialIcons } from "@expo/vector-icons";
import moment from "moment";

const PriceDetail = (navigation) => {
  const item = navigation.route.params.item;
  const date = new Date();
  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "bold", fontSize: 20 }}
        >
          {item.type_of_cleaning}
        </Text>
        <MaterialIcons
          name="arrow-back"
          size={26}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 15.0,
            fontWeight: 300,
          }}
          onPress={() => navigation.navigation.pop()}
        />
      </View>
    );
  };

  return (
    <View style={{ flex: 1 }}>
      {header()}
      <View
        style={{
          flex: 1,
          paddingHorizontal: Sizes.fixPadding * 1,
        }}
      >
        <View
          style={{
            backgroundColor: "#ffffff",
            marginTop: 20,
            borderRadius: 10,
            padding: Sizes.fixPadding * 2,
          }}
        >
          <View style={styles.detailWrap}>
            <Text style={{ fontWeight: "bold" }}>Poperty Type</Text>
            <Text>{item.property_type}</Text>
          </View>
          <View style={styles.detailWrap}>
            <Text style={{ fontWeight: "bold" }}>Price</Text>
            <Text>{item.price}</Text>
          </View>
          <View style={styles.detailWrap}>
            <Text style={{ fontWeight: "bold" }}>Room Type</Text>
            <Text>{item.type_of_rooms}</Text>
          </View>
          <View style={styles.detailWrap}>
            <Text style={{ fontWeight: "bold" }}>Creation Date</Text>
            <Text>{moment(item.created_at).format("MM-DD-YYYY")}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  headerWrapStyle: {
    height: 55.0,
    backgroundColor: Colors.primaryColor,
    elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
  },
  detailWrap: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: 15,
  },
});
export default PriceDetail;
