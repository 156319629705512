import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, ScrollView, FlatList } from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  Button,
  SpeedDial,
  ListItem,
  Overlay,
  Dialog,
  SearchBar,
} from "react-native-elements";
import {
  Feather,
  Ionicons,
  Entypo,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import { useForm, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import SelectDropdown from "react-native-select-dropdown";
import Loading from "../Utils/Loading";
import UpdatePoperty from "./UpdatePoperty";
import AuthorizedFor from "../../constants/authorizedFor";
import { authorizedForFlateList } from "../../constants/authorizedForFlateList";
import { ModulesPermission } from "../../constants/modules";

const PropertyType = (navigation) => {
  const refreshScreen = navigation.route.params ? navigation.route.params.Refresh : false;
  const [systems, setSystems] = useState([]);
  const [cleaningData, setCleaningData] = useState([]);
  const [cleaningDataIntial, setCleaningDataIntial] = useState([]);
  const [openDial, setOpenDial] = useState(false);
  const [isLoding, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible1, setIsVisible1] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [deleteShow, setDeleteShow] = useState(false);
  const [search, setSearch] = useState("");
  const [isDeleteAble, setIsDeleteAble] = useState(false);
  const [isUpdateAble, setIsUpdateAble] = useState(false);

  useEffect(() => {
    GetData();
  }, [refreshScreen]);

  useEffect(() => {
    (async () => {
      (await authorizedForFlateList(ModulesPermission.Update_Property_Type)) &&
        setIsUpdateAble(true);
      (await authorizedForFlateList(ModulesPermission.Delete_Property_Type)) &&
        setIsDeleteAble(true);
    })();
  }, [cleaningDataIntial.length]);

  const GetData = () => {
    setIsLoading(true);
    jwt
      .get(`${Url.GET_PROPERTY_TYPE}/${global.systemID}`)
      .then((response) => {
        setIsLoading(false);
        setCleaningData(response.data.property_types);
        setCleaningDataIntial(response.data.property_types);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const toggelVisible = () => {
    setIsVisible1(!isVisible1);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // if (selectedSystem !== null) {
    setIsLoading(true);
    jwt
      .post(Url.ADD_PROPERTY_TYPE, {
        name: data.type,
        system_id: parseInt(global.systemID),
      })
      .then((response) => {
        setIsLoading(false);
        setIsVisible(!isVisible);
        GetData();
        setOpenDial(!openDial);
      })
      .catch((err) => {
        setIsLoading(false);
      });
    // } else {
    //   setSystemError(true);
    // }
  };

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          Property Type
        </Text>
        <Ionicons
          name="menu-outline"
          size={30}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 20.0,
            // fontWeight: 300
          }}
          onPress={() => navigation.navigation.toggleDrawer()}
        />
      </View>
    );
  };

  const handleDelete = (id) => {
    jwt
      .del(`${Url.DELETE_PROPERTY_TYPE}/${id}`)
      .then((response) => {
        setDeleteShow(!deleteShow);
        GetData();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const renderItem = ({ item, index }) => (
    <ListItem.Swipeable
      // onPress={() => {
      //   navigation.navigation.navigate("Tasks", { item: item });
      // }}
      key={index}
      leftContent={
        isUpdateAble ? (
          <Button
            title="Update"
            icon={{ name: "edit", color: "white" }}
            buttonStyle={{
              minHeight: "80%",
              borderBottomLeftRadius: 6,
              borderTopLeftRadius: 6,
              marginTop: 15,
            }}
            onPress={() => {
              setSelectedItem(item);
              navigation.navigation.navigate("UpdateProperty", { item: item });
              
            }}
          />
        ) : null
      }
      rightContent={
        isDeleteAble ? (
          <Button
            title="Delete"
            icon={{ name: "delete", color: "white" }}
            buttonStyle={{
              minHeight: "80%",
              backgroundColor: "red",
              borderBottomRightRadius: 6,
              borderTopRightRadius: 6,
              marginTop: 15,
            }}
            onPress={() => {
              setSelectedItem(item);
              setDeleteShow(!deleteShow);
            }}
          />
        ) : null
      }
      containerStyle={{ marginTop: 15, zIndex: 1, borderRadius: 6 }}
    >
      <Feather name="type" size={38} color="#444" />
      <ListItem.Content>
        <ListItem.Title style={{ marginBottom: 4 }}>{item.name}</ListItem.Title>
        <ListItem.Subtitle numberOfLines={1}>
          {item.status == 1 ? "Active" : "InActive"}
        </ListItem.Subtitle>
      </ListItem.Content>
      {/* <ListItem.Chevron /> */}
    </ListItem.Swipeable>
  );

  const handleSearch = (value) => {
    setSearch(value);
    if (value.length > 1) {
      const newData = cleaningData.filter((e) =>
        e.name.toUpperCase().includes(value.toUpperCase())
      );
      if (newData.length > 0) {
        setCleaningData(newData);
      } else {
        setCleaningData(cleaningDataIntial);
      }
    } else {
      setCleaningData(cleaningDataIntial);
    }
  };

  return (
    <View style={styles.container}>
      {header()}
      <SearchBar
        placeholder="Type Here..."
        onChangeText={handleSearch}
        value={search}
        lightTheme
        containerStyle={{
          backgroundColor: "#eee",
          borderWidth: 0,
          elevation: 0,
          paddingLeft: Sizes.fixPadding * 2,
          paddingRight: Sizes.fixPadding * 2,
        }}
        // placeholderTextColor="#000"
        inputStyle={{
          color: "#000",
        }}
        inputContainerStyle={{
          backgroundColor: "#ccc",
        }}
      />
      <View style={{ flex: 1, backgroundColor:"#F2F3F4" ,padding: Sizes.fixPadding * 2, paddingTop: 0 }}>
        {/* <ScrollView style={{ flex: 1 }}> */}
        <FlatList
          showsVerticalScrollIndicator={false}
          nestedScrollEnabled
          data={cleaningData}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
        />
       
      </View>
      {isLoding && <Loading />}
      <Overlay
        visible={isVisible}
        overlayStyle={{
          width: "80%",
          backgroundColor: "#FFFFFF",
          borderRadius: 8,
          paddingTop: 15,
          paddingBottom: 20,
          padding: Sizes.fixPadding * 2,
        }}
        onBackdropPress={() => setIsVisible(!isVisible)}
      >
        <View style={{ alignItems: "center", marginTop: 3, marginBottom: 10 }}>
          <Text style={{ fontSize: 18 }}>New Type</Text>
        </View>
        <Controller
          name="type"
          control={control}
          rules={{
            required: { value: true, message: "Property type is required" },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              label="Property Type"
              type="text"
              onChangeText={(value) => onChange(value)}
              value={value}
              error={errors.type}
              errorText={errors?.type?.message}
            />
          )}
          defaultValue=""
        />

        <Button
          title="Add Type"
          type="solid"
          //   loading={isLoding}
          onPress={handleSubmit(onSubmit)}
          buttonStyle={{
            padding: Sizes.fixPadding,
            width: "100%",
            backgroundColor: Colors.primaryColor,
            borderRadius: 22,
          }}
          containerStyle={{
            width: "100%",
            // marginHorizontal: 50,
            marginVertical: 20,
          }}
          titleStyle={{
            fontSize: 14,
            fontWeight: "100",
            color: Colors.whiteColor,
          }}
          icon={
            <Ionicons
              name="add-circle-sharp"
              size={24}
              color="#ffffff"
              style={{ marginRight: "2%" }}
            />
          }
        />
      </Overlay>
      {AuthorizedFor(ModulesPermission.Create_Property_Type) && (
      <SpeedDial
        isOpen={openDial}
        icon={{ name: "edit", color: "#fff" }}
        openIcon={{ name: "close", color: "#fff" }}
        onOpen={() => setOpenDial(!openDial)}
        onClose={() => setOpenDial(!openDial)}
        style={{ zIndex: 2 }}
        buttonStyle={{ backgroundColor: Colors.primaryColor }}
      >
        <SpeedDial.Action
          icon={{ name: "add", color: "#fff" }}
          title="Add Type"
          onPress={() => setIsVisible(!isVisible)}
          buttonStyle={{ backgroundColor: Colors.primaryColor }}
        />
      </SpeedDial>
      )}
     

      <Dialog isVisible={deleteShow}>
        <Dialog.Title title="Delete Type!" />
        <Text>Are you sure ? this action will not be revert.</Text>

        <Dialog.Actions>
          <Dialog.Button
            title="CONFIRM"
            onPress={() => {
              handleDelete(selectedItem.id);
            }}
          />
          <Dialog.Button
            title="CANCEL"
            onPress={() => {
              setDeleteShow(!deleteShow);
            }}
          />
        </Dialog.Actions>
      </Dialog>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor:Colors.primaryColor
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom:15,
    marginTop:40
  },
  dropdown2BtnStyle: {
    width: "100%",
    height: 40,
    backgroundColor: "#FFF",
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#CCCCCC",
    marginBottom: 15,
  },
  dropdown2BtnTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 15,
  },
  dropdown2DropdownStyle: { backgroundColor: "#ddd" },
  dropdown2RowStyle: { backgroundColor: "#ddd", borderBottomColor: "#C5C5C5" },
  dropdown2RowTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 16,
  },
});
export default PropertyType;
