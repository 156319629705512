import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Platform,
  Image,
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  Button,
  SpeedDial,
  ListItem,
  Overlay,
  Dialog,
} from "react-native-elements";
import {
  Feather,
  Ionicons,
  Entypo,
  MaterialIcons,
  Octicons,
} from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import { useForm, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import SelectDropdown from "react-native-select-dropdown";
import Loading from "../Utils/Loading";
import moment from "moment";
import AuthorizedFor from "../../constants/authorizedFor";
import { authorizedForFlateList } from "../../constants/authorizedForFlateList";
import { ModulesPermission } from "../../constants/modules";

const GetQuote = (navigation) => {
  const item = navigation.route.params.item;
  const [isLoding, setIsLoading] = useState(false);
  const [ordersItems, setOrderItems] = useState([]);

  // console.log(item);

  useEffect(() => {
    (async () => {
      // GetClients();
      // GetData(navigation.route.params.item.id);
      GetOrderItems(navigation.route.params.item.id);
    })();
  }, [navigation]);

  const GetOrderItems = (id) => {
    // setIsLoading(true);

    jwt
      .get(`${Url.GET_ORDER_ITEM_LIST}/${id}`)
      .then((response) => {
        // console.log(response.data);
        setIsLoading(false);
        setOrderItems(response.data.orders_items);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const header = (item) => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          Order Details
        </Text>
        <MaterialIcons
          name="arrow-back"
          size={26}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 15.0,
            fontWeight: 300,
          }}
          onPress={() => navigation.navigation.pop()}
        />
      </View>
    );
  };

  return (
    <View style={styles.container}>
      {header(item)}

      {Platform.OS === "android" || Platform.OS === "ios" ? (
        <ScrollView
          style={{
            flex:1,
            padding: Sizes.fixPadding * 2,
            backgroundColor:"#F2F3F4"
          }}
        >
       
            <View
              style={{
                padding: Sizes.fixPadding * 3,
                backgroundColor: "#fff",
                borderRadius: 6,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  alignContent: "space-between",
                  marginBottom: 25,
                }}
              >
                <View style={{ width: "40%" }}>
                  <Image
                    source={require("../../assets/icon.png")}
                    style={{
                      width: 100,
                      height: 100,
                    }}
                  />
                </View>
                <View
                  style={{
                    width: "60%",
                    textAlign: "right",
                  }}
                >
                  <View style={{ flexDirection: "row", marginBottom: 10 }}>
                    <Text style={{ fontSize: 22 }}>OrderID: </Text>
                    <Text style={{ fontSize: 22 }}>{item.id}</Text>
                  </View>
                  <View style={{ flexDirection: "row", marginBottom: 10 }}>
                    <Text style={{ fontSize: 16 }}>Start Date: </Text>
                    <Text style={{ fontSize: 16 }}>
                      {moment(item.duration_from).format("DD-MM-YYYY")}
                    </Text>
                  </View>
                  {item.duration_to != "" && (
                    <View style={{ flexDirection: "row", marginBottom: 10 }}>
                      <Text style={{ fontSize: 16 }}>End Date: </Text>
                      <Text style={{ fontSize: 16 }}>
                        {moment(item.duration_to).format("DD-MM-YYYY")}
                      </Text>
                    </View>
                  )}
                  <View style={{ flexDirection: "row", marginBottom: 10 }}>
                    <Text style={{ fontSize: 16 }}>Order Status: </Text>
                    {item.status === "Partial-Paid" ? (
                      <Text
                        style={{
                          color: "#721c24",
                          fontSize: 16,
                          backgroundColor: "#f8d7da",
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        {item.status}
                      </Text>
                    ) : (
                      <Text
                        style={{
                          color: "#155724",
                          fontSize: 16,
                          backgroundColor: "#d4edda",
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        {item.status}
                      </Text>
                    )}
                  </View>
                </View>
              </View>
              <View>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ width: "10%", fontSize: 16 }}>#</Text>
                  <Text style={{ width: "45%", fontSize: 16 }}>Type</Text>
                  <Text style={{ width: "15%", fontSize: 16 }}>Unit Cost</Text>
                  <Text style={{ width: "15%", fontSize: 16 }}>Qunatity</Text>
                  <Text style={{ width: "15%", fontSize: 16 }}>Total</Text>
                </View>
                {ordersItems.map((item, index) => (
                  <View
                    key={index}
                    style={{
                      backgroundColor: "#f7f7f7",
                      borderTopColor: "#dee2e6",
                      borderTopWidth: 1,
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <Text
                      style={{
                        width: "10%",
                        fontSize: 16,
                        padding: Sizes.fixPadding,
                      }}
                    >
                      {index + 1}
                    </Text>
                    <Text
                      style={{
                        width: "45%",
                        fontSize: 16,
                        padding: Sizes.fixPadding,
                      }}
                    >
                      {" "}
                      {item.room_type_name ? item.room_type_name : "Hourly"}
                    </Text>
                    <Text
                      style={{
                        width: "15%",
                        fontSize: 16,
                        padding: Sizes.fixPadding,
                      }}
                    >
                      {item.price ? item.price : item.hourly_price}
                    </Text>
                    <Text
                      style={{
                        width: "15%",
                        fontSize: 16,
                        padding: Sizes.fixPadding,
                      }}
                    >
                      {item.quantity ? item.quantity : item.hours}
                    </Text>
                    <Text
                      style={{
                        width: "15%",
                        fontSize: 16,
                        padding: Sizes.fixPadding,
                      }}
                    >
                      {item.room_type_name
                        ? item.sub_total
                        : item.hours * item.hourly_price}
                    </Text>
                  </View>
                ))}
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    alignContent: "space-between",
                  }}
                >
                  <View style={{ width: "60%" }}></View>
                  <View
                    style={{
                      width: "40%",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: 10,
                        marginTop: 15,
                      }}
                    >
                      <Text style={{ fontWeight: "bold", fontSize: 16 }}>
                        Sub Total:{" "}
                      </Text>
                      <Text style={{ fontWeight: "normal", fontSize: 16 }}>
                        {item.total_price}
                      </Text>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: 10,
                        marginTop: 15,
                      }}
                    >
                      <Text style={{ fontWeight: "bold", fontSize: 16 }}>
                        {`Tax: (${item.vat_per}%)`}
                      </Text>
                      <Text style={{ fontWeight: "normal", fontSize: 16 }}>
                        {item.vat_price}
                      </Text>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: 10,
                        marginTop: 15,
                      }}
                    >
                      <Text style={{ fontWeight: "bold", fontSize: 16 }}>
                        Grand Total:{" "}
                      </Text>
                      <Text style={{ fontWeight: "normal", fontSize: 16 }}>
                        {item.grand_total}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          
        </ScrollView>
      ) : (
        <View
          style={{
            padding: Sizes.fixPadding * 2,
            backgroundColor:"#F2F3F4",
            flex:1
          }}
        >
          <View
            style={{
              padding: Sizes.fixPadding * 3,
              backgroundColor: "#fff",
              borderRadius: 6,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                alignContent: "space-between",
                marginBottom: 25,
              }}
            >
              <View style={{ width: "50%" }}>
                <Image
                  source={require("../../assets/icon.png")}
                  style={{
                    width: 210,
                    height: 210,
                    marginTop: -30,
                  }}
                />
              </View>
              <View
                style={{
                  width: "50%",
                  alignItems: "end",
                  textAlign: "right",
                }}
              >
                <View style={{ flexDirection: "row", marginBottom: 10 }}>
                  <Text style={{ fontSize: 30 }}>OrderID: </Text>
                  <Text style={{ fontSize: 30 }}>{item.id}</Text>
                </View>
                <View style={{ flexDirection: "row", marginBottom: 10 }}>
                  <Text style={{ fontSize: 18 }}>Start Date: </Text>
                  <Text style={{ fontSize: 18 }}>
                    {moment(item.duration_from).format("DD-MM-YYYY")}
                  </Text>
                </View>
                {item.duration_to != "" && (
                  <View style={{ flexDirection: "row", marginBottom: 10 }}>
                    <Text style={{ fontSize: 18 }}>End Date: </Text>
                    <Text style={{ fontSize: 18 }}>
                      {moment(item.duration_to).format("DD-MM-YYYY")}
                    </Text>
                  </View>
                )}
                <View style={{ flexDirection: "row", marginBottom: 10 }}>
                  <Text style={{ fontSize: 18 }}>Order Status: </Text>
                  {item.status === "Partial-Paid" ? (
                    <Text
                      style={{
                        color: "#721c24",
                        fontSize: 18,
                        backgroundColor: "#f8d7da",
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                    >
                      {item.status}
                    </Text>
                  ) : (
                    <Text
                      style={{
                        color: "#155724",
                        fontSize: 18,
                        backgroundColor: "#d4edda",
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                    >
                      {item.status}
                    </Text>
                  )}
                </View>
              </View>
            </View>
            <View>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text style={{ width: "10%", fontSize: 18 }}>#</Text>
                <Text style={{ width: "45%", fontSize: 18 }}>Type</Text>
                <Text style={{ width: "15%", fontSize: 18 }}>Unit Cost</Text>
                <Text style={{ width: "15%", fontSize: 18 }}>Qunatity</Text>
                <Text style={{ width: "15%", fontSize: 18 }}>Total</Text>
              </View>
              {ordersItems.map((item, index) => (
                <View
                  key={index}
                  style={{
                    backgroundColor: "#f7f7f7",
                    borderTopColor: "#dee2e6",
                    borderTopWidth: 1,
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      width: "10%",
                      fontSize: 18,
                      padding: Sizes.fixPadding,
                    }}
                  >
                    {index + 1}
                  </Text>
                  <Text
                    style={{
                      width: "45%",
                      fontSize: 18,
                      padding: Sizes.fixPadding,
                    }}
                  >
                    {" "}
                    {item.room_type_name ? item.room_type_name : "Hourly"}
                  </Text>
                  <Text
                    style={{
                      width: "15%",
                      fontSize: 18,
                      padding: Sizes.fixPadding,
                    }}
                  >
                    {item.price ? item.price : item.hourly_price}
                  </Text>
                  <Text
                    style={{
                      width: "15%",
                      fontSize: 18,
                      padding: Sizes.fixPadding,
                    }}
                  >
                    {item.quantity ? item.quantity : item.hours}
                  </Text>
                  <Text
                    style={{
                      width: "15%",
                      fontSize: 18,
                      padding: Sizes.fixPadding,
                    }}
                  >
                    {item.room_type_name
                      ? item.sub_total
                      : item.hours * item.hourly_price}
                  </Text>
                </View>
              ))}
              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  alignContent: "space-between",
                }}
              >
                <View style={{ width: "70%" }}></View>
                <View
                  style={{
                    width: "30%",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: 10,
                      marginTop: 15,
                    }}
                  >
                    <Text style={{ fontWeight: "bold", fontSize: 16 }}>
                      Sub Total:{" "}
                    </Text>
                    <Text style={{ fontWeight: "normal", fontSize: 16 }}>
                      {item.total_price}
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: 10,
                      marginTop: 15,
                    }}
                  >
                    <Text style={{ fontWeight: "bold", fontSize: 16 }}>
                      {`Tax: (${item.vat_per}%)`}
                    </Text>
                    <Text style={{ fontWeight: "normal", fontSize: 16 }}>
                      {item.vat_price}
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: 10,
                      marginTop: 15,
                    }}
                  >
                    <Text style={{ fontWeight: "bold", fontSize: 16 }}>
                      Grand Total:{" "}
                    </Text>
                    <Text style={{ fontWeight: "normal", fontSize: 16 }}>
                      {item.grand_total}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      )}
      {isLoding && <Loading />}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // justifyContent: "center",
    backgroundColor:Colors.primaryColor
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom:15,
    marginTop:40
  },
  dropdown2BtnStyle: {
    width: "100%",
    height: 40,
    backgroundColor: "#FFF",
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#CCCCCC",
    marginBottom: 15,
  },
  dropdown2BtnTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 15,
  },
  dropdown2DropdownStyle: { backgroundColor: "#ddd" },
  dropdown2RowStyle: { backgroundColor: "#ddd", borderBottomColor: "#C5C5C5" },
  dropdown2RowTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 16,
  },
});

export default GetQuote;
