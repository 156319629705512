import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, TextInput } from "react-native";
export default function Input(props) {
  return (
    <View style={{ width: "100%", marginBottom: 10 }}>
      {props.label && (
        <Text style={{ ...styles.label, marginTop: props.mt ? props.mt : 0 }}>
          {props.label}
        </Text>
      )}

      <TextInput
        editable={props.noedit ? false : true}
        style={[
          styles.input,
          props.error && styles.borderRed,
          props.style,
          props.half && { width: 50 },
          props.fiftyPercent && { width: "50%" },
        ]}
        {...props}
      />
      {props.errorText && (
        <Text style={styles.errorText}>{props.errorText}</Text>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  label: {
    color: "black",
    marginLeft: 0,
    marginBottom: 3,
  },
  input: {
    backgroundColor: "white",
    width: "100%",
    borderColor: "#CCCCCC",
    height: 40,
    padding: 10,
    borderRadius: 4,
    borderWidth: 1,
    borderRadius: 5,
    paddingLeft: 10,
    paddingRight: 10,
  },
  borderRed: {
    borderColor: "red",
  },
  errorText: {
    color: "red",
    fontSize: 14,
  },
});
