import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  StatusBar,
  Image,
  TextInput,
  TouchableOpacity,
  Alert,
  Dimensions
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import { Button, SpeedDial, ListItem, Overlay } from "react-native-elements";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { Fontisto, Ionicons, Entypo, MaterialIcons, FontAwesome5 } from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import { useForm, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import SelectDropdown from "react-native-select-dropdown";

import CustomPicker from "../../components/Picker/Picker";
const windowWidth = Dimensions.get('window').width;

const UpdateList = (navigation) => {
  const selectedItem = navigation.route.params.item;
  const [systems, setSystems] = useState([]);
  const [selectedSystem, setSelectedSystem] = useState(selectedItem.system_id);
  const [selectedStatus, setSelectedStatus] = useState(""+selectedItem.status);
  const [cleaningData, setCleaningData] = useState([]);
  const [cleaningList, setCleaningList] = useState([]);
  const [roomData, setRoomData] = useState([]);
  const [roomList, setRoomList] = useState([]);
  const [propertyData, setPropertyData] = useState([]);
  const [propertyList, setPropertList] = useState([]);
  const [selectedCleaningType, setSelectedCleaningType] = useState(parseInt(selectedItem.cleaning_type_id));
  const [selectedRoomType, setSelectedRoomType] = useState(parseInt(selectedItem.room_type_id));
  const [selectedPropertyType, setSelectedPropertyType] = useState(parseInt(selectedItem.property_type_id));
  const [systemError, setSystemError] = useState(false);
  const [systemErrorStatus, setSystemErrorStatus] = useState(false);
  const [defaultSystem, setDefaultSystem] = useState(null);

  const statusData = [{value:"1", label:"Active"},{value:"0", label:"InActive"}];

  // console.log(selectedItem)

  useEffect(() => {
    const newSystems = [];
    GetCleaningType();
    GetRoomType();
    GetPropertyType(); 
  }, [selectedItem]);

  const GetCleaningType = () => {
    jwt
      .get(`${Url.GET_ACTIVE_CLEANING_TYPE}/${global.systemID}`)
      .then((response) => {
        const newArr = [];
        // setCleaningData(response.data.cleaning_types);
        response.data.cleaning_types.map((item) => {
          newArr.push({
            value: item.id,
            label: item.name,
            ...item,
          });
        });
        setCleaningData(newArr);
      })
      .catch((err) => {});
  };

  const GetPropertyType = () => {
    jwt
      .get(`${Url.GET_ACTIVE_PROPERTY_TYPE}/${global.systemID}`)
      .then((response) => {
        const newArr = [];
        // setCleaningData(response.data.cleaning_types);
        response.data.property_types.map((item) => {
          newArr.push({
            value: item.id,
            label: item.name,
            ...item,
          });
        });
        setPropertyData(newArr);
      })
      .catch((err) => {});
  };

  const GetRoomType = () => {
    jwt
      .get(`${Url.GET_ACTIVE_ROOM_TYPE}/${global.systemID}`)
      .then((response) => {
        const newArr = [];
        // setRoomData(response.data.room_types);
        response.data.room_types.map((item) => newArr.push({
          value: item.id,
          label: item.name,
          ...item,
        }));
        setRoomData(newArr);
      })
      .catch((err) => {});
  };

  const handleSelectedCleaning = (val,index) => {
    // setSystemError(false);
    setSelectedCleaningType(val);
  };

  const handleSelectedRoom = (val, index) => {
    // setSystemError(false);
    setSelectedRoomType(val);
  };

  const handleSelectedProperty = (val, index) => {
    // setSystemError(false);
    setSelectedPropertyType(val);
  };

  const handleSelectedStatus = (val, index) => {
    setSystemErrorStatus(false);
    setSelectedStatus(val);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    jwt
      .post(`${Url.UPDATE_REJECTED_CHECKLIST}/${selectedItem.id}`, {
        cleaning_type_id: selectedCleaningType,
        room_type_id: selectedRoomType,
        property_type_id: selectedPropertyType,
        reason: data.reason,
        system_id: parseInt(global.systemID),
        status: parseInt(selectedStatus),
      })
      .then((response) => {
        navigation.navigation.navigate("RejectedCheckList", {Refresh:true});
      })
      .catch((err) => {});
  };

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
         Update Room Type
                 </Text>
        <MaterialIcons
          name="arrow-back"
          size={26}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 15.0,
            fontWeight: 300,
          }}
          onPress={() => navigation.navigation.pop()}
        />
      </View>
    );
  };

  return (
    <View style={styles.container}>
    {header()}
    <View style={{ flex: 1, backgroundColor:"#F2F3F4" ,padding: Sizes.fixPadding * 2 }}>
    <View
          style={{
            padding: Sizes.fixPadding,
            backgroundColor: "#fff",
            borderRadius: 6,
          }}
        >
      <View style={{flexDirection:'row', marginTop:15}}>
                <View style={styles.child1}>
                <Text style={{ ...styles.label, marginTop: 10 }}>
                  {"Cleaning Type"}
                </Text>
                </View>
                <View style={styles.child2}>
                <CustomPicker
                  data={cleaningData}
                  selectedValue={selectedCleaningType}
                  handleSelectedValue={handleSelectedCleaning}
                 
                  style={{}}
                />
                </View>
                </View>
                <View style={{flexDirection:'row', marginTop:15}}>
                <View style={styles.child1}>
                <Text style={styles.label}>{"Room Type"}</Text>
</View>

<View style={styles.child2}>
                <CustomPicker
                  data={roomData}
                  selectedValue={selectedRoomType}
                  handleSelectedValue={handleSelectedRoom}
              
                  style={{}}
                />
                </View>
                </View>

<View style={{flexDirection:'row', marginTop:15}}>
                <View style={styles.child1}>
                <Text style={styles.label}>{"Property Type"}</Text>
</View>

<View style={styles.child2}>
                <CustomPicker
                  data={propertyData}
                  selectedValue={selectedPropertyType}
                  handleSelectedValue={handleSelectedProperty}
                 
                  style={{}}
                />
                </View>
                </View>

                <View style={{flexDirection:'row', marginTop:15}}>
                   <View style={styles.child1}>
                <Text style={{ ...styles.label}}>{"Reason"}</Text>
               </View>
               <View style={styles.child2}>
      <Controller
        name="reason"
        control={control}
        // rules={{
        //   required: { value: true, message: "Room type is required" },
        // }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            // label="Reason"
            type="text"
            onChangeText={(value) => onChange(value)}
            value={value}

            // error={errors.type}
            // errorText={errors?.type?.message}
          />
        )}
        defaultValue={selectedItem.reason}
      />
      </View>
      </View>
    <View style={{flexDirection:'row', marginTop:15}}>
                   <View style={styles.child1}>
                <Text style={{ ...styles.label}}>{"Status"}</Text>
               </View>
               <View style={styles.child2}>
                <CustomPicker
                  data={statusData}
                  selectedValue={selectedStatus}
                  handleSelectedValue={handleSelectedStatus}
                  // defaultLabel={"Select Status"}
                  style={{}}
                />
                </View>
                </View>

      <Button
        title="Update Type"
        type="solid"
        //   loading={isLoding}
        onPress={handleSubmit(onSubmit)}
        buttonStyle={{
          padding: Sizes.fixPadding,
          width: "100%",
          backgroundColor: Colors.primaryColor,
          borderRadius: 22,
        }}
        containerStyle={{
          width: "100%",
          // marginHorizontal: 50,
          marginVertical: 20,
        }}
        titleStyle={{
          fontSize: 14,
          fontWeight: "100",
          color: Colors.whiteColor,
        }}
        icon={
          <Ionicons
            name="add-circle-sharp"
            size={24}
            color="#ffffff"
            style={{ marginRight: "2%" }}
          />
        }
      />
    </View>
    </View>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor:Colors.primaryColor
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom:15,
    marginTop:40
  },
  label: {
    color: "black",
    marginLeft: 0,
    marginBottom: 3,
    fontSize:windowWidth > 600 ? 18 : 16,
    fontWeight:'500',
    marginTop:windowWidth > 600 ? 0 : 12
  },
  child1:{
    width:windowWidth > 600 ? '15%' : '30%'
  },
  child2:{
    width:windowWidth > 600 ? '85%' : '70%'
  }
});
export default UpdateList;
