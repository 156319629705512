import React, { useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  FlatList,
  Alert,
  Dimensions,
  TouchableOpacity
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import { Button, Overlay, ListItem, SpeedDial } from "react-native-elements";

import {
  Ionicons,
  Entypo,
  MaterialIcons,
  MaterialCommunityIcons,
  FontAwesome,
  Octicons,
} from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import { useForm, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import SelectDropdown from "react-native-select-dropdown";
import Loading from "../Utils/Loading";
// import MultiSelect from "react-native-multiple-select";
import SectionedMultiSelect from "react-native-sectioned-multi-select";
import AuthorizedFor from "../../constants/authorizedFor";
import { authorizedForFlateList } from "../../constants/authorizedForFlateList";
import { ModulesPermission } from "../../constants/modules";
import CustomPicker from "../../components/Picker/Picker";

const windowWidth = Dimensions.get("window").width;

const RejectedStatus = (navigation) => {
    const item = navigation.route.params.item;
  const [tasksData, setTasksData] = useState([]);
  const [reasonsData, setReasonsData] = useState([]);
  const [openDial, setOpenDial] = useState(false);
  const [isLoding, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [employeeData, setEmployeeData] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [roles, setRoles] = useState(global.userData.role_names);
  const [isAllowStatus, setIsAllowStatus] = useState(false);
  const [isStaff, setIsStaff] = useState(false);
  const [isAllowAssign, setIsAllowAssign] = useState(false);

  const [expanded, setExpanded] = useState(false);
  const [expandedID, setExpandedID] = useState(false);

  const [statusList, setStautsList] = useState([]);
  const [assignList, setAssignList] = useState([]);
  const [priorityList, setPriorityList] = useState([]);

  const ref = useRef(null);

  useEffect(() => {
    (async () => {
      GetReasons(item);
    })();
  }, []);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {};

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          Reject Status
        </Text>
        <MaterialIcons
          name="arrow-back"
          size={26}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 15.0,
            fontWeight: 300,
          }}
          onPress={() => navigation.navigation.pop()}
        />
      </View>
    );
  };

  const GetReasons = (item) => {
    setIsLoading(true);
    jwt
      .post(`${Url.SEARCH_REJECTED_CHECKLIST}/${global.systemID}`, {
        room_type_id: item.room_type_id,
      })
      .then((response) => {
        const newData = [];
        response.data.rejected_checklist.map((item) => {
          newData.push({
            id: item.id,
            name: item.reason,
          });
        });
        setReasonsData(newData);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const onSelectedItemsChange = (selectedItems) => {
    // this.setState({ selectedItems });
    setSelectedItems(selectedItems);
  };

  const updateRejected = () => {
    setIsLoading(true);

    const reasonList = [];

    selectedItems.map((item) =>
      reasonsData
        .filter((obj) => obj.id === item)
        .map((res) => {
          reasonList.push({
            checklist_id: res.id,
            reason: res.name,
          });
        })
    );

    jwt
      .post(`${Url.UPDATE_ORDER_TASK_STATUS}/${item.id}`, {
        status: "Rejected",
        rejected_checklist: reasonList,
      })
      .then((response) => {
        setIsLoading(false)
               Alert.alert("Status Updated Successfully!");
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <View style={styles.container}>
      {header()}
      <View
        style={{
          flex: 1,
          backgroundColor: "#F2F3F4",
          padding: Sizes.fixPadding * 2,
          paddingTop: 20,
        }}
      >
        <Text>Reason for rejection</Text>
       <SectionedMultiSelect
          items={reasonsData}
          ref={ref}
          IconRenderer={MaterialIcons}
          uniqueKey="id"
          subKey="children"
          selectText="Select Reason..."
          showDropDowns={true}
          //   readOnlyHeadings={true}
          onSelectedItemsChange={onSelectedItemsChange}
          selectedItems={selectedItems}
          searchPlaceholderText="Search reasons"
          //   text={Colors.primaryColor}
          styles={{
            button: {
              backgroundColor: Colors.primaryColor,
              selectedItemText: Colors.primaryColor,
            },
          }}
          theme={{
            Colors: {
              selectToggleTextColor: "#053075",
              text: "#053075",
              selectedItemText: Colors.primaryColor,
            },
          }}
          //   buttonStyle={{ backgroundColor: Colors.primaryColor }}
        />
         <Button
          title="Updated Status"
          type="solid"
          disabled={selectedItems.length > 0 ? false : true}
          loading={isLoding}
          onPress={() => updateRejected()}
          buttonStyle={{
            padding: Sizes.fixPadding,
            width: "100%",
            backgroundColor: Colors.primaryColor,
            borderRadius: 22,
          }}
          containerStyle={{
            width: "100%",
            // marginHorizontal: 50,
            marginVertical: 20,
          }}
          titleStyle={{
            fontSize: 14,
            fontWeight: "100",
            color: Colors.whiteColor,
          }}
          icon={
            <Ionicons
              name="add-circle-sharp"
              size={24}
              color="#ffffff"
              style={{ marginRight: "2%" }}
            />
          }
        />
      </View>

      {isLoding && <Loading />}
     
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: Colors.primaryColor,
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 15,
    marginTop: 40,
  },
  label: {
    color: "black",
    marginLeft: 0,
    marginBottom: 3,
    fontSize: windowWidth > 600 ? 18 : 16,
    fontWeight: "500",
    marginTop: windowWidth > 600 ? 0 : 12,
  },
  child1: {
    width: windowWidth > 600 ? "15%" : "30%",
  },
  child2: {
    width: windowWidth > 600 ? "85%" : "70%",
  },
});
export default RejectedStatus;
