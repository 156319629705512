import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Alert,
  TextInput,
} from "react-native";
import { Button, CheckBox, Dialog } from "react-native-elements";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  Ionicons,
  Entypo,
  MaterialIcons,
  MaterialCommunityIcons,
  FontAwesome,
  Octicons,
  FontAwesome5,
} from "@expo/vector-icons";
import Input from "../Utils/Input";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import Loading from "../Utils/Loading";

// // import { CardField, useStripe } from "@stripe/stripe-react-native";
// import { CardField, useConfirmPayment } from "@stripe/stripe-react-native";

// import {
//   StripeProvider,
//   Elements,
//   CardCVCElement,
// } from "react-stripe-elements";

import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import AuthorizedFor from "../../constants/authorizedFor";
import { authorizedForFlateList } from "../../constants/authorizedForFlateList";
import { ModulesPermission } from "../../constants/modules";

const PaymentScreen = (navigation) => {
  //   const [email, setEmail] = useState();
  //   const [cardDetails, setCardDetails] = useState();
  //   const { confirmPayment, loading } = useConfirmPayment();
  const [isLoding, setIsLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState("Stripe");
  const [checked, setChecked] = useState(1);
  const [publicKey, setPublicKey] = useState(null);
  const [clientSecret, setCLientSecret] = useState("");
  const [refrenceNumber, setRefrenceNumber] = useState(null);
  const [isPaymentMethods, setIsPaymentMethods] = useState(false);

  const item = navigation.route.params.item;

  const stripe = loadStripe(
    "pk_test_51J8OHKFUCbatucqQxASbEQuC4RktSfVphN4tMvnZZnVERvIu8Np1kipij1vrhVZJmkakJq3KK2Nd0gWGIoM8Mm3G00RpH1FSUr"
  );

  useEffect(() => {
    GetPaymentMethods();
  }, []);

  useEffect(() => {
    (async () => {
      (await authorizedForFlateList(
        ModulesPermission.Get_All_Payment_Methods_In_System
      )) && setIsPaymentMethods(true);
    })();
  }, [paymentMethods]);

  const GetPaymentMethods = () => {
    setIsLoading(true);
    jwt
      .get(`${Url.GET_PAYMENT_METHODS}/${global.systemID}`)
      .then((response) => {
        const newData = [];
        response.data.payment_method.map(
          (item, index) => (
            newData.push({
              ...item,
              checkedId: index,
            }),
            item.name == "Stripe" && GetPaymentMethodsKeys(item.id)
          )
        );
        setPaymentMethods(newData);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetPaymentMethodsKeys = (id) => {
    jwt
      .post(Url.GET_PAYMENT_METHODS_KEYS, {
        payment_method_id: id,
        system_id: global.systemID,
      })
      .then((response) => {
        navigation.handlePublicKey(response.data.keys.key);
        setPublicKey(response.data.keys.key);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          Payment
        </Text>
        <MaterialIcons
          name="arrow-back"
          size={26}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 15.0,
            fontWeight: 300,
          }}
          onPress={() => navigation.navigation.pop()}
        />
      </View>
    );
  };
  //   const handlePayPress = async () => {
  //     if (selectedMethod === "Bank Trasnfer" && refrenceNumber === null) {
  //       Alert.alert("Please Put Transaction Number");
  //     } else {

  //       //1.Gather the customer's billing information (e.g., email)
  //       if (!cardDetails?.complete) {
  //         Alert.alert("Please enter Complete card details");
  //         return;
  //       }
  //       try {
  //         setIsLoading(true);
  //         await jwt
  //           .post(`${Url.PAY_INVOICE}/${InvoiceID}`, {
  //             payment_method: selectedMethod,
  //             invoice_number: refrenceNumber,
  //             system_id: global.systemID,
  //           })
  //           .then(async (response) => {

  //             if (response.status == "success") {
  //               setCLientSecret(response.data.client_secret);
  //               const { error, paymentIntent } = await confirmPayment(
  //                 // setCLientSecret(response.data.client_secret),
  //                 response.data.client_secret,
  //                 {
  //                   type: "Card",
  //                   payment_method: {
  //                     card: cardDetails,
  //                   },
  //                 }
  //               );
  //               if (error) {
  //                 setIsLoading(false);
  //                 Alert.alert(`Error code: ${error.code}`, error.message);

  //               } else if (paymentIntent) {
  //                 jwt
  //                   .post(`${Url.PAYMENT_SUCCESS}/${InvoiceID}`, {
  //                     client_secret: response.data.client_secret,
  //                   })
  //                   .then((response) => {

  //                     setIsLoading(false);
  //                     setShow(true);
  //                     // Alert.alert(
  //                     //   "Success",
  //                     //   `The payment was confirmed successfully!`
  //                     // );
  //                   })
  //                   .catch((err) => {
  //                     setIsLoading(false);

  //                   });
  //               }
  //             } else {
  //               Alert.alert(response.message);
  //             }
  //           })
  //           .catch((err) => {
  //             setIsLoading(false);
  //             Alert.alert("Something went wrong..!!");
  //           });
  //       } catch (e) {

  //       }
  //     }
  //     //3.Confirm the payment with the card details
  // };
  // const handlePaymentMethod = (id, name, index) => {
  //   GetPaymentMethodsKeys(id);
  //   setChecked(index);
  //   setSelectedMethod(name);
  // };

  return (
    <View style={{ flex: 1 }}>
      {header()}
      {isLoding ? (
        <Loading />
      ) : (
        <View style={styles.container}>
          <View
            style={{
              flexDirection: "row",
              marginBottom: 5,
              marginLeft: -10,
              width: "100%",
            }}
          >
            <View style={{ width: "58%", marginRight: "1%", marginLeft: "1%" }}>
              {isPaymentMethods && (
                <View style={{ flexDirection: "row" }}>
                  {paymentMethods.map((method, index) => (
                    <CheckBox
                      key={index}
                      center
                      title={method.name}
                      checkedIcon="dot-circle-o"
                      uncheckedIcon="circle-o"
                      checked={checked === index}
                      onPress={(event) =>
                        handlePaymentMethod(method.id, method.name, index)
                      }
                      containerStyle={{
                        width: "50%",
                        alignItems: "flex-start",
                      }}
                      textStyle={{ fontSize: 14, marginLeft: 0 }}
                      checkedColor={Colors.primaryColor}
                    />
                  ))}
                </View>
              )}
              {selectedMethod == "Stripe" && (
                <View>
                  <Elements stripe={stripe}>
                    <CheckoutForm
                      item={item}
                      InvoiceID={item.invoice_id}
                      selectedMethod={selectedMethod}
                      refrenceNumber={refrenceNumber}
                    />
                  </Elements>
                </View>
              )}
              {selectedMethod == "Bank Trasnfer" && (
                <Input
                  label="Transaction Refrence Number"
                  type="text"
                  onChangeText={(value) => setRefrenceNumber(value)}
                  value={refrenceNumber}
                  mt={20}
                />
              )}
            </View>

            <View style={{ width: "38%", marginRight: "1%", marginLeft: "1%" }}>
              <View
                style={{
                  width: "100%",
                  alignItems: "end",
                  textAlign: "right",
                }}
              >
                <View style={{ flexDirection: "row", marginBottom: 10 }}>
                  <Text style={{ fontSize: 30 }}>InvoiceID: </Text>
                  <Text style={{ fontSize: 30 }}>{item.invoice_id}</Text>
                </View>
                <View style={{ flexDirection: "row", marginBottom: 10 }}>
                  <Text style={{ fontSize: 18 }}>Invoice Date: </Text>
                  <Text style={{ fontSize: 18 }}>{item.invoice_date}</Text>
                </View>
                <View style={{ flexDirection: "row", marginBottom: 10 }}>
                  <Text style={{ fontSize: 18 }}>Due Date: </Text>
                  <Text style={{ fontSize: 18 }}>{item.due_date}</Text>
                </View>
                <View style={{ flexDirection: "row", marginBottom: 10 }}>
                  <Text style={{ fontSize: 18 }}>Invoice Statu: </Text>
                  {item.status === "Partial-Paid" ? (
                    <Text
                      style={{
                        color: "#721c24",
                        fontSize: 18,
                        backgroundColor: "#f8d7da",
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                    >
                      {item.status}
                    </Text>
                  ) : (
                    <Text
                      style={{
                        color: "#155724",
                        fontSize: 18,
                        backgroundColor: "#d4edda",
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                    >
                      {item.status}
                    </Text>
                  )}
                </View>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  alignContent: "space-between",
                }}
              >
                <View style={{ width: "70%" }}></View>
                <View
                  style={{
                    width: "30%",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: 10,
                      marginTop: 15,
                    }}
                  >
                    <Text style={{ fontWeight: "bold", fontSize: 16 }}>
                      Sub Total:{" "}
                    </Text>
                    <Text style={{ fontWeight: "normal", fontSize: 16 }}>
                      {item.sub_total}
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: 10,
                      marginTop: 15,
                    }}
                  >
                    <Text style={{ fontWeight: "bold", fontSize: 16 }}>
                      {`Tax: ${item.tax_type} (${item.tax_value}%)`}
                    </Text>
                    <Text style={{ fontWeight: "normal", fontSize: 16 }}>
                      {item.total_tax}
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: 10,
                      marginTop: 15,
                    }}
                  >
                    <Text style={{ fontWeight: "bold", fontSize: 16 }}>
                      Grand Total:{" "}
                    </Text>
                    <Text style={{ fontWeight: "normal", fontSize: 16 }}>
                      {item.grand_total}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      )}
      {/* {isLoding && <Loading />} */}
    </View>
  );
};

const CheckoutForm = ({ item, InvoiceID, selectedMethod, refrenceNumber }) => {
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const [show, setShow] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const payMoney = async () => {
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
    } else {
      setPaymentLoading(true);

      await jwt
        .post(`${Url.PAY_INVOICE}/${InvoiceID}`, {
          id:InvoiceID,
          payment_method: selectedMethod,
          invoice_number: refrenceNumber,
          system_id: global.systemID,
        })
        .then(async (response) => {
          if (response.status == "success") {
            const paymentResult = await stripe.confirmCardPayment(
              response.data.client_secret,
              {
                payment_method: {
                  card: elements.getElement(CardElement),
                },
              }
            );

            if (paymentResult.error) {
              Alert.alert(paymentResult.error.message);
            } else {
              if (paymentResult.paymentIntent.status === "succeeded") {
                jwt
                  .post(`${Url.PAYMENT_SUCCESS}/${InvoiceID}`, {
                    client_secret: response.data.client_secret,
                  })
                  .then((response) => {
                    setPaymentLoading(false);
                    setShow(true);
                    // Alert.alert(
                    //   "Success",
                    //   `The payment was confirmed successfully!`
                    // );
                  })
                  .catch((err) => {
                    setIsLoading(false);
                  });
              }
            }
          }
        });
    }

    // setPaymentLoading(true);

    // await jwt
    //   .post(`${Url.PAY_INVOICE}/${InvoiceID}`, {
    //     payment_method: selectedMethod,
    //     invoice_number: refrenceNumber,
    //     system_id: global.systemID,
    //   })
    //   .then(async (response) => {

    //     if (response.status == "success") {
    //       const paymentResult = await stripe.confirmCardPayment(
    //         response.data.client_secret,
    //         {
    //           payment_method: {
    //             card: elements.getElement(CardElement),
    //           },
    //         }
    //       );

    //       setPaymentLoading(false);
    //       if (paymentResult.error) {
    //         Alert.alert(paymentResult.error.message);
    //       } else {

    //         if (paymentResult.paymentIntent.status === "succeeded") {
    //           setShow(true);
    //         }
    //       }
    //     }
    //   });
  };

  return (
    <View>
      <View
        style={{
          backgroundColor: "#FFFFFF",
          height: 40,
          padding: 10,
        }}
      >
        <CardElement
          options={{
            style: {
              base: {
                fontSize: "18px",
                backgroundColor: "#FFFFFF",
              },
            },
          }}
        />
      </View>

      <Button
        title="Pay"
        type="solid"
        disabled={!stripe}
        loading={isPaymentLoading}
        onPress={payMoney}
        buttonStyle={{
          padding: Sizes.fixPadding,
          width: "100%",
          backgroundColor: Colors.primaryColor,
          borderRadius: 22,
        }}
        containerStyle={{
          width: "100%",
          marginTop: 15,
          // marginHorizontal: 50,
          // marginVertical: 20,
        }}
        titleStyle={{
          fontSize: 14,
          fontWeight: "100",
          color: Colors.whiteColor,
        }}
      />

      <Dialog isVisible={show}>
        <Dialog.Title title="Success!" />
        <Text>{"Payment Confirmed Successfully"}</Text>
        <Dialog.Actions>
          <Dialog.Button
            title="Ok"
            onPress={() => {
              setShow(!show);
              // navigation.navigation.navigate("InvoiceDetails", {
              //   item: InvoiceID,
              // });
            }}
          />
        </Dialog.Actions>
      </Dialog>
    </View>
  );
};

const styles = StyleSheet.create({
  headerWrapStyle: {
    height: 55.0,
    backgroundColor: Colors.primaryColor,
    elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    flex: 1,
    // justifyContent: "center",
    margin: 20,
  },
  input: {
    backgroundColor: "#ffffff",

    borderRadius: 8,
    fontSize: 20,
    height: 50,
    padding: 10,
  },
  card: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
  },
  cardContainer: {
    height: 50,
    marginVertical: 30,
  },
});

export default PaymentScreen;
