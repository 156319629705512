/* eslint-disable consistent-this */
import React, { useState, useEffect, useCallback } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  StatusBar,
  Image,
  TextInput,
  TouchableOpacity,
  // Icon,
  RefreshControl,
  Alert,
  Platform,
  Dimensions,
} from "react-native";
import { Colors, Sizes } from "../../constants/style";
import { Button, Overlay, Dialog, Icon } from "react-native-elements";
import { SafeAreaProvider } from "react-native-safe-area-context";
import {
  Fontisto,
  FontAwesome5,
  FontAwesome,
  MaterialCommunityIcons,
  Ionicons,
  MaterialIcons,
  Feather,
  Entypo,
} from "@expo/vector-icons";
import Clock from "../Clock";
import BookingClock from "../BookingClock";
import CurrentApprovedBookings from "../widgets/CurrentApprovedBookings";
import TotalProjects from "../widgets/TotalProjects";
import CleanedProjects from "../widgets/CleanedProjects";
import PendingProjects from "../widgets/PendingProjects";
import BookedProjects from "../widgets/BookedProjects";
import * as SecureStore from "expo-secure-store";
import Constants from "expo-constants";
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import { ProgressBar } from "react-native-paper";
import Loading from "../Utils/Loading";
import { ModulesPermission } from "../../constants/modules";
import AuthorizedFor from "../../constants/authorizedFor";
import { authorizedForFlateList } from "../../constants/authorizedForFlateList";
import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment";
import { Video, AVPlaybackStatus } from "expo-av";
import StaffApprovedBookings from "../widgets/StaffApprovedBookings";

const windowWidth = Dimensions.get("window").width;

const WebDashboard = (props) => {
  const [bookingData, setBookingData] = useState([]);
  const [totalProjects, setTotalProjects] = useState(0);
  const [cleanedProjects, setCleanedProjects] = useState(0);
  const [pendingBookings, setPendingBookings] = useState(0);
  const [approvedBookings, setApprovedBookings] = useState(0);
  const [approvedBookings2, setApprovedBookings2] = useState(0);
  const [bookedProjects, setBookedProjects] = useState(0);
  const [income, SetIncome] = useState(0);
  const [clients, setClients] = useState(0);
  const [employees, setEmployees] = useState(0);
  const [policies, setPolicies] = useState(0);
  const [documents, setDocuments] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [isLoding, setIsLoading] = useState(false);

  const [isVisible1, setIsVisible1] = useState(false);

  const [permissions, setPermissions] = useState([]);

  const [currentBookingID, setCurrentBookingID] = useState(null);
  const [bookingID, setBookingID] = useState(null);
  const [refer, setRefr] = useState(false);

  const [currentTime, setCurrentTime] = useState(
    moment(moment.utc(new Date())).format("hh:mm:ss")
  );

  const [approvedBookingData, setApprovedBookingData] = useState([]);

  const [staffApprovedBooking, setStaffApprovedBooking] = useState(0);
  const [staffPendingBooking, setStaffPendingBooking] = useState(0);
  const video = React.useRef(null);
  const scrollRef = React.useRef(null);

  const [scrollEnabled, setEnabled] = React.useState(true);
  const [throttle, setThrottle] = React.useState(16);

  useEffect(() => {
    (async () => {
      // console.log(
      //   await authorizedForFlateList(
      //     ModulesPermission.Get_Service_Staff_Approved_Bookings_By_ID
      //   )
      // );
      // (await authorizedForFlateList(
      //   ModulesPermission.Get_Service_Staff_Approved_Bookings_By_ID
      // ))
      GetStaffApprovedBooking();
      GetStaffPendingBooking();
      GetBookings();
      GetClients();
      GetEmployees();
    })();
  }, [props.navigation]);

  // useEffect(() => {
  //   const unsubscribe = props.navigation.navigation.addListener("focus", () => {
  //     setRefr(true);
  //   });
  //   return unsubscribe;
  // }, [props.navigation]);

  const GetBookings = () => {
    setIsLoading(true);
    jwt
      .get(`${Url.GET_BOOKING}/${global.systemID}`)
      .then((response) => {
        // console.log(response);
        setBookingData(response.data.bookings);
        setApprovedBookings(
          response.data.bookings.filter(
            (obj) => obj.booking_status === "Approved"
          ).length
        );
        setPendingBookings(
          response.data.bookings.filter(
            (obj) => obj.booking_status === "Pending"
          ).length
        );
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetClients = () => {
    // console.log("Client called");
    jwt
      .get(`${Url.GET_ACTIVE_CLIENT}/${global.systemID}`)
      .then((response) => {
        // console.log(response);
        setIsLoading(false);
        setClients(response.data.clients.length);
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
      });
  };

  const GetEmployees = () => {
    // console.log("called");
    jwt
      .get(`${Url.GET_EMPLOYEES}/${global.systemID}`)
      .then((response) => {
        setEmployees(response.data.employees.length);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    GetApprovedBookings();
  }, [props.navigation]);

  const GetApprovedBookings = () => {
    jwt
      .get(`${Url.GET_APPROVED_BOOKING}/${global.userData.id}`)
      .then((response) => {
        setApprovedBookings2(response.data["pending-bookings"]);
      })
      .catch((err) => {});
  };

  const GetStaffApprovedBooking = () => {
    jwt
      .get(`${Url.GET_APPROVED_BOOKING}/${global.userData.id}`)
      .then((response) => {
        setStaffApprovedBooking(response.data["pending-bookings"].length);
      })
      .catch((err) => {});
  };

  const GetStaffPendingBooking = () => {
    jwt
      .get(`${Url.GET_PENDING_BOOKING}/${global.userData.id}`)
      .then((response) => {
        setStaffPendingBooking(response.data["pending-bookings"].length);
      })
      .catch((err) => {});
  };

  const header = () => {
    return (
      <View style={styles.headerWrapStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "bold", fontSize: 20 }}
        >
          Dashboard
        </Text>
        <Ionicons
          name="menu-outline"
          size={30}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 20.0,
            // fontWeight: 300
          }}
          onPress={() => props.navigation.navigation.toggleDrawer()}
        />

        <MaterialCommunityIcons
          name="bell-outline"
          size={30}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            right: 20.0,
            // fontWeight: 300
          }}
          onPress={() =>
            props.navigation.navigation.navigate("NotificationScreen")
          }
        />
      </View>
    );
  };

  const onClickBooking = (id) => {
    // console.log(id)
    // console.log("onClickBooking")
    setBookingID(id);
  };

  const onCurrentBookingID = (id) => {
    // console.log(id)
    // console.log("onClickBooking")
    setCurrentBookingID(id);
  };

  return (
    <ScrollView
      showsVerticalScrollIndicator={true}
      ref={scrollRef}
      scrollEnabled={scrollEnabled}
      scrollEventThrottle={throttle}
      style={{ backgroundColor: "#F2F3F4", padding: Sizes.fixPadding * 2 }}
    >
      {/* <View
        style={{
          flex: 1,
          paddingHorizontal: Sizes.fixPadding * 2,
        }}
      > */}
      <View
        style={[
          { marginBottom: 20 },
          windowWidth > 786 ? { flexDirection: "row" } : { width: "100%" },
        ]}
      >
        {AuthorizedFor(ModulesPermission.User_ClockIn_By_ID) && (
          <View
            style={[
              windowWidth > 786 ? { width: "23%" } : { width: "100%" },
              { marginRight: "1%" },
            ]}
          >
            <Clock navigation={props.navigation} />
          </View>
        )}
        {AuthorizedFor(ModulesPermission.Booking_Clock_In) &&
        bookingID != null ? (
          <View
            style={[
              windowWidth > 786 ? { width: "23%" } : { width: "100%" },
              { marginRight: "1%" },
            ]}
          >
            <BookingClock
              navigation={props.navigation}
              bookingID={bookingID}
              currentBookingID={currentBookingID}
            />
          </View>
        ) : null}

        {AuthorizedFor(ModulesPermission.Get_All_Bookings_By_System) && (
          <TouchableOpacity
            onPress={() => {}}
            style={[
              windowWidth > 786 ? { width: "24%" } : { width: "100%" },
              { marginRight: "1%", marginLeft: "1%" },
            ]}
          >
            <View style={styles.mainWrap}>
              <View
                style={{
                  height: 50,
                  width: 50,
                  padding: 10,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 100,
                  backgroundColor: "#0e62c7",
                }}
              >
                <Feather name="file-text" color="#ffffff" size={32} />
              </View>

              <View style={{ marginLeft: "8%" }}>
                <Text style={{ fontSize: 28, color: "#3e3f3f" }}>
                  {bookingData.length}
                </Text>
                <Text style={{ fontSize: 18, color: "#979898" }}>Bookings</Text>
              </View>
            </View>
          </TouchableOpacity>
        )}

        {AuthorizedFor(
          ModulesPermission.Get_Service_Staff_Approved_Bookings_By_ID
        ) && (
          <TouchableOpacity
            onPress={() => {
              props.navigation.navigation.navigate("ApprovedBookings");
            }}
            style={[
              windowWidth > 786 ? { width: "23%" } : { width: "100%" },
              { marginRight: "1%", marginLeft: "1%" },
            ]}
          >
            <View style={styles.mainWrap}>
              <View
                style={{
                  height: 50,
                  width: 50,
                  padding: 10,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 100,
                  backgroundColor: "#68b828",
                }}
              >
                <Feather name="file-text" color="#ffffff" size={32} />
              </View>

              <View style={{ marginLeft: "8%" }}>
                <Text style={{ fontSize: 28, color: "#3e3f3f" }}>
                  {staffApprovedBooking}
                </Text>
                <Text style={{ fontSize: 18, color: "#979898" }}>
                  Approved Bookings
                </Text>
              </View>
            </View>
          </TouchableOpacity>
        )}

        {AuthorizedFor(ModulesPermission.Get_Pending_Staff_Bookings) ? (
          <TouchableOpacity
            onPress={() => {}}
            style={[
              windowWidth > 786 ? { width: "24%" } : { width: "100%" },
              { marginRight: "1%", marginLeft: "1%" },
            ]}
          >
            <View style={styles.mainWrap}>
              <View
                style={{
                  height: 50,
                  width: 50,
                  padding: 10,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 100,
                  backgroundColor: "#d5080f",
                }}
              >
                <Feather name="file-text" color="#ffffff" size={32} />
              </View>

              <View style={{ marginLeft: "8%" }}>
                <Text style={{ fontSize: 28, color: "#3e3f3f" }}>
                  {staffPendingBooking}
                </Text>
                <Text style={{ fontSize: 18, color: "#979898" }}>
                  Pending Bookings
                </Text>
              </View>
            </View>
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            onPress={() => {}}
            style={[
              windowWidth > 786 ? { width: "24%" } : { width: "100%" },
              { marginRight: "1%", marginLeft: "1%" },
            ]}
          >
            <View style={styles.mainWrap}>
              <View
                style={{
                  height: 50,
                  width: 50,
                  padding: 10,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 100,
                  backgroundColor: "#d5080f",
                }}
              >
                <Feather name="file-text" color="#ffffff" size={32} />
              </View>

              <View style={{ marginLeft: "8%" }}>
                <Text style={{ fontSize: 28, color: "#3e3f3f" }}>
                  {pendingBookings}
                </Text>
                <Text style={{ fontSize: 18, color: "#979898" }}>
                  Pending Bookings
                </Text>
              </View>
            </View>
          </TouchableOpacity>
        )}
      </View>

      <View
        style={[
          { marginBottom: 10 },
          windowWidth > 786 ? { flexDirection: "row" } : { width: "100%" },
        ]}
      >
        {AuthorizedFor(ModulesPermission.Search_Bookings_for_Client) && (
          <TouchableOpacity
            onPress={() => {
              props.navigation.navigation.navigate("SearchBookings");
            }}
            style={[
              windowWidth > 786 ? { width: "23%" } : { width: "100%" },
              {
                marginRight: "1%",
                backgroundColor: "#68b828",
                marginBottom: 10,
              },
            ]}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                alignSelf: "center",
                padding: Sizes.fixPadding * 2,
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <View
                style={{
                  height: 50,
                  width: 50,
                  padding: 10,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 100,
                  backgroundColor: "#68b828",
                }}
              >
                <Ionicons name="search" color="#ffffff" size={32} />
                {/* <Feather name="file-text" color="#ffffff" size={25} /> */}
              </View>

              <View style={{ marginLeft: "8%" }}>
                <Text style={{ fontSize: 28, color: "#fff" }}>Booking</Text>
                <Text style={{ fontSize: 18, color: "#fff" }}>search</Text>
              </View>
            </View>
          </TouchableOpacity>
        )}
        {AuthorizedFor(ModulesPermission.Get_All_Clients) && (
          <TouchableOpacity
            onPress={() => {}}
            style={[
              windowWidth > 786 ? { width: "24%" } : { width: "100%" },
              {
                marginLeft: "1%",
                backgroundColor: "#7c38bc",
                marginBottom: 10,
              },
            ]}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                alignSelf: "center",
                padding: Sizes.fixPadding * 2,
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <View
                style={{
                  height: 50,
                  width: 50,
                  padding: 10,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 100,
                  backgroundColor: "#7c38bc",
                }}
              >
                <FontAwesome5 name="user" color="#ffffff" size={32} />

                {/* <Feather name="file-text" color="#ffffff" size={25} /> */}
              </View>

              <View style={{ marginLeft: "8%" }}>
                <Text style={{ fontSize: 28, color: "#fff" }}>{clients}</Text>
                <Text style={{ fontSize: 18, color: "#fff" }}>
                  Total Clients
                </Text>
              </View>
            </View>
          </TouchableOpacity>
        )}

        {AuthorizedFor(ModulesPermission.Get_Total_Employee_widget) && (
          <TouchableOpacity
            onPress={() => {}}
            style={[
              windowWidth > 786 ? { width: "23%" } : { width: "100%" },
              {
                marginLeft: "2%",
                backgroundColor: "#40bbea",
                marginBottom: 10,
              },
            ]}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                alignSelf: "center",
                padding: Sizes.fixPadding * 2,
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <View
                style={{
                  height: 50,
                  width: 50,
                  padding: 10,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 100,
                  backgroundColor: "#40bbea",
                }}
              >
                <FontAwesome5 name="user" color="#ffffff" size={32} />

                {/* <Feather name="file-text" color="#ffffff" size={25} /> */}
              </View>

              <View style={{ marginLeft: "8%" }}>
                <Text style={{ fontSize: 28, color: "#fff" }}>{employees}</Text>
                <Text style={{ fontSize: 18, color: "#fff" }}>
                  Total Employees
                </Text>
              </View>
            </View>
          </TouchableOpacity>
        )}

        {AuthorizedFor(ModulesPermission.Create_Order) && (
          <TouchableOpacity
            onPress={() => {
              props.navigation.navigation.navigate("OrderForm");
            }}
            style={[
              windowWidth > 786 ? { width: "24%" } : { width: "100%" },
              {
                marginLeft: "2%",
                backgroundColor: "#f7aa47",
                marginBottom: 10,
              },
            ]}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                alignSelf: "center",
                padding: Sizes.fixPadding * 2,
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <View
                style={{
                  height: 50,
                  width: 50,
                  padding: 10,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 100,
                  backgroundColor: "#f7aa47",
                }}
              >
                <Ionicons name="add-circle-outline" color="#ffffff" size={32} />
              </View>

              <View style={{ marginLeft: "8%" }}>
                <Text style={{ fontSize: 18, color: "#fff", opacity: 0.6 }}>
                  {"ORDERS &"}
                </Text>
                <Text style={{ fontSize: 28, color: "#fff" }}>
                  {"APPRAISALS"}
                </Text>
              </View>
            </View>
          </TouchableOpacity>
        )}
        <View
          style={[
            windowWidth > 786 ? { width: "35%" } : { width: "100%" },
            {
              marginRight: "1%",
              marginLeft: "1%",
              padding: 10,
              backgroundColor: "#FFF",
            },
          ]}
        >
          {refer && (
            <CurrentApprovedBookings
              currentTime={currentTime}
              onClickBooking={onClickBooking}
              onCurrentBookingID={onCurrentBookingID}
            />
          )}
        </View>
      </View>

      <View
        style={[
          { marginBottom: 10 },
          windowWidth > 786 ? { flexDirection: "row" } : { width: "100%" },
        ]}
      >
        <View
          style={[
            windowWidth > 786 ? { width: "23%" } : { width: "100%" },
            {
              marginRight: "1%",
            },
          ]}
        >
          {AuthorizedFor(ModulesPermission.Filter_Bookings_By_Date) && (
            <View style={{ position: "relative", marginBottom: 20 }}>
              <TouchableOpacity
                onPress={() => {
                  props.navigation.navigation.navigate("Calendar");
                }}
                style={{
                  width: "100%",
                  backgroundColor: "#ff6264",
                  height: 135,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                    alignSelf: "center",
                    padding: Sizes.fixPadding * 2,
                    marginTop: 25,
                    marginBottom: 10,
                  }}
                >
                  <View
                    style={{
                      height: 50,
                      width: 50,
                      padding: 10,
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 100,
                      backgroundColor: "#ff6264",
                    }}
                  >
                    {/* <FontAwesome5 name="file" color="#ffffff" size={32} /> */}
                    <Entypo name="calendar" color="#ffffff" size={32} />
                    {/* <Feather name="file-text" color="#ffffff" size={25} /> */}
                  </View>

                  <View style={{ marginLeft: "8%" }}>
                    {/* <Text style={{ fontSize: 28, color: "#fff" }}>8</Text> */}
                    <Text style={{ fontSize: 18, color: "#fff" }}>
                      Calendar
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
            </View>
          )}
          {AuthorizedFor(ModulesPermission.Get_All_Invoices_In_System) && (
            <View style={{ position: "relative", marginBottom: 20 }}>
              <TouchableOpacity
                onPress={() => {
                  props.navigation.navigation.navigate("Invoices");
                }}
                style={{
                  width: "100%",
                  backgroundColor: "#cc3f44",
                  height: 135,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                    alignSelf: "center",
                    padding: Sizes.fixPadding * 2,
                    marginTop: 25,
                    marginBottom: 10,
                  }}
                >
                  <View
                    style={{
                      height: 50,
                      width: 50,
                      padding: 10,
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 100,
                      backgroundColor: "#cc3f44",
                    }}
                  >
                    <FontAwesome5 name="file" color="#ffffff" size={32} />
                  </View>

                  <View style={{ marginLeft: "8%" }}>
                    {/* <Text style={{ fontSize: 28, color: "#fff" }}>8</Text> */}
                    <Text style={{ fontSize: 18, color: "#fff" }}>
                      Invoices
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
            </View>
          )}
        </View>

        <View style={styles.videoontainer}>
          <Video
            ref={video}
            style={styles.video}
            source={require("../../assets/ronove-dashboard.mp4")}
            useNativeControls
            resizeMode="cover"
            isLooping
            isMuted
            // onPlaybackStatusUpdate={status => setStatus(() => status)}
            shouldPlay
          />
        </View>

        <View
          style={[
            windowWidth > 786 ? { width: "24%" } : { width: "100%" },
            {
              marginLeft: "2%",
            },
          ]}
        >
          {AuthorizedFor(ModulesPermission.Get_Folder_Category_List) && (
            <View style={{ position: "relative", marginBottom: 20 }}>
              <TouchableOpacity
                onPress={() => {
                  props.navigation.navigation.navigate("FolderCategory");
                }}
                style={{
                  width: "100%",
                  backgroundColor: "#d5080f",
                  height: 135,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                    alignSelf: "center",
                    padding: Sizes.fixPadding * 2,
                    marginTop: 25,
                    marginBottom: 10,
                  }}
                >
                  <View
                    style={{
                      height: 50,
                      width: 50,
                      padding: 10,
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 100,
                      backgroundColor: "#d5080f",
                    }}
                  >
                    <FontAwesome5 name="file" color="#ffffff" size={32} />
                  </View>

                  <View style={{ marginLeft: "8%" }}>
                    <Text style={{ fontSize: 18, color: "#fff" }}>
                      File Manager
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
            </View>
          )}
          {AuthorizedFor(
            ModulesPermission.Get_User_Notifications_For_Admin
          ) && (
            <View style={{ position: "relative", marginBottom: 20 }}>
              {/* UserNotifications */}
              <TouchableOpacity
                onPress={() => {
                  props.navigation.navigation.navigate("UserNotifications");
                }}
                style={{
                  width: "100%",
                  backgroundColor: "#fcd036",
                  height: 135,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                    alignSelf: "center",
                    padding: Sizes.fixPadding * 2,
                    marginTop: 25,
                    marginBottom: 10,
                  }}
                >
                  <View
                    style={{
                      height: 50,
                      width: 50,
                      padding: 10,
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 100,
                      backgroundColor: "#fcd036",
                    }}
                  >
                    {/* <FontAwesome5 name="file" color="#ffffff" size={32} /> */}
                    <MaterialCommunityIcons
                      name="bell-outline"
                      size={32}
                      color={Colors.whiteColor}
                    />
                    {/* <Feather name="file-text" color="#ffffff" size={25} /> */}
                  </View>

                  <View style={{ marginLeft: "8%" }}>
                    <Text style={{ fontSize: 28, color: "#fff" }}></Text>
                    <Text style={{ fontSize: 18, color: "#fff" }}>
                      User Notifications
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </View>

      {/* </View> */}

      <Dialog isVisible={isVisible1}>
        <Dialog.Title title="Allow Notifications!" />
        <Text>if you want notifications press allow!</Text>

        <Dialog.Actions>
          <Dialog.Button
            title="Allow"
            onPress={() => {
              setIsVisible1(!isVisible1);
              registerForPushNotificationsAsync().then((token) => {
                global.expo_token = token;
              });
            }}
          />
          <Dialog.Button
            title="CANCEL"
            onPress={() => {
              setIsVisible1(!isVisible1);
            }}
          />
        </Dialog.Actions>
      </Dialog>
      {isLoding && <Loading />}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#000000",
    alignItems: "center",
    justifyContent: "center",
  },
  headerWrapStyle: {
    height: 55.0,
    backgroundColor: Colors.primaryColor,
    elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
  },
  label: {
    color: "#ffffff",
    fontSize: 16,
    marginTop: 20,
    // marginRight: "58%",
    marginBottom: 2,
  },
  mainWrap: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#ffffff",
    alignSelf: "center",
    padding: Sizes.fixPadding * 2,
    marginTop: 20,
  },
  icon: {
    height: 80,
    width: 80,
    padding: 10,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 100,
  },
  videoontainer: {
    width: windowWidth > 786 ? "49%" : "100%",
    marginLeft: "1%",
    // backgroundColor:"#000",
  },
  video: {
    // alignSelf: 'center',
    width: "100%",
    height: 290,
  },
  overlay: {
    flex: 1,
    position: "absolute",
    left: 0,
    top: 0,
    opacity: 0.7,
    backgroundColor: "black",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
});

export default WebDashboard;
