import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, ScrollView, FlatList, } from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  Button,
  SpeedDial,
  ListItem,
  Overlay,
  Dialog,
  SearchBar,
  Icon
} from "react-native-elements";
import {
  Feather,
  Ionicons,
  Entypo,
  MaterialIcons,
  FontAwesome5,
  MaterialCommunityIcons
} from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import { useForm, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import SelectDropdown from "react-native-select-dropdown";
import Loading from "../Utils/Loading";

const OwnBookingTimesheet = (navigation) => {
  const [timesheetData, setTimesheetData] = useState([]);
  const [timesheetIntialData, setTimesheetDataIntial] = useState([]);
  const [isLoding, setIsLoading] = useState(false);
 
  const [search, setSearch] = useState("");

  useEffect(() => {
    GetData();
  }, []);

  const GetData = () => {
    setIsLoading(true);
    jwt
      .get(Url.GET_OWN_BOOKING_TIMESHEET)
      .then((response) => {
        // console.log(response)
        setIsLoading(false);
        setTimesheetData(response.data['own-booking-timesheet']);
        setTimesheetDataIntial(response.data['own-booking-timesheet']);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  
  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          Booking TimeSheet
        </Text>
        <Ionicons
          name="menu-outline"
          size={30}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 20.0,
            // fontWeight: 300
          }}
          onPress={() => navigation.navigation.toggleDrawer()}
        />
      </View>
    );
  };


  const renderItem = ({ item, index }) => (
    <View style={styles.list}>

      <ListItem key={index} bottomDivider>
      <MaterialCommunityIcons name="clock-outline" size={20} color="red" />
        {/* <Icon name="clock" type="font-awesome" color="red" /> */}
        <ListItem.Content>
          <ListItem.Title style={{ color: 'red' }}>
            {item.bookingID}
          </ListItem.Title>
          <ListItem.Subtitle>{item.status}</ListItem.Subtitle>
        </ListItem.Content>
        <ListItem.Content right>
          <ListItem.Title right style={{ color: 'green' }}>
            {item.total_working_hours}
          </ListItem.Title>
          <ListItem.Subtitle right>{item.total_break_hours}</ListItem.Subtitle>
        </ListItem.Content>
        <ListItem.Chevron />
      </ListItem>
  
  </View>
  //   <View
  //   key={index}
  //   style={{
  //     marginTop: 15,
  //     zIndex: 1,
  //     borderRadius: 6,
  //     backgroundColor: "#FFFFFF",
  //     padding: Sizes.fixPadding,
  //   }}
  // >
  //    <View style={styles.contentContainer}>
  //         <View style={{ flexDirection: "row" }}>
  //           <Text style={styles.lable}>ID: </Text>
  //           <Text>{item.bookingID}</Text>
  //         </View>
  //       </View>
  //       <View style={styles.contentContainer}>
  //         <View style={{ flexDirection: "row" }}>
  //           <Text style={styles.lable}>Date: </Text>
  //           <Text>{item.auth_date}</Text>
  //         </View>
  //       </View>
  //       <View style={styles.contentContainer}>
  //         <View style={{ flexDirection: "row" }}>
  //           <Text style={styles.lable}>Working Hours: </Text>
  //           <Text>{item.total_working_hours}</Text>
  //         </View>
  //       </View>
  //       <View style={styles.contentContainer}>
  //         <View style={{ flexDirection: "row" }}>
  //           <Text style={styles.lable}>Break Hours: </Text>
  //           <Text>{item.total_break_hours}</Text>
  //         </View>
  //       </View>
  //       <View style={styles.contentContainer}>
  //         <View style={{ flexDirection: "row" }}>
  //           <Text style={styles.lable}>Status: </Text>
  //           <Text>{item.status}</Text>
  //         </View>
  //       </View>
  //  </View>
  );

  const handleSearch = (value) => {
    setSearch(value);
    if (value.length > 1) {
      const newData = taxData.filter(
        (e) =>
          e.name.toUpperCase().includes(value.toUpperCase()) ||
          parseFloat(e.percentag) === parseFloat(value)
      );
      if (newData.length > 0) {
        setTaxData(newData);
      } else {
        setTaxData(taxDataIntial);
      }
    } else {
      setTaxData(taxDataIntial);
    }
  };

  return (
    <View style={styles.container}>
      {header()}
      <SearchBar
        placeholder="Type Here..."
        onChangeText={handleSearch}
        value={search}
        lightTheme
        containerStyle={{
          backgroundColor: "#eee",
          borderWidth: 0,
          elevation: 0,
          paddingLeft: Sizes.fixPadding * 2,
          paddingRight: Sizes.fixPadding * 2,
        }}
        // placeholderTextColor="#000"
        inputStyle={{
          color: "#000",
        }}
        inputContainerStyle={{
          backgroundColor: "#ccc",
        }}
      />
      <View style={{ flex: 1, backgroundColor:'#F2F3F4', paddingTop: 0 }}>
        {/* <ScrollView style={{ flex: 1 }}> */}
        <FlatList
          showsVerticalScrollIndicator={false}
          nestedScrollEnabled
          data={timesheetData}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
        />
      </View>
      {isLoding && <Loading />}
     
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor:Colors.primaryColor
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom:15,
    marginTop:40
  },
  dropdown2BtnStyle: {
    width: "100%",
    height: 40,
    backgroundColor: "#FFF",
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#CCCCCC",
    marginBottom: 15,
  },
  dropdown2BtnTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 15,
  },
  dropdown2DropdownStyle: { backgroundColor: "#ddd" },
  dropdown2RowStyle: { backgroundColor: "#ddd", borderBottomColor: "#C5C5C5" },
  dropdown2RowTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 16,
  },
  contentContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 10,
  },
  lable: {
    fontWeight: "700",
  },
  list: {
    // marginTop: 20,
    borderTopWidth: 1,
    borderColor: "#cbd2d9",
  },
});
export default OwnBookingTimesheet;
