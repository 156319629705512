/* eslint-disable consistent-this */
import React,{useEffect, useState} from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";
import { Colors, Sizes } from "../../../constants/style";

import {
  Fontisto,
  MaterialIcons,
  FontAwesome,
  Feather,
} from "@expo/vector-icons";

import jwt from "../../../helpers/jwt";
import * as Url from "../../../helpers/urlHelper";

const StaffApprovedBookings = ({ navigation }) => {
  const [bookingData, setBookingData] = useState([]);
  useEffect(() => {
    GetBookings();
  }, [navigation]);

  const GetBookings = () => {
    jwt
      .get(`${Url.GET_APPROVED_BOOKING}/${global.userData.id}`)
      .then((response) => {
        setBookingData(response.data["pending-bookings"]);
      })
      .catch((err) => {});
  };

  return (
    <View>
      <TouchableOpacity
        onPress={() => {
          navigation.navigation.navigate("ApprovedBookings");
        }}
      >
        <View style={styles.mainWrap}>
          <View style={{ ...styles.icon, backgroundColor: "#0288d1" }}>
            <MaterialIcons name="cleaning-services" color="#ffffff" size={35} />
          </View>
          <View style={{ marginLeft: "8%" }}>
            <Text style={{ fontSize: 24 }}>{bookingData.length}</Text>
            <Text style={{ fontSize: 13 }}>Approved Bookings</Text>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  mainWrap: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    borderRadius: 10,
    backgroundColor: "#dddddd",
    alignSelf: "center",
    padding: Sizes.fixPadding,
    marginTop: 20,
  },
  icon: {
    height: 50,
    width: 50,
    borderRadius: 25,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default StaffApprovedBookings;
