import React from "react";
import { Platform } from "react-native";
export const Colors = {
  whiteColor: "#FFFFFF",
  blackColor: "#000000",
  primaryColor: "#659D1F",
  secondaryColor: "#94764d",
  offWhiteColor: "#f8f8f8",
};

export const Sizes = {
  fixPadding: 10.0,
};

export const HeaderStyle = {
  backgroundColor: Colors.primaryColor,
  alignItems: "center",
  justifyContent: "center",
  paddingBottom:15,
  marginTop:Platform.OS === 'ios' ? 40 : 15
};

export const appStyle = { Colors, Sizes };

export default appStyle;
