import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Platform,
  Dimensions
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  Button,
  SpeedDial,
  ListItem,
  Overlay,
  Dialog,
  SearchBar,
} from "react-native-elements";
import { Feather, Ionicons, Entypo, MaterialIcons } from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import SelectDropdown from "react-native-select-dropdown";
import moment from "moment";
import Loading from "../Utils/Loading";
import CustomDatePicker from "../../components/datePicker/datePicker";
import CustomPicker from "../../components/Picker/Picker";
const windowWidth = Dimensions.get("window").width;

const UpdateInvoice = (navigation) => {
  const selectedItemData = navigation.route.params.item;
  const [invoiceData, setInvoiceData] = useState([]);
  const [invoiceDataInitial, setInvoiceDataInitial] = useState([]);
  const [clientsData, setClientsData] = useState([]);
  const [clientsList, setClientsList] = useState([]);
  const [ordersData, setOrdersData] = useState([]);
  const [ordersList, setOrdersList] = useState([]);
  const [userId, setUserId] = useState(selectedItemData.user_id);
  const [selectedClient, setSelectedClient] = useState(null);
  const [userIndex, setUserIndex] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(selectedItemData.order_id);
  const [userAddress, setUserAddress] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(
    selectedItemData.address_id
  );
  const [cleaningData, setCleaningData] = useState([]);
  const [openDial, setOpenDial] = useState(false);
  const [isLoding, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible1, setIsVisible1] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [deleteShow, setDeleteShow] = useState(false);

  const [taxsData, setTaxsData] = useState([]);
  const [taxsList, setTaxsList] = useState([]);
  const [selectedTax, setSelectedTax] = useState(selectedItemData.tax_id);
  const [selectedTaxIndex, setSelectedTaxIndex] = useState(null);

  const [date, setDate] = useState(new Date(selectedItemData.invoice_date));
  const [date1, setDate1] = useState(new Date(selectedItemData.due_date));
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [invoiceDate, setInvoiceDate] = useState(new Date(selectedItemData.invoice_date));
  const [dueDate, setDueDate] = useState(new Date(selectedItemData.due_date));
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Its Error");
  const [search, setSearch] = useState("");

  useEffect(() => {
    (async () => {
      GetData();
      GetClients();
      GetTaxes();
      GetClientOrders(selectedItemData.user_id);
      GetUserAddress(selectedItemData.user_id);
    })();
  }, []);

  const GetData = () => {
    setIsLoading(true);

    jwt
      .get(`${Url.GET_INVOICE_LIST}/${global.systemID}`)
      .then((response) => {
        // setIsLoading(false);
        setInvoiceData(response.data.invoices);
        setInvoiceDataInitial(response.data.invoices);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetClients = () => {
    setIsLoading(true);

    jwt
      .get(`${Url.GET_ACTIVE_CLIENT}/${global.systemID}`)
      .then((response) => {
        const newClients = [];
        // setIsLoading(false);
        // setClientsList(response.data.clients);

        response.data.clients.map((ite, index) => {
          if (ite.user_id === selectedItemData.user_id) {
            setUserIndex(ite.organization_name);
            setSelectedClient(ite.id);
          }
        });

        response.data.clients.map((item) => {
          newClients.push({
            value: item.id,
            label: `${item.organization_name}`,
            ...item,
          });
        });

        setClientsList(newClients);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetClientOrders = (id) => {
    setIsLoading(true);
    jwt
      .get(`${Url.GET_USER_ORDERS}/${id}`)
      .then((response) => {
        const newOrders = [];
        // setIsLoading(false);
        // setOrdersData(response.data.orders);
        response.data.orders.map((item) => newOrders.push(
          {
            value: item.id,
            label:item.name,
            ...item,
          }
        ));
        setOrdersData(newOrders);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetTaxes = () => {
    jwt
      .get(`${Url.GET_ACTIVE_TAX_LIST}/${global.systemID}`)
      .then((response) => {
     

        const newArr = [];
        response.data.taxs.map((item) => newArr.push(
          {
            value: item.id,
            label:item.name,
            ...item,
          }
        ));
        setTaxsData(newArr);
        setIsLoading(false);
      })
      .catch((err) => {});
  };

  const handleSelectedClient = (val, index) => {
    if(parseInt(val) !== 0){
      setSelectedClient(val)
    setUserId(clientsList[index-1].user_id);
    GetClientOrders(clientsList[index-1].user_id);
    GetUserAddress(clientsList[index-1].user_id);
    } else {
      setSelectedClient(0)
    }
    // GetUserShifts(clientsList[index].user_id);
  };

  const handleSelectedOrder = (val, index) => {
    if(parseInt(val) !== 0){
    setSelectedOrder(val);
    } else {
      setSelectedOrder(0)
    }
  };

  const handleSelectedAddress = (val, index) => {
    if(parseInt(val) !== 0){
    setSelectedAddress(val);
    } else {
      setSelectedAddress(0);
    }
  };

  const handleSelectedTax = (index) => {
    setSelectedTax(taxsData[index].id);
  };

  const onChangeInvoiceDate = (event, selectedDate) => {
    const currentDate = selectedDate;
    setShow1(false);
    setInvoiceDate(currentDate);
    setDate(currentDate)
  };

  const onChangeDueDate = (event, selectedDate) => {
    const currentDate = selectedDate;
    setShow2(false);
    setDueDate(currentDate);
    setDate1(currentDate)
  };

  const GetUserAddress = (id) => {
    setIsLoading(true);
    jwt
      .get(`${Url.GET_USER_ADDRESS}/${id}`)
      .then((response) => {
        setIsLoading(false);

        // setUserAddress(response.data.user_addresses);
        const List = [];
        response.data.user_addresses.map((item) => List.push(
          {
            value: item.id,
            label:item.address,
            ...item,
          }
        ));
        setUserAddress(List);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const defaultValues = {
    items: selectedItemData.items,
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  const onSubmit = (data) => {
    const itemsList = [];
    data.items.map((it) =>
      itemsList.push({
        item_id: parseInt(it.id),
        item: it.item,
        description: it.description,
        unit_cost: it.unit_cost,
        quantity: it.quantity,
        total: it.total,
      })
    );
    const newData = {
      user_id: userId,
      order_id: selectedOrder,
      address_id: selectedAddress,
      tax_id: selectedTax,
      invoice_date: invoiceDate,
      due_date: dueDate,
      sub_total: data.subTotal,
      grand_total: data.grandTotal,
      total_paid: data.totalPaid,
      notes: data.notes,
      status: "Partial-Paid",
      invoice_item: itemsList,
    };

    if (userId === null) {
      setErrorMessage("Please Select Client");
      setShow(true);
    } else if (selectedOrder === null) {
      setErrorMessage("Please Select Order");
      setShow(true);
    } else if (selectedAddress === null) {
      setErrorMessage("Please Select Address");
      setShow(true);
    } else if (selectedTax === null) {
      setErrorMessage("Please Select Vat");
      setShow(true);
    } else if (invoiceDate === null) {
      setErrorMessage("Please Select Invoice Date");
      setShow(true);
    } else if (dueDate === null) {
      setErrorMessage("Please Select Due Date");
      setShow(true);
    } else if (data.items.length === 0) {
      setErrorMessage("Please Add Items");
      setShow(true);
    } else {
      setIsLoading(true);
      jwt
        .post(`${Url.UPDATE_INVOICE}/${selectedItemData.id}`, newData)
        .then((response) => {
          setIsLoading(false)
          navigation.navigation.navigate("InvoiceDetails", { item: response.data.invoice });
        })
        .catch((err) => {
          // console.log(err)
          setIsLoading(false);
        });
    }
  };

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
         Update Invoice
                 </Text>
        <MaterialIcons
          name="arrow-back"
          size={26}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 15.0,
            fontWeight: 300,
          }}
          onPress={() => navigation.navigation.pop()}
        />
      </View>
    );
  };

  return (
    <View style={styles.container}>
    {header()}
    <View style={{ flex: 1, backgroundColor:"#F2F3F4" ,padding: Sizes.fixPadding * 2 }}>
    <View
          style={{
            padding: Sizes.fixPadding,
            backgroundColor: "#fff",
            borderRadius: 6,
            flex:1
          }}
        >

      {isLoding ? (
        <Loading />
      ) : (
        <ScrollView
          style={{
            flex: 1,
            marginBottom: 20,
            paddingBottom: 40,
            backgroundColor: "#fff",
          }}
        >
            <View style={{ flexDirection: "row" }}>
              <View style={styles.child1}>
                <Text style={{ ...styles.label }}>{"Select Client"}</Text>
              </View>
              <View style={styles.child2}>
                <CustomPicker
                  data={clientsList}
                  selectedValue={selectedClient}
                  handleSelectedValue={handleSelectedClient}
                  defaultLabel={"Please select a client"}
                  style={{}}
                />
              </View>
            </View>
            <View style={{ flexDirection: "row", marginTop:15 }}>
              <View style={styles.child1}>
                <Text style={{ ...styles.label }}>{"Select Order"}</Text>
              </View>
              <View style={styles.child2}>
                <CustomPicker
                  data={ordersData}
                  selectedValue={selectedOrder}
                  handleSelectedValue={handleSelectedOrder}
                  defaultLabel={"Please select an order"}
                  style={{}}
                />
              </View>
            </View>

         
            <View style={{ flexDirection: "row", marginTop:15 }}>
              <View style={styles.child1}>
                <Text style={{ ...styles.label }}>{"Address"}</Text>
              </View>
              <View style={styles.child2}>
                <CustomPicker
                  data={userAddress}
                  selectedValue={selectedAddress}
                  handleSelectedValue={handleSelectedAddress}
                  defaultLabel={"Please select an address"}
                  style={{}}
                />
              </View>
            </View>
            <View style={{ flexDirection: "row", marginTop:15 }}>
              <View style={styles.child1}>
                <Text style={{ ...styles.label }}>{"Vat"}</Text>
              </View>
              <View style={styles.child2}>
                <CustomPicker
                  data={taxsData}
                  selectedValue={selectedTax}
                  handleSelectedValue={handleSelectedTax}
                  defaultLabel={"Please select vat"}
                  style={{}}
                />
              </View>
            </View>
         
            <View style={{ flexDirection: "row", marginBottom: 15, marginTop:15 }}>
              <View style={styles.child1}>
                <Text style={styles.label}>Invoice Date</Text>
              </View>
              <View style={{ ...styles.child2 }}>
                <CustomDatePicker
                  date={onChangeInvoiceDate}
                  defaultDate={date}
                  show={show1}
                  setShow={setShow1}
                />
              </View>
            </View>

            <View style={{ flexDirection: "row", marginBottom: 15 }}>
              <View style={styles.child1}>
                <Text style={styles.label}>Due Date</Text>
              </View>
              <View style={{ ...styles.child2 }}>
                <CustomDatePicker
                  date={onChangeDueDate}
                  defaultDate={date1}
                  show={show2}
                  setShow={setShow2}
                />
              </View>
            </View>

            <View style={{ flexDirection: "row", marginTop:15 }}>
              <View style={styles.child1}>
                <Text style={{ ...styles.label }}>{"Sub Total"}</Text>
              </View>
              <View style={styles.child2}>
          <Controller
            name="subTotal"
            control={control}
            rules={{
              required: { value: true, message: "Sub Total is required" },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                // label="Sub Total"
                type="text"
                onChangeText={(value) => onChange(value)}
                value={value}
                error={errors.subTotal}
                errorText={errors?.subTotal?.message}
              />
            )}
            defaultValue={"" + selectedItemData.sub_total}
          />
          </View>
          </View>

          <View style={{ flexDirection: "row", marginTop:15 }}>
              <View style={styles.child1}>
                <Text style={{ ...styles.label }}>{"Grand Total"}</Text>
              </View>
              <View style={styles.child2}>
          <Controller
            name="grandTotal"
            control={control}
            rules={{
              required: { value: true, message: "Grand Total is required" },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                // label="Grand Total"
                type="text"
                onChangeText={(value) => onChange(value)}
                value={value}
                error={errors.grandTotal}
                errorText={errors?.grandTotal?.message}
              />
            )}
            defaultValue={"" + selectedItemData.grand_total}
          />
          </View>
          </View>

          <View style={{ flexDirection: "row", marginTop:15 }}>
              <View style={styles.child1}>
                <Text style={{ ...styles.label }}>{"Total Paid"}</Text>
              </View>
              <View style={styles.child2}>
          <Controller
            name="totalPaid"
            control={control}
            rules={{
              required: { value: true, message: "Total Paid is required" },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                // label="Total Paid"
                type="text"
                onChangeText={(value) => onChange(value)}
                value={value}
                error={errors.totalPaid}
                errorText={errors?.totalPaid?.message}
              />
            )}
            defaultValue={"" + selectedItemData.total_paid}
          />
          </View>
          </View>

          <View style={{ flexDirection: "row", marginTop:15 }}>
              <View style={styles.child1}>
                <Text style={{ ...styles.label }}>{"Notes"}</Text>
              </View>
              <View style={styles.child2}>
          <Controller
            name="notes"
            control={control}
            rules={{
              required: { value: true, message: "Notes is required" },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                // label="Notes"
                type="text"
                onChangeText={(value) => onChange(value)}
                value={value}
                error={errors.notes}
                errorText={errors?.notes?.message}
              />
            )}
            defaultValue={selectedItemData.notes}
          />
          </View>
          </View>

          <View>
            {fields.map((item, index) => (
              <View
                style={
                  {
                    // flexDirection: "row",
                    // width: "100%",
                  }
                }
                key={item.id}
              >
                <Controller
                  name={`items.${index}.item_id`}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "id Is required",
                    },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      placeholder="Item Id"
                      onChangeText={(value) => onChange(value)}
                      value={value}
                      error={`${errors}.items.${index}.item_id`}
                      errorText={`${errors}.items.${index}.item_id`?.message}
                      editable={false}
                      selectTextOnFocus={false}
                    />
                  )}
                  defaultValue={item.id}
                />

                <Controller
                  name={`items.${index}.item`}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Name is required",
                    },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      placeholder="Item Name"
                      onChangeText={(value) => onChange(value)}
                      value={value}
                      error={`${errors}.items.${index}.item`}
                      errorText={`${errors}.items.${index}.item`?.message}
                    />
                  )}
                  defaultValue={item.item}
                />

                <Controller
                  name={`items.${index}.description`}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Description is required",
                    },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      placeholder="Description"
                      onChangeText={(value) => onChange(value)}
                      value={value}
                      error={`${errors}.items.${index}.description`}
                      errorText={
                        `${errors}.items.${index}.description`?.message
                      }
                    />
                  )}
                />

                <View
                  style={{
                    flexDirection: "row",
                    width: "26%",
                    marginHorizontal: "1%",
                    // justifyContent: "space-between"
                  }}
                >
                  <Controller
                    name={`items.${index}.unit_cost`}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Unit Cost is required",
                      },
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        placeholder="Unit Cost"
                        onChangeText={(value) => onChange(value)}
                        value={"" + value}
                        error={`${errors}.items.${index}.unit_cost`}
                        errorText={
                          `${errors}.items.${index}.unit_cost`?.message
                        }
                        // fiftyPercent
                        keyboardType="numeric"
                      />
                    )}
                    defaultValue={"" + item.unit_cost}
                  />
                  <Controller
                    name={`items.${index}.quantity`}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Qty is required",
                      },
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        placeholder="Qty"
                        onChangeText={(value) => onChange(value)}
                        value={"" + value}
                        error={`${errors}.items.${index}.quantity`}
                        errorText={`${errors}.items.${index}.quantity`?.message}
                        // fiftyPercent
                        keyboardType="numeric"
                        marginLeft={10}
                      />
                    )}
                    defaultValue={"" + item.quantity}
                  />
                  <Controller
                    name={`items.${index}.total`}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Total is required",
                      },
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        placeholder="Total"
                        onChangeText={(value) => onChange(value)}
                        value={"" + value}
                        error={`${errors}.items.${index}.total`}
                        errorText={`${errors}.items.${index}.total`?.message}
                        // fiftyPercent
                        marginLeft={20}
                        keyboardType="numeric"
                      />
                    )}
                    defaultValue={"" + item.total}
                  />

                  <TouchableOpacity
                    style={{ width: 50, marginLeft: 25, marginTop: 8 }}
                    onPress={() => remove(index)}
                  >
                    <Feather name="delete" size={24} color="red" />
                  </TouchableOpacity>
                </View>
              </View>
            ))}

            <TouchableOpacity
              onPress={() =>
                append({
                  item: "",
                  description: "",
                  unit_cost: "",
                  quantity: "",
                })
              }
              style={{
                flexDirection: "row",
                backgroundColor: "#EEE",
                padding: 10,
                width: 110,
                borderRadius: 4,
                marginTop: 10,
              }}
            >
              <Ionicons
                name="add-circle-sharp"
                size={22}
                color="black"
                style={{ marginRight: "2%" }}
              />
              <Text style={{ paddingTop: 3 }}>Add Item</Text>
            </TouchableOpacity>
          </View>

          <Button
            title="Update Invoice"
            type="solid"
            //   loading={isLoding}
            onPress={handleSubmit(onSubmit)}
            buttonStyle={{
              padding: Sizes.fixPadding,
              width: "100%",
              backgroundColor: Colors.primaryColor,
              borderRadius: 22,
            }}
            containerStyle={{
              width: "100%",
              // marginHorizontal: 50,
              marginVertical: 20,
            }}
            titleStyle={{
              fontSize: 14,
              fontWeight: "100",
              color: Colors.whiteColor,
            }}
            icon={
              <Ionicons
                name="add-circle-sharp"
                size={24}
                color="#ffffff"
                style={{ marginRight: "2%" }}
              />
            }
          />
        </ScrollView>
      )}
    </View>
    </View>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor:Colors.primaryColor
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom:15,
    marginTop:40
  },
  label: {
    color: "black",
    marginLeft: 0,
    marginBottom: 3,
    fontSize: windowWidth > 600 ? 18 : 16,
    fontWeight: "500",
    marginTop: windowWidth > 600 ? 0 : 12,
  },
  child1: {
    width: windowWidth > 600 ? "15%" : "30%",
  },
  child2: {
    width: windowWidth > 600 ? "85%" : "70%",
  },
});
export default UpdateInvoice;
