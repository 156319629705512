import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  FlatList,
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  Button,
  SpeedDial,
  ListItem,
  Overlay,
  Dialog,
  SearchBar,
} from "react-native-elements";
import { Feather, Ionicons, Entypo, MaterialIcons } from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import Loading from "../Utils/Loading";
import moment from "moment";
import { ModulesPermission } from "../../constants/modules";
import AuthorizedFor from "../../constants/authorizedFor";
import { authorizedForFlateList } from "../../constants/authorizedForFlateList";

const ApprovedBookings = (navigation) => {
  const [orderData, setOrderData] = useState([]);
  const [orderDataInitial, setOrderDataInitial] = useState([]);
  const [isLoding, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");

  const [count, setCount] = useState(0);
  const [checkSelected, setCheckSelected] = useState(null);

  const [date, setDate] = useState(new Date());

  const [isIsTasked, setIsTasked] = useState(false);

  useEffect(() => {
    (async () => {
      GetData();
    })();
  }, [navigation, navigation.route.params]);

  useEffect(() => {
    (async () => {
      (await authorizedForFlateList(
        ModulesPermission.Get_Order_Tasks_By_Booking_ID
      )) && setIsTasked(true);
    })();
  }, [orderData.length]);

  const GetData = () => {
    setIsLoading(true);
    jwt
      .get(`${Url.GET_APPROVED_BOOKING}/${global.userData.id}`)
      .then((response) => {
        setIsLoading(false);
        setOrderData(response.data["pending-bookings"].reverse());
        setOrderDataInitial(response.data["pending-bookings"].reverse());
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          Approved Bookings
        </Text>
        <Ionicons
          name="menu-outline"
          size={30}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 20.0,
            // fontWeight: 300
          }}
          onPress={() => navigation.navigation.toggleDrawer()}
        />
      </View>
    );
  };

  const renderItem = ({ item, index }) => {
    item.booking_date === `${moment(date).format("YYYY-MM-DD")}`
      ? () => {
          setCheckSelected(item.id);
          setCount(count + 1);
        }
      : null;

    return (
      <View
        key={index}
        style={{
          marginTop: 15,
          zIndex: 1,
          borderRadius: 6,
          backgroundColor: "#FFFFFF",
          padding: Sizes.fixPadding,
        }}
      >
        <View style={styles.contentContainer}>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.lable}>ID: </Text>
            <Text>{item.id}</Text>
          </View>
        </View>

        <View style={styles.contentContainer}>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.lable}>Booking Date: </Text>
            <Text>{item.booking_date}</Text>
          </View>
        </View>

        <View style={styles.contentContainer}>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.lable}>Day: </Text>
            <Text>{item.employee_shift_day}</Text>
          </View>
        </View>

        <View style={styles.contentContainer}>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.lable}>Start Time: </Text>
            <Text>{item.employee_shift_start_time}</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.lable}>End Time: </Text>
            <Text>{item.employee_shift_end_time}</Text>
          </View>
        </View>

        <View style={styles.contentContainer}>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.lable}>Status: </Text>
            <Text>{item.booking_status}</Text>
          </View>
        </View>

        <View style={styles.contentContainer}>
          {isIsTasked && (
            <Button
              title="View Task"
              type="solid"
              loading={isLoding}
              onPress={() =>
                navigation.navigation.navigate("BookingTasks", {
                  bookingId: item.booking_id,
                })
              }
              buttonStyle={{
                backgroundColor: Colors.primaryColor,
                // borderRadius: 22,
              }}
              containerStyle={
                {
                  // width: 110,
                  // // marginHorizontal: 50,
                  // marginVertical: 30,
                }
              }
              titleStyle={{
                fontSize: 12,
                fontWeight: "100",
                color: Colors.whiteColor,
              }}
            />
          )}
          <Button
            title="History"
            type="solid"
            loading={isLoding}
            onPress={() => {
              navigation.navigation.navigate("BookingHistory", { item: item });
              // setSelectedClockItem(item.id);
              // SaveFunction();
            }}
            buttonStyle={{
              backgroundColor: Colors.secondaryColor,
              // borderRadius: 22,
            }}
            containerStyle={
              {
                // width: 110,
                // // marginHorizontal: 50,
                // marginVertical: 30,
              }
            }
            titleStyle={{
              fontSize: 12,
              fontWeight: "100",
              color: Colors.whiteColor,
            }}
          />
        </View>
      </View>
    );
  };

  const handleSearch = (value) => {
    setSearch(value);
    if (value.length > 1) {
      const newData = orderData.filter(
        (e) =>
          e.booking_id.toUpperCase().includes(value.toUpperCase()) ||
          e.booking_date.toUpperCase().includes(value.toUpperCase()) ||
          e.days_per_week.toUpperCase().includes(value.toUpperCase()) ||
          e.booking_status.toUpperCase().includes(value.toUpperCase())
      );
      if (newData.length > 0) {
        setOrderData(newData);
      } else {
        setOrderData(orderDataInitial);
      }
    } else {
      setOrderData(orderDataInitial);
    }
  };

  return (
    <View style={styles.container}>
      {header()}
      <SearchBar
        placeholder="Type Here..."
        onChangeText={handleSearch}
        value={search}
        lightTheme
        containerStyle={{
          backgroundColor: "#eee",
          borderWidth: 0,
          elevation: 0,
          paddingLeft: Sizes.fixPadding * 2,
          paddingRight: Sizes.fixPadding * 2,
        }}
        // placeholderTextColor="#000"
        inputStyle={{
          color: "#000",
        }}
        inputContainerStyle={{
          backgroundColor: "#ccc",
        }}
      />
      <View style={{ flex: 1, backgroundColor:"#F2F3F4" ,padding: Sizes.fixPadding * 2, paddingTop: 0 }}>
        {/* <ScrollView style={{ flex: 1 }}> */}
        <FlatList
          showsVerticalScrollIndicator={false}
          nestedScrollEnabled
          data={orderData}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
        />
      </View>

      {isLoding && <Loading />}
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor:Colors.primaryColor
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom:15,
    marginTop:40
  },
  contentContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 10,
  },
  lable: {
    fontWeight: "700",
  },
  dropdown2BtnStyle: {
    width: "100%",
    height: 40,
    backgroundColor: "#FFF",
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#CCCCCC",
    marginBottom: 15,
  },
  dropdown2BtnTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 15,
  },
  dropdown2DropdownStyle: { backgroundColor: "#ddd" },
  dropdown2RowStyle: { backgroundColor: "#ddd", borderBottomColor: "#C5C5C5" },
  dropdown2RowTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 16,
  },
});
export default ApprovedBookings;
