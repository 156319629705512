import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Platform,
  Dimensions,
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  Button,
  SpeedDial,
  ListItem,
  Overlay,
  Dialog,
  SearchBar,
} from "react-native-elements";
import { Feather, Ionicons, Entypo, MaterialIcons } from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import moment from "moment";
import Loading from "../Utils/Loading";
import CustomDatePicker from "../../components/datePicker/datePicker";
import CustomPicker from "../../components/Picker/Picker";

const windowWidth = Dimensions.get("window").width;

const CreateInvoice = (navigation) => {
  const [invoiceData, setInvoiceData] = useState([]);
  const [invoiceDataInitial, setInvoiceDataInitial] = useState([]);
  const [clientsData, setClientsData] = useState([]);
  const [clientsList, setClientsList] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [ordersData, setOrdersData] = useState([]);
  const [ordersList, setOrdersList] = useState([]);
  const [ordersItems, setOrderItems] = useState([]);
  const [userId, setUserId] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [userAddress, setUserAddress] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [bookingType, setBookingType] = useState(null);
  const [bookingTypes, setBookingTypes] = useState([
    {
      value: "Medical",
      label: "Medical",
    },
    {
      value: "Cleaning",
      label: "Cleaning",
    },
  ]);
  const [cleaningData, setCleaningData] = useState([]);
  const [openDial, setOpenDial] = useState(false);
  const [isLoding, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible1, setIsVisible1] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [deleteShow, setDeleteShow] = useState(false);

  const [taxsData, setTaxsData] = useState([]);
  const [taxsList, setTaxsList] = useState([]);
  const [selectedTax, setSelectedTax] = useState(null);

  const [date, setDate] = useState(new Date());
  const [date1, setDate1] = useState(new Date());
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [dueDate, setDueDate] = useState(new Date());
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Its Error");
  const [search, setSearch] = useState("");
  const [showSuccess, setShowScuccess] = useState(false);

  const [discount, setDiscount] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    (async () => {
      GetData();
      GetClients();
      GetTaxes();
    })();
  }, []);

  const GetData = () => {
    setIsLoading(true);

    jwt
      .get(`${Url.GET_INVOICE_LIST}/${global.systemID}`)
      .then((response) => {
        setIsLoading(false);
        setInvoiceData(response.data.invoices);
        setInvoiceDataInitial(response.data.invoices);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetClients = () => {
    setIsLoading(true);

    jwt
      .get(`${Url.GET_ACTIVE_CLIENT}/${global.systemID}`)
      .then((response) => {
        // console.log(response)
        setIsLoading(false);
        const NewData = [];

        response.data.clients.map((item) => {
          NewData.push({
            value: item.id,
            label: `${item.organization_name}`,
            ...item,
          });
        });

        setClientsList(NewData);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetClientOrders = (id) => {
    setIsLoading(true);

    jwt
      .get(`${Url.GET_USER_ORDERS}/${id}`)
      .then((response) => {
        const newOrders = [];
        setIsLoading(false);
        // setOrdersData(response.data.orders);
        response.data.orders.map((item) =>
          newOrders.push({
            value: item.id,
            label: item.name,
            ...item,
          })
        );
        setOrdersData(newOrders);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetTaxes = () => {
    jwt
      .get(`${Url.GET_ACTIVE_TAX_LIST}/${global.systemID}`)
      .then((response) => {
        const newArr = [];
        response.data.taxs.map((item) => {
          newArr.push({
            value: item.id,
            label: `${item.name} ${item.percentage}%`,
            ...item,
          });
        });
        setTaxsData(newArr);
      })
      .catch((err) => {});
  };

  const handleSelectedClient = (val, index) => {
    setSelectedClient(parseInt(val));
    if (parseInt(val) !== 0) {
      setUserId(clientsList[index - 1].user_id);
      GetClientOrders(clientsList[index - 1].user_id);
      GetUserAddress(clientsList[index - 1].user_id);
    }
  };

  const handleSelectedOrder = (val, index) => {
    if (parseInt(val) !== 0) {
      setSelectedOrder(val);
      GetOrderItems(val);
    } else {
      setSelectedOrder(0);
      GetOrderItems(0);
    }
  };

  const GetOrderItems = (id) => {
    setIsLoading(true);

    jwt
      .get(`${Url.GET_ORDER_ITEM_LIST}/${id}`)
      .then((response) => {
        setIsLoading(false);
        // setInvoiceData(response.data.invoices);

        // setInvoiceDataInitial(response.data.invoices);
        const itemsList = [];
        response.data.orders_items.map((it) =>
          itemsList.push({
            item: it.room_type_name,
            description: `Description of ${it.room_type_name}`,
            unit_cost: it.price,
            quantity: it.quantity,
            total: it.sub_total,
          })
        );
        setOrderItems(itemsList.length > 0 ? itemsList : []);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleSelectedAddress = (val, index) => {
    if(parseInt(val) !== 0){
    setSelectedAddress(val);
    }else{
      setSelectedAddress(0);
    }
  };

  const handleSelectedTax = (val, index) => {
  
    if(parseInt(val) !== 0){
      setSelectedTax(val);
      }else{
        setSelectedTax(0);
      }
  };

  const onChangeInvoiceDate = (event, selectedDate) => {
    const currentDate = selectedDate;
    setShow1(false);
    setInvoiceDate(currentDate);
    setDate(currentDate);
  };

  const onChangeDueDate = (event, selectedDate) => {
    const currentDate = selectedDate;
    setShow2(false);
    setDueDate(currentDate);
    setDate1(currentDate);
  };

  const GetUserAddress = (id) => {
    setIsLoading(true);
    jwt
      .get(`${Url.GET_USER_ADDRESS}/${id}`)
      .then((response) => {
        setIsLoading(false);

        const List = [];
        response.data.user_addresses.map((item) => List.push({
          value: item.id,
          label: item.address,
          ...item,
        }));
        setUserAddress(List);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleSelectedBookingType = (index) => {
    setBookingType(bookingTypes[index]);
  };

  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    trigger,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    replace(ordersItems);
  }, [ordersItems, replace]);

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "items",
  });

  const onSubmit = (data) => {
    const newData = {
      user_id: userId,
      order_id: selectedOrder,
      address_id: selectedAddress,
      tax_id: selectedTax,
      invoice_date: moment(invoiceDate).format('YYYY-MM-DD'),
      discount: discount,
      due_date: moment(dueDate).format('YYYY-MM-DD'),
      sub_total: subTotal,
      grand_total: total,
      notes: data.notes,
      invoice_item: data.items,
    };

    if (userId === null) {
      setErrorMessage("Please Select Client");
      setShow(true);
    } else if (selectedOrder === null) {
      setErrorMessage("Please Select Order");
      setShow(true);
    } else if (selectedAddress === null) {
      setErrorMessage("Please Select Address");
      setShow(true);
    } else if (selectedTax === null) {
      setErrorMessage("Please Select Vat");
      setShow(true);
    } else if (invoiceDate === null) {
      setErrorMessage("Please Select Invoice Date");
      setShow(true);
    } else if (dueDate === null) {
      setErrorMessage("Please Select Due Date");
      setShow(true);
    } else if (data.items.length === 0) {
      setErrorMessage("Please Add Items");
      setShow(true);
    } else {
      setIsLoading(true);
      jwt
        .post(`${Url.ADD_INVOICE}/${global.systemID}`, newData)
        .then((response) => {
          setIsLoading(false);
          setShowScuccess(true);
          // reset();
          setSelectedAddress(null);
          setSelectedOrder(null);
          setUserId(null);
          setSelectedItem([]);
        })
        .catch((err) => {
          setIsLoading(false);
        });
 
    }
  };

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          Create Invoice
        </Text>
        <MaterialIcons
          name="arrow-back"
          size={26}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 15.0,
            fontWeight: 300,
          }}
          onPress={() => navigation.navigation.pop()}
        />
      </View>
    );
  };

  useEffect(() => {
    if (watch().items.length > 0) {
      const DataList = watch().items;

      let NewPrice = [];

      DataList.map((item) => NewPrice.push(item.unit_cost * item.quantity));

      const addedPrice = NewPrice.reduce((a, v) => (a = a + v), 0);

      setSubTotal(addedPrice);

      let afterDsicuontPrice = addedPrice;

      if (parseInt(discount) > 0) {
        // afterDsicuont = (discount / 100) * newPercent;
        afterDsicuontPrice = (1 - discount / 100) * addedPrice;
      }

      // console.log(addedPrice);

      let tax = 0;
      if (selectedTax) {
        tax = taxsData.filter((item) => item.id === selectedTax)[0].percentage;
      }

      // let newPercent = (tax / 100) * addedPrice;

      // const newTotal = addedPrice + newPercent

      // discounted_price = original_price - (original_price * discount) / 100;

      const totalAfterTax = (1 + tax / 100) * afterDsicuontPrice;

      setTotal(totalAfterTax);
    }
  }, [watch()]);

  //   useEffect(() => {
  //     reset({
  //       subTotal: subTotal,
  //       total: total,
  //     });
  //   }, [total, subTotal]);

  // console.log(invoiceDate)
  // console.log(dueDate)

  return (
    <View style={styles.container}>
      {header()}

      {isLoding && <Loading />}

      <View
        style={{
          flex: 1,
          backgroundColor: "#F2F3F4",
          padding: Sizes.fixPadding,
          paddingTop: 20,
        }}
      >
        <ScrollView
          style={{
            flex: 1,
            padding: Sizes.fixPadding * 2,
            backgroundColor: "#fff",
            marginBottom: 20,
            borderRadius: 5,
          }}
          showsVerticalScrollIndicator={false}
        >
          <View style={{ flexDirection: "row" }}>
            <View style={styles.child1}>
              <Text style={{ ...styles.label }}>{"Select Client"}</Text>
            </View>
            <View style={styles.child2}>
              <CustomPicker
                data={clientsList}
                selectedValue={selectedClient}
                handleSelectedValue={handleSelectedClient}
                defaultLabel={"Please select a client"}
                style={{}}
              />
            </View>
          </View>

          <View style={{ flexDirection: "row", marginTop: 15 }}>
            <View style={styles.child1}>
              <Text style={{ ...styles.label }}>{"Select Order"}</Text>
            </View>
            <View style={styles.child2}>
              <CustomPicker
                data={ordersData}
                selectedValue={selectedOrder}
                handleSelectedValue={handleSelectedOrder}
                defaultLabel={"Please select an Order"}
                style={{}}
              />
            </View>
          </View>

         

          <View style={{ flexDirection: "row", marginTop: 15 }}>
              <View style={styles.child1}>
                <Text style={{ ...styles.label }}>{"Address"}</Text>
              </View>
              <View style={styles.child2}>
                <CustomPicker
                  data={userAddress}
                  selectedValue={selectedAddress}
                  handleSelectedValue={(val, index) => {
                    handleSelectedAddress(val)
                  }}
                  defaultLabel={"Please select User Address"}
                  style={{}}
                />
              </View>
            </View>

            <View style={{flexDirection:'row', marginTop: 15 }}>
                   <View style={styles.child1}>
                <Text style={{ ...styles.label}}>{"Vat"}</Text>
               </View>
               <View style={styles.child2}>
                <CustomPicker
                  data={taxsData}
                  selectedValue={selectedTax}
                  handleSelectedValue={handleSelectedTax}
                  defaultLabel={"Please select Vat"}
                  style={{}}
                />
</View>
</View>

<View style={{ flexDirection: "row", marginBottom: 15, marginTop:15 }}>
              <View style={styles.child1}>
                <Text style={styles.label}>Invoice Date</Text>
              </View>
              <View style={{ ...styles.child2 }}>
                <CustomDatePicker
                  date={onChangeInvoiceDate}
                  defaultDate={date}
                  show={show1}
                  setShow={setShow1}
                />
              </View>
            </View>

            <View style={{ flexDirection: "row", marginBottom: 15 }}>
              <View style={styles.child1}>
                <Text style={styles.label}>Due Date</Text>
              </View>
              <View style={{ ...styles.child2 }}>
                <CustomDatePicker
                  date={onChangeDueDate}
                  defaultDate={date1}
                  show={show2}
                  setShow={setShow2}
                />
              </View>
            </View>


          <View style={{ flexDirection: "row", marginTop: 15 }}>
              <View style={styles.child1}>
                <Text style={{ ...styles.label }}>{"Booking Type"}</Text>
              </View>
              <View style={styles.child2}>
                <CustomPicker
                  data={bookingTypes}
                  selectedValue={bookingType}
                  handleSelectedValue={(val, index) => {
                    setBookingType(val);
                  }}
                  defaultLabel={"Please select Booking Type"}
                  style={{}}
                />
              </View>
            </View>

          <Text style={{ marginTop: 0 }}>Items</Text>
          <View>
            {fields.map((item, index) => (
              <View
                style={
                  {
                    // flexDirection: "row",
                    // width: "100%",
                  }
                }
                key={index}
              >
                <Controller
                  name={`items.${index}.item`}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Name is required",
                    },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      placeholder="Item Name"
                      onChangeText={(value) => onChange(value)}
                      value={value}
                      error={`${errors}.items.${index}.item`}
                      errorText={`${errors}.items.${index}.item`?.message}
                    />
                  )}
                  defaultValue={item.item}
                />

                <Controller
                  name={`items.${index}.description`}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Description is required",
                    },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      placeholder="Description"
                      onChangeText={(value) => onChange(value)}
                      value={value}
                      error={`${errors}.items.${index}.description`}
                      errorText={
                        `${errors}.items.${index}.description`?.message
                      }
                    />
                  )}
                />

                <View
                  style={{
                    flexDirection: "row",
                    width: "26%",
                    marginHorizontal: "1%",
                    // justifyContent: "space-between"
                  }}
                >
                  <Controller
                    name={`items.${index}.unit_cost`}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Unit Cost is required",
                      },
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        placeholder="Unit Cost"
                        onChangeText={(value) => onChange(value)}
                        value={"" + value}
                        error={`${errors}.items.${index}.unit_cost`}
                        errorText={
                          `${errors}.items.${index}.unit_cost`?.message
                        }
                        // fiftyPercent
                        keyboardType="numeric"
                      />
                    )}
                    defaultValue={"" + item.unit_cost}
                  />
                  <Controller
                    name={`items.${index}.quantity`}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Qty is required",
                      },
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        placeholder="Qty"
                        onChangeText={(value) => onChange(value)}
                        value={"" + value}
                        error={`${errors}.items.${index}.quantity`}
                        errorText={`${errors}.items.${index}.quantity`?.message}
                        // fiftyPercent
                        keyboardType="numeric"
                        marginLeft={10}
                      />
                    )}
                    defaultValue={"" + item.quantity}
                  />
                  <Controller
                    name={`items.${index}.total`}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Total is required",
                      },
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        placeholder="Total"
                        onChangeText={(value) => onChange(value)}
                        value={"" + value}
                        error={`${errors}.items.${index}.total`}
                        errorText={`${errors}.items.${index}.total`?.message}
                        // fiftyPercent
                        marginLeft={20}
                        keyboardType="numeric"
                      />
                    )}
                    defaultValue={"" + item.total}
                  />

                  <TouchableOpacity
                    style={{ width: 50, marginLeft: 25, marginTop: 8 }}
                    onPress={() => remove(index)}
                  >
                    <Feather name="delete" size={24} color="red" />
                  </TouchableOpacity>
                </View>
              </View>
            ))}

            <TouchableOpacity
              onPress={() =>
                append({
                  item: "",
                  description: "",
                  unit_cost: "",
                  quantity: "",
                  total: "",
                })
              }
              style={{
                flexDirection: "row",
                backgroundColor: "#EEE",
                padding: 10,
                width: 110,
                borderRadius: 4,
                marginTop: 10,
              }}
            >
              <Ionicons
                name="add-circle-sharp"
                size={22}
                color="black"
                style={{ marginRight: "2%" }}
              />
              <Text style={{ paddingTop: 3 }}>Add Item</Text>
            </TouchableOpacity>
          </View>

          <View style={{flexDirection:'row', marginTop: 15 }}>
                   <View style={styles.child1}>
                <Text style={{ ...styles.label}}>{"Discount"}</Text>
               </View>
               <View style={styles.child2}>
          <Input
            // label="Discount Percentage"
            type="text"
            onChangeText={(value) => setDiscount(value)}
            value={"" + discount}
            mt={15}
          />
          <Text style={{ fontSize: 10 }}>
            {"if no discount then please leave empty or put 0"}
          </Text>
          </View>
          </View>

          {/* <Controller
            name="subTotal"
            control={control}
            rules={{
              required: { value: true, message: "Sub Total is required" },
            }}
            render={({ field: { onChange, onBlur, value } }) => ( */}
          <View style={{flexDirection:'row', marginTop: 15 }}>
                   <View style={styles.child1}>
                <Text style={{ ...styles.label}}>{"Sub Total"}</Text>
               </View>
               <View style={styles.child2}>
          <Input
            noedit
            // label="Sub Total"
            type="text"
            onChangeText={(value) => setSubTotal(value)}
            value={"" + subTotal}
            error={errors.subTotal}
            errorText={errors?.subTotal?.message}
            mt={15}
          />
          </View>
        </View>
          {/* )}
            defaultValue={subTotal}
          />

          <Controller
            name="grandTotal"
            control={control}
            rules={{
              required: { value: true, message: "Grand Total is required" },
            }}
            render={({ field: { onChange, onBlur, value } }) => ( */}
         <View style={{flexDirection:'row', marginTop: 15 }}>
                   <View style={styles.child1}>
                <Text style={{ ...styles.label}}>{"Grand Total"}</Text>
               </View>
               <View style={styles.child2}>
          <Input
            noedit
            // label="Grand Total"
            type="text"
            onChangeText={(value) => setTotal(value)}
            value={"" + total}
            error={errors.grandTotal}
            errorText={errors?.grandTotal?.message}
          />
          </View>
          </View>
          {/* )}
            defaultValue={total}
          /> */}
<View style={{flexDirection:'row', marginTop: 15 }}>
                   <View style={styles.child1}>
                <Text style={{ ...styles.label}}>{"Notes"}</Text>
               </View>
               <View style={styles.child2}>
          <Controller
            name="notes"
            control={control}
            rules={{
              required: { value: true, message: "Notes is required" },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                // label="Notes"
                type="text"
                onChangeText={(value) => onChange(value)}
                value={value}
                error={errors.notes}
                errorText={errors?.notes?.message}
              />
            )}
            defaultValue=""
          />
          </View>
          </View>

          <Button
            title="Add Invoice"
            type="solid"
            //   loading={isLoding}
            onPress={handleSubmit(onSubmit)}
            buttonStyle={{
              padding: Sizes.fixPadding,
              width: "100%",
              backgroundColor: Colors.primaryColor,
              borderRadius: 22,
              marginBottom: 20,
            }}
            containerStyle={{
              width: "100%",
              // marginHorizontal: 50,
              marginVertical: 20,
            }}
            titleStyle={{
              fontSize: 14,
              fontWeight: "100",
              color: Colors.whiteColor,
            }}
            icon={
              <Ionicons
                name="add-circle-sharp"
                size={24}
                color="#ffffff"
                style={{ marginRight: "2%" }}
              />
            }
          />
        </ScrollView>
      </View>

      <Dialog isVisible={show}>
        <Dialog.Title title="Error!" />
        <Text>{errorMessage}</Text>

        <Dialog.Actions>
          <Dialog.Button
            title="Ok"
            onPress={() => {
              setShow(!show);
            }}
          />
        </Dialog.Actions>
      </Dialog>

      <Dialog isVisible={showSuccess}>
        <Dialog.Title title="Congratulations!" />
        <Text>{"You have Scuccessfully Booked your Order"}</Text>

        <Dialog.Actions>
          <Dialog.Button
            title="Ok"
            onPress={() => {
              setShowScuccess(!showSuccess);
              navigation.navigation.navigate("Invoices");
            }}
          />
        </Dialog.Actions>
      </Dialog>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: Colors.primaryColor,
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 15,
    marginTop: 40,
  },
  contentContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 10,
  },
  label: {
    color: "black",
    marginLeft: 0,
    marginBottom: 3,
    fontSize: windowWidth > 600 ? 18 : 16,
    fontWeight: "500",
    marginTop: windowWidth > 600 ? 0 : 12,
  },
  child1: {
    width: windowWidth > 600 ? "15%" : "30%",
  },
  child2: {
    width: windowWidth > 600 ? "85%" : "70%",
  },
});
export default CreateInvoice;
