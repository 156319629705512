import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  Alert,
  Platform,
  Dimensions,
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import { Button, Dialog } from "react-native-elements";
import { Entypo, Ionicons, MaterialIcons } from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import SelectDropdown from "react-native-select-dropdown";
import Loading from "../Utils/Loading";
// import DateTimePicker from "@react-native-community/datetimepicker";
import moment from "moment";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import SectionedMultiSelect from "react-native-sectioned-multi-select";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Sign from "./Signature";
import CustomDatePicker from "../../components/datePicker/datePicker";
import CustomPicker from "../../components/Picker/Picker";

const windowWidth = Dimensions.get("window").width;

const CreateTimesheet = (navigation) => {
  const [isLoding, setIsLoading] = useState(false);
  const [currentBookingID, setCurrentBookingID] = useState(null);

  const [clientsData, setClientsData] = useState([]);
  const [clientsList, setClientsList] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [userId, setUserId] = useState(null);

  const [contactID, setContactID] = useState(null);

  const [userData, setUserData] = useState([]);
  const [userDataList, setUserDataList] = useState([]);

  const [bookingDetail, setBookingDetail] = useState(null);

  const [selectedStatus, setSelectedStatus] = useState("Approved");

  const [sign, setSign] = useState(null);

  const [date, setDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showDate, setShowDate] = useState(false);

  const [showSuccess, setShowScuccess] = useState(false);
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Its Error");

  const statusData = [
    { value: "Approved", label: "Approved" },
    { value: "Assigned Contact", label: "Assigned Contact" },
    { value: "Disputed", label: "Disputed" },
  ];

  useEffect(() => {
    async function fetchBookingID() {
      const ID = await AsyncStorage.getItem("current_Emp_bookingID");
      if (ID != null) {
        setCurrentBookingID(parseInt(ID));
      }
    }

    if (global.userData.role_names[0] === "Client") {
      ClockInDetail();
      setUserId(global.userData.id);
      GetUserContacts(global.userData.id);
    }

    fetchBookingID();
    GetClients();
  }, [navigation]);

  const GetClients = () => {
    setIsLoading(true);

    jwt
      .get(`${Url.GET_ACTIVE_CLIENT}/${global.systemID}`)
      .then((response) => {
        // console.log(response)
        setIsLoading(false);
        const NewData = [];

        response.data.clients.map((item) => {
          NewData.push({
            value: item.id,
            label: `${item.organization_name}`,
            ...item,
          });
        });

        setClientsList(NewData);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  // console.log(clientsList);
  const handleSelectedClient = (val, index) => {
    if (parseInt(val) !== 0) {
      setSelectedClient(val);
      ClockInDetail();
      setUserId(clientsList[index - 1].user_id);
      GetUserContacts(clientsList[index - 1].user_id);
    } else {
      setSelectedClient(0);
    }
    // GetUserShifts(clientsList[index].user_id);
  };

  const GetUserContacts = (id) => {
    setIsLoading(true);
    jwt
      .get(`${Url.GET_CONTACTS}/${id}`)
      .then((response) => {
        // console.log(response.data);
        setIsLoading(false);
        // setUserData(response.data.user_contacts);

        const newArray = [];

        response.data.user_contacts.map((item) =>
          newArray.push({
            value: item.id,
            label: item.name,
            ...item,
          })
        );

        setUserData([...newArray, { value: "other", label: "Other" }]);

        if (response.data.user_contacts.length > 0) {
          setContactID(userData[0].id);
        } else {
          setContactID("other");
        }
        // setUserAddress(response.data.user_addresses);
        // const List = [];
        // response.data.user_addresses.map((item) => List.push(item.address));
        // setAddressList(List);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const ClockInDetail = () => {
    setIsLoading(true);
    jwt
      .get(`${Url.ALL_CLOCKIN_DETAILS}/${currentBookingID}`)
      .then((response) => {
        setBookingDetail(response.data["booking-details"]);
        // setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleStatus = (selectedItems) => {
    setSelectedStatus(selectedItems);
  };

  const handleSelectedContact = (selectedItem, index) => {
    if (parseInt(selectedItem) !== 0) {
      setContactID(selectedItem);
    } else {
      setContactID(0);
    }
  };

  const onChangeDate = (event, selectedDate) => {
    const currentDate = selectedDate;
    setSelectedDate(currentDate);
    setShowDate(false);
  };

  useEffect(() => {
    let defaultValues = {};

    if (bookingDetail != null) {
      defaultValues.working_hours = bookingDetail.total_hours_worked;
      defaultValues.break_hours = bookingDetail.total_hours_break;
      defaultValues.clockin_at = bookingDetail.clockin;
      defaultValues.clockout_at = bookingDetail.clockout;
    } else {
      defaultValues.working_hours = "";
      defaultValues.break_hours = "";
      defaultValues.clockin_at = "";
      defaultValues.clockout_at = "";
    }
    if (userData.filter((item) => item.id === contactID).length > 0) {
      defaultValues.contact_name = userData.filter(
        (item) => item.id === contactID
      )[0].name;
      defaultValues.contact_email = userData.filter(
        (item) => item.id === contactID
      )[0].email;
      defaultValues.job_role = userData.filter(
        (item) => item.id === contactID
      )[0].job_role;
    } else {
      let defaultValues = {};
      defaultValues.contact_name = "";
      defaultValues.contact_email = "";
      defaultValues.job_role = "";
    }
    reset({ ...defaultValues });
  }, [contactID, bookingDetail]);

  const handleSignature = (sig) => {
    setSign(sig);
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    trigger,
    // setValue,
    formState: { errors },
  } = useForm({});

  const { fields, append, remove } = useFieldArray({
    control,
    name: "test",
  });

  const onSubmit = (data) => {
    const dataNew = {
      employee_booking_id: currentBookingID,
      contact_id: contactID,
      ref_user_id: userId,
      contact_name: data.contact_name,
      contact_email: data.contact_email,
      job_role: data.job_role,
      reason: selectedStatus === "Dispute" ? data.reason : "",
      status: selectedStatus,
      signature: sign,
      date:
        Platform.OS === "android" || Platform.OS === "ios"
          ? `${moment(selectedDate).format("YYYY-MM-DD")}`
          : data.date,
      total_working_hours: data.working_hours,
      total_break_hours: data.break_hours,
      clockin_at: data.clockin_at,
      clockout_at: data.clockin_at,
    };

    if (userId === null) {
      setErrorMessage("Please Select Client");
      setShow(true);
    } else if (selectedDate === null) {
      setErrorMessage("Please Select Date");
      setShow(true);
    } else if (selectedStatus === null) {
      setErrorMessage("Please Select Status");
      setShow(true);
    } else if (sign === null) {
      setErrorMessage("Please Do Signature");
      setShow(true);
    } else {
      genrateTimesheet(dataNew);
    }
  };

  const genrateTimesheet = (dataNew) => {
    setIsLoading(true);
    jwt
      .post(
        `${Url.AUTHENTICATE_BOOKING_TIMESHEET}/${currentBookingID}`,
        dataNew
      )
      .then((response) => {
        setShowScuccess(true);
        setIsLoading(false);
      })
      .catch((err) => {
        Alert.alert("Something Went Wrong!");
        setIsLoading(false);
      });
  };

  return (
    <View style={styles.container}>
      {header(navigation)}
      <ScrollView
        style={{ flex: 1, backgroundColor: "#F2F3F4", paddingBottom: 20 }}
      >
        <View
          style={{
            flex: 1,
            paddingHorizontal: Sizes.fixPadding * 2,
          }}
        >
          <View
            style={{
              backgroundColor: "#ffffff",
              marginTop: 20,
              borderRadius: 10,
              padding: Sizes.fixPadding * 1.5,
            }}
          >
            {global.userData.role_names[0] !== "Client" && (
              <View style={{ flexDirection: "row" }}>
                <View style={styles.child1}>
                  <Text style={{ ...styles.label }}>{"Select Client"}</Text>
                </View>
                <View style={styles.child2}>
                  <CustomPicker
                    data={clientsList}
                    selectedValue={selectedClient}
                    handleSelectedValue={handleSelectedClient}
                    defaultLabel={"Please select a client"}
                    style={{}}
                  />
                </View>
              </View>
            )}

            <View style={{ flexDirection: "row", marginTop: 15 }}>
              <View style={styles.child1}>
                <Text style={{ ...styles.label }}>{"Contacts"}</Text>
              </View>
              <View style={styles.child2}>
                <CustomPicker
                  data={userData}
                  selectedValue={contactID}
                  handleSelectedValue={handleSelectedContact}
                  defaultLabel={"Please select Contact"}
                  style={{}}
                />
              </View>
            </View>

            <View
              style={{ flexDirection: "row", marginBottom: 15, marginTop: 15 }}
            >
              <View style={styles.child1}>
                <Text style={styles.label}>Invoice Date</Text>
              </View>
              <View style={{ ...styles.child2 }}>
                <CustomDatePicker
                  date={onChangeDate}
                  defaultDate={selectedDate}
                  show={showDate}
                  setShow={setShowDate}
                />
              </View>
            </View>

            <View style={{ flexDirection: "row", marginTop: 15 }}>
              <View style={styles.child1}>
                <Text style={styles.label}>Contact Name</Text>
              </View>
              <View style={{ ...styles.child2 }}>
                <Controller
                  name="contact_name"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Contact name is required",
                    },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      // label=""
                      onChangeText={(value) => onChange(value)}
                      value={value}
                      error={errors.contact_name}
                      errorText={errors?.contact_name?.message}
                    />
                  )}
                  defaultValue=""
                />
              </View>
            </View>

            <View style={{ flexDirection: "row", marginTop: 15 }}>
              <View style={styles.child1}>
                <Text style={styles.label}>Contact Email</Text>
              </View>
              <View style={{ ...styles.child2 }}>
                <Controller
                  name="contact_email"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Contact email is required",
                    },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      // label=""
                      onChangeText={(value) => onChange(value)}
                      value={value}
                      error={errors.contact_email}
                      errorText={errors?.contact_email?.message}
                    />
                  )}
                  defaultValue=""
                />
              </View>
            </View>

            <View style={{ flexDirection: "row", marginTop: 15 }}>
              <View style={styles.child1}>
                <Text style={styles.label}>Job Role</Text>
              </View>
              <View style={{ ...styles.child2 }}>
                <Controller
                  name="job_role"
                  control={control}
                  rules={{
                    required: { value: true, message: "Job role is required" },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      // label=""
                      onChangeText={(value) => onChange(value)}
                      value={value}
                      error={errors.job_role}
                      errorText={errors?.job_role?.message}
                    />
                  )}
                  defaultValue=""
                />
              </View>
            </View>

            <View style={{ flexDirection: "row", marginTop: 15 }}>
              <View style={styles.child1}>
                <Text style={styles.label}>Working hours</Text>
              </View>
              <View style={{ ...styles.child2 }}>
                <Controller
                  name="working_hours"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Total working hours is required",
                    },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      noedit
                      // label=""
                      onChangeText={(value) => onChange(value)}
                      value={value}
                      error={errors.working_hours}
                      errorText={errors?.working_hours?.message}
                    />
                  )}
                  defaultValue=""
                />
              </View>
            </View>

            <View style={{ flexDirection: "row", marginTop: 15 }}>
              <View style={styles.child1}>
                <Text style={styles.label}>Break hours</Text>
              </View>
              <View style={{ ...styles.child2 }}>
                <Controller
                  name="break_hours"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Total break hours is required",
                    },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      noedit
                      // label=""
                      onChangeText={(value) => onChange(value)}
                      value={value}
                      error={errors.break_hours}
                      errorText={errors?.break_hours?.message}
                    />
                  )}
                  defaultValue=""
                />
              </View>
            </View>

            <View style={{ flexDirection: "row", marginTop: 15 }}>
              <View style={styles.child1}>
                <Text style={styles.label}>Clockin at</Text>
              </View>
              <View style={{ ...styles.child2 }}>
                <Controller
                  name="clockin_at"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Clockin at is required",
                    },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      noedit
                      // label=""
                      onChangeText={(value) => onChange(value)}
                      value={value}
                      error={errors.clockin_at}
                      errorText={errors?.clockin_at?.message}
                    />
                  )}
                  defaultValue=""
                />
              </View>
            </View>

            <View style={{ flexDirection: "row", marginTop: 15 }}>
              <View style={styles.child1}>
                <Text style={styles.label}>Clockout at</Text>
              </View>
              <View style={{ ...styles.child2 }}>
                <Controller
                  name="clockout_at"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Clockout at is required",
                    },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      noedit
                      // label=""
                      onChangeText={(value) => onChange(value)}
                      value={value}
                      error={errors.clockout_at}
                      errorText={errors?.clockout_at?.message}
                    />
                  )}
                  defaultValue=""
                />
              </View>
            </View>

            <View style={{flexDirection:'row', marginTop:15}}>
                   <View style={styles.child1}>
                <Text style={{ ...styles.label}}>{"Status"}</Text>
               </View>
               <View style={styles.child2}>
                <CustomPicker
                  data={statusData}
                  selectedValue={selectedStatus}
                  handleSelectedValue={(val)=>{setSelectedStatus(val)}}
                  // defaultLabel={"Select Status"}
                  style={{}}
                />
</View>
</View>

            {selectedStatus === "Disputed" && (
                 <View style={{flexDirection:'row', marginTop:15}}>
                 <View style={styles.child1}>
              <Text style={{ ...styles.label}}>{"Reason"}</Text>
             </View>
             <View style={styles.child2}>
              <Controller
                name="reason"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "reason is required",
                  },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    // label="Reason of dispute"
                    onChangeText={(value) => onChange(value)}
                    value={value}
                    error={errors.reason}
                    errorText={errors?.reason?.message}
                  />
                )}
                defaultValue=""
              />
              </View>
</View>
            )}

            <Sign onOkSign={handleSignature} />

            <Button
              title="Submit"
              type="solid"
              loading={isLoding}
              onPress={handleSubmit(onSubmit)}
              buttonStyle={{
                padding: Sizes.fixPadding * 1,
                width: "100%",
                backgroundColor: Colors.primaryColor,
                borderRadius: 22,
              }}
              containerStyle={{
                width: "100%",
                // marginHorizontal: 50,
                marginVertical: 30,
              }}
              titleStyle={{
                fontSize: 14,
                fontWeight: "100",
                color: Colors.whiteColor,
              }}
              icon={
                <Ionicons
                  name="add-circle-sharp"
                  size={24}
                  color="#ffffff"
                  style={{ marginRight: "2%" }}
                />
              }
            />
          </View>
          {isLoding && <Loading />}
        </View>
      </ScrollView>

      <Dialog isVisible={show}>
        <Dialog.Title title="Error!" />
        <Text>{errorMessage}</Text>

        <Dialog.Actions>
          <Dialog.Button
            title="Ok"
            onPress={() => {
              setShow(!show);
            }}
          />
        </Dialog.Actions>
      </Dialog>

      <Dialog isVisible={showSuccess}>
        <Dialog.Title title="Congratulations!" />
        <Text>{"You have Scuccessfully Submit Timesheet"}</Text>

        <Dialog.Actions>
          <Dialog.Button
            title="Ok"
            onPress={() => {
              setShowScuccess(!showSuccess);
              navigation.navigation.navigate("Dashboard");
            }}
          />
        </Dialog.Actions>
      </Dialog>
      {/* 
      {showDate && (
        <DateTimePicker
          testID="dateTimePicker"
          value={date}
          mode={"date"}
          onChange={onChangeDate}
        />
      )} */}
    </View>
  );
};

const header = (navigation) => {
  return (
    <View style={HeaderStyle}>
      <Text
        style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
      >
        TimeSheet
      </Text>
      <Ionicons
        name="menu-outline"
        size={30}
        color={Colors.whiteColor}
        style={{
          position: "absolute",
          left: 20.0,
          // fontWeight: 300
        }}
        onPress={() => navigation.navigation.toggleDrawer()}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: Colors.primaryColor,
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 15,
    marginTop: 40,
  },
  label: {
    color: "black",
    marginLeft: 0,
    marginBottom: 3,
    fontSize: windowWidth > 600 ? 18 : 16,
    fontWeight: "500",
    marginTop: windowWidth > 600 ? 0 : 12,
  },
  child1: {
    width: windowWidth > 600 ? "15%" : "30%",
  },
  child2: {
    width: windowWidth > 600 ? "85%" : "70%",
  },
});
export default CreateTimesheet;
