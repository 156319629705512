/* eslint-disable consistent-this */
import React, { useState, useEffect, useCallback } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Image,
  TextInput,
  TouchableOpacity,
  Icon,
  RefreshControl,
  Alert,
  Platform,
  Dimensions
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import { Button, Overlay, Dialog, Badge } from "react-native-elements";
import { SafeAreaProvider } from "react-native-safe-area-context";
import {
  Fontisto,
  FontAwesome5,
  FontAwesome,
  MaterialCommunityIcons,
  Ionicons,
  MaterialIcons,
} from "@expo/vector-icons";
import Clock from "../Clock";
import BookingClock from "../BookingClock";
import CurrentApprovedBookings from "../widgets/CurrentApprovedBookings";
import TotalProjects from "../widgets/TotalProjects";
import CleanedProjects from "../widgets/CleanedProjects";
import PendingProjects from "../widgets/PendingProjects";
import BookedProjects from "../widgets/BookedProjects";
import * as SecureStore from "expo-secure-store";
import Constants from "expo-constants";
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import { ProgressBar } from "react-native-paper";
import Loading from "../Utils/Loading";
import { ModulesPermission } from "../../constants/modules";
import AuthorizedFor from "../../constants/authorizedFor";
import { authorizedForFlateList } from "../../constants/authorizedForFlateList";
import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment";
import WebDashboard from "./WebDashboard";
import StaffApprovedBookings from "../widgets/StaffApprovedBookings";
import { StatusBar } from "expo-status-bar";

const windowWidth = Dimensions.get("window").width;
Notifications.setNotificationHandler({
  handleNotification: async () => {
    return {
      shouldShowAlert: true,
      shouldPlaySound: true,
    };
  },
});

const Dashboard = (navigation) => {
  Notifications.addNotificationResponseReceivedListener((response) => {
    global.getnotificationExist = true;
    global.notification = response.notification.request.content;
    const item = response.notification;
    navigation.navigation.navigate("NotificationDetail", { item });
  });

  Notifications.addNotificationReceivedListener((notification) => {
    global.notification = notification.request.content;
  });

  const [bookingData, setBookingData] = useState([]);
  const [cleanedProjects, setCleanedProjects] = useState(0);
  const [clients, setClients] = useState(0);
  const [employees, setEmployees] = useState(0);
  const [policies, setPolicies] = useState(0);
  const [documents, setDocuments] = useState(0);
  const [isLoding, setIsLoading] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showNotificationLength, setShowNotificationLength] = useState(0);
  const [isVisible1, setIsVisible1] = useState(false);
  const [currentBookingID, setCurrentBookingID] = useState(null);
  const [bookingID, setBookingID] = useState(null);
  const [refer, setRefr] = useState(false);

  const [currentTime, setCurrentTime] = useState(
    moment(moment.utc(new Date())).format("hh:mm:ss")
  );

  const [staffApprovedBooking, setStaffApprovedBooking] = useState(0);
  const [staffPendingBooking, setStaffPendingBooking] = useState(0);

  // console.log(navigation.navigation.route.params.notifyBack)

  useEffect(() => {
    GetNotificationData();
  }, [navigation.navigation.route && navigation.navigation.route.params.notifyBack]);

  const GetNotificationData = () => {
    setIsLoading(true);
    jwt
      .get(Url.GET_USER_NOTIFICATIONS)
      .then((response) => {
      setShowNotification(response.data.user_notifications.filter((item) => item.read_at === null).length);
      setShowNotificationLength(response.data.user_notifications.filter((item) => item.read_at === null).length);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const unsubscribe = navigation.navigation.addListener("focus", () => {
      setRefr(true);
    });
    return unsubscribe;
  }, [navigation]);

  useEffect(() => {
    (async () => {
      getValueFor("expoToken");
    })();
  }, []);

  const getValueFor = async (key) => {
    if (Platform.OS === "android" || Platform.OS === "ios") {
      let response = await SecureStore.getItemAsync(key);
      if (!response || response == null) {
        setIsVisible1(true);
      }
    }
  };

  const registerForPushNotificationsAsync = async () => {
    let token;
    if (Device.isDevice) {
      const { status: existingStatus } =
        await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;

      if (existingStatus !== "granted") {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== "granted") {
        alert("Failed to get push token for push notification!");
        return;
      }
      token = (await Notifications.getExpoPushTokenAsync()).data;
      var params = {
        expo_token: token,
        system_id: parseInt(global.systemID),
        os: Device.osName,
        device_name: Device.deviceName,
        device_id: Device.modelName,
        os_version: Device.osVersion,
      };

      jwt
        .post(Url.SUBSCRIBE_NOTIFICATION, params)
        .then((response) => {
          SecureStore.setItemAsync("expoToken", token);
        })
        .catch((err) => {
          Alert.alert("Something went Wrong with notification please Refresh");
        });
    } else {
      Alert.alert("Must use physical device for Push Notifications");
    }

    if (Platform.OS === "android") {
      Notifications.setNotificationChannelAsync("default", {
        name: "default",
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: "#FF231F7C",
      });
    }

    return token;
  };

  useEffect(() => {
    (async () => {
      (await authorizedForFlateList(
        ModulesPermission.Get_Service_Staff_Approved_Bookings_By_ID
      ))
        ? (GetStaffApprovedBooking(), GetStaffPendingBooking())
        : (GetBookings(), GetClients(), GetEmployees());
    })();
  }, [navigation]);

  const GetBookings = () => {
    setIsLoading(true);
    jwt
      .get(`${Url.GET_BOOKING}/${global.systemID}`)
      .then((response) => {
        // console.log(response)
        setIsLoading(false);
        setBookingData(response.data.bookings);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetClients = () => {
    jwt
      .get(`${Url.GET_ACTIVE_CLIENT}/${global.systemID}`)
      .then((response) => {
        setIsLoading(false);
        setClients(response.data.clients.length);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetEmployees = () => {
    jwt
      .get(`${Url.GET_EMPLOYEES}/${global.systemID}`)
      .then((response) => {
        setEmployees(response.data.employees.length);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetStaffApprovedBooking = () => {
    jwt
      .get(`${Url.GET_APPROVED_BOOKING}/${global.userData.id}`)
      .then((response) => {
        // console.log(response)
        setStaffApprovedBooking(response.data["pending-bookings"].length);
      })
      .catch((err) => {});
  };

  const GetStaffPendingBooking = () => {
    jwt
      .get(`${Url.GET_PENDING_BOOKING}/${global.userData.id}`)
      .then((response) => {
        setStaffPendingBooking(response.data["pending-bookings"].length);
      })
      .catch((err) => {});
  };

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "bold", fontSize: 20 }}
        >
          Dashboard
        </Text>
        <Ionicons
          name="menu-outline"
          size={30}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 20.0,
            // fontWeight: 300
          }}
          onPress={() => navigation.navigation.toggleDrawer()}
        />
        {AuthorizedFor(ModulesPermission.Get_All_User_Notification) && (
         showNotification ?
         <View style={{
          position: "absolute",
          right: 20.0,
          // fontWeight: 300
        }}>
         <MaterialCommunityIcons
            name="bell-badge-outline"
            size={30}
            color={Colors.whiteColor}
            style={{
              // position: "absolute",
              // right: 20.0,
              // fontWeight: 300
            }}
            onPress={() => navigation.navigation.navigate("NotificationScreen")}
          />
           <Badge
           value={showNotificationLength}
    status="warning"
    containerStyle={{ position: 'absolute', top: -6, right: -4 }}
  />
          </View>
         :
         <MaterialCommunityIcons
            name="bell-outline"
            size={30}
            color={Colors.whiteColor}
            style={{
              position: "absolute",
              right: 20.0,
              // fontWeight: 300
            }}
            onPress={() => navigation.navigation.navigate("NotificationScreen")}
          />
        )}
      </View>
    );
  };

  const onClickBooking = (id) => {
    setBookingID(id);
  };

  const onCurrentBookingID = (id) => {
    // console.log(id)
    // console.log("onClickBooking")
    setCurrentBookingID(id);
  };

  return (
    <View style={styles.container}>
   
      {header()}
   <ScrollView style={{backgroundColor: "#F2F3F4"}}>
      {Platform.OS === "android" || Platform.OS === "ios" ? (
        <View style={{ flex: 1, backgroundColor: "#F2F3F4" }}>
          <View
            style={{
              flex: 1,
              paddingHorizontal: Sizes.fixPadding * 2,
            }}
          >
            <View
              style={{
                // width: "92%",
                // height: 440,
                backgroundColor: "#ffffff",
                marginTop: 20,
                borderRadius: 10,
                padding: Sizes.fixPadding,
              }}
            >
              {AuthorizedFor(ModulesPermission.User_ClockIn_By_ID) && (
                <Clock navigation={navigation} />
              )}
              {bookingID != null ? (
                <BookingClock navigation={navigation} bookingID={bookingID} currentBookingID={currentBookingID} />
              ) : null}
              <TotalProjects projects={bookingData.length} />
              {AuthorizedFor(
                ModulesPermission.Get_Service_Staff_Approved_Bookings_By_ID
              ) ? (
                <CleanedProjects
                  navigation={navigation}
                  projects={staffApprovedBooking}
                />
              ) : (
                <CleanedProjects
                  navigation={navigation}
                  projects={cleanedProjects}
                />
              )}

              {AuthorizedFor(ModulesPermission.Get_Pending_Staff_Bookings) ? (
                <PendingProjects
                  navigation={navigation}
                  projects={staffPendingBooking}
                />
              ) : (
                <PendingProjects
                  navigation={navigation}
                  projects={
                    bookingData.filter(
                      (book) => book.booking_status === "Created"
                    ).length
                  }
                />
              )}
            </View>

            <View
              style={{
                width: "100%",
                alignItems: "center",
                backgroundColor: "#ffffff",
                marginTop: 15,
                marginBottom: 8,
                borderRadius: 10,
                padding: Sizes.fixPadding,
              }}
            >
              {refer && (
                <CurrentApprovedBookings
                  currentTime={currentTime}
                  onClickBooking={onClickBooking}
                  onCurrentBookingID={onCurrentBookingID}
                />
              )}
            </View>

            <View
              style={{
                width: "100%",
                alignItems: "center",
                backgroundColor: "#ffffff",
                marginTop: 15,
                marginBottom: 8,
                borderRadius: 10,
                padding: Sizes.fixPadding,
              }}
            >
              <View style={{ flexDirection: "row" }}>
                {AuthorizedFor(ModulesPermission.Get_All_Active_Clients_In_System) && (
                  <TouchableOpacity
                    style={{ width: "49%", marginRight: "1%" }}
                    onPress={() => navigation.navigate("Clients")}
                  >
                    <View
                      style={{
                        width: "100%",
                        padding: Sizes.fixPadding,
                        paddingTop: Sizes.fixPadding * 2,
                        minHeight: 130,
                        backgroundColor: "#78909c",
                        borderRadius: 10,
                      }}
                    >
                      <FontAwesome
                        name="users"
                        color="#ffffff"
                        size={35}
                        style={{ alignSelf: "center" }}
                      />

                      <Text
                        style={{
                          alignSelf: "center",
                          fontSize: 18,
                          color: "#ffffff",
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        {clients}
                      </Text>
                      <Text
                        style={{
                          alignSelf: "center",
                          fontSize: 14,
                          color: "#ffffff",
                        }}
                      >
                        TOTAL CLIENTS
                      </Text>
                    </View>
                  </TouchableOpacity>
                )}

                {AuthorizedFor(ModulesPermission.Get_Total_Employee_widget) && (
                  <TouchableOpacity
                    style={{ width: "49%", marginLeft: "1%" }}
                    onPress={() => this.props.navigation.navigate("Employees")}
                  >
                    <View
                      style={{
                        width: "100%",
                        padding: Sizes.fixPadding,
                        backgroundColor: "#F7AA47",
                        paddingTop: Sizes.fixPadding * 2,
                        minHeight: 130,
                        borderRadius: 10,
                      }}
                    >
                      <Ionicons
                        name="person"
                        color="#ffffff"
                        size={35}
                        style={{ alignSelf: "center" }}
                      />

                      <Text
                        style={{
                          alignSelf: "center",
                          fontSize: 18,
                          color: "#ffffff",
                          marginTop: 8,
                          fontWeight: "bold",
                        }}
                      >
                        {employees}
                      </Text>
                      <Text
                        style={{
                          alignSelf: "center",
                          fontSize: 14,
                          color: "#ffffff",
                        }}
                      >
                        EMPLOYEES
                      </Text>
                    </View>
                  </TouchableOpacity>
                )}
              </View>

              <View style={{ flexDirection: "row", marginTop: 10 }}>

              <View style={{position:'relative', width: "49%", marginRight: "1%", marginBottom: 10,}}>
                <TouchableOpacity
            onPress={() => {}}
            style={{
              width:'100%',
                backgroundColor: "#ff6264",
               borderRadius:8
             }
            }
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                alignSelf: "center",
                padding: Sizes.fixPadding * 2,
                marginTop: 20,

                borderBottomColor: "#fff",
                borderBottomWidth: 0.5,
              }}
            >
              <View
                style={{
                  height: 50,
                  width: 50,
                  padding: 10,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 100,
                  backgroundColor: "#ff6264",
                }}
              >
                <FontAwesome5
                  name="file"
                  color="#ffffff"
                  size={28}
                />
                {/* <Feather name="file-text" color="#ffffff" size={25} /> */}
              </View>

              <View style={{ marginLeft: "8%" }}>
                <Text style={{ fontSize: 18, color: "#fff" }}>8</Text>
                <Text style={{ fontSize: 16, color: "#fff" }}>Company policies</Text>
              </View>
            </View>
            <View style={{ padding: Sizes.fixPadding }}>
              <Text style={{ fontSize: 12, color: "#FFF", opacity: 0.5 }}>
                Result
              </Text>
              <Text style={{ fontSize: 14, color: "#FFF" }}>0% Increase</Text>
            </View>
          </TouchableOpacity>
          <View style={styles.overlay} >
            <Text style={{color:"#FFF", fontSize:20, fontWeight:'700'}}>Coming Soon</Text>
          </View>
              </View>

              
              <View style={{position:'relative', width: "49%", marginLeft: "1%", marginBottom: 10,}}>
                <TouchableOpacity
            onPress={() => {}}
            style={{
              width:'100%',
                backgroundColor: "#ff6264",
               borderRadius:8
             }
            }
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                alignSelf: "center",
                padding: Sizes.fixPadding * 2,
                marginTop: 20,

                borderBottomColor: "#fff",
                borderBottomWidth: 0.5,
              }}
            >
              <View
                style={{
                  height: 50,
                  width: 50,
                  padding: 10,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 100,
                  backgroundColor: "#ff6264",
                }}
              >
                <FontAwesome5
                  name="file"
                  color="#ffffff"
                  size={28}
                />
                {/* <Feather name="file-text" color="#ffffff" size={25} /> */}
              </View>

              <View style={{ marginLeft: "8%" }}>
                <Text style={{ fontSize: 18, color: "#fff" }}>8</Text>
                <Text style={{ fontSize: 16, color: "#fff" }}>Company policies</Text>
              </View>
            </View>
            <View style={{ padding: Sizes.fixPadding }}>
              <Text style={{ fontSize: 12, color: "#FFF", opacity: 0.5 }}>
                Result
              </Text>
              <Text style={{ fontSize: 14, color: "#FFF" }}>0% Increase</Text>
            </View>
          </TouchableOpacity>
          <View style={styles.overlay} >
            <Text style={{color:"#FFF", fontSize:20, fontWeight:'700'}}>Coming Soon</Text>
          </View>
              </View>

               
              </View>

              {AuthorizedFor(ModulesPermission.Create_Order) && (
                <View
                  style={{
                    height: 190,
                    width: "100%",
                    marginTop: 10,
                    backgroundColor: "#b39ddb",
                    borderRadius: 10,
                  }}
                >
                  <View style={{ flexDirection: "row" }}>
                    <MaterialIcons
                      name="description"
                      color="#ffffff"
                      size={65}
                      style={{ alignSelf: "center", marginTop: 18 }}
                    />
                    <View>
                      <Text
                        style={{
                          color: "#ffffff",
                          fontSize: 16,
                          marginTop: 26,
                        }}
                      >
                        ORDER &
                      </Text>
                      <Text style={{ color: "#ffffff", fontSize: 22 }}>
                        APPRAISALS
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      width: "80%",
                      height: 1,
                      backgroundColor: "#000000",
                      marginTop: 10,
                      alignSelf: "center",
                    }}
                  ></View>

                  <TouchableOpacity
                    onPress={() => navigation.navigation.navigate("OrderForm")}
                  >
                    <View
                      style={{
                        width: "80%",
                        justifyContent: "center",
                        height: 40,
                        backgroundColor: "#000000",
                        marginTop: 20,
                        borderRadius: 6,
                        alignSelf: "center",
                      }}
                    >
                      <Text style={{ color: "#ffffff", alignSelf: "center" }}>
                        NEW ORDER
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
              )}
            </View>

            <View
          style={{
           flexDirection: "row" , width: "100%" }
          }
        >
          <View style={{ width: "49%" ,
                marginRight: "1%",
                backgroundColor: "#00b19d",
                position:'relative', marginBottom: 20,
                borderRadius:8
              }
            } >
          <TouchableOpacity
            onPress={() => {}}
            style={{
                width:'100%',
                backgroundColor: "#00b19d",
                borderRadius:8
              }
            }
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                alignSelf: "center",
                padding: Sizes.fixPadding * 2,
                marginTop: 20,

                borderBottomColor: "#fff",
                borderBottomWidth: 0.5,
              }}
            >
              <View
                style={{
                  height: 50,
                  width: 50,
                  padding: 10,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 100,
                  backgroundColor: "#00b19d",
                }}
              >
                <FontAwesome5
                  name="file"
                  color="#ffffff"
                  size={28}
                />
                {/* <Feather name="file-text" color="#ffffff" size={25} /> */}
              </View>

              <View style={{ marginLeft: "8%" }}>
                <Text style={{ fontSize: 18, color: "#fff" }}>0</Text>
                <Text style={{ fontSize: 14, color: "#fff" }}>TEAM COMMUNICATIONS</Text>
              </View>
            </View>
            <View style={{ padding: Sizes.fixPadding }}>
              <Text style={{ fontSize: 12, color: "#FFF", opacity: 0.5 }}>
                Result
              </Text>
              <Text style={{ fontSize: 14, color: "#FFF" }}>0% Increase</Text>
            </View>
          </TouchableOpacity>
          <View style={styles.overlay} >
            <Text style={{color:"#FFF", fontSize:20, fontWeight:'700'}}>Coming Soon</Text>
          </View>
          </View>


          <View  style={{ width: "49%" ,
                marginLeft: "1%",
                position:'relative',
                marginBottom: 20,
                borderRadius:8
               
              }
            } >
          <TouchableOpacity
            onPress={() => {}}
            style={{width:'100%',backgroundColor: "#00b19d",borderRadius:8, minHeight:190}}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                alignSelf: "center",
                padding: Sizes.fixPadding * 2,
                marginTop: 20,

                borderBottomColor: "#fff",
                borderBottomWidth: 0.5,
              }}
            >
              <View
                style={{
                  height: 50,
                  width: 50,
                  padding: 10,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 100,
                  backgroundColor: "#00b19d",
                }}
              >
                <FontAwesome5
                  name="file"
                  color="#ffffff"
                  size={28}
                />
                {/* <Feather name="file-text" color="#ffffff" size={25} /> */}
              </View>

              <View style={{ marginLeft: "8%" }}>
                <Text style={{ fontSize: 18, color: "#fff" }}>0</Text>
                <Text style={{ fontSize: 16, color: "#fff" }}>ARCHIVED</Text>
              </View>
            </View>
            <View style={{ padding: Sizes.fixPadding }}>
              <Text style={{ fontSize: 12, color: "#FFF", opacity: 0.5 }}>
                Result
              </Text>
              <Text style={{ fontSize: 14, color: "#FFF" }}>0% Increase</Text>
            </View>
          </TouchableOpacity>
          <View style={styles.overlay} >
            <Text style={{color:"#FFF", fontSize:20, fontWeight:'700'}}>Coming Soon</Text>
          </View>
          </View>

          </View>

          </View>

          <Dialog isVisible={isVisible1}>
            <Dialog.Title title="Allow Notifications!" />
            <Text>if you want notifications press allow!</Text>

            <Dialog.Actions>
              <Dialog.Button
                title="Allow"
                onPress={() => {
                  setIsVisible1(!isVisible1);
                  registerForPushNotificationsAsync().then((token) => {
                    global.expo_token = token;
                  });
                }}
              />
              <Dialog.Button
                title="CANCEL"
                onPress={() => {
                  setIsVisible1(!isVisible1);
                }}
              />
            </Dialog.Actions>
          </Dialog>
          {isLoding && <Loading />}
        </View>
      ) : (
        <View style={{ flex: 1}} >
        <WebDashboard navigation={navigation} />
        </View>
      )}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor:Colors.primaryColor
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom:15,
    marginTop:40
  },
  label: {
    color: "#ffffff",
    fontSize: 16,
    marginTop: 20,
    // marginRight: "58%",
    marginBottom: 2,
  },
  overlay: {
    flex: 1,
    position: 'absolute',
    left: 0,
    top: 0,
    opacity: 0.7,
    backgroundColor: 'black',
    height:'100%',
    width: '100%',
    justifyContent:'center',
    alignItems:'center',
    alignContent:'center',
    borderRadius:8
  }
});

export default Dashboard;
