import React, { useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  FlatList,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  Button,
  Overlay,
  ListItem,
  SpeedDial,
  Dialog,
  SearchBar,
} from "react-native-elements";

import {
  Ionicons,
  Entypo,
  MaterialIcons,
  FontAwesome,
  Feather,
} from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import { useForm, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import SelectDropdown from "react-native-select-dropdown";
import Loading from "../Utils/Loading";
import UpdatePrice from "./UpdatePrice";
import AuthorizedFor from "../../constants/authorizedFor";
import { authorizedForFlateList } from "../../constants/authorizedForFlateList";
import { ModulesPermission } from "../../constants/modules";
import CustomPicker from "../../components/Picker/Picker";

const windowWidth = Dimensions.get("window").width;

const AllPrices = (navigation) => {
  const [priceDataInitial, setPriceDataIntial] = useState([]);
  const [priceData, setPriceData] = useState([]);
  const [cleaningData, setCleaningData] = useState([]);
  const [cleaningList, setCleaningList] = useState([]);
  const [roomData, setRoomData] = useState([]);
  const [roomList, setRoomList] = useState([]);
  const [propertyData, setPropertyData] = useState([]);
  const [propertyList, setPropertList] = useState([]);
  const [openDial, setOpenDial] = useState(false);
  const [isLoding, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [selectedCleaningType, setSelectedCleaningType] = useState(0);
  const [selectedRoomType, setSelectedRoomType] = useState(null);
  const [selectedPropertyType, setSelectedPropertyType] = useState(null);
  const [isdefault, setIsDefauult] = useState("0");
  const [isrecurring, setIsRecurring] = useState("0");
  const [selectedItem, setSelectedItem] = useState(null);
  const [deleteShow, setDeleteShow] = useState(false);
  const [isVisible1, setIsVisible1] = useState(false);
  const [userId, setUserId] = useState(0);
  const [selectedClient, setSelectedCleint] = useState(0);
  const [clientsData, setClientsData] = useState([]);
  const [clientsList, setClientsList] = useState([]);
  const [priceType, setPriceType] = useState("Room Based");
  const [search, setSearch] = useState("");
  const [isDeleteAble, setIsDeleteAble] = useState(false);
  const [isUpdateAble, setIsUpdateAble] = useState(false);

  const [showAppPrices, setShowAllPrices] = useState(true);
  const [showDefaultPrices, setShowDefaultPrices] = useState(false);

  useEffect(() => {
    (async () => {
      GetPrices();
      GetCleaningType();
      GetRoomType();
      GetPropertyType();
      GetClients();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      (await authorizedForFlateList(
        ModulesPermission.Update_Single_Order_Price
      )) && setIsUpdateAble(true);
      (await authorizedForFlateList(ModulesPermission.Delete_Order_Price)) &&
        setIsDeleteAble(true);
    })();
  }, [priceDataInitial.length]);

  const defaultValues = [
    { value: "0", label: "No" },
    { value: "1", label: "Yes" },
  ];

  const recurringValues = [
    { value: "0", label: "No" },
    { value: "1", label: "Yes" },
  ];

  const priceTypes = [
    { value: "Room Based", label: "Room Based" },
    { value: "Hourly", label: "Hourly" },
  ];

  const GetPrices = () => {
    setIsLoading(true);
    jwt
      .get(`${Url.GET_ORDER_PRICE}/${global.systemID}`)
      .then((response) => {
        setIsLoading(false);
        setPriceData(response.data.order_prices);
        setPriceDataIntial(response.data.order_prices);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetCleaningType = () => {
    jwt
      .get(`${Url.GET_ACTIVE_CLEANING_TYPE}/${global.systemID}`)
      .then((response) => {
        const newArr = [];
        // setCleaningData(response.data.cleaning_types);
        response.data.cleaning_types.map((item) =>
          newArr.push({ value: item.id, label: item.name, ...item })
        );
        setCleaningData(newArr);
      })
      .catch((err) => {});
  };

  const GetRoomType = () => {
    jwt
      .get(`${Url.GET_ACTIVE_ROOM_TYPE}/${global.systemID}`)
      .then((response) => {
        const newArr = [];
        // setRoomData(response.data.room_types);
        response.data.room_types.map((item) =>
          newArr.push({ value: item.id, label: item.name, ...item })
        );
        setRoomData(newArr);
      })
      .catch((err) => {});
  };

  const GetPropertyType = () => {
    jwt
      .get(`${Url.GET_ACTIVE_PROPERTY_TYPE}/${global.systemID}`)
      .then((response) => {
        const newArr = [];
        // setPropertyData(response.data.property_types);
        response.data.property_types.map((item) =>
          newArr.push({ value: item.id, label: item.name, ...item })
        );
        setPropertyData(newArr);
      })
      .catch((err) => {});
  };

  const GetClients = () => {
    setIsLoading(true);

    jwt
      .get(`${Url.GET_ACTIVE_CLIENT}/${global.systemID}`)
      .then((response) => {
        const newClients = [];
        setIsLoading(false);
        // setClientsList(response.data.clients);
        response.data.clients.map((item) =>
          newClients.push({
            value: item.id,
            label: `${item.organization_name}`,
            ...item,
          })
        );
        setClientsData(newClients);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleSelectedCleaning = (val, index) => {
    // setSystemError(false);
    if (parseInt(val) !== 0) {
      setSelectedCleaningType(val);
    } else {
      setSelectedCleaningType(null);
    }
  };

  const handleSelectedRoom = (val, index) => {
    // setSystemError(false);
    if (parseInt(val) !== 0) {
      setSelectedRoomType(val);
    } else {
      setSelectedRoomType(null);
    }
  };

  const handleSelectedProperty = (val, index) => {
    // setSystemError(false);
    if (parseInt(val) !== 0) {
      setSelectedPropertyType(val);
    } else {
      setSelectedPropertyType(null);
    }
  };

  const handleSelectedDefault = (selectedItem, index) => {
    setIsDefauult(selectedItem);
  };

  const handleSelectedRecurring = (selectedItem, index) => {
    setIsRecurring(selectedItem);
  };

  const handleSelectedClient = (val, index) => {
    if (parseInt(val) !== 0) {
      setSelectedCleint(val);
      const client = clientsData.filter((item) => item.id === val);
      if (client.length > 0) {
        setUserId(client[0].user_id);
      } else {
        setUserId(0);
      }
    } else {
      setUserId(0);
      setSelectedCleint(0);
    }
  };

  const toggelVisible = () => {
    setIsVisible1(!isVisible1);
    GetPrices();
  };

  const handlePriceType = (type, index) => {
    setPriceType(type);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setIsLoading(true);
    jwt
      .post(Url.ADD_ORDER_PRICE, {
        cleaning_type_id: selectedCleaningType,
        room_type_id: selectedRoomType,
        property_type_id: selectedPropertyType,
        is_default: parseInt(isdefault),
        is_recurring: parseInt(isrecurring),
        price_type: priceType,
        price: data.price,
        assign_to: userId != 0 ? userId : 0,
        system_id: parseInt(global.systemID),
      })
      .then((response) => {
        setIsLoading(false);
        setIsVisible(!isVisible);
        GetPrices();
        setOpenDial(!openDial);
      })
      .catch((err) => {
        setIsVisible(!isVisible);
        setOpenDial(!openDial);
        setIsLoading(false);
      });
  };

  const handleDelete = (id) => {
    jwt
      .del(`${Url.DELETE_ORDER_PRICE}/${id}`)
      .then((response) => {
        setDeleteShow(!deleteShow);
        GetPrices();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const renderItem = ({ item }) => (
    <View
      style={{
        marginTop: 15,
        zIndex: 1,
        borderRadius: 6,
        backgroundColor: "#FFFFFF",
        padding: Sizes.fixPadding * 1.5,
      }}
    >
      <View style={styles.contentContainer}>
        <View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.lable}>PriceID: </Text>
            <Text>{item.id}</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.lable}>Property Type: </Text>
            <Text>{item.property_type}</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.lable}>Cleaning Type: </Text>
            <Text>{item.cleaning_type}</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.lable}>Price Type: </Text>
            <Text>{item.price_type}</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.lable}>Status: </Text>
            <Text>{item.status == 1 ? "Active" : "InActive"}</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.lable}>Default: </Text>
            <Text>{item.is_default == 1 ? "Yes" : "No"}</Text>
          </View>
        </View>

        <View style={{ flexDirection: "column", justifyContent: "center" }}>
          {isUpdateAble && (
            <View>
              <Button
                loading={isLoding}
                onPress={() => {
                  setSelectedItem(item);
                  setIsVisible1(!isVisible1);
                }}
                buttonStyle={{
                  padding: 5,

                  backgroundColor: Colors.primaryColor,
                  borderRadius: 50,
                }}
                containerStyle={{
                  marginVertical: 10,
                }}
                icon={<Feather name="edit-2" size={18} color="#ffffff" />}
              />
            </View>
          )}
          {isDeleteAble && (
            <View>
              <Button
                loading={isLoding}
                onPress={() => {
                  setSelectedItem(item);
                  setDeleteShow(!deleteShow);
                }}
                buttonStyle={{
                  padding: 5,

                  backgroundColor: "#C70000",
                  borderRadius: 50,
                }}
                icon={
                  <Ionicons name="trash-outline" size={20} color="#ffffff" />
                }
              />
            </View>
          )}
        </View>
      </View>
    </View>
  );

  const handleSearch = (value) => {
    setSearch(value);
    if (value.length > 1) {
      const newData = priceData.filter(
        (e) =>
          e.id === parseInt(value) ||
          e.cleaning_type.toUpperCase().includes(value.toUpperCase()) ||
          e.property_type.toUpperCase().includes(value.toUpperCase()) ||
          e.price_type.toUpperCase().includes(value.toUpperCase())
      );
      if (newData.length > 0) {
        setPriceData(newData);
      } else {
        setPriceData(priceDataInitial);
      }
    } else {
      setPriceData(priceDataInitial);
    }
  };

  return (
    <View style={styles.container}>
      <SearchBar
        placeholder="Type Here..."
        onChangeText={handleSearch}
        value={search}
        lightTheme
        containerStyle={{
          backgroundColor: "#eee",
          borderWidth: 0,
          elevation: 0,
          paddingLeft: Sizes.fixPadding * 2,
          paddingRight: Sizes.fixPadding * 2,
        }}
        // placeholderTextColor="#000"
        inputStyle={{
          color: "#000",
        }}
        inputContainerStyle={{
          backgroundColor: "#ccc",
        }}
      />
      {/* <ScrollView style={{ flex: 1 }}> */}
      <View
        style={{
          flex: 1,
          backgroundColor: "#F2F3F4",
          padding: Sizes.fixPadding * 2,
          paddingTop: 0,
        }}
      >
        {/* <ScrollView style={{ flex: 1 }}> */}
        <FlatList
          showsVerticalScrollIndicator={false}
          nestedScrollEnabled
          data={priceData}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
        />

        <Overlay
          isVisible={isVisible}
          overlayStyle={{
            width: "90%",
            backgroundColor: "#FFFFFF",
            borderRadius: 8,
            paddingTop: 15,
            paddingBottom: 20,
            padding: Sizes.fixPadding * 2,
            height: 500,
          }}
          onBackdropPress={() => setIsVisible(!isVisible)}
        >
          <ScrollView>
            <View
              style={{ alignItems: "center", marginTop: 3, marginBottom: 10 }}
            >
              <Text style={{ fontSize: 18 }}>New Price</Text>
            </View>

            <View style={{ flexDirection: "row", marginTop: 15 }}>
              <View style={styles.child1}>
                <Text style={styles.label}>{"Cleaning"}</Text>
              </View>
              <View style={styles.child2}>
                <CustomPicker
                  data={cleaningData}
                  selectedValue={selectedCleaningType}
                  handleSelectedValue={handleSelectedCleaning}
                  defaultLabel={"select cleaning Type"}
                  // style={{backgroundColor:"#FFF"}}
                />
              </View>
            </View>

            <View style={{ flexDirection: "row", marginTop: 15 }}>
              <View style={styles.child1}>
                <Text style={styles.label}>{"Room"}</Text>
              </View>
              <View style={styles.child2}>
                <CustomPicker
                  data={roomData}
                  selectedValue={selectedRoomType}
                  handleSelectedValue={handleSelectedRoom}
                  defaultLabel={"select Room Type"}
                  // style={{backgroundColor:"#FFF"}}
                />
              </View>
            </View>

            <View style={{ flexDirection: "row", marginTop: 15 }}>
              <View style={styles.child1}>
                <Text style={styles.label}>{"Property type"}</Text>
              </View>
              <View style={styles.child2}>
                <CustomPicker
                  data={propertyData}
                  selectedValue={selectedPropertyType}
                  handleSelectedValue={handleSelectedProperty}
                  defaultLabel={"Select Property Type"}
                  // style={{backgroundColor:"#FFF"}}
                />
              </View>
            </View>

            <View style={{ flexDirection: "row", marginTop: 15 }}>
              <View style={styles.child1}>
                <Text style={styles.label}>{"Is Default"}</Text>
              </View>
              <View style={styles.child2}>
                <CustomPicker
                  data={defaultValues}
                  selectedValue={isdefault}
                  handleSelectedValue={handleSelectedDefault}
                />
              </View>
            </View>

            <View style={{ flexDirection: "row", marginTop: 15 }}>
              <View style={styles.child1}>
                <Text style={styles.label}>{"Is Recurring"}</Text>
              </View>
              <View style={styles.child2}>
                <CustomPicker
                  data={recurringValues}
                  selectedValue={isrecurring}
                  handleSelectedValue={handleSelectedRecurring}
                />
              </View>
            </View>

            <View style={{ flexDirection: "row", marginTop: 15 }}>
              <View style={styles.child1}>
                <Text style={styles.label}>{"Price Type"}</Text>
              </View>
              <View style={styles.child2}>
                <CustomPicker
                  data={priceTypes}
                  selectedValue={priceType}
                  handleSelectedValue={handlePriceType}
                />
              </View>
            </View>

            <View style={{ flexDirection: "row", marginTop: 15 }}>
              <View style={styles.child1}>
                <Text style={styles.label}>{"Price"}</Text>
              </View>
              <View style={styles.child2}>
                <Controller
                  name="price"
                  control={control}
                  rules={{
                    required: { value: true, message: "Price is required" },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      // label="Price"
                      onChangeText={(value) => onChange(value)}
                      value={value}
                      error={errors.price}
                      errorText={errors?.price?.message}
                    />
                  )}
                  defaultValue=""
                />
              </View>
            </View>

            <Text style={{ ...styles.label, fontSize: 10 }}>
              {"if want to assign to a specific client otherwise leave it"}
            </Text>
            <View style={{ flexDirection: "row", marginTop: 15 }}>
              <View style={styles.child1}>
                <Text style={styles.label}>{"Client"}</Text>
              </View>
              <View style={styles.child2}>
                <CustomPicker
                  data={clientsData}
                  selectedValue={selectedClient}
                  handleSelectedValue={handleSelectedClient}
                  defaultLabel={"Select Client"}
                  // style={{backgroundColor:"#FFF"}}
                />
              </View>
            </View>

            <Button
              title="Add Price"
              type="solid"
              loading={isLoding}
              onPress={handleSubmit(onSubmit)}
              buttonStyle={{
                padding: Sizes.fixPadding * 1,
                width: "100%",
                backgroundColor: Colors.primaryColor,
                borderRadius: 22,
              }}
              containerStyle={{
                width: "100%",
                // marginHorizontal: 50,
                marginVertical: 20,
              }}
              titleStyle={{
                fontSize: 14,
                fontWeight: "100",
                color: Colors.whiteColor,
              }}
              icon={
                <Ionicons
                  name="add-circle-sharp"
                  size={24}
                  color="#ffffff"
                  style={{ marginRight: "2%" }}
                />
              }
            />
          </ScrollView>
        </Overlay>

        {AuthorizedFor(ModulesPermission.Create_Order_Price) && (
          <SpeedDial
            isOpen={openDial}
            icon={{ name: "edit", color: "#fff" }}
            openIcon={{ name: "close", color: "#fff" }}
            onOpen={() => setOpenDial(!openDial)}
            onClose={() => setOpenDial(!openDial)}
            style={{ zIndex: 2 }}
            buttonStyle={{ backgroundColor: Colors.primaryColor }}
          >
            <SpeedDial.Action
              icon={{ name: "add", color: "#fff" }}
              title="Add"
              onPress={() => setIsVisible(!isVisible)}
              buttonStyle={{ backgroundColor: Colors.primaryColor }}
            />
          </SpeedDial>
        )}

        {isVisible1 && (
          <UpdatePrice
            selectedPrice={selectedItem}
            isVisible1={isVisible1}
            toggleOverlay={toggelVisible}
          />
        )}

        <Dialog isVisible={deleteShow}>
          <Dialog.Title title="Delete Price!" />
          <Text>Are you sure ? this action will not be revert.</Text>

          <Dialog.Actions>
            <Dialog.Button
              title="CONFIRM"
              onPress={() => {
                handleDelete(selectedItem.id);
              }}
            />
            <Dialog.Button
              title="CANCEL"
              onPress={() => {
                setDeleteShow(!deleteShow);
              }}
            />
          </Dialog.Actions>
        </Dialog>
      </View>
      {/* </ScrollView> */}
      {isLoding && <Loading />}
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: Colors.primaryColor,
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 15,
    marginTop: 40,
  },
  contentContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 10,
  },
  label: {
    color: "black",
    marginLeft: 0,
    marginBottom: 3,
    fontSize: windowWidth > 600 ? 18 : 16,
    fontWeight: "500",
    marginTop: windowWidth > 600 ? 0 : 12,
  },
  child1: {
    width: windowWidth > 600 ? "15%" : "30%",
  },
  child2: {
    width: windowWidth > 600 ? "85%" : "70%",
  },
});
export default AllPrices;
