/* eslint-disable consistent-this */
import React from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";
import { Colors, Sizes } from "../../../constants/style";

import {
  Fontisto,
  MaterialIcons,
  FontAwesome,
  Feather,
} from "@expo/vector-icons";

const TotalProjects = ({ projects }) => {
  return (
    <View>
      <TouchableOpacity onPress={() => {}}>
        <View style={styles.mainWrap}>
          <View style={{ ...styles.icon, backgroundColor: "#c85a54" }}>
            <Feather name="file-text" color="#ffffff" size={35} />
          </View>
          <View style={{ marginLeft: "8%" }}>
            <Text style={{ fontSize: 24 }}>{projects}</Text>
            <Text style={{ fontSize: 13 }}>BOOKINGS</Text>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  mainWrap: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    borderRadius: 10,
    backgroundColor: "#dddddd",
    alignSelf: "center",
    padding: Sizes.fixPadding,
    marginTop: 20,
  },
  icon: {
    height: 50,
    width: 50,
    borderRadius: 25,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default TotalProjects;
