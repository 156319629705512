import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, ScrollView } from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  Button,
  SpeedDial,
  ListItem,
  Overlay,
  Dialog,
  PricingCardProps,
} from "react-native-elements";
import {
  Feather,
  Ionicons,
  Entypo,
  MaterialIcons,
  Octicons,
} from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import Loading from "../Utils/Loading";
import moment from "moment";
import AuthorizedFor from "../../constants/authorizedFor";
import { ModulesPermission } from "../../constants/modules";
import CustomPicker from "../../components/Picker/Picker";

const OrderDetails = (navigation) => {
  const item = navigation.route.params.item;

  const [isVisible, setIsVisible] = useState(false);
  const [isVisible1, setIsVisible1] = useState(false);
  const [isLoding, setIsLoading] = useState(false);
  const [orderId, setOrderId] = useState(item.id);
  const [selectedStatus, setSelectedStatus] = useState(item.status);
  const [selectedPriority, setSelectedPriority] = useState(item.priority);

  const [statusData, setStatusData] = useState([
    { value: "Cleaned", label: "Cleaned" },
    { value: "Confirmed", label: "Confirmed" },
    { value: "Pending", label: "Pending" },
    { value: "Rejected", label: "Rejected" },
    { value: "Subcontracted", label: "Subcontracted" },
  ]);

  const priorityData = [
    { value: "High", label: "High" },
    { value: "Low", label: "Low" },
    { value: "Medium", label: "Medium" },
    { value: "Urgent", label: "Urgent" },
  ];

  useEffect(() => {
    if (global.userData && global.userData.roles[0].name === "Client") {
      setStatusData(["Cleaned", "Confirmed", "Pending", "Rejected"]);
    }
  }, []);

  const handleStatus = (selectedItems, index) => {
    if (parseInt(selectedItems) !== 0) {
      setSelectedStatus(selectedItems);
    }
  };

  const handlePriority = (selectedItems, index) => {
    if (parseInt(selectedItems) !== 0) {
      setSelectedPriority(selectedItems);
    }
  };

  const handleSubmitStatus = (navigation) => {
    setIsLoading(true);
    jwt
      .post(`${Url.UPDATE_ORDER_STATUS}/${orderId}`, { status: selectedStatus })
      .then((response) => {
        setIsLoading(false);
        setIsVisible(false);
        if (global.userData && global.userData.roles[0].name === "Client") {
          navigation.navigation.navigate("ClientOrders", { item: "refresh" });
        } else {
          navigation.navigation.navigate("Order", { item: "refresh" });
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleSubmitPriority = (navigation) => {
    setIsLoading(true);
    jwt
      .post(`${Url.UPDATE_ORDER_PRIORITY}/${orderId}`, {
        priority: selectedPriority,
      })
      .then((response) => {
        setIsLoading(false);
        navigation.navigation.navigate("Order", { item: "refresh" });
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const header = (item) => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          Order Details
        </Text>
        <MaterialIcons
          name="arrow-back"
          size={26}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 15.0,
            fontWeight: 300,
          }}
          onPress={() => navigation.navigation.pop()}
        />
        {AuthorizedFor(ModulesPermission.Get_Order_Tasks_By_Order_ID) && (
          <Octicons
            name="tasklist"
            size={26}
            color={Colors.whiteColor}
            style={{
              position: "absolute",
              right: 15.0,
              fontWeight: 300,
            }}
            onPress={() =>
              navigation.navigation.navigate("Tasks", {
                orderId: item.id,
              })
            }
          />
        )}
      </View>
    );
  };

  return (
    <View style={styles.container}>
      {header(item)}

      <ScrollView
        style={{
          padding: Sizes.fixPadding * 2,
          backgroundColor: "#F2F3F4",
          flex:1
        }}
      >
        <View
          style={{
            padding: Sizes.fixPadding,
            backgroundColor: "#fff",
            borderRadius: 6,
            flex:1
          }}
        >
          <View style={{ flexDirection: "row", marginBottom: 10 }}>
            <Text style={{ fontWeight: "bold" }}>Name: </Text>
            <Text style={{ fontWeight: "normal", fontSize: 14 }}>
              {item.name}
            </Text>
          </View>

          <View style={{ flexDirection: "row", marginBottom: 10 }}>
            <Text style={{ fontWeight: "bold" }}>Cleaning Type: </Text>
            <Text style={{ fontWeight: "normal", fontSize: 14 }}>
              {item.cleaning_type}
            </Text>
          </View>

          <View style={{ flexDirection: "row", marginBottom: 10 }}>
            <Text style={{ fontWeight: "bold" }}>Property Type: </Text>
            <Text style={{ fontWeight: "normal", fontSize: 14 }}>
              {item.property_type}
            </Text>
          </View>

          <View style={{ flexDirection: "row", marginBottom: 10 }}>
            <Text style={{ fontWeight: "bold" }}>Duration From: </Text>
            <Text style={{ fontWeight: "normal", fontSize: 14 }}>
              {`${moment(item.duration_from).format("YYYY-MM-DD")}`}
            </Text>
          </View>
          {item.duration_to != "" && (
            <View style={{ flexDirection: "row", marginBottom: 10 }}>
              <Text style={{ fontWeight: "bold" }}>Duration To: </Text>
              <Text style={{ fontWeight: "normal", fontSize: 14 }}>
                {`${moment(item.duration_to).format("YYYY-MM-DD")}`}
              </Text>
            </View>
          )}

          <View style={{ flexDirection: "row", marginBottom: 10 }}>
            <Text style={{ fontWeight: "bold" }}>Status: </Text>
            <Text style={{ fontWeight: "normal", fontSize: 14 }}>
              {item.status}
            </Text>
            {AuthorizedFor(ModulesPermission.Update_Order_Status) &&
              item.status != "Confirmed" && (
                <Feather
                  name="edit"
                  size={18}
                  color={Colors.primaryColor}
                  style={{
                    position: "absolute",
                    right: 20.0,
                    // fontWeight: 300
                  }}
                  onPress={() => setIsVisible(!isVisible)}
                />
              )}
          </View>

          <View style={{ flexDirection: "row", marginBottom: 10 }}>
            <Text style={{ fontWeight: "bold" }}>Recurring: </Text>
            <Text style={{ fontWeight: "normal", fontSize: 14 }}>
              {item.is_recurring == 1 ? "Yes" : "No"}
            </Text>
          </View>

          <View style={{ flexDirection: "row", marginBottom: 10 }}>
            <Text style={{ fontWeight: "bold" }}>Priority: </Text>
            <Text style={{ fontWeight: "normal", fontSize: 14 }}>
              {item.priority}
            </Text>
            {AuthorizedFor(ModulesPermission.Update_Order_Priority) && (
              <Feather
                name="edit"
                size={18}
                color={Colors.primaryColor}
                style={{
                  position: "absolute",
                  right: 20.0,
                  // fontWeight: 300
                }}
                onPress={() => setIsVisible1(!isVisible1)}
              />
            )}
          </View>

          <View style={{ flexDirection: "row", marginBottom: 10 }}>
            <Text style={{ fontWeight: "bold" }}>Vat Percentage: </Text>
            <Text style={{ fontWeight: "normal", fontSize: 14 }}>
              {item.vat_per}
            </Text>
          </View>

          <View style={{ flexDirection: "row", marginBottom: 10 }}>
            <Text style={{ fontWeight: "bold" }}>Vat Price: </Text>
            <Text style={{ fontWeight: "normal", fontSize: 14 }}>
              {item.vat_price}
            </Text>
          </View>

          <View style={{ flexDirection: "row", marginBottom: 10 }}>
            <Text style={{ fontWeight: "bold" }}>Total Price: </Text>
            <Text style={{ fontWeight: "normal", fontSize: 14 }}>
              {item.total_price}
            </Text>
          </View>

          <View style={{ alignItems: "center" }}>
            <Button
              title="Get Quote"
              type="solid"
              //   loading={isLoding}
              onPress={() =>
                navigation.navigation.navigate("GetQuote", {
                  item: item,
                })
              }
              buttonStyle={{
                padding: Sizes.fixPadding,
                width: "100%",
                backgroundColor: Colors.primaryColor,
                borderRadius: 22,
              }}
              containerStyle={{
                width: "50%",
                // marginHorizontal: 50,
                marginVertical: 20,
              }}
              titleStyle={{
                fontSize: 14,
                fontWeight: "100",
                color: Colors.whiteColor,
              }}
              icon={
                <Ionicons
                  name="add-circle-sharp"
                  size={24}
                  color="#ffffff"
                  style={{ marginRight: "2%" }}
                />
              }
            />
          </View>
        </View>
      </ScrollView>
      {isLoding && <Loading />}

      <Overlay
        visible={isVisible}
        overlayStyle={{
          width: "80%",
          backgroundColor: "#FFFFFF",
          borderRadius: 8,
          paddingTop: 15,
          paddingBottom: 20,
          padding: Sizes.fixPadding * 2,
        }}
        onBackdropPress={() => setIsVisible(!isVisible)}
      >
        <View style={{ alignItems: "center", marginTop: 3, marginBottom: 10 }}>
          <Text style={{ fontSize: 18 }}>Update Status</Text>
        </View>

        <Text style={styles.label}>{"Status"}</Text>
        <CustomPicker
          data={statusData}
          selectedValue={selectedStatus}
          handleSelectedValue={handleStatus}
          defaultLabel={"Please select a status"}
          style={{}}
        />

        <Button
          title="Update Status"
          type="solid"
          loading={isLoding}
          onPress={() => handleSubmitStatus(navigation)}
          buttonStyle={{
            padding: Sizes.fixPadding * 1,
            width: "100%",
            backgroundColor: Colors.primaryColor,
            borderRadius: 22,
          }}
          containerStyle={{
            width: "100%",
            // marginHorizontal: 50,
            marginVertical: 20,
          }}
          titleStyle={{
            fontSize: 14,
            fontWeight: "100",
            color: Colors.whiteColor,
          }}
          icon={
            <Ionicons
              name="add-circle-sharp"
              size={24}
              color="#ffffff"
              style={{ marginRight: "2%" }}
            />
          }
        />
      </Overlay>

      <Overlay
        visible={isVisible1}
        overlayStyle={{
          width: "80%",
          backgroundColor: "#FFFFFF",
          borderRadius: 8,
          paddingTop: 15,
          paddingBottom: 20,
          padding: Sizes.fixPadding * 2,
        }}
        onBackdropPress={() => setIsVisible1(!isVisible1)}
      >
        <View style={{ alignItems: "center", marginTop: 3, marginBottom: 10 }}>
          <Text style={{ fontSize: 18 }}>Update Priority</Text>
        </View>

        <Text style={styles.label}>{"Priority"}</Text>
        <CustomPicker
          data={priorityData}
          selectedValue={selectedPriority}
          handleSelectedValue={handlePriority}
          defaultLabel={"Please select a Priority"}
          style={{}}
        />

        <Button
          title="Update Priority"
          type="solid"
          loading={isLoding}
          onPress={() => handleSubmitPriority(navigation)}
          buttonStyle={{
            padding: Sizes.fixPadding * 1,
            width: "100%",
            backgroundColor: Colors.primaryColor,
            borderRadius: 22,
          }}
          containerStyle={{
            width: "100%",
            // marginHorizontal: 50,
            marginVertical: 20,
          }}
          titleStyle={{
            fontSize: 14,
            fontWeight: "100",
            color: Colors.whiteColor,
          }}
          icon={
            <Ionicons
              name="add-circle-sharp"
              size={24}
              color="#ffffff"
              style={{ marginRight: "2%" }}
            />
          }
        />
      </Overlay>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: Colors.primaryColor,
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 15,
    marginTop: 40,
  },
});

export default OrderDetails;
