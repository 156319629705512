import React, { useState, useEffect, useCallback } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Image,
  TextInput,
  TouchableOpacity,
  Alert,
  Dimensions,
  FlatList,
  SafeAreaView,
  RefreshControl,
  Platform,
  ActivityIndicator
} from "react-native";
import {
  Button,
  Overlay,
  ListItem,
  SpeedDial,
  Avatar,
  Dialog,
} from "react-native-elements";

import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import { FontAwesome5, MaterialIcons } from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import Constants from "expo-constants";
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import * as SecureStore from "expo-secure-store";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { StatusBar } from "expo-status-bar";

const window = Dimensions.get("window");
const Systems = (navigation) => {
  const [dataSource, setDataSource] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(true);

// useEffect(() => {
//   async function fetchData() {
//     // You can await here
//     const response = await MyAPI.getData(someId);
//     // ...
//   }
//   fetchData();
// }, [someId]); 

  useEffect(async() => {
   
    getData();
  }, []);

  const getData = async() => {
    setLoading(true)
  const response =JSON.parse(await AsyncStorage.getItem("systems")).slice((offset*5),((offset+1)*5)-1);
                
 
                 setOffset(offset + 1);
                 //After the response increasing the offset for the next API call.
                 setDataSource([...dataSource, ...response]);
               setLoading(false)
                };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleSystem = (id) => {
    if (Platform.OS === "android" || Platform.OS === "ios") {
      global.isRemember && SecureStore.setItemAsync("systemID", id.toString());
    } else {
      global.isRemember && AsyncStorage.setItem("systemID", id.toString());
    }

    global.systemID = id;
    global.systems=[]
    setOffset(0)
    navigation.navigation.navigate("Home");
  };

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          Systems
        </Text>
      
        <MaterialIcons
          name="arrow-back"
          size={26}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 15.0,
            fontWeight: 300,
          }}
          onPress={() => navigation.navigation.pop()}
        />
      </View>
    );
  };

  const renderFooter = () => {
    return (
      //Footer View with Load More button
      <View style={styles.footer}>
        <TouchableOpacity
          activeOpacity={0.9}
          onPress={getData}
          //On Click of button calling getData function to load more data
          style={styles.loadMoreBtn}>
          
          {loading ? (
            <ActivityIndicator color="white" style={{marginLeft: 8}} />
          ) : <Text style={styles.btnText}>Load More</Text>}
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <StatusBar
        // translucent
        backgroundColor={Colors.primaryColor}
        // barStyle="light-content"
        style='light'
      />
      {header()}
      {/* <ScrollView contentContainerStyle={styles.scrollView}> */}
      <View style={{ flex: 1, padding:Sizes.fixPadding *2, paddingTop: 20, backgroundColor: "#F2F3F4" }}>
        <FlatList
          nestedScrollEnabled
          data={dataSource}
          renderItem={({ item }) => (
            <TouchableOpacity
              style={{
                ...styles.innerBox,
                flex: 1,
                flexDirection: "row",
                margin: 8,
                marginLeft:Platform.OS === 'web' ? 8 : 0,
                borderRadius: 6,
                elevation: 5,
                backgroundColor:"#fff",
                padding: 20
              }}
              onPress={() => {
                handleSystem(item.id);
              }}
            >
              <FontAwesome5
                name="laptop-medical"
                style={{ marginRight: 10 }}
                size={30}
                color="black"
              />
              <Text style={{ fontSize: 18,marginTop:3 }}>
                {capitalizeFirstLetter(item.name)}
              </Text>
            </TouchableOpacity>
          )}
          numColumns={Platform.OS === 'web' ? 2 : 1}
          keyExtractor={(item, index) => index}
          ListFooterComponent={renderFooter}
        />
      </View>
      {/* </ScrollView> */}
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor:Colors.primaryColor
  },
  scrollView: {
    flex: 1,
    // // backgroundColor: "pink",
    // alignItems: "center",
    // justifyContent: "center",
  },
  headerWrapStyle: {
    // height: Platform.OS === "ios" ? 0 : 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom:15,
    marginTop:40
  },
  flexbox: {
    flexDirection: "row",
    // alignItems: "center",
    // justifyContent: "center",
    flexWrap: "wrap",
    // display: "flex",
  },
  innerBox: {
    backgroundColor: Colors.whiteColor,
    height: 100,
    // padding: Sizes.fixPadding * 2,
    alignItems: "center",
    // justifyContent: "center",
    // width: "50%",
    width: "100%",
  },
  footer:{
    marginTop:10,
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    marginBottom:15
  },
  loadMoreBtn:{
    backgroundColor: Colors.primaryColor,
    alignContent:'center',
    alignItems:'center',
    padding:10,
    borderRadius:20,
    width:150,
    justifyContent:'center'
  },
  btnText:{
    color:"#FFF"
  }
});
export default Systems;
