import React, { useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  Dimensions
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  Button,
  Overlay,
  ListItem,
  SpeedDial,
  TabView,
  Avatar,
  BottomSheet,
} from "react-native-elements";

import {
  Ionicons,
  Entypo,
  MaterialIcons,
  MaterialCommunityIcons,
  FontAwesome,
  Octicons,
  FontAwesome5,
} from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import { useForm, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import Loading from "../Utils/Loading";
import * as ImagePicker from "expo-image-picker";
import SectionedMultiSelect from "react-native-sectioned-multi-select"
import moment from "moment";
import CustomDatePicker from "../../components/datePicker/datePicker";
import CustomPicker from "../../components/Picker/Picker";
const windowWidth = Dimensions.get("window").width;

const UpdateEmployee = (navigation) => {
  const item = navigation.route.params.item;
  console.log(item)
  const [clientsData, setClientsData] = useState([]);
  const [openDial, setOpenDial] = useState(false);
  const [isLoding, setIsLoading] = useState(false);
  const [employeeRoles, setEmployeeRoles] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [showBottomSheet, SetShowBottomSheet] = useState(false);
  const [imagePicked, setImagePicked] = useState(null);
  const [imagePickedPath, setImagePickedPath] = useState(null);
  const [selectedItems, setSelectedItems] = useState(item.role_names);
  const [selectedGender, setSelectedGender] = useState(item.employee.gender);
  const [selectedStatus, setSelectedStatus] = useState(item.employee.status);
  const [date, setDate] = useState(new Date());
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [dob, setDob] = useState(new Date(item.employee.dob));
  const [joiningDate, setJoiningDate] = useState(new Date(item.employee.joining_date));

  const gendersData = [{value:"Male", label:"Male"},{value:"Female", label:"Female"}];

  const statusData = [{value:"Active", label:"Active"},{value:"Inactive", label:"Inactive"}];

  useEffect(() => {
    (async () => {
      GetEmployeeRoles();
    })();
  }, []);

  const GetEmployeeRoles = () => {
    jwt
      .get(`${Url.GET_EMPLOYEE_ROLES}/${global.systemID}`)
      .then((response) => {
        setEmployeeRoles(response.data.roles);
        // setClientsData(response.data.clients);
      })
      .catch((err) => {});
  };

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          {item.employee.first_name}
        </Text>
        <MaterialIcons
          name="arrow-back"
          size={26}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 15.0,
            fontWeight: 300,
          }}
          onPress={() => navigation.navigation.pop()}
        />
      </View>
    );
  };

  const openCamera = async () => {
    // Ask the user for the permission to access the camera
    const permissionResult = await ImagePicker.requestCameraPermissionsAsync();

    if (permissionResult.granted === false) {
      alert("You've refused to allow this appp to access your camera!");
      return;
    }

    const result = await ImagePicker.launchCameraAsync();

    if (!result.cancelled) {
      setImagePicked(result);
      setImagePickedPath(result.uri);
      SetShowBottomSheet(false);
    }
  };

  const showImagePicker = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (result.cancelled) {
    } else {
      setImagePicked(result);
      setImagePickedPath(result.uri);
      SetShowBottomSheet(false);
    }
  };

  const handleGender = (selectedItem) => {
    setSelectedGender(selectedItem);
  };

  const handleStatus = (selectedItem) => {
    setSelectedStatus(selectedItem);
  };

  const onSelectedItemsChange = (selectedItems) => {
    setSelectedItems(selectedItems);
  };

  
  const onChangeDob = (event, selectedDate) => {
    const currentDate = selectedDate;
    setShow1(false);
    setDob(currentDate);
  };

  const onChangeJoiningDate = (event, selectedDate) => {
    const currentDate = selectedDate;
    setShow2(false);
    setJoiningDate(currentDate);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setIsLoading(true);
    let newRoles = [];
    selectedItems.map((item) =>
      employeeRoles
        .filter((obj) => obj.name === item)
        .map((rol) =>
          newRoles.push({
            role_id: rol.id,
            name: rol.name,
          })
        )
    );

    const newData = {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      phone: data.phone,
      address: data.address,
      post_code: data.postCode,
      gender: selectedGender,
      roles: newRoles,
      dob: `${moment(dob).format("YYYY-MM-DD")}`,
      joining_date: `${moment(joiningDate).format("YYYY-MM-DD")}`,
      status: selectedStatus,
      system_id: global.systemID,
    };

    jwt
      .post(`${Url.UPDATE_EMPLOYEE}/${item.employee.id}`, newData)
      .then((response) => {
        navigation.navigation.navigate("Employees", { item: "refresh" });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });

    if (imagePicked != null) {
      imageUpload();
    }
  };

  const getMimeType = (ext) => {
    // mime type mapping for few of the sample file types
    switch (ext) {
      case "pdf":
        return "application/pdf";
      case "jpg":
        return "image/jpeg";
      case "jpeg":
        return "image/jpeg";
      case "png":
        return "image/png";
    }
  };

  const imageUpload = async () => {
    const fileUri = imagePicked.uri;
    let filename = fileUri.split("/").pop();
    const extArr = /\.(\w+)$/.exec(filename);
    const fileType = getMimeType(extArr[1]);
    let imageForm = new FormData();
    imageForm.append("image", { uri: fileUri, name: filename, type: fileType });

    let userid = item.employee.id;
    let finalurl = `${Url.UPDATE_AVATAR}/${userid}`;
    let headers = {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + global.accessToken,
    };
    jwt
      .postfile(finalurl, imageForm, {
        headers,

        transformRequest: (data) => {
          // !!! override data to return formData
          // since axios converts that to string

          return imageForm; // this is doing the trick
        },
        onUploadProgress: (progressEvent) => {},
      })
      .then((res) => {
        setIsLoading(false);
        // this.setState({ isLoading: false });
        // SecureStore.setItemAsync('userData', JSON.stringify(res.data.user))
        // global.userData = res.data.user
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetIntials = (nameString) => {
    const fullName = nameString.split(" ");
    const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
    return initials.toUpperCase();
  };

  return (
    <View style={styles.container}>
      {header()}
      <ScrollView
        style={{
          flex: 1,
          backgroundColor:"#F2F3F4",
          paddingHorizontal: Sizes.fixPadding * 2,
        }}
      >
        <View
          style={{
            backgroundColor: "#ffffff",
            marginTop: 20,
            borderRadius: 10,
            padding: Sizes.fixPadding * 2,
          }}
        >
          <View
            style={{
              alignSelf: "center",
              marginTop: Sizes.fixPadding * 1.0,
              marginBottom: Sizes.fixPadding + 5.0,
              alignItems: "center",
            }}
          >
            <Avatar
              size={94}
              rounded
              source={{ uri: imagePickedPath }}
              title= {GetIntials(`${item.employee.first_name} ${item.employee.last_name}`)}
              containerStyle={{ backgroundColor: "grey" }}
              titleStyle={{fontSize:34}}
            >
              <Avatar.Accessory
                size={33}
                onPress={() => {
                  SetShowBottomSheet(true);
                }}
              />
            </Avatar>
          </View>
          <View style={{flexDirection:'row', marginBottom:15, marginTop:15}}>
                <View style={styles.child1}>
                <Text style={styles.label}>{"First Name"}</Text>
                </View>
                <View style={styles.child2}>
          <Controller
            name="firstName"
            control={control}
            rules={{
              required: { value: true, message: "FirstName is required" },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                // label="First Name"
                type="text"
                onChangeText={(value) => onChange(value)}
                value={value}
                error={errors.firstName}
                errorText={errors?.firstName?.message}
              />
            )}
            defaultValue={item.employee.first_name}
          />
          </View>
          </View>

          <View style={{flexDirection:'row', marginBottom:15}}>
                <View style={styles.child1}>
                <Text style={styles.label}>{"Last Name"}</Text>
                </View>
                <View style={styles.child2}>
          <Controller
            name="lastName"
            control={control}
            rules={{
              required: { value: true, message: "Last Name is required" },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                // label="Last Name"
                type="text"
                onChangeText={(value) => onChange(value)}
                value={value}
                error={errors.lastName}
                errorText={errors?.lastName?.message}
              />
            )}
            defaultValue={item.employee.last_name}
          />
          </View>
          </View>

          <View style={{flexDirection:'row', marginBottom:15}}>
                <View style={styles.child1}>
                <Text style={styles.label}>{"Email"}</Text>
                </View>
                <View style={styles.child2}>
          <Controller
            name="email"
            control={control}
            rules={{
              required: { value: true, message: "Email is required" },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                // label="Email"
                type="email"
                onChangeText={(value) => onChange(value)}
                value={value}
                error={errors.email}
                errorText={errors?.email?.message}
              />
            )}
            defaultValue={item.employee.email}
          />
          </View>
          </View>

          <View style={{flexDirection:'row', marginBottom:15}}>
                <View style={styles.child1}>
                <Text style={styles.label}>{"Phone"}</Text>
                </View>
                <View style={styles.child2}>
          <Controller
            name="phone"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                // label="Phone"
                type="text"
                onChangeText={(value) => onChange(value)}
                value={value}
              />
            )}
            defaultValue={item.employee.phone}
          />
          </View>
          </View>


<View style={{flexDirection:'row', marginBottom:15}}>
                <View style={styles.child1}>
                <Text style={ styles.label }>Dob</Text>
                </View>
                <View style={{...styles.child2, }}>
                <CustomDatePicker
                  date={onChangeDob}
                  defaultDate={dob}
                  show={show1}
                  setShow={setShow1}
                 
                />
                </View>
                </View>

                <View style={{flexDirection:'row', marginBottom:15}}>
                <View style={styles.child1}>
                <Text style={styles.label}>{"Address"}</Text>
                </View>
                <View style={styles.child2}>

          <Controller
            name="address"
            control={control}
            rules={{
              required: { value: true, message: "Address is required" },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                // label="Address"
                type="text"
                onChangeText={(value) => onChange(value)}
                value={value}
                error={errors.address}
                errorText={errors?.address?.message}
              />
            )}
            defaultValue={item.employee.address}
          />
          </View>
          </View>

          <View style={{flexDirection:'row', marginBottom:15}}>
                <View style={styles.child1}>
                <Text style={styles.label}>{"PostCode"}</Text>
                </View>
                <View style={styles.child2}>
          <Controller
            name="postCode"
            control={control}
            //   rules={{
            //     required: { value: true, message: "Company is required" },
            //   }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                // label="PostCode"
                type="text"
                onChangeText={(value) => onChange(value)}
                value={value}
                //   error={errors.company}
                //   errorText={errors?.company?.message}
              />
            )}
            defaultValue={item.employee.post_code}
          />
          </View>
          </View>

<View style={{flexDirection:'row', marginBottom:15}}>
                <View style={styles.child1}>
                <Text style={ styles.label }>Joining Date</Text>
                </View>
                <View style={{...styles.child2, }}>
                <CustomDatePicker
                  date={onChangeJoiningDate}
                  defaultDate={joiningDate}
                  show={show2}
                  setShow={setShow2}
                 
                />
                </View>
                </View>
          
                <View style={{flexDirection:'row', marginBottom:15}}>
                <View style={styles.child1}>
                <Text style={styles.label}>{"Gender"}</Text>
                </View>
                <View style={styles.child2}>
                <CustomPicker
                  data={gendersData}
                  selectedValue={selectedGender}
                  handleSelectedValue={handleGender}
                  defaultLabel = {"Select gender"}
                  style={{}}
                />
</View>
                </View>

        
                <View style={{flexDirection:'row', marginBottom:15}}>
                <View style={styles.child1}>
                <Text style={styles.label}>{"Role"}</Text>
                </View>
                <View style={styles.child2}>
         
          <SectionedMultiSelect
            items={employeeRoles}
            // ref={ref}
            IconRenderer={MaterialIcons}
            uniqueKey="name"
            // subKey="children"
            selectText="Select Days"
            showDropDowns={true}
            //   readOnlyHeadings={true}
            onSelectedItemsChange={onSelectedItemsChange}
            selectedItems={selectedItems}
            hideSearch={true}
            searchPlaceholderText="Search reasons"
            //   text={Colors.primaryColor}
            styles={{
              button: {
                backgroundColor: Colors.primaryColor,
                selectedItemText: Colors.primaryColor,
              },
              selectToggle: {
                borderColor: "#CCCCCC",
                padding: 10,
                borderRadius: 4,
                borderWidth: 1,
                height: 40,
                // marginBottom: 15,
              },
              selectToggleText: {
                fontSize: 14,
              },
              itemIconStyle: {
                fontSize: 14,
              },
            }}
            theme={{
              Colors: {
                selectToggleTextColor: "#053075",
                text: "#053075",
                selectedItemText: Colors.primaryColor,
              },
            }}
          />
          </View>
          </View>

          <View style={{ flexDirection: "row", marginTop: 15 }}>
          <View style={styles.child1}>
            <Text style={styles.label}>{"Status"}</Text>
          </View>
          <View style={styles.child2}>
            <CustomPicker
              data={statusData}
              selectedValue={selectedStatus}
              handleSelectedValue={(val, index) => {setSelectedStatus(val)}}
            />
          </View>
        </View>

          <Button
            title="Update Profile"
            type="solid"
            loading={isLoding}
            onPress={handleSubmit(onSubmit)}
            buttonStyle={{
              padding: Sizes.fixPadding,
              width: "100%",
              backgroundColor: Colors.primaryColor,
              borderRadius: 22,
            }}
            containerStyle={{
              width: "100%",
              // marginHorizontal: 50,
              marginVertical: 20,
            }}
            titleStyle={{
              fontSize: 14,
              fontWeight: "100",
              color: Colors.whiteColor,
            }}
            icon={
              <MaterialCommunityIcons
                name="account-edit-outline"
                size={24}
                color="#ffffff"
                style={{ marginRight: "2%" }}
              />
            }
          />
        </View>
      </ScrollView>

      {show1 && (
        <DateTimePicker
          testID="dateTimePicker"
          value={date}
          mode={"date"}
          // display={"spinner"}
          // is24Hour={true}
          onChange={onChangeDob}
        />
      )}

      {show2 && (
        <DateTimePicker
          testID="dateTimePicker"
          value={date}
          mode={"date"}
          // display={"spinner"}
          // is24Hour={true}
          onChange={onChangeJoiningDate}
        />
      )}

      <BottomSheet modalProps={{}} isVisible={showBottomSheet}>
        <ListItem>
          <ListItem.Content>
            <TouchableOpacity
              style={{ flexDirection: "row", width: "100%" }}
              onPress={() => openCamera()}
            >
              <MaterialIcons
                name="camera"
                size={26}
                color={Colors.primaryColor}
              />

              <Text
                style={{
                  paddingLeft: 5,
                  fontSize: 16,
                  paddingTop: 2,
                }}
              >
                Take a photo
              </Text>
            </TouchableOpacity>
          </ListItem.Content>
        </ListItem>

        <ListItem style={{ borderBottomWidth: 1, borderBottomColor: "#CCC" }}>
          <ListItem.Content>
            <TouchableOpacity
              style={{ flexDirection: "row", width: "100%" }}
              onPress={() => showImagePicker()}
            >
              <MaterialIcons
                name="photo-library"
                size={26}
                color={Colors.primaryColor}
              />

              <Text
                style={{
                  paddingLeft: 5,
                  fontSize: 16,
                  paddingTop: 2,
                }}
              >
                Choose from your photos
              </Text>
            </TouchableOpacity>
          </ListItem.Content>
        </ListItem>

        <ListItem>
          <ListItem.Content>
            <TouchableOpacity
              style={{ flexDirection: "row", width: "100%" }}
              onPress={() => SetShowBottomSheet(false)}
            >
              <Entypo name="cross" size={26} color={Colors.primaryColor} />

              <Text
                style={{
                  paddingLeft: 5,
                  fontSize: 16,
                  paddingTop: 2,
                }}
              >
                Cancel
              </Text>
            </TouchableOpacity>
          </ListItem.Content>
        </ListItem>
      </BottomSheet>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor:Colors.primaryColor
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom:15,
    marginTop:40
  },
  label: {
    color: "black",
    marginLeft: 0,
    marginBottom: 3,
    fontSize:windowWidth > 600 ? 18 : 16,
    fontWeight:'500',
    marginTop:windowWidth > 600 ? 0 : 12
  },
  child1:{
    width:windowWidth > 600 ? '15%' : '30%'
  },
  child2:{
    width:windowWidth > 600 ? '85%' : '70%'
  }
});
export default UpdateEmployee;
