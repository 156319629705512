import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  Button,
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  Ionicons,
  Entypo,
  MaterialIcons,
  MaterialCommunityIcons,
  FontAwesome,
  Octicons,
  FontAwesome5,
} from "@expo/vector-icons";
import moment from "moment";

import MonthView from "./MonthView";
import DateView from "./DateView";
import { SafeAreaProvider } from "react-native-safe-area-context";

const Calendar = (navigation) => {
  const [activeDate, setActiveDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState();
  const [calendarView, setCalendarView] = useState(false);

  useEffect(() => {
    setSelectedDate(moment(activeDate).format("DD-MM-YYYY"));
  }, []);

  

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          Calender
        </Text>
        <Ionicons
          name="menu-outline"
          size={30}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 20.0,
            // fontWeight: 300
          }}
          onPress={() => navigation.navigation.toggleDrawer()}
        />
        <TouchableOpacity
          style={{
            position: "absolute",
            right: 20.0,
            // fontWeight: 300
          }}
          onPress={() => setCalendarView(!calendarView)}
        >
          {!calendarView ? (
            <Ionicons name="calendar" size={30} color={Colors.whiteColor} />
          ) : (
            <MaterialCommunityIcons
              name="calendar-today"
              size={32}
              color={Colors.whiteColor}
            />
          )}
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <SafeAreaProvider style={styles.container}>
      {header()}
      <View style={{flex:1, backgroundColor:"#F2F3F4"}}>
      {calendarView ? <MonthView /> : <DateView navigation={navigation} />}
      </View>
    </SafeAreaProvider>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor:Colors.primaryColor
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom:15,
    marginTop:40
  },
  calendarHeader: {
    // height: 55.0,
    // backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
  },
});
export default Calendar;

// export default class Calendar extends React.Component {
//   months = [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December",
//   ];

//   weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
//   nDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

//   state = {
//     activeDate: new Date(),
//   };

//   generateMatrix() {
//     var matrix = [];
//     // Create header
//     matrix[0] = this.weekDays;

//     var year = this.state.activeDate.getFullYear();
//     var month = this.state.activeDate.getMonth();

//     var firstDay = new Date(year, month, 1).getDay();

//     var maxDays = this.nDays[month];
//     if (month == 1) {
//       // February
//       if ((year % 4 == 0 && year % 100 != 0) || year % 400 == 0) {
//         maxDays += 1;
//       }
//       var counter = 1;
//       for (var row = 1; row < 7; row++) {
//         matrix[row] = [];
//         for (var col = 0; col < 7; col++) {
//           matrix[row][col] = -1;
//           if (row == 1 && col >= firstDay) {
//             // Fill in rows only after the first day of the month
//             matrix[row][col] = counter++;
//           } else if (row > 1 && counter <= maxDays) {
//             // Fill in rows only if the counter's not greater than
//             // the number of days in the month
//             matrix[row][col] = counter++;
//           }
//         }
//       }

//       return matrix;
//     }
//   }

//   matrix = this.generateMatrix();
//   rows = [];
//   rows = this.matrix.map((row, rowIndex) => {
//     var rowItems = row.map((item, colIndex) => {
//       return (
//         <Text
//           style={{
//             flex: 1,
//             height: 18,
//             textAlign: "center",
//             // Highlight header
//             backgroundColor: rowIndex == 0 ? "#ddd" : "#fff",
//             // Highlight Sundays
//             color: colIndex == 0 ? "#a00" : "#000",
//             // Highlight current date
//             fontWeight: item == this.state.activeDate.getDate() ? "bold" : "",
//           }}
//           onPress={() => this._onPress(item)}
//         >
//           {item != -1 ? item : ""}
//         </Text>
//       );
//     });
//     return (
//       <View
//         style={{
//           flex: 1,
//           flexDirection: "row",
//           padding: 15,
//           justifyContent: "space-around",
//           alignItems: "center",
//         }}
//       >
//         {rowItems}
//       </View>
//     );
//   });

//   _onPress = (item) => {
//     this.setState(() => {
//       if (!item.match && item != -1) {
//         this.state.activeDate.setDate(item);
//         return this.state;
//       }
//     });
//   };
//   changeMonth = (n) => {
//     this.setState(() => {
//       this.state.activeDate.setMonth(this.state.activeDate.getMonth() + n);
//       return this.state;
//     });
//   };
//   render() {
//     return (
//       <View>
//         <Text
//           style={{
//             fontWeight: "bold",
//             fontSize: 18,
//             textAlign: "center",
//           }}
//         >
//           {this.months[this.state.activeDate.getMonth()]} &nbsp;
//           {this.state.activeDate.getFullYear()}
//         </Text>
//         {/* {rows} */}
//         <Button title="Previous" onPress={() => this.changeMonth(-1)} />
//         <Button title="Next" onPress={() => this.changeMonth(+1)} />
//       </View>
//     );
//   }
// }
