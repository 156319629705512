import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, ScrollView } from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  Button,
  SpeedDial,
  ListItem,
  Overlay,
  Dialog,
} from "react-native-elements";
import {
  Feather,
  Ionicons,
  Entypo,
  MaterialIcons,
  Octicons,
} from "@expo/vector-icons";
import Loading from "../Utils/Loading";
import moment from "moment";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";

const NotificationDetail = (navigation) => {
  const ListItem = navigation.route.params.ListItem;

  useEffect(() => {
    (async () => {
      MarkRead();
    })();
  }, []);

  const MarkRead = () => {
    ListItem &&
      jwt
        .get(`${Url.MARK_READ_NOTIFICATIONS}/${ListItem.id}`)
        .then((response) => {})
        .catch((err) => {});
  };

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          {ListItem
            ? ListItem.data.data.push_notification_info.title
            : global.notification.title}
        </Text>
        <MaterialIcons
          name="arrow-back"
          size={26}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 15.0,
            fontWeight: 300,
          }}
          onPress={() => {
            navigation.navigation.navigate("NotificationScreen");
            global.notification = false;
          }}
        />
      </View>
    );
  };

  return (
    <View style={styles.container}>
      {header()}
      <View style={{ flex: 1, backgroundColor:"#F2F3F4" ,padding: Sizes.fixPadding * 2, paddingTop: 20 }}>
        <View
          style={{
            flex: 1,
            backgroundColor: "#FFF",
            borderRadius: 5,
            marginBottom: 15,
            padding: Sizes.fixPadding * 2,
          }}
        >
          <Text style={{ lineHeight: 24 }}>
            {ListItem
              ? ListItem.data.data.push_notification_info.description
              : global.notification.body}
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor:Colors.primaryColor
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom:15,
    marginTop:40
  },
});

export default NotificationDetail;
