import React from "react";
import { Platform } from "react-native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { createStackNavigator } from "@react-navigation/stack";

import CustomDrawer from "../components/CustomDrawer";
import { Colors } from "../constants/style";
import { Ionicons } from "@expo/vector-icons";

import Login from "../screens/Login/index";
import Systems from "../screens/Systems";
import Dashboard from "../screens/Dashboard";
import PriceList from "../screens/PriceList";
import DefaultPrices from "../screens/PriceList/DefaultPrices";
import ClientPriceList from "../screens/PriceList/ClientPriceList";
import Order from "../screens/Order";
import OrderDetails from "../screens/Order/OrderDetails";
import OrderForm from "../screens/OrderForm";
import ClientOrders from "../screens/Order/ClientOrders";
import PriceDetail from "../screens/PriceList/PriceDetails";
import Projects from "../screens/Projects";
import Tasks from "../screens/Tasks";
import TaskDetails from "../screens/Tasks/taskDetails";
import RejectedStatus from "../screens/Tasks/rejectedStatus";
import BookingTasks from "../screens/Tasks/BookingTasks";
import RejectedHistory from "../screens/Tasks/rejectedHistroy";
import UserTasks from "../screens/Tasks/UserTasks";
import GroupTasks from "../screens/Tasks/GroupTasks";
import Clients from "../screens/Clients";
import UpdateClient from "../screens/Clients/UpdateClient";
import Profile from "../screens/Profile";
import Employees from "../screens/Employee";
import EmployeeDetails from "../screens/Employee/EmployeeDetails";
import UpdateEmployee from "../screens/Employee/UpdateEmployee";
import Calendar from "../screens/Calendar";
import CleanType from "../screens/CleanType";
import UpdateCleaning from "../screens/CleanType/Update";
import PropertyType from "../screens/PropertyType";
import UpdateProperty from "../screens/PropertyType/UpdatePoperty";
import RoomType from "../screens/RoomType";
import UpdateRoom from "../screens/RoomType/UpdateRoom";
import RejectedCheckList from "../screens/RejectedCheckList";
import UpdateList from "../screens/RejectedCheckList/UpdateList";
// import TestDatePicker from "../screens/TestDatePicker/index.";
import Bookings from "../screens/Bookings";
import PendingBookings from "../screens/Bookings/PendingBookings";
import ApprovedBookings from "../screens/Bookings/ApprovedBookings";
import CreateBooking from "../screens/Bookings/CreateBooking";
import BookingDetails from "../screens/Bookings/BookingDetails";
import BookingStaff from "../screens/Bookings/BookingStaff";
import SearchBookings from "../screens/Bookings/SearchBooking";
import UpdateBooking from "../screens/Bookings/UpdateBooking";
import Tax from "../screens/Tax";
import UpdateTax from "../screens/Tax/UpdateTax";
import Invoices from "../screens/Invoices";
import UpdateInvoice from "../screens/Invoices/UpdateInvoice";
import CreateInvoice from "../screens/Invoices/CreateInvoice";
import UserInvoices from "../screens/Invoices/UserInvoices";
import InvoiceDetailsUser from "../screens/Invoices/InvoiceDetailsUser";
import InvoiceDetails from "../screens/Invoices/InvoiceDetails";
import PaymentScreen from "../screens/PaymentScreen";
import NotificationScreen from "../screens/NotificationScreen";
import NotificationDetail from "../screens/NotificationDetail";
import Shifts from "../screens/Shifts";
import LoadingScreen from "../constants/loadingScreen";
import Groups from "../screens/Groups";
import UpdateGroup from "../screens/Groups/UpdateGroup";
import GroupUsers from "../screens/Groups/GroupUsers";
import Password from "../screens/Password";
import BookingHistory from "../screens/Bookings/BookingHistory";
import CreateTimesheet from "../screens/Timesheets/CreateTimesheet";
import LeaveRequest from "../screens/LeaveRequest";
import GetQuote from "../screens/Order/GetQuote";
import OwnBookingTimesheet from "../screens/UserTimeSheet/OwnBookingTimesheet";
import UserBookingTimesheet from "../screens/UserTimeSheet/UserBookingTimesheet";
import ClientBookingTimesheet from "../screens/UserTimeSheet/ClientBookingTimesheet";
import Folders from "../screens/FileManager";
import FolderCategory from "../screens/FileManager/FolderCategory";
import FolderContent from "../screens/FileManager/folderContent";
import UserNotifications from "../screens/NotificationScreen/userNotifications";

const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();

const HomeStack = (onSelectPublicKey) => {
  return (
    <Stack.Navigator
      initialRouteName={
        global.notification ||
        global.notification != undefined ||
        global.notification != null
          ? "NotificationDetail"
          : "Dashboard"
      }
      headerShown={false}
    >
      <Stack.Screen
        name="Dashboard"
        component={Dashboard}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="PriceList"
        component={PriceList}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="DefaultPrices"
        component={DefaultPrices}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="ClientPriceList"
        component={ClientPriceList}
        options={{ headerShown: false }}
        // options={({ route }) => ({
        //   title: route.params?.title,
        // })}
      />
      <Stack.Screen
        name="PriceDetail"
        component={PriceDetail}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="OrderForm"
        component={OrderForm}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Order"
        component={Order}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="OrderDetails"
        component={OrderDetails}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="ClientOrders"
        component={ClientOrders}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Projects"
        component={Projects}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Tasks"
        component={Tasks}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="TaskDetails"
        component={TaskDetails}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="RejectedStatus"
        component={RejectedStatus}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="BookingTasks"
        component={BookingTasks}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="UserTasks"
        component={UserTasks}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="GroupTasks"
        component={GroupTasks}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="RejectedHistory"
        component={RejectedHistory}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Clients"
        component={Clients}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="UpdateClient"
        component={UpdateClient}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Profile"
        component={Profile}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Employees"
        component={Employees}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="EmployeeDetails"
        component={EmployeeDetails}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="UpdateEmployee"
        component={UpdateEmployee}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Calendar"
        component={Calendar}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="CleanType"
        component={CleanType}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="UpdateCleaning"
        component={UpdateCleaning}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="PropertyType"
        component={PropertyType}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="UpdateProperty"
        component={UpdateProperty}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="RoomType"
        component={RoomType}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="UpdateRoom"
        component={UpdateRoom}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="RejectedCheckList"
        component={RejectedCheckList}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="UpdateList"
        component={UpdateList}
        options={{ headerShown: false }}
      />
      {/*  <Stack.Screen
        name="TestDatePicker"
        component={TestDatePicker}
        options={{ headerShown: false }}
      />
    */}
      <Stack.Screen
        name="Bookings"
        component={Bookings}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="PendingBookings"
        component={PendingBookings}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="ApprovedBookings"
        component={ApprovedBookings}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="CreateBooking"
        component={CreateBooking}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="BookingDetails"
        component={BookingDetails}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="UpdateBooking"
        component={UpdateBooking}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="BookingStaff"
        component={BookingStaff}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="BookingHistory"
        component={BookingHistory}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="SearchBookings"
        component={SearchBookings}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Tax"
        component={Tax}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="UpdateTax"
        component={UpdateTax}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Invoices"
        component={Invoices}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="CreateInvoice"
        component={CreateInvoice}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="UpdateInvoice"
        component={UpdateInvoice}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="UserInvoices"
        component={UserInvoices}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="InvoiceDetails"
        component={InvoiceDetails}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="InvoiceDetailsUser"
        component={InvoiceDetailsUser}
        options={{ headerShown: false }}
      />

      <Stack.Screen
        name="PaymentScreen"
        // component={() => (
        //   <PaymentScreen onSelectPublicKey={props.onSelectPublicKey} />
        // )}
        // component={PaymentScreen}
        options={{ headerShown: false }}
      >
        {(props) => (
          <PaymentScreen
            {...props}
            handlePublicKey={onSelectPublicKey.onSelectPublicKey}
          />
        )}
      </Stack.Screen>

      <Stack.Screen
        name="NotificationScreen"
        component={NotificationScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="NotificationDetail"
        component={NotificationDetail}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Shifts"
        component={Shifts}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Groups"
        component={Groups}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="UpdateGroup"
        component={UpdateGroup}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="GroupUsers"
        component={GroupUsers}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Password"
        component={Password}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="CreateTimesheet"
        component={CreateTimesheet}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="OwnBookingTimesheet"
        component={OwnBookingTimesheet}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="UserBookingTimesheet"
        component={UserBookingTimesheet}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="ClientBookingTimesheet"
        component={ClientBookingTimesheet}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="LeaveRequest"
        component={LeaveRequest}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="GetQuote"
        component={GetQuote}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="FileManger"
        component={Folders}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="FolderCategory"
        component={FolderCategory}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="FolderContent"
        component={FolderContent}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="UserNotifications"
        component={UserNotifications}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  );
};

const AppStack = ({ onSelectPublicKey }) => {
  return (
    <Drawer.Navigator
      initialRouteName="Loading"
      drawerContent={(props) => <CustomDrawer {...props} />}
      screenOptions={{
        headerShown: false,
        drawerActiveBackgroundColor: Colors.primaryColor,
        drawerActiveTintColor: "#fff",
        drawerInactiveTintColor: "#333",
        drawerLabelStyle: {
          marginLeft: -25,
          // fontFamily: 'Roboto-Medium',
          fontSize: 15,
        },
        headerStyle: {
          backgroundColor: Colors.primaryColor,
        },
        headerTintColor: "#fff",
        headerTitleStyle: {
          fontWeight: "bold",
          fontSize: 18,
        },
        headerTitleAlign: "center",
      }}
    >
      <Drawer.Screen
        name="Loading"
        component={LoadingScreen}
        options={{
          drawerItemStyle: {
            display: "none",
          },
          headerShown: false,
          swipeEnabled: false,
        }}
      />
      <Drawer.Screen
        name="Login"
        component={Login}
        options={{
          drawerItemStyle: {
            display: "none",
          },
          headerShown: false,
          swipeEnabled: false,
          drawerIcon: ({ color }) => (
            <Ionicons name="person-outline" size={22} color={color} />
          ),
        }}
      />
      <Drawer.Screen
        name="Systems"
        component={Systems}
        options={{
          drawerItemStyle: {
            display: "none",
          },
          headerShown: false,
          swipeEnabled: false,
          drawerIcon: ({ color }) => (
            <Ionicons name="person-outline" size={22} color={color} />
          ),
        }}
      />
      <Drawer.Screen
        name="Home"
        options={{
          drawerIcon: ({ color }) => (
            <Ionicons name="home-outline" size={22} color={color} />
          ),
        }}
      >
        {(props) => (
          <HomeStack {...props} onSelectPublicKey={onSelectPublicKey} />
        )}
      </Drawer.Screen>
    </Drawer.Navigator>
  );
};

export default AppStack;
