import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  StatusBar,
  Image,
  TextInput,
  TouchableOpacity,
  Alert,
  Dimensions,
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import { Button, SpeedDial, ListItem, Overlay } from "react-native-elements";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { Fontisto, Ionicons, Entypo, FontAwesome5 } from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import { useForm, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import CustomPicker from "../../components/Picker/Picker";
import Loading from "../Utils/Loading";

const windowWidth = Dimensions.get("window").width;

const UpdatePrice = ({ isVisible1, selectedPrice, toggleOverlay }) => {

  const [selectedSystem, setSelectedSystem] = useState(selectedPrice.system_id);
  const [selectedStatus, setSelectedStatus] = useState(
    "" + selectedPrice.status
  );
  const [cleaningData, setCleaningData] = useState([]);

  const [selectedCleaningType, setSelectedCleaningType] = useState(
    selectedPrice.cleaning_type_id !== null ? selectedPrice.cleaning_type_id : 0
  );
  const [roomData, setRoomData] = useState([]);
  const [selectedRoomType, setSelectedRoomType] = useState(
    selectedPrice.room_type_id !== null ? selectedPrice.room_type_id : 0
  );
  const [propertyData, setPropertyData] = useState([]);
  const [selectedPropertyType, setSelectedPropertyType] = useState(
    selectedPrice.property_type_id !== null ? selectedPrice.property_type_id : 0
  );
  const [isdefault, setIsDefauult] = useState("0");
  const [isrecurring, setIsRecurring] = useState("0");
  const [systemError, setSystemError] = useState(false);
  const [systemErrorStatus, setSystemErrorStatus] = useState(false);

  const [priceType, setPriceType] = useState(
    selectedPrice.price_type !== "" || selectedPrice.price_type !== null
      ? selectedPrice.price_type
      : 0
  ); 
  const [isLoding, setIsLoading] = useState(false);

  const defaultValues = [
    { value: "0", label: "No" },
    { value: "1", label: "Yes" },
  ];

  const recurringValues = [
    { value: "0", label: "No" },
    { value: "1", label: "Yes" },
  ];

  const priceTypes = [
    { value: "Room Based", label: "Room Based" },
    { value: "Hourly", label: "Hourly" },
  ];

  const statusData = [
    { value: "0", label: "InActive" },
    { value: "1", label: "Active" },
  ];

  useEffect(() => {
    (async () => {
      GetCleaningType();
      GetRoomType();
      GetPropertyType();
    })();
  }, []);

  const GetCleaningType = () => {
    setIsLoading(true)
    jwt
      .get(`${Url.GET_ACTIVE_CLEANING_TYPE}/${global.systemID}`)
      .then((response) => {
        const newArr = [];
        // setCleaningData(response.data.cleaning_types);
        response.data.cleaning_types.map((item) =>
          newArr.push({ value: item.id, label: item.name, ...item })
        );
        setCleaningData(newArr);
      })
      .catch((err) => {});
  };

  const GetRoomType = () => {
    jwt
      .get(`${Url.GET_ACTIVE_ROOM_TYPE}/${global.systemID}`)
      .then((response) => {
        const newArr = [];
        // setRoomData(response.data.room_types);
        response.data.room_types.map((item) =>
          newArr.push({ value: item.id, label: item.name, ...item })
        );
        setRoomData(newArr);
      })
      .catch((err) => {});
  };

  const GetPropertyType = () => {
    jwt
      .get(`${Url.GET_ACTIVE_PROPERTY_TYPE}/${global.systemID}`)
      .then((response) => {
        const newArr = [];
        // setPropertyData(response.data.property_types);
        response.data.property_types.map((item) =>
          newArr.push({ value: item.id, label: item.name, ...item })
        );
        setPropertyData(newArr);
        setIsLoading(false)
      })
      .catch((err) => {});
  };

  const handleSelectedCleaning = (val, index) => {
    // setSystemError(false);
    if (parseInt(val) !== 0) {
      setSelectedCleaningType(val);
    } else {
      setSelectedCleaningType(null);
    }
  };

  const handleSelectedRoom = (val, index) => {
    // setSystemError(false);
    if (parseInt(val) !== 0) {
      setSelectedRoomType(val);
    } else {
      setSelectedRoomType(null);
    }
  };

  const handleSelectedProperty = (val, index) => {
    // setSystemError(false);
    if (parseInt(val) !== 0) {
      setSelectedPropertyType(val);
    } else {
      setSelectedPropertyType(null);
    }
  };

  const handleSelectedDefault = (selectedItem, index) => {
    setIsDefauult(selectedItem);
  };

  const handleSelectedRecurring = (selectedItem, index) => {
    setIsRecurring(selectedItem);
  };

  const handleSelectedStatus = (val, index) => {
    setSystemErrorStatus(false);
    setSelectedStatus(val);
  };

  const handlePriceType = (type, index) => {
    setPriceType(type);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (selectedSystem !== null) {
      jwt
        .post(`${Url.UPDATE_Order_PRICE}/${selectedPrice.id}`, {
          cleaning_type_id: selectedCleaningType,
          room_type_id: selectedRoomType,
          property_type_id: selectedPropertyType,
          is_default: parseInt(isdefault),
          is_recurring: parseInt(isrecurring),
          price_type: priceType,
          price: data.price,
          assign_to: parseInt(global.userData.id),
          system_id: parseInt(global.systemID),
          status: parseInt(selectedStatus),
        })
        .then((response) => {
          toggleOverlay();
        })
        .catch((err) => {});
    } else {
      setSystemError(true);
    }
  };

  return (
    <Overlay
      visible={isVisible1}
      overlayStyle={{
        width: "80%",
        backgroundColor: "#FFFFFF",
        borderRadius: 8,
        paddingTop: 15,
        paddingBottom: 20,
        padding: Sizes.fixPadding * 2,
        height: 500,
      }}
      onBackdropPress={toggleOverlay}
    >
      <View style={{ alignItems: "center", marginTop: 3, marginBottom: 10 }}>
        <Text style={{ fontSize: 18 }}>Update Price</Text>
      </View>
      {isLoding ? <Loading /> :
      <ScrollView>
        <View style={{ flexDirection: "row", marginTop: 15 }}>
          <View style={styles.child1}>
            <Text style={styles.label}>{"Cleaning"}</Text>
          </View>
          <View style={styles.child2}>
            <CustomPicker
              data={cleaningData}
              selectedValue={selectedCleaningType}
              handleSelectedValue={handleSelectedCleaning}
              defaultLabel={"select cleaning Type"}
              // style={{backgroundColor:"#FFF"}}
            />
          </View>
        </View>

        <View style={{ flexDirection: "row", marginTop: 15 }}>
          <View style={styles.child1}>
            <Text style={styles.label}>{"Room"}</Text>
          </View>
          <View style={styles.child2}>
            <CustomPicker
              data={roomData}
              selectedValue={selectedRoomType}
              handleSelectedValue={handleSelectedRoom}
              defaultLabel={"select Room Type"}
              // style={{backgroundColor:"#FFF"}}
            />
          </View>
        </View>

        <View style={{ flexDirection: "row", marginTop: 15 }}>
          <View style={styles.child1}>
            <Text style={styles.label}>{"Property type"}</Text>
          </View>
          <View style={styles.child2}>
            <CustomPicker
              data={propertyData}
              selectedValue={selectedPropertyType}
              handleSelectedValue={handleSelectedProperty}
              defaultLabel={"Select Property Type"}
              // style={{backgroundColor:"#FFF"}}
            />
          </View>
        </View>

        <View style={{ flexDirection: "row", marginTop: 15 }}>
          <View style={styles.child1}>
            <Text style={styles.label}>{"Is Default"}</Text>
          </View>
          <View style={styles.child2}>
            <CustomPicker
              data={defaultValues}
              selectedValue={isdefault}
              handleSelectedValue={handleSelectedDefault}
            />
          </View>
        </View>

        <View style={{ flexDirection: "row", marginTop: 15 }}>
          <View style={styles.child1}>
            <Text style={styles.label}>{"Is Recurring"}</Text>
          </View>
          <View style={styles.child2}>
            <CustomPicker
              data={recurringValues}
              selectedValue={isrecurring}
              handleSelectedValue={handleSelectedRecurring}
            />
          </View>
        </View>

        <View style={{ flexDirection: "row", marginTop: 15 }}>
          <View style={styles.child1}>
            <Text style={styles.label}>{"Price Type"}</Text>
          </View>
          <View style={styles.child2}>
            <CustomPicker
              data={priceTypes}
              selectedValue={priceType}
              handleSelectedValue={handlePriceType}
            />
          </View>
        </View>

        <View style={{ flexDirection: "row", marginTop: 15 }}>
          <View style={styles.child1}>
            <Text style={styles.label}>{"Price"}</Text>
          </View>
          <View style={styles.child2}>
            <Controller
              name="price"
              control={control}
              rules={{
                required: { value: true, message: "Price is required" },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  // label="Price"
                  onChangeText={(value) => onChange(value)}
                  value={value}
                  error={errors.price}
                  errorText={errors?.price?.message}
                />
              )}
              defaultValue={"" + selectedPrice.price}
            />
          </View>
        </View>

        <View style={{ flexDirection: "row", marginTop: 15 }}>
          <View style={styles.child1}>
            <Text style={styles.label}>{"Status"}</Text>
          </View>
          <View style={styles.child2}>
            <CustomPicker
              data={statusData}
              selectedValue={selectedStatus}
              handleSelectedValue={handleSelectedStatus}
            />
          </View>
        </View>

        <Button
          title="Update Price"
          type="solid"
          //   loading={isLoding}
          onPress={handleSubmit(onSubmit)}
          buttonStyle={{
            padding: Sizes.fixPadding,
            width: "100%",
            backgroundColor: Colors.primaryColor,
            borderRadius: 22,
          }}
          containerStyle={{
            width: "100%",
            // marginHorizontal: 50,
            marginVertical: 20,
          }}
          titleStyle={{
            fontSize: 14,
            fontWeight: "100",
            color: Colors.whiteColor,
          }}
          icon={
            <Ionicons
              name="add-circle-sharp"
              size={24}
              color="#ffffff"
              style={{ marginRight: "2%" }}
            />
          }
        />
      </ScrollView>
}
    </Overlay>
  );
};
const styles = StyleSheet.create({
  headerWrapStyle: {
    height: 55.0,
    backgroundColor: Colors.primaryColor,
    elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
  },
  label: {
    color: "black",
    marginLeft: 0,
    marginBottom: 3,
    fontSize: windowWidth > 600 ? 18 : 16,
    fontWeight: "500",
    marginTop: windowWidth > 600 ? 0 : 12,
  },
  child1: {
    width: windowWidth > 600 ? "15%" : "30%",
  },
  child2: {
    width: windowWidth > 600 ? "85%" : "70%",
  },
});
export default UpdatePrice;
