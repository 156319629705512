import React, { useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  Button,
  Overlay,
  ListItem,
  SpeedDial,
  TabView,
  Avatar,
  BottomSheet,
} from "react-native-elements";

import {
  Ionicons,
  Entypo,
  MaterialIcons,
  MaterialCommunityIcons,
  FontAwesome,
  Octicons,
  FontAwesome5,
} from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import { useForm, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import Loading from "../Utils/Loading";
import * as ImagePicker from "expo-image-picker";
import SectionedMultiSelect from "react-native-sectioned-multi-select";
import moment from "moment";
import CustomDatePicker from "../../components/datePicker/datePicker";
import CustomPicker from "../../components/Picker/Picker";
const windowWidth = Dimensions.get("window").width;

const UpdateClient = (navigation) => {
  const item = navigation.route.params.item;
  // console.log(item)
  const [clientsData, setClientsData] = useState([]);
  const [openDial, setOpenDial] = useState(false);
  const [isLoding, setIsLoading] = useState(false);
  const [employeeRoles, setEmployeeRoles] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [showBottomSheet, SetShowBottomSheet] = useState(false);
  const [imagePicked, setImagePicked] = useState(null);
  const [imagePickedPath, setImagePickedPath] = useState(null);
  const [selectedItems, setSelectedItems] = useState(item.role_names);
  const [selectedStatus, setSelectedStatus] = useState(item.status);
  const [clientType, setClientType] = useState(item.client_type);
  const [date, setDate] = useState(new Date());
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [dob, setDob] = useState(new Date(item.dob));


  const clientTypes = [
    { value: "Individual", label: "Individual" },
    { value: "Company", label: "Company" },
    { value: "Chairity", label: "Chairity" },
  ];

  const statusData = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          {item.first_name} {item.last_name}
        </Text>
        <MaterialIcons
          name="arrow-back"
          size={26}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 15.0,
            fontWeight: 300,
          }}
          onPress={() => navigation.navigation.pop()}
        />
      </View>
    );
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setIsLoading(true);

    const newData = {
      organization_name: data.organization_name,
      email: data.email,
      phone: data.phone,
      address: data.address,
      post_code: data.post_code,
      client_type: clientType,
      company: data.company,
      company_register: data.company_register,
      unique_tax_reference: data.unique_tax_reference,
      charity: data.charity,
      system_id: parseInt(global.systemID),
      status: selectedStatus,
    };

    jwt
      .post(`${Url.UPDATE_CLIENT}/${item.id}`, newData)
      .then((response) => {
        navigation.navigation.navigate("Clients");
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });

    if (imagePicked != null) {
      imageUpload();
    }
  };

  return (
    <View style={styles.container}>
      {header()}
      <ScrollView
        style={{
          flex: 1,
          backgroundColor: "#F2F3F4",
          paddingHorizontal: Sizes.fixPadding * 2,
        }}
      >
        <View
          style={{
            backgroundColor: "#ffffff",
            marginTop: 20,
            borderRadius: 10,
            padding: Sizes.fixPadding * 2,
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <View style={styles.child1}>
              <Text style={{ ...styles.label }}>{"Client Type"}</Text>
            </View>
            <View style={styles.child2}>
              <CustomPicker
                data={clientTypes}
                selectedValue={clientType}
                handleSelectedValue={(val) => setClientType(val)}
                defaultLabel={"Select Client Type"}
                style={{}}
              />
            </View>
          </View>

          <View
            style={{ flexDirection: "row", marginBottom: 15, marginTop: 15 }}
          >
            <View style={styles.child1}>
              <Text style={styles.label}>{"Organization Name"}</Text>
            </View>
            <View style={styles.child2}>
              <Controller
                name="organization_name"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Organization Name is required",
                  },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    // label="First Name"
                    type="text"
                    onChangeText={(value) => onChange(value)}
                    value={value}
                    error={errors.organization_name}
                    errorText={errors?.organization_name?.message}
                  />
                )}
                defaultValue={item.organization_name}
              />
            </View>
          </View>

          <View style={{ flexDirection: "row", marginBottom: 15 }}>
            <View style={styles.child1}>
              <Text style={styles.label}>{"Email"}</Text>
            </View>
            <View style={styles.child2}>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: { value: true, message: "Email is required" },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    // label="Email"
                    type="email"
                    onChangeText={(value) => onChange(value)}
                    value={value}
                    error={errors.email}
                    errorText={errors?.email?.message}
                  />
                )}
                defaultValue={item.email}
              />
            </View>
          </View>

          <View style={{ flexDirection: "row", marginBottom: 15 }}>
            <View style={styles.child1}>
              <Text style={styles.label}>{"Phone"}</Text>
            </View>
            <View style={styles.child2}>
              <Controller
                name="phone"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    // label="Phone"
                    type="text"
                    onChangeText={(value) => onChange(value)}
                    value={value}
                  />
                )}
                defaultValue={item.phone}
              />
            </View>
          </View>

          <View style={{ flexDirection: "row", marginBottom: 15 }}>
            <View style={styles.child1}>
              <Text style={styles.label}>{"Address"}</Text>
            </View>
            <View style={styles.child2}>
              <Controller
                name="address"
                control={control}
                rules={{
                  required: { value: true, message: "Address is required" },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    // label="Address"
                    type="text"
                    onChangeText={(value) => onChange(value)}
                    value={value}
                    error={errors.address}
                    errorText={errors?.address?.message}
                  />
                )}
                defaultValue={item.address}
              />
            </View>
          </View>

          <View style={{ flexDirection: "row", marginBottom: 15 }}>
            <View style={styles.child1}>
              <Text style={styles.label}>{"PostCode"}</Text>
            </View>
            <View style={styles.child2}>
              <Controller
                name="postCode"
                control={control}
                //   rules={{
                //     required: { value: true, message: "Company is required" },
                //   }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    // label="PostCode"
                    type="text"
                    onChangeText={(value) => onChange(value)}
                    value={value}
                    //   error={errors.company}
                    //   errorText={errors?.company?.message}
                  />
                )}
                defaultValue={item.post_code}
              />
            </View>
          </View>

          {clientType === "Company" && (
            <View>
              <View style={{ flexDirection: "row", marginTop: 15 }}>
                <View style={styles.child1}>
                  <Text style={{ ...styles.label }}>{"Company"}</Text>
                </View>
                <View style={styles.child2}>
                  <Controller
                    name="company"
                    control={control}
                    // rules={{
                    //   required: { value: true, message: "Company is required" },
                    // }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        // label="Company"
                        type="text"
                        onChangeText={(value) => onChange(value)}
                        value={value}
                      />
                    )}
                    defaultValue={item.company}
                  />
                </View>
              </View>

              <View style={{ flexDirection: "row", marginTop: 15 }}>
                <View style={styles.child1}>
                  <Text style={{ ...styles.label }}>{"Company Register"}</Text>
                </View>
                <View style={styles.child2}>
                  <Controller
                    name="company_register"
                    control={control}
                    // rules={{
                    //   required: { value: true, message: "Country is required" },
                    // }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        // label="Company Register"
                        type="text"
                        onChangeText={(value) => onChange(value)}
                        value={value}
                      />
                    )}
                    defaultValue={item.company_register}
                  />
                </View>
              </View>

              <View style={{ flexDirection: "row", marginTop: 15 }}>
                <View style={styles.child1}>
                  <Text style={{ ...styles.label }}>{"Tax Reference"}</Text>
                </View>
                <View style={styles.child2}>
                  <Controller
                    name="unique_tax_reference"
                    control={control}
                    //   rules={{
                    //     required: { value: true, message: "Company is required" },
                    //   }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        // label="Unique Tax Reference"
                        type="text"
                        onChangeText={(value) => onChange(value)}
                        value={value}
                        //   error={errors.company}
                        //   errorText={errors?.company?.message}
                      />
                    )}
                    defaultValue={item.unique_tax_reference}
                  />
                </View>
              </View>
            </View>
          )}
          {clientType === "Chairity" && (
            <View style={{ flexDirection: "row", marginTop: 15 }}>
              <View style={styles.child1}>
                <Text style={{ ...styles.label }}>{"Chairity"}</Text>
              </View>
              <View style={styles.child2}>
                <Controller
                  name="charity"
                  control={control}
                  //   rules={{
                  //     required: { value: true, message: "Company is required" },
                  //   }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      // label="Charity"
                      type="text"
                      onChangeText={(value) => onChange(value)}
                      value={value}
                      //   error={errors.company}
                      //   errorText={errors?.company?.message}
                    />
                  )}
                  defaultValue={item.charity}
                />
              </View>
            </View>
          )}

          <View style={{ flexDirection: "row", marginTop: 15 }}>
            <View style={styles.child1}>
              <Text style={styles.label}>{"Status"}</Text>
            </View>
            <View style={styles.child2}>
              <CustomPicker
                data={statusData}
                selectedValue={selectedStatus}
                handleSelectedValue={(val, index) => {
                  setSelectedStatus(val);
                }}
              />
            </View>
          </View>

          <Button
            title="Update Client"
            type="solid"
            loading={isLoding}
            onPress={handleSubmit(onSubmit)}
            buttonStyle={{
              padding: Sizes.fixPadding,
              width: "100%",
              backgroundColor: Colors.primaryColor,
              borderRadius: 22,
            }}
            containerStyle={{
              width: "100%",
              // marginHorizontal: 50,
              marginVertical: 20,
            }}
            titleStyle={{
              fontSize: 14,
              fontWeight: "100",
              color: Colors.whiteColor,
            }}
            icon={
              <MaterialCommunityIcons
                name="account-edit-outline"
                size={24}
                color="#ffffff"
                style={{ marginRight: "2%" }}
              />
            }
          />
        </View>
      </ScrollView>

      {show1 && (
        <DateTimePicker
          testID="dateTimePicker"
          value={date}
          mode={"date"}
          // display={"spinner"}
          // is24Hour={true}
          onChange={onChangeDob}
        />
      )}

      {show2 && (
        <DateTimePicker
          testID="dateTimePicker"
          value={date}
          mode={"date"}
          // display={"spinner"}
          // is24Hour={true}
          onChange={onChangeJoiningDate}
        />
      )}

      <BottomSheet modalProps={{}} isVisible={showBottomSheet}>
        <ListItem>
          <ListItem.Content>
            <TouchableOpacity
              style={{ flexDirection: "row", width: "100%" }}
              onPress={() => openCamera()}
            >
              <MaterialIcons
                name="camera"
                size={26}
                color={Colors.primaryColor}
              />

              <Text
                style={{
                  paddingLeft: 5,
                  fontSize: 16,
                  paddingTop: 2,
                }}
              >
                Take a photo
              </Text>
            </TouchableOpacity>
          </ListItem.Content>
        </ListItem>

        <ListItem style={{ borderBottomWidth: 1, borderBottomColor: "#CCC" }}>
          <ListItem.Content>
            <TouchableOpacity
              style={{ flexDirection: "row", width: "100%" }}
              onPress={() => showImagePicker()}
            >
              <MaterialIcons
                name="photo-library"
                size={26}
                color={Colors.primaryColor}
              />

              <Text
                style={{
                  paddingLeft: 5,
                  fontSize: 16,
                  paddingTop: 2,
                }}
              >
                Choose from your photos
              </Text>
            </TouchableOpacity>
          </ListItem.Content>
        </ListItem>

        <ListItem>
          <ListItem.Content>
            <TouchableOpacity
              style={{ flexDirection: "row", width: "100%" }}
              onPress={() => SetShowBottomSheet(false)}
            >
              <Entypo name="cross" size={26} color={Colors.primaryColor} />

              <Text
                style={{
                  paddingLeft: 5,
                  fontSize: 16,
                  paddingTop: 2,
                }}
              >
                Cancel
              </Text>
            </TouchableOpacity>
          </ListItem.Content>
        </ListItem>
      </BottomSheet>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: Colors.primaryColor,
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 15,
    marginTop: 40,
  },
  label: {
    color: "black",
    marginLeft: 0,
    marginBottom: 3,
    fontSize: windowWidth > 600 ? 18 : 16,
    fontWeight: "500",
    marginTop: windowWidth > 600 ? 0 : 12,
  },
  child1: {
    width: windowWidth > 600 ? "15%" : "30%",
  },
  child2: {
    width: windowWidth > 600 ? "85%" : "70%",
  },
});
export default UpdateClient;
