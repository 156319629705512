import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, ScrollView } from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  Button,
  SpeedDial,
  ListItem,
  Overlay,
  Dialog,
} from "react-native-elements";
import {
  Feather,
  Ionicons,
  Entypo,
  MaterialIcons,
  Octicons,
} from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import Loading from "../Utils/Loading";
import moment from "moment";
import { ModulesPermission } from "../../constants/modules";
import AuthorizedFor from "../../constants/authorizedFor";
import { authorizedForFlateList } from "../../constants/authorizedForFlateList";

const BookingDetails = (navigation) => {
  const item = navigation.route.params.item;

  const [isVisible, setIsVisible] = useState(false);
  const [isVisible1, setIsVisible1] = useState(false);
  const [isLoding, setIsLoading] = useState(false);
  const [orderId, setOrderId] = useState(item.id);
  const [selectedStatus, setSelectedStatus] = useState(item.status);
  const [selectedPriority, setSelectedPriority] = useState(item.priority);

  const [taskID, setTaskID] = useState(null);

  const [weeks, setWeeks] = useState([]);
  const [weeksData, setWeeksData] = useState([]);
  const [count, setCount] = useState(-1);
  const [viewTask, setViewTask] = useState(false);

  // console.log(item);
  useEffect(() => {
    (async () => {
      (await authorizedForFlateList(
        ModulesPermission.Get_Booking_Tasks_By_BookingID_For_Client
      ))
        ? (setTaskID(item.booking_id), GetTasks(item.booking_id)) 
        : setTaskID(`${item.id}`.split("-")), GetTasks(`${item.id}`.split("-"));
    })();

    // AuthorizedFor(ModulesPermission.Get_Booking_Tasks_By_BookingID_For_Client)
    //   ? setTaskID(item.booking_id)
    //   : setTaskID(`${item.id}`.split("-"));
    // // setIsLoading(true);
  }, []);

  // useEffect(() => {
  //   (async () => {

  //   })();
  // }, []);

  useEffect(() => {
    const newData = [];

    for (let i = 0; i < item.no_of_recurring + 1; i++) {
      newData.push({ week: `Week ${i}` });
      // GetData(i);
    }

    setWeeks(newData);
  }, [item]);

  useEffect(() => {
    item.no_of_recurring == count && setIsLoading(false);
  }, [count]);

  const GetTasks = (id) => {
    setIsLoading(true);

    jwt
      .get(`${Url.GET_ORDER_TASKS_WITH_BOOKING}/${id}`)
      .then((response) => {
        setIsLoading(false);
        response.data.order_task.length > 0 && setViewTask(true);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const statusData = [
    "Cleaned",
    "Confirmed",
    "Pending",
    "Rejected",
    "Subcontracted",
  ];

  const priorityData = ["High", "Low", "Medium", "Urgent"];

  const handleStatus = (selectedItems) => {
    setSelectedStatus(selectedItems);
  };

  const handlePriority = (selectedItems) => {
    setSelectedPriority(selectedItems);
  };

  const handleSubmitStatus = (navigation) => {
    setIsLoading(true);
    jwt
      .post(`${Url.UPDATE_ORDER_STATUS}/${orderId}`, { status: selectedStatus })
      .then((response) => {
        setIsLoading(false);

        navigation.navigation.navigate("Order", { item: "refresh" });
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleSubmitPriority = (navigation) => {
    setIsLoading(true);
    jwt
      .post(`${Url.UPDATE_ORDER_PRIORITY}/${orderId}`, {
        priority: selectedPriority,
      })
      .then((response) => {
        setIsLoading(false);

        navigation.navigation.navigate("Order", { item: "refresh" });
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  // console.log(item);
  const header = (item) => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          Booking Details
        </Text>
        <MaterialIcons
          name="arrow-back"
          size={26}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 15.0,
            fontWeight: 300,
          }}
          onPress={() => navigation.navigation.pop()}
        />
        {AuthorizedFor(ModulesPermission.Get_All_Staff_Assigned_To_Booking) && (
          <Feather
            name="users"
            size={26}
            color={Colors.whiteColor}
            style={{
              position: "absolute",
              right: 15.0,
              fontWeight: 300,
            }}
            onPress={() =>
              navigation.navigation.navigate("BookingStaff", {
                bookingId: item.id,
              })
            }
          />
        )}
      </View>
    );
  };

  const GetData = (weekNo) => {
    jwt
      .post(`${Url.GET_EACH_USER_BOOKING}/${item.id}`, {
        week_no: "" + weekNo,
      })
      .then((response) => {
        if (response.data.user_bookings.length > 0) {
          const data = response.data.user_bookings;
          setWeeksData([...weeksData, ...data]);
        }

        setCount(parseInt(weekNo));
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleCompleteOrder = (id) => {
    setIsLoading(true);
    jwt
      .post(`${Url.UPDATE_ORDER_STATUS}/${id}`, { status: "Cleaned" })
      .then((response) => {
        // console.log(response);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  // useEffect(() => {
  //   setIsLoading(true);

  //   GetUserShifts(bookingItem.user_ref_id);

  //   const newData = [...weeks];

  //   for (let i = 0; i < bookingItem.no_of_recurring + 1; i++) {
  //     newData.push(`Week ${i}`);
  //     GetData(i);
  //   }

  //   setWeeks(newData);
  // }, []);

  return (
    <View style={styles.container}>
      {header(item)}

      <ScrollView
        style={{
          padding: Sizes.fixPadding * 2,
          backgroundColor:"#F2F3F4"
        }}
      >
        <View
          style={{
            padding: Sizes.fixPadding,
            backgroundColor: "#fff",
            borderRadius: 6,
          }}
        >
          <View style={{ flexDirection: "row", marginBottom: 10 }}>
            <Text style={{ fontWeight: "bold" }}>BookingId: </Text>
            <Text style={{ fontWeight: "normal", fontSize: 14 }}>
              {item.booking_id}
            </Text>
            {/* {AuthorizedFor(ModulesPermission.Approve_Or_Reject_Booking) && ( */}
            <Feather
              name="edit"
              size={22}
              color={Colors.primaryColor}
              style={{
                position: "absolute",
                right: 0.0,
                // fontWeight: 300
              }}
              onPress={() =>
                navigation.navigation.navigate("UpdateBooking", {
                  bookingDetails: {
                    bookingItem: item,
                    bookingData: weeksData,
                  },
                })
              }
            />
            {/* )} */}
          </View>

          <View style={{ flexDirection: "row", marginBottom: 10 }}>
            <Text style={{ fontWeight: "bold" }}>Booking Type: </Text>
            <Text style={{ fontWeight: "normal", fontSize: 14 }}>
              {item.booking_type}
            </Text>
          </View>

          <View style={{ flexDirection: "row", marginBottom: 10 }}>
            <Text style={{ fontWeight: "bold" }}>Booking Date: </Text>
            <Text style={{ fontWeight: "normal", fontSize: 14 }}>
              {item.booking_date}
            </Text>
          </View>

          <View style={{ flexDirection: "row", marginBottom: 10 }}>
            <Text style={{ fontWeight: "bold" }}>No .of Staff: </Text>
            <Text style={{ fontWeight: "normal", fontSize: 14 }}>
              {item.no_of_staff_required}
            </Text>
          </View>

          <View style={{ flexDirection: "row", marginBottom: 10 }}>
            <Text style={{ fontWeight: "bold" }}>No .of Recurring: </Text>
            <Text style={{ fontWeight: "normal", fontSize: 14 }}>
              {item.no_of_recurring}
            </Text>
          </View>

          <View style={{ flexDirection: "row", marginBottom: 10 }}>
            <Text style={{ fontWeight: "bold" }}>Status: </Text>
            <Text style={{ fontWeight: "normal", fontSize: 14 }}>
              {item.booking_status}
            </Text>
          </View>

          <View style={{ flexDirection: "row", marginBottom: 10 }}>
            <Text style={{ fontWeight: "bold" }}>Days: </Text>
            <Text style={{ fontWeight: "normal", fontSize: 14 }}>
              {item.days_per_week}
            </Text>
          </View>

          <View style={{ flexDirection: "row", marginBottom: 10 }}>
            <Text style={{ fontWeight: "bold" }}>Notes: </Text>
            <Text style={{ fontWeight: "normal", fontSize: 14 }}>
              {item.booking_notes}
            </Text>
          </View>

          <View style={{ flexDirection: "row" }}>
            {AuthorizedFor(ModulesPermission.Get_Order_Tasks_By_Booking_ID) &&
              viewTask && (
                <Button
                  title="View Tasks"
                  type="solid"
                  //   loading={isLoding}
                  // onPress={() =>
                  //   navigation.navigation.navigate("BookingTasks", {
                  //     bookingId: taskID,
                  //   })
                  // }
                  onPress={() =>
                    navigation.navigation.navigate("Tasks", {
                       bookingId: taskID,
                    })
                  }
                  buttonStyle={{
                    padding: Sizes.fixPadding,
                    width: "100%",
                    backgroundColor: Colors.primaryColor,
                    borderRadius: 22,
                  }}
                  containerStyle={{
                    width: "48%",
                    // marginHorizontal: 50,
                    marginVertical: 20,
                  }}
                  titleStyle={{
                    fontSize: 14,
                    fontWeight: "100",
                    color: Colors.whiteColor,
                  }}
                  icon={
                    <Ionicons
                      name="add-circle-sharp"
                      size={24}
                      color="#ffffff"
                      style={{ marginRight: "2%" }}
                    />
                  }
                />
              )}
            {AuthorizedFor(ModulesPermission.Complete_Booking_Order) &&
              item.order_id != null && (
                <Button
                  title="Complete Booking Order"
                  type="solid"
                  //   loading={isLoding}
                  onPress={() => handleCompleteOrder(item.order_id)}
                  buttonStyle={{
                    padding: Sizes.fixPadding,
                    width: "100%",
                    backgroundColor: Colors.primaryColor,
                    borderRadius: 22,
                  }}
                  containerStyle={{
                    width: "48%",
                    // marginHorizontal: 50,
                    marginVertical: 20,
                    marginLeft: "2%",
                  }}
                  titleStyle={{
                    fontSize: 14,
                    fontWeight: "100",
                    color: Colors.whiteColor,
                  }}
                  icon={
                    <Ionicons
                      name="add-circle-sharp"
                      size={24}
                      color="#ffffff"
                      style={{ marginRight: "2%" }}
                    />
                  }
                />
              )}
          </View>
        </View>

        {weeksData.length > 0 &&
          weeksData.map((item, index) => (
            <View
              style={{
                padding: Sizes.fixPadding,
                backgroundColor: "#fff",
                borderRadius: 6,
                marginTop: 15,
              }}
              key={index}
            >
              <Text style={{ fontSize: 18, fontWeight: "700" }}>
                {/* {`Recurring ${index}`} */}
                {`Week ${item.week_no}`}
              </Text>

              <View
                style={{
                  flexDirection: "row",
                  marginBottom: 10,
                  marginTop: 15,
                }}
              >
                <Text style={{ fontWeight: "bold" }}>Day: </Text>
                <Text style={{ fontWeight: "normal", fontSize: 14 }}>
                  {item.day}
                </Text>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  marginBottom: 10,
                  // marginTop: 15,
                }}
              >
                <Text style={{ fontWeight: "bold" }}>Shift: </Text>
                <Text style={{ fontWeight: "normal", fontSize: 14 }}>
                  {`${item.shift.day} ${item.shift.start_time}-${item.shift.end_time}`}
                </Text>
              </View>

              {item.booking_staff.length > 0 && (
                <View
                  style={{
                    flexDirection: "row",
                    marginBottom: 10,
                    // marginTop: 15,
                  }}
                >
                  <Text style={{ fontWeight: "bold" }}>Staff: </Text>
                  {item.booking_staff.map((staff, index) => (
                    <Text
                      key={index}
                      style={{ fontWeight: "normal", fontSize: 14 }}
                    >
                      {`${staff.full_name}, `}
                    </Text>
                  ))}
                </View>
              )}
            </View>
          ))}
      </ScrollView>
      {isLoding && <Loading />}

     
    
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor:Colors.primaryColor
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom:15,
    marginTop:40
  },
  dropdown2BtnStyle: {
    width: "100%",
    height: 40,
    backgroundColor: "#FFF",
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#CCCCCC",
    marginBottom: 15,
  },
  dropdown2BtnTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 15,
  },
  dropdown2DropdownStyle: { backgroundColor: "#ddd" },
  dropdown2RowStyle: { backgroundColor: "#ddd", borderBottomColor: "#C5C5C5" },
  dropdown2RowTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 16,
  },
});

export default BookingDetails;
