/* eslint-disable consistent-this */
import React, { useState } from "react";
import { StyleSheet, Text, View, ScrollView } from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import { Button, Dialog } from "react-native-elements";
import { Ionicons } from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import Loading from "../Utils/Loading";
import { useForm, Controller } from "react-hook-form";
import Input from "../Utils/Input";

const Password = (navigation) => {
  const [isLoding, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Its Error");

  const {
    register,
    control,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = useForm({});

  const onSubmit = (data) => {
    if (data.password !== data.confirm_password) {
      setErrorMessage("Both password not matched");
      setShow(true);
    } else {
      data = {
        password: data.password,
        password_confirmation: data.confirm_password,
      };
      setIsLoading(true);
      jwt
        .post(`${Url.UPDATE_PASSWORD}/${global.userData.id}`, data)
        .then((response) => {
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  return (
    <View style={styles.container}>
      {header(navigation)}
      <ScrollView
        style={{ flex: 1, backgroundColor: "#F2F3F4", paddingBottom: 20 }}
      >
        <View
          style={{
            flex: 1,
            paddingHorizontal: Sizes.fixPadding * 2,
          }}
        >
          <View
            style={{
              backgroundColor: "#ffffff",
              marginTop: 20,
              borderRadius: 10,
              padding: Sizes.fixPadding * 1.5,
            }}
          >
            <Controller
              name="password"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Password is required",
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label="New Password"
                  onChangeText={(value) => onChange(value)}
                  value={value}
                  error={errors.password}
                  errorText={errors?.password?.message}
                />
              )}
              defaultValue=""
            />

            <Controller
              name="confirm_password"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Confirm password is required",
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label="Confirm Password"
                  onChangeText={(value) => onChange(value)}
                  value={value}
                  error={errors.confirm_password}
                  errorText={errors?.confirm_password?.message}
                />
              )}
              defaultValue=""
            />

            <Button
              title="Update Password"
              type="solid"
              loading={isLoding}
              onPress={handleSubmit(onSubmit)}
              buttonStyle={{
                padding: Sizes.fixPadding * 1,
                width: "100%",
                backgroundColor: Colors.primaryColor,
                borderRadius: 22,
              }}
              containerStyle={{
                width: "100%",
                // marginHorizontal: 50,
                marginVertical: 30,
              }}
              titleStyle={{
                fontSize: 14,
                fontWeight: "100",
                color: Colors.whiteColor,
              }}
              icon={
                <Ionicons
                  name="add-circle-sharp"
                  size={24}
                  color="#ffffff"
                  style={{ marginRight: "2%" }}
                />
              }
            />
          </View>
          {isLoding && <Loading />}

          <Dialog isVisible={show}>
            <Dialog.Title title="Error!" />
            <Text>{errorMessage}</Text>

            <Dialog.Actions>
              <Dialog.Button
                title="Ok"
                onPress={() => {
                  setShow(!show);
                }}
              />
            </Dialog.Actions>
          </Dialog>
        </View>
      </ScrollView>
    </View>
  );
};

const header = (navigation) => {
  return (
    <View style={HeaderStyle}>
      <Text
        style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
      >
        Update Password
      </Text>
      <Ionicons
        name="menu-outline"
        size={30}
        color={Colors.whiteColor}
        style={{
          position: "absolute",
          left: 20.0,
          // fontWeight: 300
        }}
        onPress={() => navigation.navigation.toggleDrawer()}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor:Colors.primaryColor
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom:15,
    marginTop:40
  },
  label: {
    color: "black",
    marginLeft: 0,
    marginBottom: 3,
    fontSize: 14,
  },
  dropdown2BtnStyle: {
    width: "100%",
    height: 40,
    backgroundColor: "#FFF",
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#CCCCCC",
    marginBottom: 15,
  },
  dropdown2BtnTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 16,
  },
  dropdown2DropdownStyle: { backgroundColor: "#ddd" },
  dropdown2RowStyle: { backgroundColor: "#ddd", borderBottomColor: "#C5C5C5" },
  dropdown2RowTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 16,
  },
});
export default Password;
