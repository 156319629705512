import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  StatusBar,
  Image,
  TextInput,
  TouchableOpacity,
  Alert,
  Dimensions
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import { Button, SpeedDial, ListItem, Overlay } from "react-native-elements";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { Fontisto, Ionicons, MaterialIcons, Entypo, FontAwesome5 } from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import { useForm, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import SelectDropdown from "react-native-select-dropdown";
import CustomPicker from "../../components/Picker/Picker";

const windowWidth = Dimensions.get("window").width;

const UpdateTax = (navigation) => {
  const selectedItem = navigation.route.params.item;
  const [systems, setSystems] = useState([]);
  const [selectedSystem, setSelectedSystem] = useState(selectedItem.system_id);
  const [selectedStatus, setSelectedStatus] = useState("" +selectedItem.status);
  const [systemError, setSystemError] = useState(false);
  const [systemErrorStatus, setSystemErrorStatus] = useState(false);
  const [defaultSystem, setDefaultSystem] = useState(null);

  const statusData = [{value:"1", label:"Active"},{value:"0", label:"InActive"}];

  const handleSelectedStatus = (val, index) => {
   
    setSelectedStatus(val);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {

      jwt
        .post(`${Url.UPDATE_TAX}/${selectedItem.id}`, {
          name: data.name,
          percentage: data.percentage,
          system_id: parseInt(global.systemID),
          status: parseInt(selectedStatus),
        })
        .then((response) => {
          navigation.navigation.navigate("Tax", {Refresh:true});
        })
        .catch((err) => {});
    
  };

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
         Update Tax
                 </Text>
        <MaterialIcons
          name="arrow-back"
          size={26}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 15.0,
            fontWeight: 300,
          }}
          onPress={() => navigation.navigation.pop()}
        />
      </View>
    );
  };


  return (
    <View style={styles.container}>
    {header()}
    <View style={{ flex: 1, backgroundColor:"#F2F3F4" ,padding: Sizes.fixPadding * 2 }}>
    <View
          style={{
            padding: Sizes.fixPadding,
            backgroundColor: "#fff",
            borderRadius: 6,
          }}
        >
          <View style={{flexDirection:'row'}}>
                   <View style={styles.child1}>
                <Text style={{ ...styles.label}}>{"Tax Name"}</Text>
               </View>
               <View style={styles.child2}>
      <Controller
        name="name"
        control={control}
        rules={{
          required: { value: true, message: "name is required" },
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            // label="Tax Name"
            type="text"
            onChangeText={(value) => onChange(value)}
            value={value}
            error={errors.name}
            errorText={errors?.name?.message}
          />
        )}
        defaultValue={selectedItem.name}
      />
      </View>
      </View>
        <View style={{flexDirection:'row', marginTop:15}}>
                   <View style={styles.child1}>
                <Text style={{ ...styles.label}}>{"Percentage"}</Text>
               </View>
               <View style={styles.child2}>
      <Controller
        name="percentage"
        control={control}
        rules={{
          required: { value: true, message: "Percentage is required" },
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            // label="Percentage"
            type="text"
            onChangeText={(value) => onChange(value)}
            value={value}
            error={errors.percentage}
            errorText={errors?.percentage?.message}
          />
        )}
        defaultValue={selectedItem.percentage.toString()}
      />
      </View>
      </View>
     
    <View style={{flexDirection:'row', marginTop:15}}>
                   <View style={styles.child1}>
                <Text style={{ ...styles.label}}>{"Status"}</Text>
               </View>
               <View style={styles.child2}>
                <CustomPicker
                  data={statusData}
                  selectedValue={selectedStatus}
                  handleSelectedValue={handleSelectedStatus}
                  // defaultLabel={"Select Status"}
                  style={{}}
                />
                </View>
                </View>

      <Button
        title="Update Tax"
        type="solid"
        //   loading={isLoding}
        onPress={handleSubmit(onSubmit)}
        buttonStyle={{
          padding: Sizes.fixPadding,
          width: "100%",
          backgroundColor: Colors.primaryColor,
          borderRadius: 22,
        }}
        containerStyle={{
          width: "100%",
          // marginHorizontal: 50,
          marginVertical: 20,
        }}
        titleStyle={{
          fontSize: 14,
          fontWeight: "100",
          color: Colors.whiteColor,
        }}
        icon={
          <Ionicons
            name="add-circle-sharp"
            size={24}
            color="#ffffff"
            style={{ marginRight: "2%" }}
          />
        }
      />
    </View>
    </View>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor:Colors.primaryColor
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom:15,
    marginTop:40
  },
  label: {
    color: "black",
    marginLeft: 0,
    marginBottom: 3,
    fontSize: windowWidth > 600 ? 18 : 16,
    fontWeight: "500",
    marginTop: windowWidth > 600 ? 0 : 12,
  },
  child1: {
    width: windowWidth > 600 ? "15%" : "30%",
  },
  child2: {
    width: windowWidth > 600 ? "85%" : "70%",
  },
});
export default UpdateTax;
