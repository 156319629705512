/* eslint-disable consistent-this */
import React, { useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  StatusBar,
  Image,
  TouchableOpacity,
  Modal,
  TextInput,
  Alert,
  Platform,
  Dimensions
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  Button,
  Overlay,
  Icon,
  CheckBox,
  Switch,
  Dialog,
} from "react-native-elements";
import { Entypo, Ionicons, MaterialIcons, Feather } from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import SelectDropdown from "react-native-select-dropdown";
import Loading from "../Utils/Loading";
// import DateTimePicker from "@react-native-community/datetimepicker";
import moment from "moment";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import SectionedMultiSelect from "react-native-sectioned-multi-select";
import CustomDatePicker from "../../components/datePicker/datePicker";
import CustomPicker from "../../components/Picker/Picker";

const windowWidth = Dimensions.get('window').width;

const OrderForm = (navigation) => {
  const [clientsList, setClientsList] = useState([]);
  const [clientPriceList, setClientPriceList] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  const [isHourlyBased, setIsHourlyBased] = useState(false);
  const [isPropertyBased, setIsPropertyBased] = useState(false);
  const [isLoding, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Its Error");
  const [date, setDate] = useState(new Date());
  const [date1, setDate1] = useState(new Date());
  const [dateFrom, setDateFrom] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [dateTo, setDateTo] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);

  const [cleaningData, setCleaningData] = useState([]);

  const [propertyData, setPropertyData] = useState([]);

  const [isrecurring, setIsRecurring] = useState(0);
  const [userId, setUserId] = useState(null);
  const [selectedCleaningType, setSelectedCleaningType] = useState(null);
  const [selectedPropertyType, setSelectedPropertyType] = useState(null);
  const [fillteredData, setFillteredData] = useState([]);

  const [selectedItems, setSelectedItems] = useState([]);

  const [taxsData, setTaxsData] = useState([]);

  const [selectedTax, setSelectedTax] = useState(0);

  const [userShifts, setUserShifts] = useState([]);

  const [days, setDays] = useState([]);
  const [newDaysList, setNewDaysList] = useState([]);

  const [rooms, setRooms] = useState([]);

  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [showSuccess, setShowScuccess] = useState(false);
  const [showNoClient, setShowClient] = useState(false);

  const [showHour, setShowHour] = useState(false);
  const [showProperty, setShowProperty] = useState(false);

  const [isCleaning, setIsCleaning] = useState(false);
  const [isProperty, setIsProperty] = useState(false);
  const [showRecurring, setShowRecurring] = useState(false);

  const recurringOptions = [
    {
      value: 0,
      label: "No",
    },
    {
      value: 1,
      label: "Yes",
    },
  ];

  const items = [
    {
      id: "1",
      name: "Monday",
    },
    {
      id: "2",
      name: "Tuesday",
    },
    {
      id: "3",
      name: "Wednesday",
    },
    {
      id: "4",
      name: "Thursday",
    },
    {
      id: "5",
      name: "Friday",
    },
    {
      id: "6",
      name: "Saturday",
    },
    {
      id: "7",
      name: "Sunday",
    },
  ];

  useEffect(() => {
    (async () => {
      if (global.userData.role_names[0] === "Client") {
        setSelectedClient(global.userData.id);
        setUserId(global.userData.id);
        GetClientPrices(global.userData.id);
        GetUserShifts(global.userData.id);
      } else {
        GetClients();
      }

      GetCleaningType();
      GetPropertyType();
      GetRooms();
      GetTaxes();
    })();
  }, []);

  const GetClients = () => {
    setIsLoading(true);

    jwt
      .get(`${Url.GET_ACTIVE_CLIENT}/${global.systemID}`)
      .then((response) => {
        setIsLoading(false);
        if (response.data.clients.length > 0) {
          // setClientsList(response.data.clients);
          const NewData = [];

          response.data.clients.map((item) => {
            NewData.push({
              value: item.id,
              label: `${item.organization_name}`,
              ...item,
            });
          });

          setClientsList(NewData);
        } else {
          setShowClient(true);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  // console.log(clientsList)
  const GetClientPrices = (id) => {
    setIsLoading(true);

    jwt
      .get(`${Url.GET_ORDER_PRICE_CLIENT}/${id}`)
      .then((response) => {
        const newList = [];
        // console.log(response.data)
        response.data.order_prices.map(
          (item) =>
            item.price > 0 &&
            newList.push({
              id: item.id,
              room_type: `ID:${item.id}-${item.room_type}`,
              price_type: item.price_type,
              price: item.price,
              cleaning_type_id: item.cleaning_type_id,
              property_type_id: item.property_type_id,
            })
        );

        newList.filter((obj) => obj.price_type === "Hourly").length > 0 &&
          setShowHour(true);
        newList.filter((obj) => obj.price_type === "Room Based").length > 0 &&
          setShowProperty(true);

        // newList.filter((obj) => obj.price_type === "Hourly").length > 0 &&
        //   (setIsHourlyBased(false), setIsPropertyBased(true));

        setClientPriceList(newList);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetCleaningType = () => {
    jwt
      .get(`${Url.GET_ACTIVE_CLEANING_TYPE}/${global.systemID}`)
      .then((response) => {
        const newArr = [];
        // setCleaningData(response.data.cleaning_types);
        response.data.cleaning_types.map((item) => {
          newArr.push({
            value: item.id,
            label: item.name,
            ...item,
          });
        });
        setCleaningData(newArr);
      })
      .catch((err) => {});
  };

  const GetPropertyType = () => {
    jwt
      .get(`${Url.GET_ACTIVE_PROPERTY_TYPE}/${global.systemID}`)
      .then((response) => {
        const newArr = [];
        // setCleaningData(response.data.cleaning_types);
        response.data.property_types.map((item) => {
          newArr.push({
            value: item.id,
            label: item.name,
            ...item,
          });
        });
        setPropertyData(newArr);
      })
      .catch((err) => {});
  };

  const GetRooms = () => {
    jwt
      .get(`${Url.GET_ACTIVE_ROOM_TYPE}/${global.systemID}`)
      .then((response) => {
        // setRooms(response.data.room_types);
      })
      .catch((err) => {});
  };

  const GetTaxes = () => {
    jwt
      .get(`${Url.GET_ACTIVE_TAX_LIST}/${global.systemID}`)
      .then((response) => {
        // setTaxsData(response.data.taxs);
        const newArr = [];
        response.data.taxs.map((item) => {
          newArr.push({
            value: item.id,
            label: `${item.name} ${item.percentage}%`,
            ...item,
          });
        });
        setTaxsData(newArr);
      })
      .catch((err) => {});
  };

  const GetUserShifts = (id) => {
    jwt
      .post(Url.GET_USER_SHIFTS, { user_id: id })
      .then((response) => {
        // setUserShifts(response.data.user_shifts);
        const Shifts = [];
        response.data.user_shifts.map((item) =>
          Shifts.push({
            value: item.id,
            label: `${item.day} ${item.start_time} - ${item.end_time}`,
            ...item,
          })
        );
        setUserShifts(Shifts);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleSelectedProperty = (val, index) => {
    setSelectedPropertyType(val);

    setFillteredData(
      clientPriceList.filter((obj) => obj.property_type_id == parseInt(val))
    );
    if(parseInt(val) > 0 ) {
      setIsCleaning(true)
    } else{
      setIsCleaning(false)
    }
  };

  const handleSelectedCleaning = (val, index) => {
    // navigation.navigation.navigate("OrderForm");
    setSelectedCleaningType(val);

    setFillteredData(
      fillteredData.filter((obj) => obj.cleaning_type_id == parseInt(val))
    );

    const newFilter = fillteredData.filter(
      (obj) => obj.cleaning_type_id == parseInt(val)
    );

    const newRooms = [];
    newFilter.map((item) =>
      newRooms.push({ value: item.room_type, label: item.room_type, ...item })
    );
    setRooms(newRooms);

    if(parseInt(val) > 0 ) {
      setShowRecurring(true)
    } else{
      setShowRecurring(false)
    }
   

  };

  const handleSelectedTax = (val, index) => {
    setSelectedTax(val);

    let tax = 0;
    if (parseInt(val) !== 0) {
      tax = taxsData[index - 1].percentage;
    }
    if (subTotal > 0) {
      const newPercent = (tax / 100) * subTotal;

      setTotal(subTotal + newPercent);
    }
  };

  const handleSelectedClient = (val, index) => {
    setSelectedClient(parseInt(val));
    if (parseInt(val) !== 0) {
      setUserId(clientsList[index - 1].user_id);
      GetClientPrices(clientsList[index - 1].user_id);
      GetUserShifts(clientsList[index - 1].user_id);
    }
  };

  const handleSelectedRecurring = (val, index) => {
    setIsRecurring(val);
  };

  const onSelectedItemsChange = (selectedItems) => {
    // this.setState({ selectedItems });
    setSelectedItems(selectedItems);
    const newDays = [];
    selectedItems.map((item) => newDays.push({ day: item }));
    setDays(newDays);
  };

  const handleSelectedShift = (val, index) => {
    const newData = userShifts.filter((item) => item.id === parseInt(val));

    // console.log(newData)
    if (newData.length > 0) {
      const labelNew = `${newData[0].day} ${newData[0].start_time} - ${newData[0].end_time}`;

      let newDays = [...days];
      newDays[index] = {
        ...newDays[index],
        shift_id: parseInt(val),
        label: labelNew,
        value: parseInt(val),
      };

      setDays(newDays);

      setNewDaysList(newDays);
    }
  };

  const {
    register,
    control,
    watch,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    // defaultValues: {}; you can populate the fields by this attribute
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "test",
  });

  const handleRateBased = (rate) => {
    if (rate === "Hour") {
      setIsHourlyBased(true);
      setIsPropertyBased(false);
    } else {
      setIsHourlyBased(false);
      setIsPropertyBased(true);
    }
  };

  const onSubmit = (data) => {
    let hourly_price = 0;
    let hourly_price_id = 0;
    if (isHourlyBased) {
      const newPriceData = fillteredData.filter(
        (item) => item.price_type == "Hourly"
      );

      newPriceData.length > 0 &&
        ((hourly_price = newPriceData[0].price),
        (hourly_price_id = newPriceData[0].id));
    }

    const HourDays = [
      {
        day: `${moment(dateFrom).format("dddd")}`,
        shift_id: 14,
      },
    ];

    const hourlyPrice = [
      {
        order_price_id: hourly_price_id,
        price: hourly_price,
        quantity: 0,
      },
    ];

    let order_items = [];

    if (!isHourlyBased) {
      data.test.map((item) =>
        fillteredData
          .filter((obj) => obj.room_type === item.room)
          .map((rom) =>
            order_items.push({
              order_price_id: rom.id,
              price: rom.price,
              quantity: item.quantity,
            })
          )
      );
    }

    const dataNew = {
      cleaning_type_id: selectedCleaningType,
      property_type_id: selectedPropertyType,
      order_type: isHourlyBased ? "Hourly" : "Room Based",
      client_id: userId,
      is_recurring: isrecurring,
      duration_from:
         `${moment(dateFrom).format("YYYY-MM-DD")}`
         ,
      duration_to:
        isrecurring == 1
          ? `${moment(dateTo).format("YYYY-MM-DD")}`
           : false,
      hourly_price: data.hourly_price ? parseInt(data.hourly_price) : false,
      system_id: parseInt(global.systemID),
      tax_id: selectedTax,
      hours_per_day: data.hours_per_day ? parseInt(data.hours_per_day) : false,
      days_per_week: isrecurring == 1 ? newDaysList : HourDays,
      order_items: isHourlyBased ? hourlyPrice : order_items,
    };

    if (userId === null || userId === "0") {
      setErrorMessage("Please Select Client");
      setShow(true);
    } else if (selectedPropertyType === null || selectedPropertyType === "0") {
      setErrorMessage("Please Select Property Type");
      setShow(true);
    } else if (selectedCleaningType === null || selectedCleaningType === "0") {
      setErrorMessage("Please Select Cleaning Type");
      setShow(true);
    } else if (dateFrom === null) {
      setErrorMessage("Please Select Duration From");
      setShow(true);
    } else if (dateTo === null && isrecurring === 1) {
      setErrorMessage("Please Select Duration To");
      setShow(true);
    } else if (selectedTax === null || selectedTax === "0") {
      setErrorMessage("Please Select Vat");
      setShow(true);
    } else if (isHourlyBased && data.hours_per_day === "") {
      setErrorMessage("Please add hours per day");
      setShow(true);
    } else if (isrecurring === 1 && newDaysList.length === 0) {
      setErrorMessage("Please select Days and Shifts");
      setShow(true);
    } else if (!isHourlyBased && order_items.length === 0) {
      setErrorMessage("Please add Rooms to cleans ");
      setShow(true);
    } else {
      genrateOrder(dataNew);
    }
  };

  const genrateOrder = (dataNew) => {
    setIsLoading(true);
    jwt
      .post(`${Url.BOOK_ORDER}/${global.systemID}`, dataNew)
      .then((response) => {
        setIsLoading(false);
        // navigation.navigation.
        setShowScuccess(true);
      })
      .catch((err) => {
        // Alert.alert("Something Went Wrong!");
        setErrorMessage("Something Went Wrong!");
        setShow(true);
        setIsLoading(false);
      });
  };

  const handleHours = (value) => {
    if (selectedTax) {
      const newPriceData = fillteredData.filter(
        (item) => item.price_type == "Hourly"
      );

      const subTotal =
        newPriceData.length > 0 ? newPriceData[0].price * value : 0;
      setSubTotal(subTotal);

      let tax = 0;
      if (selectedTax) {
        tax = taxsData.filter((item) => item.id == parseInt(selectedTax))[0]
          .percentage;
      }

      const newPercent = (tax / 100) * subTotal;

      setTotal(subTotal + newPercent);
    } else {
      Alert.alert(
        "Please first select Tax Type and then enter hours to get precise total"
      );
    }
  };

  useEffect(() => {
    if (watch().test.length > 0) {
      const DataList = watch().test;

      let NewPrice = [];

      DataList.map((item) =>
        fillteredData.map((da) => {
          if (item.room == da.room_type) {
            NewPrice.push(da.price * item.quantity);
          }
        })
      );

      const addedPrice = NewPrice.reduce((a, v) => (a = a + v), 0);

      setSubTotal(addedPrice);

      let tax = 0;
      if (selectedTax) {
        tax = taxsData.filter((item) => item.id == parseInt(selectedTax))[0]
          .percentage;
      }

      const newPercent = (tax / 100) * addedPrice;

      setTotal(addedPrice + newPercent);
    }
  }, [watch()]);

  const handleDate = (event, date) => {
    setDate(date);
    setDateFrom(date);
    setShow1(!show1);
    // console.log(date)
  };
  const handleDateTo = (event, date) => {
    setDate1(date);
    setDateTo(date);
    setShow2(!show2);
    // console.log(date)
  };
  // console.log(days);

  return (
    <View style={styles.container}>
      {header(navigation)}
      <ScrollView
        style={{ flex: 1, backgroundColor: "#F2F3F4", paddingBottom: 20 }}
      >
        <View
          style={{
            flex: 1,
            paddingHorizontal: Sizes.fixPadding * 1.5,
          }}
        >
          <View
            style={{
              backgroundColor: "#ffffff",
              marginTop: 20,
              borderRadius: 10,
              padding: Sizes.fixPadding * 1,
            }}
          >
            {global.userData.role_names[0] !== "Client" && (
              <View style={{flexDirection:'row'}}>
                <View style={styles.child1}>
                <Text style={{ ...styles.label }}>{"Select Client"}</Text>
                </View>
                <View style={styles.child2}>
                <CustomPicker
                  data={clientsList}
                  selectedValue={selectedClient}
                  handleSelectedValue={handleSelectedClient}
                  defaultLabel={"Please select a client"}
                  style={{}}
                />
                </View>
              </View>
            )}
            {clientPriceList.length > 0 && selectedClient !== 0 && (
              
                <View style={{ flexDirection: "row", marginLeft: -10 }}>
                  {showHour && (
                    <CheckBox
                      center
                      title="Hour Based"
                      checkedIcon="dot-circle-o"
                      uncheckedIcon="circle-o"
                      checked={isHourlyBased}
                      onPress={() => {handleRateBased("Hour");  setIsProperty(true);}}
                      containerStyle={{
                        padding: 5,
                        paddingTop: 8,
                        paddingBottom: 8,
                      }}
                      textStyle={{ fontSize: 13, marginLeft: 0 }}
                      checkedColor={Colors.primaryColor}
                    />
                  )}

                  {showProperty && (
                    <CheckBox
                      center
                      title="Property Based"
                      checkedIcon="dot-circle-o"
                      uncheckedIcon="circle-o"
                      checked={isPropertyBased}
                      onPress={() => {handleRateBased("Property"); setIsProperty(true)}}
                      containerStyle={{
                        padding: 5,
                        paddingTop: 8,
                        paddingBottom: 8,
                      }}
                      textStyle={{ fontSize: 13, marginLeft: 0 }}
                      checkedColor={Colors.primaryColor}
                    />
                  )}
                </View>
              
                )}
                {isHourlyBased || isPropertyBased &&
                <View>
               
                </View>
}
                {isProperty &&
                <View>
                   <Text
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  Order Information
                </Text>
                <View style={{flexDirection:'row', marginBottom:15}}>
                <View style={styles.child1}>
                <Text style={styles.label}>{"Property Type"}</Text>
</View>

<View style={styles.child2}>
                <CustomPicker
                  data={propertyData}
                  selectedValue={selectedPropertyType}
                  handleSelectedValue={handleSelectedProperty}
                  defaultLabel={"Please select Property Type"}
                  style={{}}
                />
                </View>
                </View>
                </View>
                }
               
{isCleaning &&
                <View style={{flexDirection:'row', marginBottom:15}}>
                <View style={styles.child1}>
                <Text style={{ ...styles.label, marginTop: 10 }}>
                  {"Cleaning Type"}
                </Text>
                </View>
                <View style={styles.child2}>
                <CustomPicker
                  data={cleaningData}
                  selectedValue={selectedCleaningType}
                  handleSelectedValue={handleSelectedCleaning}
                  defaultLabel={"Please select cleaning type"}
                  style={{}}
                />
                </View>
                </View>

}

{showRecurring &&
<View>
                <View style={{flexDirection:'row', marginBottom:15}}>
                <View style={styles.child1}>
                <Text style={styles.label}>{"Is Recurring"}</Text>
                </View>
                <View style={styles.child2}>
                <CustomPicker
                  data={recurringOptions}
                  selectedValue={isrecurring}
                  handleSelectedValue={handleSelectedRecurring}
                  style={{}}
                />
</View>
                </View>

                <View style={{flexDirection:'row', marginBottom:15}}>
                <View style={styles.child1}>
                <Text style={ styles.label }>Duration From</Text>
                </View>
                <View style={{...styles.child2, }}>
                <CustomDatePicker
                  date={handleDate}
                  defaultDate={date}
                  show={show1}
                  setShow={setShow1}
                 
                />
                </View>
                </View>
                {isrecurring == 1 && (
                   <View style={{flexDirection:'row', marginBottom:15}}>
                   <View style={styles.child1}>
                    <Text style={styles.label}>Duration To</Text>
                   </View>
                   <View style={styles.child2}>
                    <CustomDatePicker
                      date={handleDateTo}
                      defaultDate={date1}
                      show={show2}
                      setShow={setShow2}
                    />
                    </View>
                  </View>
                )}
  <View style={{flexDirection:'row', marginBottom:15}}>
                   <View style={styles.child1}>
                <Text style={{ ...styles.label}}>{"Vat"}</Text>
               </View>
               <View style={styles.child2}>
                <CustomPicker
                  data={taxsData}
                  selectedValue={selectedTax}
                  handleSelectedValue={handleSelectedTax}
                  defaultLabel={"Please select Vat"}
                  style={{}}
                />
</View>
</View>
                </View>

}

           

              
                {isHourlyBased && (
                  <View style={{flexDirection:'row', marginBottom:15}}>
                  <View style={styles.child1}>
                  <Text style={{ ...styles.label}}>{"Hours Per Day"}</Text>
                  </View>

                  <View style={styles.child2}>
                  <Controller
                    name="hours_per_day"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        // label="Hours Per Day"
                        onChangeText={(value) => {
                          handleHours(value);
                          onChange(value);
                        }}
                        value={value}
                      />
                    )}
                    defaultValue=""
                  />
                  </View>
                  </View>
                )}

                {isrecurring == 1 && (
                  <View>
                     <View style={{flexDirection:'row', marginBottom:15}}>
                   <View style={styles.child1}>
                    <Text style={styles.label}>{"Days"}</Text>
                   </View>
                   <View style={styles.child2}>
                    <SectionedMultiSelect
                      items={items}
                      // ref={ref}
                      IconRenderer={MaterialIcons}
                      uniqueKey="name"
                      // subKey="children"
                      selectText="Select Days"
                      showDropDowns={true}
                      //   readOnlyHeadings={true}
                      onSelectedItemsChange={onSelectedItemsChange}
                      selectedItems={selectedItems}
                      hideSearch={true}
                      searchPlaceholderText="Search reasons"
                      //   text={Colors.primaryColor}
                      styles={{
                        button: {
                          backgroundColor: Colors.primaryColor,
                          selectedItemText: Colors.primaryColor,
                        },
                        selectToggle: {
                          borderColor: "#CCCCCC",
                          padding: 10,
                          borderRadius: 4,
                          borderWidth: 1,
                          height: 40,
                          // marginBottom: 15,
                        },
                        selectToggleText: {
                          fontSize: 14,
                        },
                        itemIconStyle: {
                          fontSize: 14,
                        },
                      }}
                      theme={{
                        Colors: {
                          selectToggleTextColor: "#053075",
                          text: "#053075",
                          selectedItemText: Colors.primaryColor,
                        },
                      }}
                    />
</View>
</View>
                    {selectedItems.map((item, Mainindex) => (
                       <View style={{flexDirection:'row', marginBottom:15}}  key={Mainindex}>
                   <View style={styles.child1}> 
                   <Text style={styles.label}>{`${item}`}</Text>
</View>
<View style={styles.child2}> 
                        <CustomPicker
                          data={userShifts.filter(
                            (shift) => shift.day === item
                          )}
                          selectedValue={
                            days[Mainindex].shift_id !== undefined
                              ? days[Mainindex].shift_id > 0 &&
                                days[Mainindex].shift_id
                              : 0
                          }
                          handleSelectedValue={handleSelectedShift}
                          defaultLabel={"Select Shift"}
                          style={{}}
                          customIndex={Mainindex}
                        />
</View>
                      </View>
                    ))}
                  </View>
                )}
                {isPropertyBased && (
                  <View style={{ marginTop: 15 }}>
                    {fields.map((item, index) => (
                      <View
                        style={{
                          flexDirection: "row",
                          width: "100%",
                        }}
                        key={item.id}
                      >
                        <View style={{ width: "75%" }}>
                          <Controller
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <CustomPicker
                                data={rooms}
                                selectedValue={
                                  value && value !== undefined && value !== null
                                    ? value
                                    : 0
                                }
                                handleSelectedValue={onChange}
                                defaultLabel={"Please select room"}
                                style={{}}
                              />
                            )}
                            name={`test.${index}.room`}
                            control={control}
                          />
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            width: "15%",
                            marginLeft: 5,
                            marginTop: 0,
                          }}
                        >
                          <Controller
                            name={`test.${index}.quantity`}
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: "Qty is required",
                              },
                            }}
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <Input
                                placeholder="Qty"
                                onChangeText={(value) => onChange(value)}
                                value={value}
                                error={`${errors}.test.${index}.quantity`}
                                errorText={
                                  `${errors}.test.${index}.quantity`?.message
                                }
                              />
                            )}
                          />

                          <TouchableOpacity
                            style={{ width: 50, marginLeft: 10, marginTop: 8 }}
                            onPress={() => remove(index)}
                          >
                            <Feather name="delete" size={24} color="red" />
                          </TouchableOpacity>
                        </View>
                      </View>
                    ))}
 <View style={{flexDirection:'row', marginBottom:15}}>
                   <View style={styles.child1}></View>
                   <View style={styles.child2}>
                    <TouchableOpacity
                      onPress={() => append({ room: "", quantity: "" })}
                      style={{
                        flexDirection: "row",
                        backgroundColor: "#EEE",
                        padding: 10,
                        width: 180,
                        borderRadius: 4,
                        marginTop: 10,
                      }}
                    >
                      <Ionicons
                        name="add-circle-sharp"
                        size={22}
                        color="black"
                        style={{ marginRight: "2%" }}
                      />
                      <Text style={{ paddingTop: 3 }}>Add Room To Clean</Text>
                    </TouchableOpacity>
                    </View>
                    </View>
                  </View>
                )}

                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    marginTop: 10,
                    marginBottom: 10,
                    // textAlign:'right'
                  }}
                >
                  Sub Total: {subTotal}
                </Text>

                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    marginTop: 10,
                    marginBottom: 10,
                    // textAlign:'right'
                  }}
                >
                  Grand Total: {total}
                </Text>

                <Button
                  title="Book Order"
                  type="solid"
                  loading={isLoding}
                  onPress={handleSubmit(onSubmit)}
                  buttonStyle={{
                    padding: Sizes.fixPadding * 1,
                    width: "100%",
                    backgroundColor: Colors.primaryColor,
                    borderRadius: 22,
                  }}
                  containerStyle={{
                    width: "100%",
                    // marginHorizontal: 50,
                    marginVertical: 30,
                  }}
                  titleStyle={{
                    fontSize: 14,
                    fontWeight: "100",
                    color: Colors.whiteColor,
                  }}
                  icon={
                    <Ionicons
                      name="add-circle-sharp"
                      size={24}
                      color="#ffffff"
                      style={{ marginRight: "2%" }}
                    />
                  }
                />
            
           
          </View>

          {isLoding && <Loading />}

          <Dialog isVisible={showNoClient}>
            <Dialog.Title title="Alert!" />
            <Text>{"No Clients available in system"}</Text>

            <Dialog.Actions>
              <Dialog.Button
                title="Ok"
                onPress={() => {
                  setShowClient(!showNoClient);
                }}
              />
            </Dialog.Actions>
          </Dialog>

          <Dialog isVisible={show}>
            <Dialog.Title title="Error!" />
            <Text>{errorMessage}</Text>

            <Dialog.Actions>
              <Dialog.Button
                title="Ok"
                onPress={() => {
                  setShow(!show);
                }}
              />
            </Dialog.Actions>
          </Dialog>

          <Dialog isVisible={showSuccess}>
            <Dialog.Title title="Congratulations!" />
            <Text>{"You have Scuccessfully Create your Order"}</Text>

            <Dialog.Actions>
              <Dialog.Button
                title="Ok"
                onPress={() => {
                  setShowScuccess(!showSuccess);
                  navigation.navigation.navigate("Dashboard");
                }}
              />
            </Dialog.Actions>
          </Dialog>
        </View>
      </ScrollView>
    </View>
  );
};

const header = (navigation) => {
  return (
    <View style={HeaderStyle}>
      <Text
        style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
      >
        Order Form
      </Text>
      <Ionicons
        name="menu-outline"
        size={30}
        color={Colors.whiteColor}
        style={{
          position: "absolute",
          left: 20.0,
          // fontWeight: 300
        }}
        onPress={() => navigation.navigation.toggleDrawer()}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: Colors.primaryColor,
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 15,
    marginTop: 40,
  },
  label: {
    color: "black",
    marginLeft: 0,
    marginBottom: 3,
    fontSize:windowWidth > 600 ? 18 : 16,
    fontWeight:'500',
    marginTop:windowWidth > 600 ? 0 : 12
  },
  child1:{
    width:windowWidth > 600 ? '15%' : '30%'
  },
  child2:{
    width:windowWidth > 600 ? '85%' : '70%'
  }

});
export default OrderForm;
