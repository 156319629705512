import axios from "axios";
import { Platform } from "react-native";
import jwtDefaultConfig from "./jwtDefaultConfig";
import * as SecureStore from "expo-secure-store";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // ** For Refreshing Token
  subscribers = [];

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };
    axios.defaults.baseURL = "https://my.ronove.io/api/v2";
    // ** Request Interceptor
    axios.interceptors.request.use(
      (config) => {
        // ** Get token from localStorage
        const accessToken = this.getToken();

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
        }

        return config;
      },
      (error) => Promise.reject(error)
    );

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // ** const { config, response: { status } } = error
        const { config, response } = error;
        const originalRequest = config;

        // ** if(status === 400)
        if (response && response.status === 400) {
        }

        // ** if (status === 401) {
        // if (response && response.status === 401) {
        //   if (Platform.OS === "android" || Platform.OS === "") {
        //     SecureStore.deleteItemAsync("userData");
        //     SecureStore.deleteItemAsync("permissions");
        //     SecureStore.deleteItemAsync("systemID");
        //   } else {
        //     AsyncStorage.removeItem("userData");
        //     AsyncStorage.removeItem("permissions");
        //     AsyncStorage.removeItem("systemID");
        //   }
        // }
        return Promise.reject(error);
      }
    );
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    );
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return global.accessToken;
  }

  getRefreshToken() {
    if (Platform.OS === "android" || Platform.OS === "") {
      return SecureStore.getItemAsync(
        this.jwtConfig.storageRefreshTokenKeyName
      );
    } else {
      return AsyncStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName);
    }
  }

  setToken(value) {
    if (Platform.OS === "android" || Platform.OS === "ios") {
      SecureStore.setItemAsync(this.jwtConfig.storageTokenKeyName, value);
    } else {
      AsyncStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
    }
  }

  setRefreshToken(value) {
    if (Platform.OS === "android" || Platform.OS === "ios") {
      SecureStore.setItemAsync(
        this.jwtConfig.storageRefreshTokenKeyName,
        value
      );
    } else {
      AsyncStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
    }
  }

  login(...args) {
    return axios.post(this.jwtConfig.loginEndpoint, ...args);
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args);
  }

  refreshToken() {
    return axios.get(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    });
  }

  async get(url, config = {}) {
    const headers = {
      "system-id": global.systemID,
    };
    return await axios.get(url, { headers }).then((response) => response.data);
  }

  async post(url, data, config = {}) {
    // console.log(url, data);
    const headers = {
      "system-id": global.systemID,
    };
    return axios
      .post(url, { ...data }, { headers })
      .then((response) => response.data)
      .catch(function (e) {
        return Promise.reject(e);
      });
  }

  async postfile(url, data, config) {
    return axios.post(url, data, config).then((response) => response.data);
  }

  async del(url, config = {}) {
    const headers = {
      "system-id": global.systemID,
    };
    return await axios
      .delete(url, { headers })
      .then((response) => response.data);
  }
}
