import React, { useState, useEffect, useRef } from "react";
import {
    StyleSheet,
    Text,
    View,
    ScrollView,
    StatusBar,
    Image,
    TouchableOpacity,
    Modal,
    TextInput,
    Alert,
    Platform,
  } from "react-native";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Portal } from 'react-overlays'

const CalendarContainer = ({children}) => {
  const el = document.getElementById('calendar-portal')

  return (
    <Portal container={el}>
      {children}
    </Portal>
  )
}


const CustomDatePicker = (props) => {

    return(
    <View style={{width:'100%'}}>
         <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={props.defaultDate}
                  onChange={(date) => {
                  props.date('event', date)
                  }}
                  popperContainer={CalendarContainer}
                //  withPortal
                />
    </View>)
}


export default CustomDatePicker

