import React, { useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
} from "react-native";
import { Colors, Sizes } from "../../constants/style";
import {
  Button,
  Overlay,
  ListItem,
  SpeedDial,
  TabView,
  Avatar,
  BottomSheet,
} from "react-native-elements";

import {
  Ionicons,
  Entypo,
  MaterialIcons,
  MaterialCommunityIcons,
  FontAwesome,
  Octicons,
  FontAwesome5,
} from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import { useForm, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import SelectDropdown from "react-native-select-dropdown";
import Loading from "../Utils/Loading";
import * as ImagePicker from "expo-image-picker";

const EmployeeDetails = (navigation) => {
  const [clientsData, setClientsData] = useState([]);
  const [openDial, setOpenDial] = useState(false);
  const [isLoding, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [showBottomSheet, SetShowBottomSheet] = useState(false);
  const [imagePicked, setImagePicked] = useState(null);
  const [imagePickedPath, setImagePickedPath] = useState(null);
  const item = navigation.route.params.item;
  const countries = [
    "Pending",
    "Accepted",
    "Assigned",
    "Do Not Clean",
    "Done",
    "Rejected",
  ];

  const header = (item) => {
    return (
      <View style={styles.headerWrapStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          {item.first_name}
        </Text>
        <MaterialIcons
          name="arrow-back"
          size={26}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 15.0,
            fontWeight: 300,
          }}
          onPress={() => navigation.navigation.pop()}
        />
      </View>
    );
  };

  const openCamera = async () => {
    // Ask the user for the permission to access the camera
    const permissionResult = await ImagePicker.requestCameraPermissionsAsync();

    if (permissionResult.granted === false) {
      alert("You've refused to allow this appp to access your camera!");
      return;
    }

    const result = await ImagePicker.launchCameraAsync();

    if (!result.cancelled) {
      setImagePicked(result);
      setImagePickedPath(result.uri);
      SetShowBottomSheet(false);
    }
  };

  const showImagePicker = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (result.cancelled) {
    } else {
      setImagePicked(result);
      setImagePickedPath(result.uri);
      SetShowBottomSheet(false);
    }
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {};

  return (
    <View style={styles.container}>
      {header(item)}
      <ScrollView
        style={{
          flex: 1,
          paddingHorizontal: Sizes.fixPadding * 2,
          backgroundColor:"#F2F3F4"
        }}
      >
        <View
          style={{
            backgroundColor: "#ffffff",
            marginTop: 20,
            borderRadius: 10,
            padding: Sizes.fixPadding * 2,
          }}
        >
          <View
            style={{
              alignSelf: "center",
              marginTop: Sizes.fixPadding * 1.0,
              marginBottom: Sizes.fixPadding + 5.0,
              alignItems: "center",
            }}
          >
            <Avatar
              size={94}
              rounded
              source={
                imagePickedPath == null
                  ? require("../../assets/images/user.jpg")
                  : { uri: imagePickedPath }
              }
              // title="Bj"
              containerStyle={{ backgroundColor: "grey" }}
            >
              <Avatar.Accessory
                size={33}
                onPress={() => {
                  SetShowBottomSheet(true);
                }}
              />
            </Avatar>
          </View>
          <Controller
            name="company"
            control={control}
            rules={{
              required: { value: true, message: "Company is required" },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label="Company Name"
                type="text"
                onChangeText={(value) => onChange(value)}
                value={value}
                error={errors.company}
                errorText={errors?.company?.message}
              />
            )}
            defaultValue={item.company_name}
          />
          <Controller
            name="firstName"
            control={control}
            rules={{
              required: { value: true, message: "FirstName is required" },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label="First Name"
                type="text"
                onChangeText={(value) => onChange(value)}
                value={value}
                error={errors.firstName}
                errorText={errors?.firstName?.message}
              />
            )}
            defaultValue={item.first_name}
          />
          <Controller
            name="lastName"
            control={control}
            rules={{
              required: { value: true, message: "Last Name is required" },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label="Last Name"
                type="text"
                onChangeText={(value) => onChange(value)}
                value={value}
                error={errors.lastName}
                errorText={errors?.lastName?.message}
              />
            )}
            defaultValue={item.last_name}
          />
          <Controller
            name="email"
            control={control}
            rules={{
              required: { value: true, message: "Email is required" },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label="Email"
                type="email"
                onChangeText={(value) => onChange(value)}
                value={value}
                error={errors.email}
                errorText={errors?.email?.message}
              />
            )}
            defaultValue={item.email}
          />
          <Controller
            name="address"
            control={control}
            rules={{
              required: { value: true, message: "Address is required" },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label="Address"
                type="text"
                onChangeText={(value) => onChange(value)}
                value={value}
                error={errors.address}
                errorText={errors?.address?.message}
              />
            )}
            defaultValue={item.address}
          />
          <Controller
            name="address2"
            control={control}
            //   rules={{
            //     required: { value: true, message: "Addre is required" },
            //   }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label="Address 2"
                type="text"
                onChangeText={(value) => onChange(value)}
                value={value}
                //   error={errors.company}
                //   errorText={errors?.company?.message}
              />
            )}
            defaultValue={item.address_2}
          />
          <Controller
            name="city"
            control={control}
            rules={{
              required: { value: true, message: "City is required" },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label="City"
                type="text"
                onChangeText={(value) => onChange(value)}
                value={value}
                error={errors.city}
                errorText={errors?.city?.message}
              />
            )}
            defaultValue={item.city}
          />
          <Controller
            name="country"
            control={control}
            rules={{
              required: { value: true, message: "Country is required" },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label="Country"
                type="text"
                onChangeText={(value) => onChange(value)}
                value={value}
                error={errors.country}
                errorText={errors?.country?.message}
              />
            )}
            defaultValue={item.country}
          />
          <Controller
            name="postCode"
            control={control}
            //   rules={{
            //     required: { value: true, message: "Company is required" },
            //   }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label="PostCode"
                type="text"
                onChangeText={(value) => onChange(value)}
                value={value}
                //   error={errors.company}
                //   errorText={errors?.company?.message}
              />
            )}
            defaultValue={item.postcode}
          />

          <Text style={styles.label}>{"Gender"}</Text>
          <SelectDropdown
            data={countries}
            //   defaultValueByIndex={1}
            onSelect={(selectedItem, index) => {}}
            defaultButtonText={"Select Gender"}
            buttonTextAfterSelection={(selectedItem, index) => {
              return selectedItem;
            }}
            rowTextForSelection={(item, index) => {
              return item;
            }}
            buttonStyle={styles.dropdown2BtnStyle}
            buttonTextStyle={styles.dropdown2BtnTxtStyle}
            renderDropdownIcon={(isOpened) => {
              return (
                <Entypo
                  name={isOpened ? "chevron-thin-up" : "chevron-thin-down"}
                  color={"#444"}
                  size={14}
                />
              );
            }}
            dropdownIconPosition={"right"}
            dropdownStyle={styles.dropdown2DropdownStyle}
            rowStyle={styles.dropdown2RowStyle}
            rowTextStyle={styles.dropdown2RowTxtStyle}
          />

          <Text style={styles.label}>{"Role"}</Text>
          <SelectDropdown
            data={countries}
            //   defaultValueByIndex={1}
            onSelect={(selectedItem, index) => {}}
            defaultButtonText={"Select Role"}
            buttonTextAfterSelection={(selectedItem, index) => {
              return selectedItem;
            }}
            rowTextForSelection={(item, index) => {
              return item;
            }}
            buttonStyle={styles.dropdown2BtnStyle}
            buttonTextStyle={styles.dropdown2BtnTxtStyle}
            renderDropdownIcon={(isOpened) => {
              return (
                <Entypo
                  name={isOpened ? "chevron-thin-up" : "chevron-thin-down"}
                  color={"#444"}
                  size={14}
                />
              );
            }}
            dropdownIconPosition={"right"}
            dropdownStyle={styles.dropdown2DropdownStyle}
            rowStyle={styles.dropdown2RowStyle}
            rowTextStyle={styles.dropdown2RowTxtStyle}
          />
          <Button
            title="Update Profile"
            type="solid"
            loading={isLoding}
            onPress={handleSubmit(onSubmit)}
            buttonStyle={{
              padding: Sizes.fixPadding,
              width: "100%",
              backgroundColor: Colors.primaryColor,
              borderRadius: 22,
            }}
            containerStyle={{
              width: "100%",
              // marginHorizontal: 50,
              marginVertical: 20,
            }}
            titleStyle={{
              fontSize: 14,
              fontWeight: "100",
              color: Colors.whiteColor,
            }}
            icon={
              <MaterialCommunityIcons
                name="account-edit-outline"
                size={24}
                color="#ffffff"
                style={{ marginRight: "2%" }}
              />
            }
          />
        </View>
      </ScrollView>
      <BottomSheet modalProps={{}} isVisible={showBottomSheet}>
        <ListItem>
          <ListItem.Content>
            <TouchableOpacity
              style={{ flexDirection: "row", width: "100%" }}
              onPress={() => openCamera()}
            >
              <MaterialIcons
                name="camera"
                size={26}
                color={Colors.primaryColor}
              />

              <Text
                style={{
                  paddingLeft: 5,
                  fontSize: 16,
                  paddingTop: 2,
                }}
              >
                Take a photo
              </Text>
            </TouchableOpacity>
          </ListItem.Content>
        </ListItem>

        <ListItem style={{ borderBottomWidth: 1, borderBottomColor: "#CCC" }}>
          <ListItem.Content>
            <TouchableOpacity
              style={{ flexDirection: "row", width: "100%" }}
              onPress={() => showImagePicker()}
            >
              <MaterialIcons
                name="photo-library"
                size={26}
                color={Colors.primaryColor}
              />

              <Text
                style={{
                  paddingLeft: 5,
                  fontSize: 16,
                  paddingTop: 2,
                }}
              >
                Choose from your photos
              </Text>
            </TouchableOpacity>
          </ListItem.Content>
        </ListItem>

        <ListItem>
          <ListItem.Content>
            <TouchableOpacity
              style={{ flexDirection: "row", width: "100%" }}
              onPress={() => SetShowBottomSheet(false)}
            >
              <Entypo name="cross" size={26} color={Colors.primaryColor} />

              <Text
                style={{
                  paddingLeft: 5,
                  fontSize: 16,
                  paddingTop: 2,
                }}
              >
                Cancel
              </Text>
            </TouchableOpacity>
          </ListItem.Content>
        </ListItem>
      </BottomSheet>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor:Colors.primaryColor
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom:15,
    marginTop:40
  },
  dropdown2BtnStyle: {
    width: "100%",
    height: 40,
    backgroundColor: "#FFF",
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#CCCCCC",
    marginBottom: 15,
  },
  dropdown2BtnTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 15,
  },
  dropdown2DropdownStyle: { backgroundColor: "#ddd" },
  dropdown2RowStyle: { backgroundColor: "#ddd", borderBottomColor: "#C5C5C5" },
  dropdown2RowTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 16,
  },
});
export default EmployeeDetails;
