import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  FlatList,
  TouchableOpacity,
  Image,
  Dimensions,
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  Button,
  SpeedDial,
  ListItem,
  Overlay,
  Dialog,
  SearchBar,
  BottomSheet,
  Avatar,
} from "react-native-elements";
import {
  Feather,
  Ionicons,
  Entypo,
  MaterialIcons,
  FontAwesome5,
} from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import { useForm, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import SelectDropdown from "react-native-select-dropdown";
import Loading from "../Utils/Loading";

import AuthorizedFor from "../../constants/authorizedFor";
import { authorizedForFlateList } from "../../constants/authorizedForFlateList";
import { ModulesPermission } from "../../constants/modules";

import * as DocumentPicker from "expo-document-picker";
const windowWidth = Dimensions.get("window").width;

const FolderContent = (navigation) => {
  const Item = navigation.route.params.item;
  // console.log(Item);
  console.log(windowWidth);
  const refreshScreen = navigation.route.params
    ? navigation.route.params.Refresh
    : false;
  const [folderData, setFolderData] = useState([]);
  const [folderDataIntial, setFolderDataIntial] = useState([]);
  const [files, setFiles] = useState([]);
  const [openDial, setOpenDial] = useState(false);
  const [isLoding, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible1, setIsVisible1] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [deleteShow, setDeleteShow] = useState(false);
  const [search, setSearch] = useState("");
  const [isDeleteAble, setIsDeleteAble] = useState(false);
  const [isUpdateAble, setIsUpdateAble] = useState(false);
  const [showBottomSheet, SetShowBottomSheet] = useState(false);
  const [filePicked, setFilePicked] = useState(null);
  const [filePickedPath, setFilePickedPath] = useState(null);

  const [newFiles, setNewFiles] = useState([]);

  useEffect(() => {
    GetData();
  }, [refreshScreen]);

  useEffect(() => {
    (async () => {
      (await authorizedForFlateList(ModulesPermission.Update_Tax_Type)) &&
        setIsUpdateAble(true);
      (await authorizedForFlateList(ModulesPermission.Delete_Tax_Type)) &&
        setIsDeleteAble(true);
    })();
  }, [folderDataIntial.length]);

  const GetData = () => {
    setIsLoading(true);
    jwt
      .get(`${Url.Show_Folder_Content}/${Item.id}`)
      .then((response) => {
        setIsLoading(false);
        console.log(response);
        setFolderData(response.data.children);
        setFolderDataIntial(response.data.children);
        setFiles(response.data.folder.files);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const toggelVisible = () => {
    setIsVisible1(!isVisible1);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const form_data = new FormData();

    form_data.append("folder_id", Item.id);

    for (let i = 0; i < newFiles.length; i++) {
      form_data.append(`file[${i}]`, newFiles[i]);
    }

    for (var key of form_data.entries()) {
      console.log(key[0] + ", " + key[1]);
    }

    let headers = {
      "system-id": global.systemID,
      "content-type": "multipart/form-data",
    };

    jwt
      .postfile(`${Url.Upload_Files}?folder_id=${Item.id}`, form_data, {
        headers,
      })
      .then((res) => {
        GetData();
        setIsVisible(!isVisible);
        setOpenDial(!openDial);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          Files
        </Text>
        <Ionicons
          name="menu-outline"
          size={30}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 20.0,
            // fontWeight: 300
          }}
          onPress={() => navigation.navigation.toggleDrawer()}
        />
      </View>
    );
  };

  const handleDelete = (id) => {
    jwt
      .del(`${Url.Delete_File}/${id}`)
      .then((response) => {
        setDeleteShow(!deleteShow);
        GetData();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const renderItem = ({ item, index }) => (
    <View
      style={{
        backgroundColor: "#fff",
        margin: 10,
        marginTop: 15,
        padding: 15,
        borderRadius: 3,
      }}
    >
      <Feather
        name="trash-2"
        size={18}
        color="red"
        style={{ position: "absolute", top: 10, right: 10, zIndex: 99999 }}
        onPress={() => {
          setSelectedItem(item);
          setDeleteShow(!deleteShow);
        }}
      />
      <a
        target={"_blank"}
        href={`https://dev.my.ronove.io${item.original_url}`}
      >
        {item.mime_type === "application/pdf" ? (
          <View
            style={{
              width: 270,
              height: 250,
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              source={require("../../assets/images/PDF_file_icon.png")}
              style={{ width: 140, height: 150 }}
              resizeMode="stretch"
            />
          </View>
        ) : item.mime_type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
          <View
            style={{
              width: 270,
              height: 250,
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              source={require("../../assets/images/docx_icon.png")}
              style={{ width: 140, height: 150 }}
              resizeMode="stretch"
            />
          </View>
        ) : item.mime_type === "text/plain" ? (
          <View
            style={{
              width: 270,
              height: 250,
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              source={require("../../assets/images/text-logo.png")}
              style={{ width: 140, height: 150 }}
              resizeMode="stretch"
            />
          </View>
        ) : (
          <Image
            source={{ uri: `https://dev.my.ronove.io${item.original_url}` }}
            style={{ width: 270, height: 250 }}
            resizeMode="stretch"
          />
        )}
      </a>
    </View>
  );

  const pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: "*/*",
      copyToCacheDirectory: true,
    }).then((response) => {
      console.log(response);
      setFilePicked(response);
      setFilePickedPath(response.uri);
      setNewFiles([...newFiles, response.file]);
    });
  };

  return (
    <View style={styles.container}>
      {header()}

      <View
        style={{
          flex: 1,
          backgroundColor: "#F2F3F4",
          padding: Sizes.fixPadding * 2,
          paddingTop: 0,
        }}
      >
        {/* <ScrollView style={{ flex: 1 }}> */}
        <FlatList
          showsVerticalScrollIndicator={false}
          nestedScrollEnabled
          data={files}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
          numColumns={windowWidth > 992 ? 4 : 2}
        />
      </View>
      {isLoding && <Loading />}
      <Overlay
        visible={isVisible}
        overlayStyle={{
          width: "80%",
          backgroundColor: "#FFFFFF",
          borderRadius: 8,
          paddingTop: 15,
          paddingBottom: 20,
          padding: Sizes.fixPadding * 2,
        }}
        onBackdropPress={() => setIsVisible(!isVisible)}
      >
        {/* <View style={{ alignItems: "center", marginTop: 3, marginBottom: 10 }}>
          <Text style={{ fontSize: 18 }}>New Folder</Text>
        </View>
        <Controller
          name="name"
          control={control}
          rules={{
            required: { value: true, message: "File is required" },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              label="File"
              type="file"
              onChangeText={(value) => onChange(value)}
              value={value}
              error={errors.name}
              errorText={errors?.name?.message}
            />
          )}
          defaultValue=""
        /> */}

        <View
          style={{
            alignSelf: "center",
            marginTop: Sizes.fixPadding * 1.0,
            marginBottom: Sizes.fixPadding + 5.0,
            alignItems: "center",
          }}
        >
          <Avatar
            size={150}
            // rounded
            source={
              filePickedPath == null
                ? require("../../assets/images/dummy-image.jpg")
                : { uri: filePickedPath }
            }
            // title="Bj"
            containerStyle={{ backgroundColor: "grey" }}
          >
            <Avatar.Accessory
              size={33}
              onPress={() => {
                pickDocument();
              }}
            />
          </Avatar>
        </View>

        <Button
          title="Upload"
          type="solid"
          //   loading={isLoding}
          onPress={handleSubmit(onSubmit)}
          buttonStyle={{
            padding: Sizes.fixPadding,
            width: "100%",
            backgroundColor: Colors.primaryColor,
            borderRadius: 22,
          }}
          containerStyle={{
            width: "100%",
            // marginHorizontal: 50,
            marginVertical: 20,
          }}
          titleStyle={{
            fontSize: 14,
            fontWeight: "100",
            color: Colors.whiteColor,
          }}
          icon={
            <Ionicons
              name="add"
              size={24}
              color="#ffffff"
              style={{ marginRight: "2%" }}
            />
          }
        />
      </Overlay>

      <SpeedDial
        isOpen={openDial}
        icon={{ name: "add", color: "#fff" }}
        openIcon={{ name: "close", color: "#fff" }}
        onOpen={() => setOpenDial(!openDial)}
        onClose={() => setOpenDial(!openDial)}
        style={{ zIndex: 2 }}
        buttonStyle={{ backgroundColor: Colors.primaryColor }}
      >
        <SpeedDial.Action
          icon={{ name: "add", color: "#fff" }}
          title="Upload File"
          onPress={() => {
            setIsVisible(!isVisible);
          }}
          buttonStyle={{ backgroundColor: Colors.primaryColor }}
        />
      </SpeedDial>

      <Dialog isVisible={deleteShow}>
        <Dialog.Title title="Delete File!" />
        <Text>Are you sure ? this action will not be revert.</Text>

        <Dialog.Actions>
          <Dialog.Button
            title="CONFIRM"
            onPress={() => {
              handleDelete(selectedItem.id);
            }}
          />
          <Dialog.Button
            title="CANCEL"
            onPress={() => {
              setDeleteShow(!deleteShow);
            }}
          />
        </Dialog.Actions>
      </Dialog>

      <BottomSheet modalProps={{}} isVisible={showBottomSheet}>
        <ListItem>
          <ListItem.Content>
            <TouchableOpacity
              style={{ flexDirection: "row", width: "100%" }}
              onPress={() => openCamera()}
            >
              <MaterialIcons
                name="camera"
                size={26}
                color={Colors.primaryColor}
              />

              <Text
                style={{
                  paddingLeft: 5,
                  fontSize: 16,
                  paddingTop: 2,
                }}
              >
                Take a photo
              </Text>
            </TouchableOpacity>
          </ListItem.Content>
        </ListItem>

        <ListItem style={{ borderBottomWidth: 1, borderBottomColor: "#CCC" }}>
          <ListItem.Content>
            <TouchableOpacity
              style={{ flexDirection: "row", width: "100%" }}
              onPress={() => showImagePicker()}
            >
              <MaterialIcons
                name="photo-library"
                size={26}
                color={Colors.primaryColor}
              />

              <Text
                style={{
                  paddingLeft: 5,
                  fontSize: 16,
                  paddingTop: 2,
                }}
              >
                Choose from your photos
              </Text>
            </TouchableOpacity>
          </ListItem.Content>
        </ListItem>

        <ListItem>
          <ListItem.Content>
            <TouchableOpacity
              style={{ flexDirection: "row", width: "100%" }}
              onPress={() => {
                SetShowBottomSheet(false);
                setOpenDial(!openDial);
              }}
            >
              <Entypo name="cross" size={26} color={Colors.primaryColor} />

              <Text
                style={{
                  paddingLeft: 5,
                  fontSize: 16,
                  paddingTop: 2,
                }}
              >
                Cancel
              </Text>
            </TouchableOpacity>
          </ListItem.Content>
        </ListItem>
      </BottomSheet>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: Colors.primaryColor,
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 15,
    marginTop: 40,
  },
  dropdown2BtnStyle: {
    width: "100%",
    height: 40,
    backgroundColor: "#FFF",
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#CCCCCC",
    marginBottom: 15,
  },
  dropdown2BtnTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 15,
  },
  dropdown2DropdownStyle: { backgroundColor: "#ddd" },
  dropdown2RowStyle: { backgroundColor: "#ddd", borderBottomColor: "#C5C5C5" },
  dropdown2RowTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 16,
  },
});
export default FolderContent;
