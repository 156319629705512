import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  FlatList,
  Platform,
  TouchableOpacity,
  Dimensions
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  Button,
  SpeedDial,
  ListItem,
  Overlay,
  Dialog,
  SearchBar,
} from "react-native-elements";
import {
  Feather,
  Ionicons,
  Entypo,
  MaterialIcons,
  FontAwesome5,
} from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import { useForm, Controller } from "react-hook-form";
import Input from "../Utils/Input";
import SelectDropdown from "react-native-select-dropdown";
import Loading from "../Utils/Loading";

import moment from "moment";
import CustomDatePicker from "../../components/datePicker/datePicker";
import CustomPicker from "../../components/Picker/Picker";

const windowWidth = Dimensions.get("window").width;

const LeaveRequest = (navigation) => {
  const [typeData, setTypeData] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [isLoding, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [date, setDate] = useState(new Date());
  const [date1, setDate1] = useState(new Date());
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Its Error");
  const [showSuccess, setShowScuccess] = useState(false);

  useEffect(() => {
    GetTypeData();
  }, []);

  const GetTypeData = () => {
    setIsLoading(true);
    jwt
      .get(`${Url.GET_LEAVE_TYPES}/${global.systemID}`)
      .then((response) => {
        // console.log(response)
        setIsLoading(false);
        // setTypeData(response.data.leave_types);
        const newList = [];
        response.data.leave_types.map((item) => newList.push(
          {value:item.id,
            label:item.name,
          ...item}));
        setTypeData(newList);
      })
      .catch((err) => {
        // console.log(err)
        setIsLoading(false);
      });
  };

  const toggelVisible = () => {
    setIsVisible(!isVisible);
  };

  const handleSelectedType = (val, index) => {
    setSelectedType(val);
  };

  const onChangeDateFrom = (event, selectedDate) => {
    const currentDate = selectedDate;
    setShow1(true);
    setDateFrom(currentDate);
    setDate(currentDate)
  };

  const onChangeDateTo = (event, selectedDate) => {
    const currentDate = selectedDate;
    setShow2(true);
    setDateTo(currentDate);
    setDate1(currentDate)
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const newData = {
      user_id: global.userData.id,
      leave_type_id: selectedType,
      start_date: dateFrom,
      end_date: dateTo,
      status: data.status,
    };

    if (selectedType === null) {
      setErrorMessage("Please Select Leave Type");
      setShow(true);
    } else if (dateFrom === null) {
      setErrorMessage("Please Select Date From");
      setShow(true);
    } else if (dateTo === null) {
      setErrorMessage("Please Select Date To");
      setShow(true);
    } else {
      setIsLoading(true);
      jwt
        .post(Url.LEAVE_REQUEST, newData)
        .then((response) => {
          setIsLoading(false);
          setShowScuccess(!showSuccess);
          reset();
        
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
    // } else {
    //   setSystemError(true);
    // }
  };

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
         Leave Request
        </Text>
        <Ionicons
          name="menu-outline"
          size={30}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 20.0,
            // fontWeight: 300
          }}
          onPress={() => navigation.navigation.toggleDrawer()}
        />
      </View>
    );
  };

  return (
    <View style={styles.container}>
      {header()}

      {isLoding && <Loading />}

      <View
        style={{
          flex: 1,
          padding: Sizes.fixPadding * 2,
          paddingTop: 20,
          backgroundColor:"#F2F3F4"
        }}
      >
        <View
          style={{
            flex: 1,
            padding: Sizes.fixPadding * 2,
            backgroundColor: "#fff",
            marginBottom: 20,
            borderRadius: 5,
          }}
        >

<View style={{flexDirection:'row', marginBottom:15}}>
                   <View style={styles.child1}>
                <Text style={{ ...styles.label}}>{"Leave Type"}</Text>
               </View>
               <View style={styles.child2}>
                <CustomPicker
                  data={typeData}
                  selectedValue={selectedType}
                  handleSelectedValue={handleSelectedType}
                  defaultLabel={"Select Leave Type"}
                  style={{}}
                />
</View>
</View>

<View style={{ flexDirection: "row", marginBottom: 15 }}>
            <View style={styles.child1}>
              <Text style={styles.label}>Duration From</Text>
            </View>
            <View style={{ ...styles.child2 }}>
              <CustomDatePicker
                date={onChangeDateFrom}
                defaultDate={dateFrom}
                show={show1}
                setShow={setShow1}
              />
            </View>
          </View>

          <View style={{ flexDirection: "row", marginBottom: 15 }}>
            <View style={styles.child1}>
              <Text style={styles.label}>Duration To</Text>
            </View>
            <View style={{ ...styles.child2 }}>
              <CustomDatePicker
                date={onChangeDateTo}
                defaultDate={dateTo}
                show={show2}
                setShow={setShow2}
              />
            </View>
          </View>
        
          <View style={{ flexDirection: "row", marginBottom: 15 }}>
            <View style={styles.child1}>
              <Text style={styles.label}>Satus</Text>
            </View>
            <View style={{ ...styles.child2 }}>
          <Controller
            name="status"
            control={control}
            rules={{
              required: { value: true, message: "status is required" },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
               
                type="text"
                onChangeText={(value) => onChange(value)}
                value={value}
                error={errors.status}
                errorText={errors?.status?.message}
              />
            )}
            defaultValue=""
          />
          </View>
          </View>

          <Button
            title="Submit Request"
            type="solid"
            //   loading={isLoding}
            onPress={handleSubmit(onSubmit)}
            buttonStyle={{
              padding: Sizes.fixPadding,
              width: "100%",
              backgroundColor: Colors.primaryColor,
              borderRadius: 22,
              marginBottom: 20,
            }}
            containerStyle={{
              width: "100%",
              // marginHorizontal: 50,
              marginVertical: 20,
            }}
            titleStyle={{
              fontSize: 14,
              fontWeight: "100",
              color: Colors.whiteColor,
            }}
            icon={
              <Ionicons
                name="add-circle-sharp"
                size={24}
                color="#ffffff"
                style={{ marginRight: "2%" }}
              />
            }
          />
        </View>
      </View>
    
      <Dialog isVisible={show}>
        <Dialog.Title title="Error!" />
        <Text>{errorMessage}</Text>

        <Dialog.Actions>
          <Dialog.Button
            title="Ok"
            onPress={() => {
              setShow(!show);
            }}
          />
        </Dialog.Actions>
      </Dialog>

      <Dialog isVisible={showSuccess}>
        <Dialog.Title title="Congratulations!" />
        <Text>{"Your request Submit scuccessfully"}</Text>

        <Dialog.Actions>
          <Dialog.Button
            title="Ok"
            onPress={() => {
              setShowScuccess(!showSuccess);
            }}
          />
        </Dialog.Actions>
      </Dialog>
      {isLoding && <Loading />}
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor:Colors.primaryColor
  },
  headerWrapStyle: {
    // height: 55.0,
    backgroundColor: Colors.primaryColor,
    // elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom:15,
    marginTop:40
  },
  label: {
    color: "black",
    marginLeft: 0,
    marginBottom: 3,
    fontSize: windowWidth > 600 ? 18 : 16,
    fontWeight: "500",
    marginTop: windowWidth > 600 ? 0 : 12,
  },
  child1: {
    width: windowWidth > 600 ? "15%" : "30%",
  },
  child2: {
    width: windowWidth > 600 ? "85%" : "70%",
  }
});
export default LeaveRequest;
