import React, { useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  FlatList,
} from "react-native";
import { Colors, Sizes, HeaderStyle } from "../../constants/style";
import {
  Button,
  Overlay,
  ListItem,
  SpeedDial,
  Avatar,
  Dialog,
  SearchBar,
} from "react-native-elements";

import {
  Ionicons,
  Entypo,
  MaterialIcons,
  MaterialCommunityIcons,
  FontAwesome,
  Octicons,
  FontAwesome5,
} from "@expo/vector-icons";
import jwt from "../../helpers/jwt";
import * as Url from "../../helpers/urlHelper";
import { useForm, Controller } from "react-hook-form";
import Loading from "../Utils/Loading";
import SectionedMultiSelect from "react-native-sectioned-multi-select";
import moment from "moment";
import { ModulesPermission } from "../../constants/modules";
import { authorizedForFlateList } from "../../constants/authorizedForFlateList";
import AuthorizedFor from "../../constants/authorizedFor";

const GroupUsers = (navigation) => {
  const [membersData, setMembersData] = useState([]);
  const [membersList, setMemberList] = useState([]);
  const [membersDataIntial, setMembersDataIntial] = useState([]);
  const [employeesData, setEmployeesData] = useState([]);
  const [employeesDataIntial, setEmployeesDataIntial] = useState([]);
  const [openDial, setOpenDial] = useState(false);
  const [isLoding, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [employeeRoles, setEmployeeRoles] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedGender, setSelectedGender] = useState(null);
  const [date, setDate] = useState(new Date());
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [dob, setDob] = useState(null);
  const [joiningDate, setJoiningDate] = useState(null);
  const [deleteShow, setDeleteShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Its Error");
  const [search, setSearch] = useState("");

  const gendersData = ["Male", "Female"];

  const [rolesList, setRolesList] = useState([]);

  const [isDeleteAble, setIsDeleteAble] = useState(false);
  const [isUpdateAble, setIsUpdateAble] = useState(false);

  const GroupId = navigation.route.params.GroupId;

  const ref = useRef(null);

  useEffect(() => {
    (async () => {
      GetEmployees();
      GetMembers();
    })();
  }, [navigation, navigation.route.params]);

  useEffect(() => {
    (async () => {
      (await authorizedForFlateList(ModulesPermission.Update_Employee)) &&
        setIsUpdateAble(true);
      (await authorizedForFlateList(ModulesPermission.Delete_Employee)) &&
        setIsDeleteAble(true);
    })();
  }, [employeesDataIntial.length]);

  const GetMembers = () => {
    setIsLoading(true);

    jwt
      .get(`${Url.GET_GROUP_MEMBERS}/${GroupId}`)
      .then((response) => {
        // console.log(response.data);
        setIsLoading(false);
        setMembersData(response.data.group_users);
        setMembersDataIntial(response.data.group_users);
        // setClientsData(response.data.clients);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetEmployees = () => {
    setIsLoading(true);

    jwt
      .get(`${Url.GET_ACTIVE_EMPLOYEES}/${global.systemID}`)
      .then((response) => {
        // console.log(response.data);
        setIsLoading(false);
        const newData = [];
        response.data.employee.map((item) => {
          newData.push({
            id: item.id,
            name: item.name,
          });
        });
        // setReasonsData(newData);
        setEmployeesData(newData);
        setEmployeesDataIntial(response.data.employee);
        // setClientsData(response.data.clients);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setIsLoading(true);
    let newRoles = [];
    selectedItems.map((item) =>
      employeesDataIntial
        .filter((obj) => obj.name === item)
        .map((rol) =>
          newRoles.push({
            user_id: rol.id,
            first_name: rol.first_name,
            last_name: rol.last_name,
          })
        )
    );

    const newData = {
      system_id: global.systemID,
      selected_users: newRoles,
    };

    if (selectedItem === null) {
      setErrorMessage("Please Select Users");
      setShow(true);
    } else {
      jwt
        .post(`${Url.ASSIGN_USER_GROUP}/${GroupId}`, newData)
        .then((response) => {
          setIsLoading(false);
          setIsVisible(!isVisible);
          GetMembers();
          setOpenDial(!openDial);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const header = () => {
    return (
      <View style={HeaderStyle}>
        <Text
          style={{ color: Colors.whiteColor, fontWeight: "500", fontSize: 20 }}
        >
          Group Members
        </Text>
        <MaterialIcons
          name="arrow-back"
          size={26}
          color={Colors.whiteColor}
          style={{
            position: "absolute",
            left: 15.0,
            fontWeight: 300,
          }}
          onPress={() => navigation.navigation.pop()}
        />
      </View>
    );
  };

  const handleSearch = (value) => {
    setSearch(value);
    if (value.length > 1) {
      const newData = membersData.filter(
        (e) =>
          e.name.toUpperCase().includes(value.toUpperCase()) ||
          e.first_name.toUpperCase().includes(value.toUpperCase()) ||
          e.last_name.toUpperCase().includes(value.toUpperCase()) ||
          e.employee.address.toUpperCase().includes(value.toUpperCase())
      );
      if (newData.length > 0) {
        setMembersData(newData);
      } else {
        setMembersData(membersDataIntial);
      }
    } else {
      setMembersData(membersDataIntial);
    }
  };

  const GetIntials = (nameString) => {
    const fullName = nameString.split(" ");
    const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
    return initials.toUpperCase();
  };

  const renderItem = ({ item, index }) => (
    <ListItem.Swipeable
      key={index}
      containerStyle={{
        marginTop: 15,
        zIndex: 1,
        borderRadius: 6,
      }}
    >
      <Avatar
        size={54}
        rounded
        // source={item.avatar != null && { uri: item.avatar }}
        title={GetIntials(`${item.name}`)}
        containerStyle={{ backgroundColor: "grey" }}
      ></Avatar>

      <ListItem.Content>
        <ListItem.Title style={{ marginBottom: 4 }}>{item.name}</ListItem.Title>
        <ListItem.Subtitle numberOfLines={1}>{item.email}</ListItem.Subtitle>
        <View
          style={{
            flexDirection: "row",

            paddingTop: 10,
            width: "100%",
          }}
        >
          <Text>{item.status}</Text>
        </View>
      </ListItem.Content>
    </ListItem.Swipeable>
  );

  const onSelectedItemsChange = (selectedItems) => {
    // this.setState({ selectedItems });
    setSelectedItems(selectedItems);
  };

  return (
    <View style={{ flex: 1 }}>
      {header()}
      <SearchBar
        placeholder="Type Here..."
        onChangeText={handleSearch}
        value={search}
        lightTheme
        containerStyle={{
          backgroundColor: "#eee",
          borderWidth: 0,
          elevation: 0,
          paddingLeft: Sizes.fixPadding * 2,
          paddingRight: Sizes.fixPadding * 2,
        }}
        // placeholderTextColor="#000"
        inputStyle={{
          color: "#000",
        }}
        inputContainerStyle={{
          backgroundColor: "#ccc",
        }}
      />

      <View style={{ flex: 1, padding: Sizes.fixPadding * 2, paddingTop: 0 }}>
        {/* <ScrollView style={{ flex: 1 }}> */}
        <FlatList
          showsVerticalScrollIndicator={false}
          nestedScrollEnabled
          data={membersData}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
        />
      </View>

      {isLoding && <Loading />}
      {AuthorizedFor(ModulesPermission.Assign_User_To_Group) && (
        <SpeedDial
          isOpen={openDial}
          icon={{ name: "edit", color: "#fff" }}
          openIcon={{ name: "close", color: "#fff" }}
          onOpen={() => setOpenDial(!openDial)}
          onClose={() => setOpenDial(!openDial)}
          style={{ zIndex: 2 }}
          buttonStyle={{ backgroundColor: Colors.primaryColor }}
        >
          <SpeedDial.Action
            icon={{ name: "add", color: "#fff" }}
            title="Assign Users"
            onPress={() => setIsVisible(!isVisible)}
            buttonStyle={{ backgroundColor: Colors.primaryColor }}
          />
        </SpeedDial>
      )}
      <Overlay
        visible={isVisible}
        overlayStyle={{
          width: "80%",
          backgroundColor: "#FFFFFF",
          borderRadius: 8,
          paddingTop: 15,
          paddingBottom: 20,
          padding: Sizes.fixPadding * 2,
          //   marginTop: 40,
          //   marginBottom: 50,
          height: 500,
        }}
        onBackdropPress={() => setIsVisible(!isVisible)}
      >
        <View style={{ alignItems: "center", marginTop: 3, marginBottom: 10 }}>
          <Text style={{ fontSize: 18 }}>Assign Users</Text>
        </View>
        <ScrollView
        //   style={{
        //     flex: 1,
        //   }}
        >
          <SectionedMultiSelect
            items={employeesData}
            ref={ref}
            IconRenderer={MaterialIcons}
            uniqueKey="id"
            subKey="children"
            selectText="Select Reason..."
            showDropDowns={true}
            //   readOnlyHeadings={true}
            onSelectedItemsChange={onSelectedItemsChange}
            selectedItems={selectedItems}
            searchPlaceholderText="Search reasons"
            //   text={Colors.primaryColor}
            styles={{
              button: {
                backgroundColor: Colors.primaryColor,
                selectedItemText: Colors.primaryColor,
              },
            }}
            theme={{
              Colors: {
                selectToggleTextColor: "#053075",
                text: "#053075",
                selectedItemText: Colors.primaryColor,
              },
            }}
            //   buttonStyle={{ backgroundColor: Colors.primaryColor }}
          />

          <Button
            title="Assign User"
            type="solid"
            loading={isLoding}
            onPress={handleSubmit(onSubmit)}
            buttonStyle={{
              padding: Sizes.fixPadding,
              width: "100%",
              backgroundColor: Colors.primaryColor,
              borderRadius: 22,
            }}
            containerStyle={{
              width: "100%",
              // marginHorizontal: 50,
              marginVertical: 20,
            }}
            titleStyle={{
              fontSize: 14,
              fontWeight: "100",
              color: Colors.whiteColor,
            }}
            icon={
              <Ionicons
                name="add-circle-sharp"
                size={24}
                color="#ffffff"
                style={{ marginRight: "2%" }}
              />
            }
          />
        </ScrollView>
      </Overlay>

      <Dialog isVisible={show}>
        <Dialog.Title title="Error!" />
        <Text>{errorMessage}</Text>

        <Dialog.Actions>
          <Dialog.Button
            title="Ok"
            onPress={() => {
              setShow(!show);
            }}
          />
        </Dialog.Actions>
      </Dialog>
    </View>
  );
};
const styles = StyleSheet.create({
  headerWrapStyle: {
    height: 55.0,
    backgroundColor: Colors.primaryColor,
    elevation: 2.0,
    alignItems: "center",
    justifyContent: "center",
  },
  dropdown2BtnStyle: {
    width: "100%",
    height: 40,
    backgroundColor: "#FFF",
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#CCCCCC",
    marginBottom: 15,
  },
  dropdown2BtnTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 15,
  },
  dropdown2DropdownStyle: { backgroundColor: "#ddd" },
  dropdown2RowStyle: { backgroundColor: "#ddd", borderBottomColor: "#C5C5C5" },
  dropdown2RowTxtStyle: {
    color: "#444",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 16,
  },
});
export default GroupUsers;
